import constants from '../redux/constants/auth';
import store from "../redux/store";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
const https = require('https');

const axios = require('axios').default.create({
    httpsAgent: new https.Agent({
        rejectUnauthorized: false,
        requestCert: false,
        agent: false
    })
});

const TOKEN_EXPIRED_ERROR = "The access token expired";

class CommonBDL {

    getConfig = (header, isDownload) => {
        let config;
        const token = localStorage.getItem(constants.TOKEN);
        if (header && token && token.trim()) {
            config = {
                headers: {
                    ...header,
                    Authorization: `Bearer ${token}`
                }
            };
        } else if(!header && token && token.trim()){
            config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
        }
        if (isDownload) {
            config.responseType = 'blob'
        }
        return config;
    };

    dispatchTokenExpired = ()=>{
        store.dispatch({
            type: constants.EXPIRES_DATE,
            payload: true,
        })
    }

    handleException = (exception, error) => {
        if (exception.response && exception.response.data && exception.response.data.message === TOKEN_EXPIRED_ERROR) {
            this.dispatchTokenExpired();
            error(exception.response.data.message);
        } else {
            error(exception);
        }
    }

    post = (url, data, header) =>
        new Promise(async(success, error) => {
            const response = await axios
                .post(url, data, this.getConfig(header))
                .catch(exception => this.handleException(exception, error))
            response && success(response)
        });

    put = (url, data, header) =>
        new Promise(async(success, error) => {
            const response = await axios
                .put(url, data, this.getConfig(header))
                .catch(exception => this.handleException(exception, error))
            response && success(response)
        });

    get = (url, header) =>
        new Promise(async(success, error) => {
            const response = await axios
                .get(url, this.getConfig(header))
                .catch(exception => this.handleException(exception, error))
            response && success(response)
        });

    delete = (url, header) =>
        new Promise(async(success, error) => {
            const response = await axios
                .delete(url, this.getConfig(header))
                .catch(exception => this.handleException(exception, error))
            response && success(response)
        });
        

    download = (url, header) =>
        new Promise(async(success, error) => {
            const response = await axios
                .get(url, this.getConfig(header, true))
                .catch(exception => this.handleException(exception, error))
            response && success(response)
        });

    getAxiosInstance = () => axios;
}

export default CommonBDL;

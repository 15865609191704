import { sellerSA } from "../../applicatif/seller.sa";
import constants from '../constants/seller';

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};

export const fetchAll = (query) => dispatch => {
    showLoading(dispatch, constants.FETCH_ALL_SELLERS)
    const request = sellerSA.fetchAll(query);

    const response = dispatch({
        type: constants.FETCH_ALL_SELLERS,
        payload: request
    });
    response.then(() => {
        dispatch({
            type: constants.SELLER_QUERY,
            payload: query
        })
    })
};

export const fetchAllPartners = (query) => dispatch => {
    showLoading(dispatch, constants.FETCH_ALL_PARTNER_SELLERS)
    const request = sellerSA.fetchAllPartnerSellers(query);

    const response = dispatch({
        type: constants.FETCH_ALL_PARTNER_SELLERS,
        payload: request,
    });
    response.then(() => {
        dispatch({
            type: constants.PARTNER_QUERY,
            payload: query
        })
    })
};

export const openPartnershipRequestModal = (data, callback) => {
    return {
        type: constants.PARTNER_OPEN_MODAL,
        payload: {data, callback},
    };
};

export const closePartnershipRequestModal = () => {
    return {
        type: constants.PARTNER_CLOSE_MODAL,
        payload: null,
    };
};

export const fetchPartnershipRequest = () =>  dispatch => {
    showLoading(dispatch, constants.FETCH_PARTNERSHIP_REQUESTS)
    const request = sellerSA.fetchPartnershipRequest();

    dispatch({
        type: constants.FETCH_PARTNERSHIP_REQUESTS,
        payload: request,
    });
};

export const sendResponse = (data) =>  dispatch => {
    showLoading(dispatch, constants.SEND_RESPONSE)
    const request = sellerSA.sendResponse(data);

    dispatch({
        type: constants.SEND_RESPONSE,
        payload: request,
    });
};

export const getPartnersFilters = () =>  dispatch => {
    showLoading(dispatch, constants.GET_PARTNERS_FILTERS)
    const request = sellerSA.getPartnersFilters();

    dispatch({
        type: constants.GET_PARTNERS_FILTERS,
        payload: request,
    });
};


import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {uploadKbisPhotocopy} from "../../../services/redux/actions/hotel";
import FileUpload from "../../components/file-upload/file-upload";
import PopupHandler from '../../../services/error-handlers/popupHandler';


class ImportKPhotocopy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.hotel;
        const props = this.props.hotel;
        if (!prev) {
            return;
        }

        if (prev.uploadKbis && props.uploadKbis && prev.uploadKbis.loading === true && props.uploadKbis.loading === false) {
            if (props.uploadKbis.error) {
                this.setState({uploadKbisSuccess: false});
            }else{
                this.setState({uploadKbisSuccess: true});
                this.props.history.push("/gestion_administration")
            }
        }
    }

    uploadKbisPhotocopy = (file) =>{
        this.props.uploadKbisPhotocopy(file);
    }
    render () {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>
                            <Link to={"/gestion_administration"}>
                                <span className="back">
                                    <i className="material-icons icon-back">arrow_back</i>
                                </span>
                            </Link>
                            {this.props.title || t("Importer fichier")}
                        </h1>
                    </div>
                </div>
                <div>
                    {this.state.uploadKbisSuccess === false && PopupHandler.showError(t("Une erreur est survenue lors de l'import du fichier."))}
                    {this.state.uploadKbisSuccess === true && PopupHandler.showSuccess(t("Le fichier a bien été importé."))}
                </div>
                <FileUpload 
                    title={t("Importer la photocopie du Kbis")}
                    onSubmit = {this.uploadKbisPhotocopy}
                />
            </div>
        )
    }
    
}
const mapStateToProps = (state) => ({hotel: state.hotel})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({uploadKbisPhotocopy}, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(ImportKPhotocopy)));
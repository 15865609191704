import CommonBDL from './common.bdl';
import constants from '../redux/constants/hotel';
import config from "../../config";


class HotelBDL extends CommonBDL {

    fetchInfos = () => {    
        return this.get(`${constants.FETCH_URL}?param=${config.codeHotel}`);
    };
    
    updateInfos = (data) => {   
        if (data) {
            data["code_hotel"] = config.codeHotel
        }   
        return this.post(constants.UPDATE_URL, data);
    };
    
    updateCarousel = (data) => {    
        return this.post(constants.UPDATE_CAROUSEL_URL, data);
    };

    getCarousel = () => {
        return this.get(`${constants.GET_CAROUSEL_URL}`);
    };

    getHotelUser =() => {    
        return this.get(constants.GET_HOTEL_USER_URL);
    };

    saveUserHotel =(data) => {    
        return this.post(constants.SAVE_HOTEL_USER_URL, data);
    };

    getProfilProgression = () => {
        return this.get(`${constants.GET_PROFIL_PROGRESSION_URL}?param=${config.codeHotel}`);
    };

    saveHeadOfficeInfos = (data) => {
        return this.post(constants.SAVE_HEAD_OFFICE_INFOS_URL,data);
    };

    saveContactInfos = (data) => {
        return this.post(constants.SAVE_CONTACT_INFOS_URL,data);
    };

    saveLegalInfos = (data) => {
        return this.post(constants.SAVE_LEGAL_INFOS_URL,data);
    };

    saveAccountInfos = (data) => {
        return this.post(constants.SAVE_ACCOUNT_INFOS_URL,data);
    };

    uploadIdentityPaper = (file) => {
        const data = new FormData();
        data.append('file', file);    
        return this.post(constants.UPLOAD_IDENTITY_PAPER_URL, data);
    };

    uploadKbisPhotocopy = (file) => {
        const data = new FormData();
        data.append('file', file);    
        return this.post(constants.UPLOAD_KBIS_PHOTOCOPY_URL, data);
    };

    uploadLogo = (data) => {
        return this.post(constants.SAVE_LOGO_URL, data);
    };

    getLogo = () => {
        return this.get(`${constants.GET_LOGO_URL}`);
    };

    getStripeStatus = (infoId) => {
        return this.get(`${constants.GET_STRIPE_STATUS_URL}?code_hotel=${config.codeHotel}`);
    };

    saveNotificationStatus = (status) => {
        return this.post(constants.SAVE_SMS_NOTIFICATION_STATUS_URL, status);
    };

    getActivityZones = () => {
        return this.get(`${constants.GET_ACTIVITY_ZONE_URL}`);
    };

    getDefaultCommision = () => {
        return this.get(`${constants.GET_DEFAULT_COMMISSION_URL}`);
    };

    getPricePerCuterly = () => {
        return this.get(`${constants.GET_PRICE_PER_CUTERLY_URL}`);
    };

    
    
}

export const hotelBDL = new HotelBDL();

import config from "../../../../src/config";

const baseURL = config.baseURL;

export default {
    FETCH_ALL_REPORTED_NOTICES_URL: `${baseURL}/rest/V1/getReportedReview`,
    FETCH_ALL_REPORTED_NOTICES: 'notice/FETCH_ALL_REPORTED_NOTICES',
    KEEP_NOTICE_URL: `${baseURL}/rest/V1/approveReportedReview`,
    KEEP_NOTICE: 'notice/KEEP_NOTICE',
    DELETE_NOTICE_URL: `${baseURL}/rest/V1/deleteReportedReview`,
    DELETE_NOTICE: 'notice/DELETE_NOTICE',
    GET_UNREAD_NOTICE_NB_URL: `${baseURL}/rest/V1/getCountUnseenReview`,
    GET_UNREAD_NOTICE_NB: 'notice/GET_UNREAD_NOTICE_NB',
    INITIALIZE_UNREAD_NOTICE_NB_URL: `${baseURL}/rest/V1/initializeUnreadNoticeNb`,
    INITIALIZE_UNREAD_NOTICE_NB: 'notice/INITIALIZE_UNREAD_NOTICE_NB',
    LOADING: 'notice/LOADING',
    LOADING_NB_NOTICE: 'notice/LOADING_NB_NOTICE',
};

import { clientBDL } from "../business-delegate/client.bdl";

class ClientSA {
    
    fetchCustomers = (page, nbPerPage, query) => {
        return clientBDL.fetchCustomers(page, nbPerPage, query);
    };
    
    resetPassword = (id) => {    
        return clientBDL.resetPassword(id);
    };
    
    exportCustomers = (customers) => {
        return clientBDL.exportCustomers(customers);
    }

    getCustomerDetails = (id) => {
        return clientBDL.getCustomerDetails(id);
    };
    
    saveCustomer = (data) => {
        return clientBDL.saveCustomer(data);
    };
}

export const clientSA = new ClientSA();

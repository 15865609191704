import CommonBDL from './common.bdl';
import constants from '../redux/constants/seller';
import config from "../../config";

class SellerBDL extends CommonBDL {
    fetchAll = (filter) => {
        const query = filter || {};
        const url = `${constants.FETCH_ALL_SELLERS_URL}?code_hotel=${config.codeHotel}&from_hotel=${config.codeHotel}&search=${query.name || ''}&type=${query.type || ''}&category=${query.category || ''}&page=${query.page || 1}&perPage=${query.perPage || 10}`;
        return this.get(url);
    };
    
    fetchAllPartnerSellers = (filter) => {
        const query = filter || {};
        const url = `${constants.FETCH_ALL_PARTNER_SELLERS_URL}?code_hotel=${config.codeHotel}&from_hotel=${config.codeHotel}&status=2&search=${query.name || ''}&type=${query.type || ''}&category=${query.category || ''}&page=${query.page || 1}&perPage=${query.perPage || 10}`;
        return this.get(url);
    };
    
    getSellerDetails = (query) => {
        let params = `?code_hotel=${config.codeHotel}`;
        if (query) {
            Object.keys(query).forEach(key=>{
                params =  `${params}${params === '' ? '?' : '&'}${key}=${query[key]}`;
            })
        }
        //const url = `${constants.DETAILS_SELLER_URL}?id=${id}&prestationPage=${prestationPage || ''}&avisPage=${avisPage || ''}`;
        const url = `${constants.DETAILS_SELLER_URL}${params}`;
        return this.get(url);
    };
    
    getSellerInfo = (id) => {
        const url = `${constants.INFO_SELLER_URL}?id=${id}`;
        return this.get(url);
    };
    
    sendPartnershipRequest = (seller) => {
        const data = seller ? {...seller} : {};
        data.code_hotel = config.codeHotel;
        return this.post(constants.SEND_PARTNERSHIP_REQUEST_URL, data);
    };
    
    endPartnership = (id) => {
        const url = `${constants.END_PARTNERSHIP_URL}?id=${id}`;
        return this.delete(url);
    };
    
    sendInvitation = (invitation) => {
        const data = {...invitation} || {};
        data.hotel = config.codeHotel;
        return this.post(constants.SEND_INVITATION_URL, data);
    };
    
    fetchPartnershipRequest = () => {
        return this.get(constants.FETCH_PARTNERSHIP_REQUESTS_URL);
    };
    
    sendResponse = (data) => {
        return this.post(constants.SEND_RESPONSE_URL, data);
    };

    getPartnersFilters = () => {
        return this.get(constants.GET_PARTNERS_FILTERS_URL);
    };
}

export const  sellerBDL = new SellerBDL();

import React from 'react';
import {translate} from 'react-i18next';
import {connect} from 'react-redux';


class Footer extends React.Component {
   
    render() {
        const { t } = this.props;
        const hotelName = (this.props.hotel && 
            this.props.hotel.hotelInfo && 
            this.props.hotel.hotelInfo.data && 
            this.props.hotel.hotelInfo.data.InfosHotel && 
            this.props.hotel.hotelInfo.data.InfosHotel.length > 0 &&
            this.props.hotel.hotelInfo.data.InfosHotel[0].nomhotel) ||
            ""
        const logo = (this.props.hotel && this.props.hotel.logoImage && this.props.hotel.logoImage.data && this.props.hotel.logoImage.data.data) || "";
        return (
            <div className="footer">
            <div className="wrapper clr">
                <div className="row">
                    <div className="col col-3 logo">
                        {
                            logo &&
                            <a href="/" className="img-logo">
                                <img src={logo} alt={t("Image logo")} className="imgLogo" />
                            </a>
                        }
                        {/* <span>{hotelName}</span> */}
                    </div>
                    <div className="col col-2">
                        <ul className="menu-footer">
                            <li>
                                <a href="mailto:admin@myconciergehotel.com">
                                    {t("Contact")}
                                </a>
                            </li>
                            <li>
                                <a href={t("FAQ url")}
                                    target = '_blank'
                                    rel="noopener noreferrer"
                                >
                                    {t("FAQ")}
                                </a>
                            </li>
                            <li>
                                <a href={t("CGU url")}
                                    target = '_blank'
                                    rel="noopener noreferrer"
                                >
                                    {t("CGU")}
                                </a>
                            </li>
                            <li>
                                <a href={t("CGV url")}
                                    target = '_blank'
                                    rel="noopener noreferrer"
                                >
                                    {t("CGV")}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col col-4">
                        <ul className="menu-footer">
                            <li>
                                <a href={t("Mentions Légales url")} 
                                    target = '_blank'
                                    rel="noopener noreferrer"
                                >
                                    {t("Mentions légales")}
                                </a>
                            </li>
                            <li>
                                <a href={t("Politique de Confidentialité url")}
                                    target = '_blank'
                                    rel="noopener noreferrer"
                                >
                                    {t("Politiques de confidentialité")}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {hotel: state.hotel};
};


export default translate('translation')(connect(mapStateToProps, null)(Footer));
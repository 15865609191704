import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ToggleDisplay from 'react-toggle-display';
import StarRatings from 'react-star-ratings';
import {translate} from 'react-i18next';
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import { sellerSA } from "../../../services/applicatif/seller.sa";
import Pagination from "../../components/pagination/pagination.component";
import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";

class SellerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            id: this.props.match.params.id,
            seller: null,
            prestations: [],
            avis: [],
            show: [],
            pager: {
                prestations: {
                    currentPage: 1,
                    nbPages: 1,
                    perPage: 5
                },
                avis: {
                    currentPage: 1,
                    nbPages: 1,
                    perPage: 5
                }
            },
            loading: true,
            from: props.location.from,
            weekDays: [
                "",
                props.t("Lundi"),
                props.t("Mardi"),
                props.t("Mercredi"),
                props.t("Jeudi"),
                props.t("Vendredi"),
                props.t("Samedi"),
                props.t("Dimanche"),
              ]
        };
    }

    handlePrestationsPaginationChange = (page) => {
        this.setState(oldstate =>({
            pager: {
                ...oldstate.pager, 
                prestations:{
                    ...oldstate.pager.prestations,
                    currentPage: page
                }
            }
        }), ()=>{
            this.getSellerDetails();
        });
        
    };

    handleAvisPaginationChange = (page) => {
        this.setState(oldstate =>({
            pager: {...oldstate.pager, avis:{...oldstate.pager.avis,currentPage: page}}
        }), ()=>{
            this.getSellerDetails();
        });
    };

    componentDidMount() {
        this.getSellerDetails();
    }
    handleClick =(event)=>{
        const id = event.target.dataset.id;
        const show = [...this.state.show]
        if (!show.includes(id)) {
            show.push(id);
        } else {
            show.splice(show.indexOf(id), 1);
        }
        this.setState({
            show
        });
    }

    getSellerDetails = ()=> {
        const {t}=this.props;
        this.props.showLoading();
        const query = {
            id: this.state.id, 
            reviewPage: this.state.pager.avis.currentPage, 
            reviewItemPerPage: this.state.pager.avis.perPage,
            prestationPage: this.state.pager.prestations.currentPage,
            prestationItemPerPage: this.state.pager.prestations.perPage
        }
        sellerSA.getSellerDetails(query)
            .then(result =>{
                const response = result.data || {};
                this.setState({
                    seller: response.seller,
                    prestations: (response.prestations && response.prestations.list) || [],
                    avis: (response.avis && response.avis.list) || [],
                    nbAvis: (response.avis && response.avis.total) || 0,
                    pager: {
                        prestations: {
                            currentPage: (response.prestations && parseInt(response.prestations.page)) || 1,
                            nbPages: (response.prestations && parseInt(response.prestations.nbPage)) || 1,
                            perPage: 5,
                        },
                        avis: {
                            currentPage: (response.avis && parseInt(response.avis.page)) || 1,
                            nbPages: (response.avis && parseInt(response.avis.nbPage)) || 1,
                            perPage: 5,
                        },
                    },
                    error: false,
                });
                this.props.hideLoading();
            })
            .catch(exception =>{
                this.props.hideLoading();
                const response = (exception.response && exception.response.data && exception.response.data.messages && exception.response.data.messages.error && exception.response.data.messages.error[0]) || {} ;
                if (response.code === 500 &&  response.message === 'Not Found Error.'){ // :-D
                    this.props.history.push({pathname: "/prestataires", tab: this.state.from, from: "details"});
                    showPopupExtra(<PopupError title={t("Erreur")} message={t("Prestataire inexistant.")}/>)
                }else{
                    this.setState({
                        error: true,
                    });
                }
            })
    }

    renderSellerInfo = () => {
        const seller = this.state.seller || {};
        const {t} = this.props;
        const rating = seller.rating && seller.nbRating ? seller.nbRating / seller.rating / 20 : 0
        return (
            <div className="info-etablissement">
                <div className="banner-client" style={{backgroundImage: `url(${seller.image})`}}>
                    {/* <img src={seller.image} alt=""/> */}
                </div>
                <div>
                    <h2 className="name-etablissement">{seller.name}</h2>
                    <div className="ratings">
                    <StarRatings
                        rating={rating}
                        starRatedColor="rgb(255, 181, 0)"
                        starEmptyColor="rgb(255, 237, 191)"
                        starDimension="17px"
                        starSpacing="2px"
                        numberOfStars={5}
                    /> <span className="nbr-avis">{this.state.nbAvis || 0} {t("avis")}</span></div>
                    <div className="adress-etablissement">
                        {(seller.address && `${seller.address.street || ''} ${seller.address.postalCode || ''} ${seller.address.city || ''}`) || ""}
                        <span className="cordonne"><span className="icon"><i className="material-icons arrow-room">room</i></span> {seller.distance < 0 ? t("Not specified") : `${t("à")} ${parseFloat(seller.distance || 0).toFixed(2)} km`}</span>
                    </div>
                </div>
                <div className="heure-etablissement">
                    <p className="title-heure">{t("Horaires d’ouverture")}</p>
                    {seller.hours && seller.hours.map((hour, index)=>(
                        <React.Fragment>
                            {index !== 0 && <br/>}
                            {`${t('Du')} ${(hour.dayStart && this.state.weekDays[hour.dayStart].toLowerCase()) || ''} ${t('au')} ${(hour.dayStart && this.state.weekDays[hour.dayEnd].toLowerCase()) || ''} ${t('de')} ${hour.timeStart} ${t('à')} ${hour.timeEnd}`}
                        </React.Fragment>
                    ))
                    }
                </div>
                <div className="detail-etablissement">
                    <p>{seller.description}</p>
                </div>
            </div>
        );
    };

    renderPrestationsList = () => {
        const {t} = this.props;
        return this.state.prestations && this.state.prestations.map(prestation =>
            <div key={prestation.id} className="cnt-line-product">
                <div className="line-product">
                    <div className="infos-product">
                        <h3>{prestation.name}</h3>
                        <p>{`${prestation.duration || 0} mn`}</p>
                        <p>{prestation.details}</p>
                        <p>
                            <span data-id={prestation.id} 
                                className={this.state.show.includes(`${prestation.id}`) ? 'link active' : 'link'} 
                                onClick={this.handleClick}><span className="plus">+</span><span className="minus">-</span> {t("détails")}
                            </span>
                        </p>
                    </div>
                    <div className="action-product">
                        <p className="price">à partir de {(prestation.specialPrice && prestation.price && parseFloat(prestation.specialPrice).toFixed(2)) || parseFloat(prestation.price || 0).toFixed(2)} €</p>
                        {
                            (prestation.price && prestation.specialPrice) ?
                                <p className="price-rong">{(parseFloat(prestation.price).toFixed(2)) || 0} €</p>
                            : null
                        }
                        
                        <div className="cnt-btn">
                            <span className="button">{t("Réserver")}</span>
                        </div>
                    </div>
                </div>
                <ToggleDisplay show={this.state.show.includes(`${prestation.id}`)} tag="section">
                    {prestation.description}
                </ToggleDisplay>
            </div>
        );
    };

    renderPagination = (nbPages, currentPage, perPage, handler) => {
        return (
            <Pagination
                totalElements={nbPages * perPage}
                changePageCallback={pageNumber =>
                    handler(pageNumber)
                }
                itemsPerPage={perPage}
                activePage={currentPage}
            />
        );
    };

    renderAvisList = () => {
        return this.state.avis && this.state.avis.map(avis =>
            <div key={avis.id} className="item-avis-client">
                <div className="infos-avis">
                    <h3>{avis.prestation}</h3>
                    <div className="ratings">
                        <StarRatings
                            rating={avis.rating ? parseFloat(avis.rating || 0)/20 : 0}
                            starRatedColor="rgb(255, 181, 0)"
                            starEmptyColor="rgb(255, 237, 191)"
                            starDimension="17px"
                            starSpacing="2px"
                            numberOfStars={5}
                        />
                    </div>
                </div>
                <div className="detail-avis">
                    <p className="name">{avis.username || this.props.t("Anonyme")}</p>
                    <p>{avis.comment}</p>
                    <p>{avis.date}</p>
                </div>
            </div>
        );
    };

    render() {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>
                            <Link to={{pathname: "/prestataires", tab: this.state.from, from: "details"}}>
                                <span className="back"><i className="material-icons icon-back">arrow_back</i></span>
                            </Link> 
                            {this.state.seller && this.state.seller.name}
                        </h1>
                    </div>
                </div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                </div>
                <div className="main">
                    <div className="wrapper clr pt-0">
                        {this.state.seller && this.renderSellerInfo()}
                        {this.state.prestations && this.state.prestations.length > 0 &&
                            <div className="liste-product">
                                {this.renderPrestationsList()}
                                {!this.state.error && this.renderPagination(this.state.pager.prestations.nbPages, this.state.pager.prestations.currentPage, this.state.pager.prestations.perPage, this.handlePrestationsPaginationChange)}
                            </div>
                        }
                        {this.state.avis && this.state.avis.length > 0 && 
                            <div className="cnt-avis-client">
                                <div className="titre-avis-client">
                                    {t("Avis client")}
                                </div>
                                {this.renderAvisList()}
                                {!this.state.error && this.renderPagination(this.state.pager.avis.nbPages, this.state.pager.avis.currentPage, this.state.pager.avis.perPage, this.handleAvisPaginationChange)}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showLoading, hideLoading}, dispatch);
};

export default translate('translation')(withRouter(connect(null, mapDispatchToProps)(SellerDetail)));

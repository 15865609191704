import React from 'react';
import {translate} from 'react-i18next';
import {Link} from 'react-router-dom';

class MenuMobile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false
      }
      this._menuToggle = this._menuToggle.bind(this);
      this._handleDocumentClick = this._handleDocumentClick.bind(this);
    }
    componentDidMount() {
      document.addEventListener('click', this._handleDocumentClick, false);
    }
    componentWillUnmount() {
      document.removeEventListener('click', this._handleDocumentClick, false);
    }
    _handleDocumentClick(e) {
      if (!this.refs.root.contains(e.target) && this.state.isOpen === true) {
        this.setState({
            isOpen: false
        });
      }
    }
    _menuToggle(e) {
      e.stopPropagation();
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
    render() {
      let menuStatus = this.state.isOpen ? 'is-open' : '';
      const { t } = this.props;
  
      return (
        <div ref="root">
            <div className="hamburger" onClick={ this._menuToggle }><span className="material-icons">menu</span></div>
            <div id="overlayburger" onClick={ this._menuToggle } className={ menuStatus }></div>
            <div className={ menuStatus } id='menu-sidebar'>
                <div className="text-right">
                    <span className="material-icons" onClick={ this._menuToggle }>cancel</span>
                </div>
                <ul className="menu-mobile">
                    <li className="menuMobile-item"><Link to="/" onClick={ this._menuToggle }>{t("Tableau de bord")}</Link></li>
                    <li className="menuMobile-item"><Link to="/MonHotel" onClick={ this._menuToggle }>{t("Mon hôtel")}</Link></li>
                    <li className="menuMobile-item"><Link to="/catalogue" onClick={ this._menuToggle }>{t("Catalogue")}</Link></li>
                    <li className="menuMobile-item"><Link to="/clients" onClick={ this._menuToggle }>{t("Clients")}</Link></li>
                    <li className="menuMobile-item"><Link to="/prestataires" onClick={ this._menuToggle }>{t("Les prestataires")}</Link></li>
                    <li className="menuMobile-item"><Link to="/gestion-commandes" onClick={ this._menuToggle }>{t("Gestion des commandes")} <span className="nbr notif-nbr">1</span></Link></li>
                </ul>
            </div>
        </div>
      )
    }
  }

  export default translate('translation')(MenuMobile);
import constants from '../constants/client';
const fields=[
    "customers",
    "resetPassword",
    "exportCustomers",
    "customerQuery"
]
const initState = {}

fields.forEach(field =>{
    initState[field] = {};
})

const client = (state = initState, action) => {
    let field = null;
    const newState = {...state};
    const type = action.type !== constants.LOADING ? action.type : action.payload;
    let data = (action.payload && action.payload.data) || null
    switch (type) {
        case constants.LOADING:
            break;
        case constants.FETCH:
            field = fields[0];
            break;
        case constants.RESET_PASSWORD:
            field = fields[1];
            break;
        case constants.EXPORT:
            field = fields[2];
            break;
        case constants.CUSTOMER_QUERY:
            return {
                ...state,
                customerQuery:action.payload
            };
        default:
            if(action.type !== constants.LOADING){
                return state
            }
    }
    const loading = fields.find(element =>(
        element !== field && newState[element].loading === true
    ));
    if (action.type === constants.LOADING) {
        if (field) {
            newState[field].loading = true;
        }
        newState.loading = true;
        return newState;
    }else if (action.payload && action.payload.status === 200 && action.payload.data) {
        newState[field] = {
            error: false,
            data,
            loading: false
        }
        return { ...newState, loading: !!loading};   
    } else {
        newState[field] = {
            error: true,
            data: null,
            loading: false
        }
        return { ...newState, loading: !!loading};
    }
};

export default client;

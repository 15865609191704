/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import { Router, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import store from '../../../services/redux/store';

const $body = document.getElementsByTagName('body')
export const showPopUp = (Arg, title, subtitle, className) => {
  if(document.getElementById('popUp-container'))
  {
    $body[0].classList.add('show-popup')
    ReactDOM.render((
        <Provider store={store}>
            <I18nextProvider i18n={i18next}> 
                <div className={`popup ${className ? className: ""}`}>
                    <div className='popup-wrap'>
                        <div className='popUp-content'>
							<div className="head-modal">
								<span className="close-modal clickable" onClick={hidePopUp}>
									<i className="material-icons icons-close">close</i>
								</span>
								<h1>{title}</h1>
								{subtitle}
							</div>
                            <div className='popUp-body'>
                                {Arg}
                            </div>
                        </div>
                    </div>
                    </div>
            </I18nextProvider>  
        </Provider>
    ), document.getElementById('popUp-container'));
  }
}

export const hidePopUp = () => {
  	if(document.getElementById('popUp-container')){
		$body[0].classList.remove('show-popup')
		ReactDOM.unmountComponentAtNode( document.getElementById('popUp-container'))
	}
}

export const showPopupExtra = (Arg, className) => {
  if(document.getElementById('popUpExtra-container'))
  {
    $body[0].classList.add('show-popupExtra')
    ReactDOM.render((
		<Provider store={store}>
			<I18nextProvider i18n={i18next}> 
				<div className={`popup ${className ? className: ""}`}>
					<div className='popup-wrap'>
						<div className='popUp-content'>
							<div className='popUp-body'>
								{Arg}
							</div>
						</div>
					</div>
				</div>
			</I18nextProvider>  
		</Provider>
    ), document.getElementById('popUpExtra-container'));
  }
}

export const hidePopupExtra = () => {
	if(document.getElementById('popUpExtra-container'))
	{
		$body[0].classList.remove('show-popupExtra')
		ReactDOM.unmountComponentAtNode( document.getElementById('popUpExtra-container'))
	}
}
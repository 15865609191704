import React from 'react';
import {translate} from 'react-i18next';
import  {hidePopUp} from '../popup/popup';
import FormHandler from '../../../services/error-handlers/formHandler';

class PartnershipRequestModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            errors:{
                name: "", 
                address: "",
                postalCode: "",
                city: "",
                prestationPrice: "",
                cessionPrice: "",
            }
        };
    }

    componentDidMount() {
        const deliveryPlace = this.props.deliveryPlace || {};
        this.setState({
            name: deliveryPlace.name || "", 
            address: deliveryPlace.address || "",
            postalCode: deliveryPlace.code_postal || "",
            city: deliveryPlace.city || "",
            prestationPrice: (deliveryPlace.price_prestation && parseFloat(`${deliveryPlace.price_prestation}`.replace(',','.')).toFixed(2)) || "",
            cessionPrice: (deliveryPlace.price_cession && parseFloat(`${deliveryPlace.price_cession}`.replace(',','.')).toFixed(2)) || "",
            enableButton: this.props.deliveryPlace,
            id: deliveryPlace.place_id,
            country: this.props.country,
        },this.validateFields);  
    }

    validateFields = () => {
        const errors = {
            name: FormHandler.notVoidError(this.state.name), 
            address: FormHandler.notVoidError(this.state.address),
            postalCode: FormHandler.notVoidError(this.state.postalCode) || FormHandler.numberIntegerErrors(this.state.postalCode),
            city: FormHandler.adressNotVoidError(this.state.city),
            prestationPrice: this.getPrestationPriceError(),
            cessionPrice: FormHandler.numberErrors(this.state.cessionPrice),
        }
        let disableddButton = false
        Object.keys(errors).forEach(key=>{
            if (errors[key]) {
                disableddButton = true;
            }
        });
        this.setState({
            errors,
            disableddButton
        })
    }


    getPrestationPriceError = ()=>{
        const error = FormHandler.numberErrors(this.state.prestationPrice);
        if (error) {
            return error;
        }
        const prestationPrice = (this.state.prestationPrice && parseFloat(`${this.state.prestationPrice}`.replace(',','.'))) || 0;
        const cessionPrice = (this.state.cessionPrice && parseFloat(`${this.state.cessionPrice}`.replace(',','.'))) || 0;
        const prestationPriceMin = this.round((cessionPrice || 0)/(1 - parseFloat((this.props.commissions && this.props.commissions.commission_indep && this.props.commissions.commission_indep.taux_mch) || 0)/100));
        if (prestationPrice < prestationPriceMin) {
            return `${this.props.t("Le prix doit être supérieur ou égal à")} ${prestationPriceMin}`
        }
        return ""
    }

    round = (number)=>{
        return Math.round(number * 100) / 100
    }

    onValidate = ()=>{
        const data = {
            name: this.state.name, 
            address: this.state.address,
            code_postal: this.state.postalCode,
            city: this.state.city,
            price_prestation: `${parseFloat(`${this.state.prestationPrice}`.replace(',','.'))}`,
            price_cession: `${parseFloat(`${this.state.cessionPrice}`.replace(',','.'))}`, 
            place_id: this.state.id,
            country: this.state.country,
            flag: 'vtc'
        }
        this.props.onValidate(data);
    }

    handleInputChange = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, this.validateFields)
    }

 
    render() {
        const {t} = this.props;
        return (
            <div className="main-modal">
                <div className="row">
                    <div className="col col-12 line-input">
                        <label>{t("Nom")}</label>
                        <div className="cnt-input">
                            <input 
                                className="" 
                                name="name" 
                                type="text" 
                                value={this.state.name}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        {
                            this.state.errors && this.state.errors.name && 
                            <span className="error">{this.state.errors.name}</span>
                        }
                    </div>
                    <div className="col col-12 line-input">
                        <label>{t("Adresse")}</label>
                        <div className="cnt-input">
                            <input 
                                className="" 
                                name="address" 
                                type="text" 
                                value={this.state.address}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        {
                            this.state.errors && this.state.errors.address && 
                            <span className="error">{this.state.errors.address}</span>
                        }
                    </div>
                    <div className="col col-6 line-input">
                        <label>{t("Code Postal")}</label>
                        <div className="cnt-input">
                            <input 
                                className="" 
                                name="postalCode" 
                                type="number" 
                                value={this.state.postalCode}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        {
                            this.state.errors && this.state.errors.postalCode && 
                            <span className="error">{this.state.errors.postalCode}</span>
                        }
                    </div>
                    <div className="col col-6 line-input">
                        <label>{t("Ville")}</label>
                        <div className="cnt-input">
                            <input 
                                className="" 
                                name="city" 
                                type="text" 
                                value={this.state.city}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        {
                            this.state.errors && this.state.errors.city && 
                            <span className="error">{this.state.errors.city}</span>
                        }
                    </div>
                    <div className="col col-6 line-input">
                        <label>{t("Prix de vente")}</label>
                        <div className="cnt-input">
                            <input 
                                className="" 
                                name="prestationPrice" 
                                type="text" 
                                value={this.state.prestationPrice}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        {
                            this.state.errors && this.state.errors.prestationPrice && 
                            <span className="error">{this.state.errors.prestationPrice}</span>
                        }
                    </div>
                    <div className="col col-6 line-input">
                        <label>{t("Prix de cession")}</label>
                        <div className="cnt-input">
                            <input 
                                className="" 
                                name="cessionPrice" 
                                type="text" 
                                value={this.state.cessionPrice}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        {
                            this.state.errors && this.state.errors.cessionPrice && 
                            <span className="error">{this.state.errors.cessionPrice}</span>
                        }
                    </div>
                </div>
              
                <div className="cnt-Btn align-right">
                    <button className="btn-cancel" onClick={hidePopUp}>{t("Annuler")}</button>
                    <button 
                        className="button" 
                        onClick={this.onValidate}
                        disabled = {this.state.disableddButton}
                    >
                        {this.props.deliveryPlace ? t("Modifier") : t("Ajouter")}
                    </button>
                </div>
            </div>
        );
    }
}


export default translate('translation')(PartnershipRequestModal);

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {updateProduct, getProduct, saveDeliveryPlace, getAllDeliveryPlace} from "../../../services/redux/actions/catalog";
import FormHandler from "../../../services/error-handlers/formHandler";
import PopupHandler from "../../../services/error-handlers/popupHandler";
import {showPopupExtra, showPopUp, hidePopUp} from "../../components/popup/popup";
import PopupError from "../../components/popup/popup.error";
import Path from "../../components/path/Path";


const PRICE_LENGTH = 7;

class DetailsProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            options: [],
            error: false,
            priceError: null,
            success: null,
            lastIndex: 0,
            validOptions: true,
            availableOptions: [],
            allDeliveryPlaces: [],
            listDeleted: [],
            listAdded: [],
            listModified: [],
            lastDeliveryPlaceId: 0,
            commissions: {}
        };

        this.handlePriceChange = this.handlePriceChange.bind(this);
    }

    componentDidMount(){
        if (this.props.hotel.hotelInfo && this.props.hotel.hotelInfo.data && this.props.hotel.hotelInfo.data.InfosHotel && this.props.hotel.hotelInfo.data.InfosHotel.length > 0 ) {
            const country = this.props.hotel.hotelInfo.data.InfosHotel[0].pays
            this.setState({
                country
            })
        }
        if (this.props.hotel && this.props.hotel.getDefaultCommision && this.props.hotel.getDefaultCommision.data && this.props.hotel.getDefaultCommision.data.commissions) {
            this.setState({
                commissions: this.props.hotel.getDefaultCommision.data.commissions || {}
            })
        }else{
            this.props.getDefaultCommision()
        }
        
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.catalog;
        const props = this.props.catalog;
        const {t}= this.props;

        if (!prev) {
            return;
        }
        if (prev.product && props.product &&  prev.product.loading && !props.product.loading ) {
            if (props.product.error) {
                this.setState({
                    error: true
                })
            } else {
                let options =  (props.product.data && props.product.data.category  && props.product.data.category.options) || {};
                options = this.objectToList(options);
                let availableOptions = (props.product.data && props.product.data.category  && props.product.data.category.options_disponibles) || {};
                availableOptions = this.objectToList(availableOptions);
                const product =  props.product.data.category || {};
                product.name = product.name || "";
                product.description = product.description || "";
                product.price = product.price ? parseFloat(product.price).toFixed(2) : 0;
                product.sales_price = product.sales_price ? parseFloat(product.sales_price).toFixed(2) : 0;
                this.setState({
                    product,
                    availableOptions,
                    allOptions: this.getAllOptions(options, availableOptions),
                    options,
                    error: false,
                }, ()=>{
                    this.validateExtraPrice();
                });      
            }
        }

        if (prev.allDeliveryPlaces && props.allDeliveryPlaces &&  prev.allDeliveryPlaces.loading && !props.allDeliveryPlaces.loading ) {
            if (props.allDeliveryPlaces.error) {
                this.setState({
                    errorDeliveryPlaces: true
                })
            } else {
                const allDeliveryPlaces = (props.allDeliveryPlaces && props.allDeliveryPlaces.data && props.allDeliveryPlaces.data.data) || []
                this.setState({
                    allDeliveryPlaces: this.copyArray(allDeliveryPlaces),
                    errorDeliveryPlaces: false,
                })
            }
        }

        if (prev.updateProduct && props.updateProduct && prev.updateProduct.loading && !props.updateProduct.loading ) {
            if (props.updateProduct.error) {
                const error = props.updateProduct.data;
                if (error && error.message) {
                    let errorMessage = error.message;
                    errorMessage = errorMessage.replace('Price', t('Le prix'));
                    errorMessage = errorMessage.replace('must exceed', t('doit être supérieur ou égal à'));
                    showPopupExtra(<PopupError title={t("Erreur")} message={errorMessage}/>)
                }  
                this.setState({
                    success: false
                })                
            } else {
                this.setState({
                    success: true
                });
                this.props.toogleCategorySubCategoryById(this.state.product.id, parseInt(this.state.product.showInCatalog) ? 1 : 0)
                this.props.getProduct(this.state.product.id);
            }
        }  
        
        if (prev.saveDeliveryPlace && props.saveDeliveryPlace &&  prev.saveDeliveryPlace.loading && !props.saveDeliveryPlace.loading ) {
            this.setState({
                saveDeliveryPlaceError: props.saveDeliveryPlace.error ? true : false
            })
            if (!props.saveDeliveryPlace.error) {
                this.props.getAllDeliveryPlace(this.state.product.id)
            }else{
                const error = props.saveDeliveryPlace.data;
                if (error && error.messages && error.messages.error && error.messages.error.length > 0) {
                    let errorMessage = error.messages.error[0].message;
                    errorMessage = errorMessage.replace('Price', t('Le prix'));
                    errorMessage = errorMessage.replace('must be equal or more than', t('doit être supérieur ou égal à'));
                    showPopupExtra(<PopupError title={t("Erreur")} message={errorMessage}/>)
                }
                this.setState({
                    saveSuccess: false,
                })
            }
        }
        const prevHotel = prevProps.hotel;
        const propsHotel = this.props.hotel;

        if (prevHotel.getDefaultCommision && propsHotel.getDefaultCommision && prevHotel.getDefaultCommision.loading && !propsHotel.getDefaultCommision.loading) {
            if (propsHotel.getDefaultCommision.error) {
                this.setState({
                    successCommission: false,
                });
            } else {
                this.setState({
                    successCommission: true,
                    commissions: (propsHotel.getDefaultCommision.data && propsHotel.getDefaultCommision.data.commissions) || {}
                });
            }
        }
    }

    getAllOptions =(options, availableOptions)=>{
        let allOptions = [];
        if ((!options || options.length < 1)) {
            allOptions = this.copyArray(availableOptions);
        }else if ((!availableOptions || availableOptions.length < 1)) {
            allOptions = this.copyArray(options)
        }else{
            allOptions = [...this.copyArray(options), ...this.copyArray(availableOptions)]
            allOptions = allOptions.filter((option, index, self) => self.findIndex(element => element.option_id === option.option_id)=== index);
        }
        return allOptions;
    }

    objectToList =(object)=>{
        if (!object || Object.keys(object).length === 0) {
            return []
        }
        return Object.keys(object).map(key=>{
            return {...object[key]}
        })
    }

    listToObject = (idField,list)=>{
        if (!list || list.length === 0) {
            return []
        }
        const object = {};
        list.forEach(element=>{
            object[element[idField]] = element
        })
        return object; 
    }

    copyObject = (object)=>{
        if (!object) {
            return {}
        }
        return JSON.parse(JSON.stringify(object)); 
    }

    copyArray = (array)=>{
        if (!array || array.length === 0) {
            return []
        }
        return JSON.parse(JSON.stringify(array)); 
    }

    round = (number)=>{
        return Math.round(number * 100) / 100
    }

    changeOption = (id, oldId)=>{
        let options = this.state.options ? [...this.state.options] : [];
        let availableOptions = this.state.availableOptions ? [...this.state.availableOptions] : [];
        options = options.map(option=>{
            if (option.option_id === oldId) {
                return this.getOptionById(id)
            }
            return option
        });
        availableOptions = this.addOptionToAvailableOptions(oldId, availableOptions);
        availableOptions = this.deleteOptionById(id, availableOptions);
        this.setState({
            availableOptions,
            options
        },this.validateExtraPrice)
    }

    addOption = ()=>{
        let options = this.state.options ? [...this.state.options] : [];
        let availableOptions = this.state.availableOptions ? [...this.state.availableOptions] : [];
        const id = (availableOptions && availableOptions[0] && availableOptions[0].option_id) || null
        if (id) {
            options = this.addOptionById(id, options);
            availableOptions = this.deleteOptionById(id, availableOptions);
        }
        this.setState({
            availableOptions,
            options
        },this.validateExtraPrice)
    }

    removeOption = (id)=>{
        let options = this.state.options ? [...this.state.options] : [];
        let availableOptions = this.state.availableOptions ? [...this.state.availableOptions] : [];
        availableOptions = this.addOptionToAvailableOptions(id, availableOptions);
        options = this.deleteOptionById(id, options)
        this.setState({
            availableOptions,
            options
        },this.validateExtraPrice)
    }
    
    getOptionById = (id) =>{
        const option = this.state.allOptions && this.state.allOptions.find(option=>option.option_id === id);
        if (option) {
            return this.copyObject(option);
        }
        return null
    }

    deleteOptionById = (id, options) =>{
        return options && options.filter(option=>option.option_id !== id);
    }

    addOptionById = (id, options) =>{
        const option = this.getOptionById(id);
        if (!option) {
            return options;
        }
        return [...options, option];
    }

    addOptionToAvailableOptions = (id, options) =>{
        const option = this.getOptionById(id);
        if (!option) {
            return options;
        }
        const optionValues = option.options_values || {}
        optionValues && Object.keys(optionValues).forEach(key=>{
            const optionValue = optionValues[key];
            optionValue.visible= "0"
        })
        return [option,...options];
    }


    handlePriceChange = (event) => {
        const price = event.target.value;
        if (price.length > PRICE_LENGTH) {
            return;
        }

        const product = {...this.state.product, price};
        this.validatePrice(price);
        this.setState({product})
    };

    validatePrice = (price) => {
        const {t} = this.props;
        let priceError = null;
        if (price.length === 0 || !FormHandler.isFloat(price)) {
            priceError= t("Le prix de la prestation est un nombre obligatoire.");
        } else {
            const minPrice = this.round(parseFloat(this.state.product.sales_price || 0)/(1 - parseFloat((this.state.commissions && this.state.commissions.commission_indep && this.state.commissions.commission_indep.taux_mch) || 0)/100));
            if (parseFloat(price) < minPrice) {
                priceError= `${t("Le prix doit être supérieur ou égal à")} ${minPrice}`;
            }
        } 
        this.setState({priceError})

    };

    handleOptionChange = (event) => {
        const id = event.target.value;
        const oldId = event.target.dataset.id;
        this.changeOption(id, oldId);
    };

    handleExtraPriceChange = (event) => {
        const id = event.target.dataset.id;
        const optionId = event.target.dataset.option;
        const price = event.target.value;
        const options = [...this.state.options].map(option=>{
            const optionValues = option.options_values || {};
            if (option.option_id === optionId) {
                optionValues && Object.keys(optionValues).forEach(key=>{
                    const optionValue = optionValues[key];
                    if (optionValue && optionValue.value_id === id) {
                        optionValue.price = price
                    }
                })
            }
            return {...option}
        });
        this.setState({
            options
        }, this.validateExtraPrice)
    };

    handleVisibleChange = (event) => {
        const id = event.target.dataset.id;
        const optionId = event.target.dataset.option;
        const checked = event.target.checked;
        const options = [...this.state.options].map(option=>{
            const optionValues = option.options_values || {};
            if (option.option_id === optionId) {
                optionValues && Object.keys(optionValues).forEach(key=>{
                    const optionValue = optionValues[key];
                    if (optionValue && optionValue.value_id === id) {
                        optionValue.visible = checked ? "1" : "0"
                    }
                })
            }
            return {...option}
        });
        this.setState({
            options
        })
    };

    validateExtraPrice = () => {
        if (!this.state.options) {
            return
        }
        const {t} = this.props;
        let extraPricesError = false;
        const options = [...this.state.options].map(option=>{
            const optionValues = option.options_values || {}
            optionValues && Object.keys(optionValues).forEach(key=>{
                let priceError = "";
                const optionValue = optionValues[key];
                if (!optionValue.price || parseInt(optionValue.price) === 0) {
                    priceError= t("Le supplément est un nombre obligatoire.");
                } else {
                    const minPrice = this.round(parseFloat(optionValue.price_cession || 0)/(1 - parseFloat((this.state.commissions && this.state.commissions.commission_indep && this.state.commissions.commission_indep.taux_mch) || 0)/100));
                    if (parseFloat(optionValue.price) < minPrice) {
                        priceError= `${t("Le prix doit être supérieur ou égal à")} ${minPrice}`;
                    }
                } 
                if (priceError) {
                    extraPricesError = true
                }
                optionValue.priceError = priceError
            })
            return {...option}
        })
        this.setState({
            options,
            extraPricesError
        })
    };

    handleSubmit = () => {
        if (this.state.priceError || this.state.extraPricesError) {
            return;
        }
        const param = this.prepareParams();
        this.props.updateProduct(param);
        if (this.props.category && this.props.category.flag && this.props.category.flag === 'vtc' && (this.state.listDeleted.length > 0 || this.state.listAdded.length > 0 || this.state.listModified.length > 0)) {
            const data = {
                id_prestation : this.state.product.id,
                listDeleted: this.state.listDeleted,
                listAdded: this.setIdToNull(this.state.listAdded),
                listModified: this.setIdToNull(this.state.listModified),

            };
            this.props.saveDeliveryPlace(data);
        }
    };

    prepareParams = () => {
        let options = this.copyArray(this.state.options).map(option=>{
            const optionValues = option.options_values || {}
            optionValues && Object.keys(optionValues).forEach(key=>{
                const optionValue = optionValues[key];
                delete optionValue.priceError
            })
            return option
        })
        options = this.listToObject("option_id", options);
        const product = {...this.state.product};
        delete Object.assign(product, {category_id: product.id }).id;
        delete product.options_disponibles;
        product.showInCatalog = product.showInCatalog === "1" ? true : false; 
        const availableOptions = this.copyArray(this.listToObject("option_id",this.state.availableOptions));
        product.options = {...options, ...availableOptions};
        
        return product;
    };

    handleShowInCatalogChange = () => {
        const product = {
            ...this.state.product,
            showInCatalog: this.state.product.showInCatalog === "0" ? "1" : "0" 
        };

        this.setState({product});
    };

    getAvailableOptionsByOption = (option)=>{
        const availableOptions = [...this.state.availableOptions] || [];
        availableOptions.push(option);
        return availableOptions;
    }

    openDeliveryPlaceDialog = (event) => {
        const id = (event.target.dataset && event.target.dataset.id) || null;
        const deliveryPlace = id ? this.getDeliveryPlaceById(id) : null;
        const vtcRate = this.props.category && this.props.category.commission_for_admin ? parseFloat(`${this.props.category.commission_for_admin}`.replace('%', "")) : 0;
        showPopUp(
            <Path 
                deliveryPlace={deliveryPlace}
                onValidate={this.editDeliveryPlace}
                vtcRate = {vtcRate}
                country={this.state.country}
                commissions={this.state.commissions}
            />,
            id ? this.props.t("Modification lieu") : this.props.t("Ajout lieu"),
        )
    };

    getDeliveryPlaceById = (id) =>{
        return this.state.allDeliveryPlaces && this.state.allDeliveryPlaces.find(deliveryPlace => deliveryPlace.place_id === id);
    }

    editDeliveryPlace = (deliveryPlace)=>{
        hidePopUp();
        let allDeliveryPlaces = [...this.state.allDeliveryPlaces];
        let listDeleted = [...this.state.listDeleted];
        let listAdded = [...this.state.listAdded];
        let listModified = [...this.state.listModified];
        let lastDeliveryPlaceId = this.state.lastDeliveryPlaceId;

        if (!deliveryPlace.place_id) {
            lastDeliveryPlaceId = `${lastDeliveryPlaceId -1}`;
            deliveryPlace.place_id = lastDeliveryPlaceId;
            allDeliveryPlaces = [...allDeliveryPlaces, deliveryPlace];
            listAdded = [...listAdded, deliveryPlace]
        }else{
            allDeliveryPlaces = this.addOrReplace(deliveryPlace, allDeliveryPlaces);
            if (parseInt(deliveryPlace.place_id) > 0) {
                listModified = this.addOrReplace(deliveryPlace, listModified);
            }else{
                listAdded = this.addOrReplace(deliveryPlace, listAdded);
            }
        }
        this.setState({
            allDeliveryPlaces,
            listDeleted,
            listAdded,
            listModified,
            lastDeliveryPlaceId
        })
    }

    removeDeliveryPlace = event =>{
        const id = (event.target.dataset && event.target.dataset.id) || null;
        let allDeliveryPlaces = [...this.state.allDeliveryPlaces];
        let listDeleted = [...this.state.listDeleted];
        let listAdded = [...this.state.listAdded];
        allDeliveryPlaces = allDeliveryPlaces.filter(element => element.place_id !== id);
        if (parseInt(id) > 0) {
            listDeleted = [...listDeleted, parseInt(id)]
        }else{
            listAdded = listAdded.filter(element => element.place_id !== id);
        }
        this.setState({
            allDeliveryPlaces,
            listDeleted,
            listAdded,
        })
    }

    addOrReplace = (element, list) =>{
        if (!list || list.length === 0) {
            return [element]
        }
        const index = list.findIndex(e => e.place_id === element.place_id);
        let returnList = [...list]
        if (index < 0) {
            returnList = [...returnList, element];
        }else{
            returnList[index] = element;
        }
        return returnList;
    }

    setIdToNull = list =>{
        if (!list || list.length === 0) {
            return []
        }
        const returnList = list.map(element => {
            if (parseInt(element.place_id) < 0) {
                element.place_id = null;
            }
            return element
        });
        return returnList;
    }

    renderOptions() {
        const {t} = this.props
        return this.state.options && this.state.options.map(option => {
            const availableOptions = this.getAvailableOptionsByOption(option) || [];
            const optionValues = option.options_values || {}
            return (
                <div className="row option-item" key={option.option_id}>
                    <div className="col col-12">
                        <div className="row option-top">
                            <span 
                                className="clickable remove-option"
                                title={t("Supprimer")} 
                                onClick={()=>this.removeOption(option.option_id)}
                            >
                                <span className="arrow">
                                    <i className="material-icons arrow-cancel" onClick={()=>this.removeOption(option.option_id)}>cancel</i>
                                </span>
                            </span>
                            <div className="col col-12 line-input">
                                <label>{t("Option")}</label>
                                <select 
                                    className="option"
                                    value={option.option_id}
                                    data-id={option.option_id}
                                    onChange={this.handleOptionChange}
                                >
                                    {  
                                        availableOptions.map(availableOption => {
                                            return(
                                                <option 
                                                    value={availableOption.option_id}
                                                    key={option.option_id}
                                                >
                                                    {availableOption.title}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12">
                        {
                            optionValues && Object.keys(optionValues).map(key => {
                                const optionValue = optionValues[key];
                                return(
                                    <div className="row option-bottom" key={`${option.option_id}_${optionValue.value_id}`}>
                                        <div className="col col-5 line-input">
                                            <label>&nbsp;</label>
                                            <label className="checkbox-label">
                                                <input type="checkbox"
                                                    data-id = {optionValue.value_id}
                                                    data-option = {option.option_id}
                                                    checked={(optionValue.visible && parseInt(optionValue.visible))}
                                                    onChange={this.handleVisibleChange}
                                                />
                                                <span className="check"></span>
                                                <b>{optionValue.value  || optionValue.description || ''}</b>
                                            </label>
                                        </div>
                                        <div className="col col-4 line-input">
                                            <label>{t("Supplément")}</label>
                                            <div className="cnt-input">
                                                <input type="number"
                                                    data-id = {optionValue.value_id}
                                                    data-option = {option.option_id}
                                                    className = {optionValue.priceError ? "hasError": ""}
                                                    value={optionValue.price ? parseFloat(optionValue.price) : ''}
                                                    onChange={this.handleExtraPriceChange}
                                                />
                                            </div>
                                            <span className="erreur">{optionValue.priceError}</span>
                                        </div>
                                        <div className="col col-3 line-input">
                                            <label>{t("Prix de cession")}</label>
                                            <p className="price-cession">{optionValue.price_cession || 0} €</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            );
        });
    }

    renderDeliveryPlaces() {
        const {t} = this.props
        return (
            <div className="vtc">
                {
                    this.state.allDeliveryPlaces && this.state.allDeliveryPlaces.length > 0 &&
                    <h4 className="title-option">{t("Les lieux")}</h4>
                }
                {this.state.allDeliveryPlaces && this.state.allDeliveryPlaces.map(deliveryPlace => {
                    return (
                        <ul className="tripList">
                            <li className="tripList-item row">
                                <div className="col col-7">
                                    <label>{t("Lieu")}</label>
                                    <p>
                                        {t("Nom")} : <strong>{deliveryPlace.name}</strong><br/>
                                        {t("Adresse")} : <strong>{deliveryPlace.address}</strong><br/>
                                    </p>
                                </div>
                                <div className="col col-5">
                                    <label>{t("Prix")}</label>
                                    <p>
                                        {t("Vente")} : <strong>{`${(deliveryPlace.price_prestation && parseFloat(deliveryPlace.price_prestation).toFixed(2)) || 0}€`}</strong><br/>
                                        {t("Cession")} : <strong>{`${(deliveryPlace.price_cession && parseFloat(deliveryPlace.price_cession).toFixed(2)) || 0}€`}</strong><br/>
                                    </p>
                                </div>
                                <div class="actions">
                                    <i 
                                        data-id={deliveryPlace.place_id}
                                        onClick={this.openDeliveryPlaceDialog}  
                                        class="material-icons clickable"
                                    >
                                        edit
                                    </i>
                                    <i 
                                        data-id={deliveryPlace.place_id}
                                        onClick={this.removeDeliveryPlace}  
                                        class="material-icons clickable"
                                    >
                                        cancel
                                    </i>
                                </div>
                            </li>
                        </ul>
                    )})
                }
            </div>
        );
    }

    render() {
        const {t, category} = this.props;
        const product = this.state.product || {price: ""};
        return (
            <div className="cnt-right">
                {(this.state.availableOptionsSuccess === false || this.state.successCommission === false || this.state.errorDeliveryPlaces) && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                {(this.state.success === true || this.state.saveDeliveryPlaceError === false) && PopupHandler.showSuccess(t("Les modifications ont été enregistrées."))}
                {(this.state.success === false || this.state.saveDeliveryPlaceError === true) && PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
                {!this.state.validOptions && PopupHandler.showError(t("Le supplément doit etre supérieur ou égal au prix de cession"))}
                <div className="blc-presta-top">
                    <div className="cntDetCategory-flex">
                        <div className="blc-presta">
                            <span className="t-presta">{t("Prestataires")}</span>
                            <span className="liste-presta">
                                <span className="item-presta">presta 1</span>
                                <span className="item-presta">presta 2</span>
                                <span className="item-presta">presta 3</span>
                            </span>
                            <span className="nomber-presta">+11
                            </span>
                        </div>
                        <h1 className="title-product">{product.name}</h1>
                    </div>
                    <div className="blc-top-product">
                        <p>{product.description}</p>
                        <label className="checkbox-label">
                            <input type="checkbox"
                                    checked={product.showInCatalog ? parseInt(product.showInCatalog) : 0}
                                    onChange={this.handleShowInCatalogChange}
                            />
                            <span className="check"></span>
                            {t("Afficher dans le catalogue")}
                        </label>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-6 line-input">
                        <label htmlFor="price">{t("Prix de la prestation")}</label>
                        <div className="cnt-input">
                            <input type="number"
                                    className = {this.state.priceError ? "hasError": ""}
                                    value={product.price ? parseFloat(product.price) : ""}
                                    id="price"
                                    onChange={this.handlePriceChange}
                            />
                        </div>
                        <span className="erreur">{this.state.priceError}</span>
                    </div>
                    <div className="col  col-6  line-input">
                        <label>{t("Prix de cession")}</label>
                        <p className="price-cession">{product.sales_price ? parseFloat(product.sales_price).toFixed(2) : 0}€</p>
                    </div>
                </div>
                {
                    category &&  category.flag && category.flag === 'vtc' &&
                    <React.Fragment>
                        { this.renderDeliveryPlaces()}
                        <div className="cnt-Btn align-right cnt-btn-strock">
                            <span 
                                className="button"
                                onClick={this.openDeliveryPlaceDialog} 
                            >
                                {t("Ajouter un lieu")}
                            </span>
                            <div className="clr"></div>
                        </div>
                    </React.Fragment>
                }
                
                <div className="option">
                    {this.renderOptions()}
                </div>
                <div className="cnt-Btn align-right cnt-btn-strock">
                    <span 
                        className={`button ${!this.state.availableOptions || this.state.availableOptions.length < 1 ? "disable" : ""}`}
                        onClick={this.addOption} 
                    >
                        {t("Ajouter une option")}
                    </span>
                    <div className="clr"></div>
                </div>
                <div className="cnt-Btn align-center">
                    <span 
                        className={`button ${this.state.priceError || this.state.extraPricesError ? "disable" : ""}`}
                        onClick={this.handleSubmit}>
                            {t("Enregistrer")}
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        catalog: state.catalog,
        hotel: state.hotel
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({updateProduct, getProduct, saveDeliveryPlace, getAllDeliveryPlace}, dispatch);
};

export default translate('translation')(connect(mapStateToProps, mapDispatchToProps)(DetailsProduct));

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import countryList from 'react-select-country-list';
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import PhoneInput from 'react-phone-number-input/input';
import Select, { components } from "react-select";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import FormHandler from '../../../services/error-handlers/formHandler';
import "./style.css";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {resetPassword} from "../../../services/redux/actions/client";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import { clientSA } from "../../../services/applicatif/client.sa";
import PopupConfirm from "../../components/popup/popup.confirmation";
import {showPopUp, hidePopUp} from "../../components/popup/popup";

const NAME_LENGTH = 30;
const TEXT_LENGTH = 50;
const EMAIL_LENGTH = 100;
const ZIPCODE_LENGTH = 10;

const SingleValue = props => (
    <components.SingleValue {...props}>
      {props.data.chipLabel}
    </components.SingleValue>
);

const options = getCountries().map((country) => ({
    label: en[country] + ' +'+getCountryCallingCode(country),
    chipLabel: '+'+getCountryCallingCode(country),
    value: country
})); 
class DetailsClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            addressId: null,
            countries: countryList().setEmpty(props.t('Sélectionnez un pays')).getData(),
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            street: '',
            zipCode: '',
            city: '',
            country: '',
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            phoneError: false,
            streetError: false,
            zipCodeError: false,
            cityError: false,
            countryError: false,
            hasEmptyFields: false,
            hasInvalidFields: false,
            visible : false,
            isResetSuccess: null,
            error: false,
            isFormError: true,
            currentCountry: 'FR'
        };
    }

    componentDidMount() {
        this.props.showLoading();
        clientSA.getCustomerDetails(this.state.id)
            .then(result =>{
                const customer = (result && result.data && result.data.length > 0 && result.data[0]) || {};
                if (customer) {
                    this.setState({
                        firstName: customer.firstName || '',
                        lastName: customer.lastName || '',
                        email: customer.email || '',
                        phone: customer.phone || '',
                        street: (customer.address && customer.address.street) || '' ,
                        zipCode: (customer.address && customer.address.postalCode) || '',
                        city: (customer.address && customer.address.city) || '',
                        country: (customer.address && customer.address.country) || '',
                        addressId: (customer.address && customer.address.id) || null,
                        state: (customer.address && customer.address.state) || '',
                        currentCountry: (customer.phone && isValidPhoneNumber(customer.phone)) ? parsePhoneNumber(customer.phone).country : "FR",
                        orderQuantity: customer.orderQuantity
                    }, this.validateFields)
                }
                this.props.hideLoading();
            })
            .catch(()=>{
                this.props.hideLoading();
                this.setState({
                    error: true,
                })
            })
    }

    handleFirstNameChange = (event) => {
        const firstName = event.target.value;
        if (firstName.length > NAME_LENGTH) {
            return;
        }
        this.setState({firstName},this.validateFields);
    };

    handleLastNameChange = (event) => {
        const lastName = event.target.value;
        if (lastName.length > NAME_LENGTH) {
            return;
        }
        this.setState({lastName},this.validateFields);
    };

    handleEmailChange = (event) => {
        const email = event.target.value;
        if (email.length > EMAIL_LENGTH) {
            return;
        }
        this.setState({email},this.validateFields);
    };

    handlePhoneChange = (phone) => {
        this.setState({phone}, this.validateFields);
    };

    handleContrySelect (value) {
        this.setState({
            currentCountry: value.value,
            phone: ""
        }, this.validateFields);
    }

    handleStreetChange = (event) => {
        const street = event.target.value;
        if (street.length > TEXT_LENGTH) {
            return;
        }
        this.setState({street},this.validateFields);
    };

    handleZipCodeChange = (event) => {
        const zipCode = event.target.value;
        if (zipCode.length > ZIPCODE_LENGTH) {
            return;
        }
        this.setState({zipCode},this.validateFields);
    };

    handleCityChange = (event) => {
        const city = event.target.value;
        if (city.length > TEXT_LENGTH) {
            return;
        }
        this.setState({city},this.validateFields);
    };

    handleCountryChange = (event) => {
        const country = event.target.value;
        this.setState({country},this.validateFields);
    };

    handleSubmit = () => {
        if(this.validateFields()){
            const customer = {
                id: this.state.id,
                firstname: this.state.firstName,
                lastname: this.state.lastName,
                email: this.state.email,
                mobile_phone: this.state.phone,
            }
            if (this.state.city || this.state.zipCode || this.state.state || this.state.street || this.state.country) {
                customer.address = {
                    id: this.state.addressId,
                    city: this.state.city,
                    postalCode: this.state.zipCode,
                    state: this.state.state,
                    street: this.state.street,
                    country: this.state.country
                }
            }
            this.props.showLoading();
            clientSA.saveCustomer(customer)
            .then(() =>{
                this.setState({isSaveSuccess: true})
                this.props.hideLoading();
            })
            .catch(()=>{
                this.setState({isSaveSuccess: false});
                this.props.hideLoading();
            })
        }
    };

    validateFields = () => {
        const firstNameError =  FormHandler.nameErrors(this.state.firstName);
        const lastNameError = FormHandler.nameErrors(this.state.lastName);
        const emailError = FormHandler.emailError(this.state.email);
        const phoneError = FormHandler.phoneError(this.state.phone);
        const streetError = FormHandler.adressError(this.state.street) || this.adressError('street');
        const zipCodeError = FormHandler.numberIntegerErrors(this.state.zipCode)|| this.adressError('zipCode');
        const cityError = FormHandler.adressError(this.state.city) || this.adressError('city');
        const countryError = FormHandler.adressError(this.state.country) || this.adressError('country');
        this.setState({
            firstNameError,
            lastNameError,
            emailError,
            phoneError,
            streetError,
            zipCodeError,
            cityError,
            countryError
        });

       
        if (firstNameError || lastNameError || emailError || zipCodeError || phoneError || streetError || cityError || countryError) {
            this.setState({isFormError: true});
            return false;
        }
        this.setState({
            isFormError: false
        })
        return true;
    };

    adressError = (field) =>{
        let adressError = false;
        switch (field) {
            case 'street':
                adressError = !this.state.street && (this.state.zipCode || this.state.city || this.state.country);
                break;
            case 'zipCode':
                adressError = !this.state.zipCode && (this.state.street || this.state.city || this.state.country);
                break;
            case 'city':
                adressError = !this.state.city && (this.state.street || this.state.zipCode || this.state.country);
                break
            case 'country':
                adressError = !this.state.country && (this.state.street || this.state.zipCode || this.state.city);
                break
            default:
              break;
        }
        if (adressError) {
            return "Si un champ de l'adresse est renseigné, ce champ est obligatoire"
        }
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.client;
        const props = this.props.client;

        if (!prev) {
            return;
        }

        if (prev.resetPassword && props.resetPassword && prev.resetPassword.loading && !props.resetPassword.loading) {
            if (props.resetPassword.error) {
                this.setState({isResetSuccess: false});
            } else {
                this.setState({isResetSuccess: true});
            }
        }
    }
    openModal = () => {
        showPopUp(
            <PopupConfirm 
                message = {this.props.t("Voulez-vous vraiment réinitialiser le mot de passe ?")}
                onAccept = { this.handlePasswordReset }
            />,
            this.props.t("Confirmation")
        )
    };

    handlePasswordReset = () => {
        this.props.resetPassword(this.state.id);
        hidePopUp();
    };

    render() {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>
                            <Link to={{pathname: "/clients", from: "details"}}>
                                <span className="back">
                                    <i className="material-icons icon-back">arrow_back</i>
                                </span>
                            </Link>
                            {`${this.state.firstName || ''} ${this.state.lastName || ''}`}
                        </h1>
                    </div>
                </div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {this.state.isResetSuccess === true && PopupHandler.showSuccess(t("La demande de réinitialisation du mot de passe a bien été envoyée."))}
                    {this.state.isResetSuccess === false && PopupHandler.showError(t("Une erreur est survenue, merci de réessayer ultérieurement."))}
                    {this.state.isSaveSuccess === true && PopupHandler.showSuccess(t("Succes de la sauvegarde du client."))}
                    {this.state.isSaveSuccess === false && PopupHandler.showError(t("Erreur de la sauvegarde du client."))}
                </div>
                <div className="main monHotel">
                    <div className="wrapper clr">
                        <div className="row mainBody">
                            <div className="col col-6 line-input">
                                <label>{t("Nom")}</label>
                                <div className="cnt-input">
                                    <input type="text"
                                           className={this.state.firstNameError ? "hasError" : ""}
                                           value={this.state.firstName}
                                           onChange={this.handleFirstNameChange}
                                    />
                                </div>
                                {
                                    this.state.firstNameError && 
                                    <span className="error">{this.state.firstNameError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Prénom")}</label>
                                <div className="cnt-input">
                                    <input type="text"
                                           className={this.state.lastNameError ? "hasError" : ""}
                                           value={this.state.lastName}
                                           onChange={this.handleLastNameChange}
                                    />
                                </div>
                                {
                                    this.state.lastNameError && 
                                    <span className="error">{this.state.lastNameError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Adresse e-mail")}</label>
                                <div className="cnt-input">
                                    <input type="email"
                                           className={this.state.emailError ? "hasError" : ""}
                                           value={this.state.email}
                                           onChange={this.handleEmailChange}
                                    />
                                </div>
                                {
                                    this.state.emailError && 
                                    <span className="error">{this.state.emailError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Numéro de téléphone")}</label>
                                <div className="PhoneInput">
                                    <Select
                                        onChange={(value) => this.handleContrySelect(value)}
                                        options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                                        components={{ SingleValue }}
                                        value={options.find(op => { return op.value === (this.state.currentCountry) })}
                                    />
                                    <PhoneInput
                                        className={this.state.phoneError ? 'hasError' : ''}
                                        country={this.state.currentCountry}
                                        international
                                        value={this.state.phone ? this.state.phone : ""}
                                        onChange={this.handlePhoneChange}
                                        name='phone'
                                    />
                                </div>
                                {
                                    this.state.phoneError && 
                                    <span className="error">{this.state.phoneError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Rue")}</label>
                                <div className="cnt-input">
                                    <input type="text"
                                           className={this.state.streetError ? "hasError" : ""}
                                           value={this.state.street}
                                           onChange={this.handleStreetChange}
                                    />
                                </div>
                                {
                                    this.state.streetError && 
                                    <span className="error">{this.state.streetError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Code postal")}</label>
                                <div className="cnt-input">
                                    <input type="number"
                                           className={this.state.zipCodeError ? "hasError" : ""}
                                           value={this.state.zipCode}
                                           onChange={this.handleZipCodeChange}
                                    />
                                </div>
                                {
                                    this.state.zipCodeError && 
                                    <span className="error">{this.state.zipCodeError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Ville")}</label>
                                <div className="cnt-input">
                                    <input type="text"
                                           className={this.state.cityError ? "hasError" : ""}
                                           value={this.state.city}
                                           onChange={this.handleCityChange}
                                    />
                                </div>
                                {
                                    this.state.cityError && 
                                    <span className="error">{this.state.cityError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Pays")}</label>
                                <div className="cnt-input">
                                    <select 
                                        onChange={this.handleCountryChange}
                                        className={`w-100 ${this.state.countryError === true ? "hasError" : ""}`}
                                        value={this.state.country}
                                    >
                                        {this.state.countries.map((country) => <option
                                            value={country.value} key={country.value}>{country.label}</option>)}
                                    </select>
                                </div>
                                {
                                    this.state.countryError && 
                                    <span className="error">{this.state.countryError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Nombre de prestations commandées")}</label>
                                <div className="cnt-input">
                                    <input type="text"
                                           value={this.state.orderQuantity}
                                           readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="cnt-Btn align-right">
                            <span 
                                className= {`button ${this.state.isFormError ? 'disable': ''}`}
                                onClick={this.handleSubmit}
                            >
                                {t("Enregistrer")}
                            </span>
                        </div>

                        <div className="cnt-Btn">
                            <span  className="button" onClick={() => this.openModal()}>
                            {t("Réinitialiser le mot de passe")}
                            </span>
                        </div>
                        <div className="cnt-Btn">
                            <span 
                                onClick={()=>{this.props.history.push(`/gestion-commandes?customer=${this.state.firstName} ${this.state.lastName}`)}} 
                                className="button">
                                    {t("Commandes")}
                            </span>
                        </div>
                        <div className="clr"></div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({resetPassword, showLoading, hideLoading}, dispatch);
};

const mapStateToProps = (state) => {
    return {client: state.client};
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsClient)));

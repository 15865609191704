import React from 'react';
import {getCustomerReview} from "../../../services/redux/actions/commandes";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import PopupHandler from "../../../services/error-handlers/popupHandler";

class AvisClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            review: null,
            from: props.location.from,
        };
    }

    componentDidMount() {
        if (this.state.from === "details" && this.props.commandes && this.props.commandes.customerReview.data && this.props.commandes.customerReview.data.data) {
            const data = this.props.commandes.customerReview.data.data;
            this.setState({
                review:  data && !Array.isArray(data) && Object.keys(data).length > 0 ? data : null
            });
        }else{
            this.props.getCustomerReview(this.state.id);
        }
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.commandes;
        const props = this.props.commandes;

        if (!prev) {
            return;
        }
        if (prev.customerReview && props.customerReview && prev.customerReview.loading && !props.customerReview.loading) {
            if (props.customerReview.error) {
                this.setState({
                    error: true,
                });
            }else{
                const data = (props.customerReview.data && props.customerReview.data.data) || null;
                const review =  data && !Array.isArray(data) && Object.keys(data).length > 0 ? data : null
                this.setState({
                    error: false,
                    review
                });
            }
        }
    }

    render() {
        const review = this.state.review;
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>
                            <Link to={{pathname: "/gestion-commandes", from: "details"}}>
                                <span className="back"><i className="material-icons icon-back">arrow_back</i></span>
                            </Link> 
                            {t("Avis client")}
                        </h1>
                    </div>
                </div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                </div>

                {
                    review &&
                    <React.Fragment>
                        <div className="wrapper main-commande">
                            <div className="mainBody">
                                <div className="infos-commande">
                                    <div className="head-det-commande">
                                        <p className="nameClient"><span className="ico"><i className="material-icons icon-person">person_outline</i></span>{review.sellerName}</p>
                                        <p className="date-commande"><span className="ico"><i className="material-icons icon-person">date_range</i></span>{review.date_review ? moment(review.date_review, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY  hh:mm') : ""}</p>
                                        <p className="adress-celler">{review.orderName}</p>
                                        <p className="adress-celler">{review.address}</p>
                                    </div>
                                    <div className="txt-msg">
                                        <p>
                                            {`«${review.text || ''}»`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {commandes: state.commandes};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({getCustomerReview}, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(AvisClient)));

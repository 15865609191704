import config from "../../../../src/config";

const baseURL = config.baseURL;
const sellerURL = config.sellerUrl;

export default {
    FETCH_ALL_SELLERS_URL: `${baseURL}/rest/V1/getAllSeller`,
    FETCH_ALL_SELLERS: 'seller/FETCH-ALL-SELLERS',
    FETCH_ALL_PARTNER_SELLERS_URL: `${baseURL}/rest/V1/getAllSeller`,
    FETCH_ALL_PARTNER_SELLERS: 'seller/FETCH-ALL-PARTNER-SELLERS',
    LOADING_ALL_PARTNER_SELLERS: 'seller/LOADING_ALL_PARTNER_SELLERS',
    LOADING: 'seller/LOADING',
    DETAILS_SELLER_URL: `${baseURL}/rest/V1/getSellerDetails`,
    DETAILS_SELLER: 'seller/DETAILS',
    PARTNER_OPEN_MODAL: 'seller/PARTNER_CLOSE_OPEN_MODAL',
    PARTNER_CLOSE_MODAL: 'seller/PARTNER_CLOSE_MODAL',
    INFO_SELLER_URL: `${baseURL}/rest/V1/getSellerInfo`,
    INFO_SELLER: 'seller/INFO',
    SEND_PARTNERSHIP_REQUEST_URL: `${baseURL}/rest/V1/sendPartnershipRequest`,
    SEND_PARTNERSHIP_REQUEST: 'seller/SEND-PARTNERSHIP-REQUEST',
    END_PARTNERSHIP_URL: `${baseURL}/rest/V1/deletePartnership`,
    END_PARTNERSHIP: 'seller/END-PARTNERSHIP',
    SEND_INVITATION_URL: `${sellerURL}/api/seller/register/invitation`,
    SEND_INVITATION: 'seller/SEND-INVITATION',
    FETCH_PARTNERSHIP_REQUESTS_URL: `${baseURL}/rest/V1/fetchPartnershipRequests`,
    FETCH_PARTNERSHIP_REQUESTS: 'seller/FETCH-PARTNERSHIP-REQUESTS',
    LOADING_PARTNERSHIP_REQUESTS: 'seller/LOADING-PARTNERSHIP-REQUESTS',
    SEND_RESPONSE_URL: `${baseURL}/rest/V1/sendPartnershipRequestResponse`,
    SEND_RESPONSE: 'seller/SEND-RESPONSE',
    SELLER_QUERY: 'seller/SELLER_QUERY',
    PARTNER_QUERY: 'seller/PARTNER_QUERY',
    GET_PARTNERS_FILTERS_URL: `${baseURL}/rest/V1/getSelectPartenaires`,
    GET_PARTNERS_FILTERS: 'GET_PARTNERS_FILTERS',
};

import CommonBDL from './common.bdl';
import constants from '../redux/constants/auth';
import config from "../../config";


class AuthenticationBDL extends CommonBDL {
    authenticate = (username, password)=> {
        const data = {
            username: username,
            password: password,
            code_hotel: config.codeHotel
        };
        return this.getAxiosInstance().post(constants.AUTH_URL, data);
    }
}

export const authenticationBDL = new AuthenticationBDL();
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import Currency from 'react-currency-formatter';
import moment from 'moment';
import {getOrderInvoice} from "../../../services/redux/actions/commandes";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import {withRouter} from 'react-router-dom';
import { commandesSA } from "../../../services/applicatif/commandes.sa";

class Facture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            error: false,
            order: {}
        };
    }

    componentDidMount() {
        this.props.getOrderInvoice(this.state.id);
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.commandes;
        const props = this.props.commandes;
        if (!prev) {
            return;
        }

        if (prev.orderInvoice && props.orderInvoice && prev.orderInvoice.loading && !props.orderInvoice.loading) {
            if (props.orderInvoice.error) {
                this.setState({
                    error: true,
                });
            }else{
                this.setState({
                    error: false,
                    order: (props.orderInvoice.data && props.orderInvoice.data.order_invoice) || {},
                });
            }
        }
    }

    generateInvoice = () =>{
        const id = (this.state.order.invoice && this.state.order.invoice.invoice_id) || "";
        this.props.showLoading();
        commandesSA.generateInvoice(id, "facture.pdf")
        .then(byte =>{
            this.props.hideLoading();
            this.setState({
                generateInvoiceSuccess: true,
            });
            })
        .catch(()=>{
            this.props.hideLoading();
            this.setState({
                generateInvoiceSuccess: false,
            });
        })
    }

    render() {
        const {t} = this.props;
        const invoice = this.state.order.invoice || {};
        const order = this.state.order.invoice_order || {};
        const hotelInfo = this.state.order.invoice_hotel_info || {};
        const customer = this.state.order.invoice_client_info || {};
        const items = this.state.order.invoice_items || [];
        const price = this.state.order.invoice_price || {};
        const currencyCode = (price && price.currency_code) || 'EUR';

        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>
                            <Link to={{pathname: "/gestion-commandes", from: "details"}}>
                                <span className="back"><i className="material-icons icon-back">arrow_back</i></span>
                            </Link> {t("Facture")}</h1>
                    </div>
                </div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {this.state.generateInvoiceSuccess === false && PopupHandler.showError(t("La génération de facture a échoué."))}
                    {this.state.generateInvoiceSuccess && PopupHandler.showSuccess(t("Succès de la génération de facture."))}
                </div>
                <div className="wrapper main-commande">
                <div className="mainBody">
                    <div className="cnt-Btn align-right">
                        <span onClick={this.generateInvoice} className="button">{t("Télécharger votre facture")}</span>
                        <div className="clr"></div>
                    </div>
                    <div className="infos-commande">
                        <div className="head-det-commande">
                            <p className="nameClient"><span className="ico">
                                <i className="material-icons icon-person">description</i></span> 
                                {t("Facture n°")}{invoice.invoice_number || 0} 
                                <span className="statut">Payer</span>
                            </p>
                            <p className="date-commande">
                                <span className="ico"><i className="material-icons icon-person">date_range</i></span> 
                                {invoice.invoice_date ? moment(invoice.invoice_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY  HH:mm') : ""}
                            </p>
                            <p>{hotelInfo.hotel_name || ""}</p>
                            <p className="num-commande">{t("Commande n°")}{order.order_number || 0} </p>
                            <p className="transaction-Commande">{t("Transaction n°")}{price.transaction || ''} </p>
                        </div>
                        <div className="row">
                            <div className="col col-6 info-client-commande">
                            </div>
                            <div className="col col-6 info-client-commande">
                                <p className="nameClient">{customer.customer_name || ""}</p>
                                <p className="mailClient">{customer.customer_email || ""}</p>
                                <p className="adressClient">{`${customer.street || ""}, ${customer.postcode || ""} ${customer.region || ""} ${customer.city || ""}`}</p>
                                <p className="telClient">{customer.telephone || ""}</p>
                            </div>
                        </div>
                    </div>
                    <div className="recap-commande">
                        <div className="titre-recap-commande">
                            {t("Recapitulatif de la commande")}
                        </div>
                        <div className="table table-commande">
                            <div className="table-responsive scrollbar">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{t("Nom produit")}</th>
                                            <th className="th-date">{t("Date de la prestation")}</th>
                                            <th className="th-qtt">{t("Quantité")}</th>
                                            <th className="th-montant">{t("Prix")}</th>
                                            <th className="th-reponse">{t("Montant")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items && items.map(item =>(
                                            <tr>
                                                <td><span className="name-prestation">{item.prestation}</span><p>{item.type_prestation}</p></td>
                                                <td>{item.date_start_prestation ? moment(item.date_start_prestation, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY  HH:mm') : ""}</td>
                                                <td>{(item.qty_invoiced && parseFloat(item.qty_invoiced) > 0 && parseFloat(item.qty_invoiced).toFixed(2)) || 1}</td>
                                                <td> 
                                                    <Currency
                                                        quantity={item.price ? parseFloat(item.price) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                                <td className="align-right"> 
                                                    <Currency
                                                        quantity={item.row_total ? parseFloat(item.row_total) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-6">&nbsp;</div>
                            <div className="col col-6 table table-commande">
                                <div className="table-responsive scrollbar">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="font-bold">{t("Sous-total")} </td>
                                                <td className="align-right"> 
                                                    <Currency
                                                        quantity={price.subtotal ? parseFloat(price.subtotal) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">{t("Montant HT")}</td>
                                                <td className="align-right"> 
                                                    <Currency
                                                        quantity={price.total_HT ? parseFloat(price.total_HT) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">{t("TVA")}</td>
                                                <td className="align-right"> 
                                                    <Currency
                                                        quantity={price.tax_amount ? parseFloat(price.tax_amount) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">{t("Taux TVA")}</td>
                                                <td className="align-right">{(price.taux_tva && parseFloat(price.taux_tva).toFixed(2)) || 0} %</td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">{t("Montant TTC")}</td>
                                                <td className="align-right"> 
                                                    <Currency
                                                        quantity={price.total_TTC ? parseFloat(price.total_TTC) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">{t("Remise")}</td>
                                                <td className="align-right">{(price.discount_amount && parseFloat(price.discount_amount).toFixed(2)) || 0} %</td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">{t("Montant remboursé")}</td>
                                                <td className="align-right"> 
                                                    <Currency
                                                        quantity={price.total_refunded ? parseFloat(price.total_refunded) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="total-commande">
                                                <td className="font-bold">{t("Total")}</td>
                                                <td className="align-right font-bold">
                                                    <Currency
                                                        quantity={price.total ? parseFloat(price.total) : 0}
                                                        currency={currencyCode}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {commandes: state.commandes};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({getOrderInvoice, showLoading, hideLoading}, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Facture)));

import CommonBDL from './common.bdl';
import constants from '../redux/constants/language';
import config from "../../config";

class LanguageBDL extends CommonBDL {

    fetchLanguages = () => {    
        return this.get(`${constants.FETCH_LANGUAGES_URL}`);
    };
    
    enableDisableLanguage = (name) => {  
        const data = {
            code_hotel: config.codeHotel,
            name
    } 
        return this.post(constants.ENABLE_DISABLE_LANGUAGES_URL, data);
    };
    
    orderLanguage = (locales) => {    
        const data = {
            code_hotel: config.codeHotel,
            locales
        }
        return this.post(constants.ORDER_LANGUAGES_URL, data);
    };
}

export const languageBDL = new LanguageBDL();

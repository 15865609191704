import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import fileDownload from 'js-file-download';
import {fetchCustomers} from "../../../services/redux/actions/client";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import { clientSA } from "../../../services/applicatif/client.sa";
import Pagination from "../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "../../../data/constants/page.size";

class Clients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            hasExportError: false,
            clients: [],
            nbPages: 0,
            currentPage: 1,
            perPage: PAGE_SIZE_LIST[0],
            query: '',
            direction: 'asc',
            selectedCustomers: [],
            loading: true,
            from: props.location.from,
        };
    }

    componentDidMount() {
        if (this.state.from === "details" && this.props.client && this.props.client.customers && this.props.client.customers.data && this.props.client.customers.data.customer) {
            this.setState({
                hasError: false,
                clients: (this.props.client.customers.data && this.props.client.customers.data.customer) || [],
                nbPages: (this.props.client.customers.data && this.props.client.customers.data.nbPages) || 0,
                currentPage: (this.props.client.customers.data && parseInt(this.props.client.customers.data.currentPage)) || 1,
                query: (this.props.client.customerQuery && this.props.client.customerQuery.query) || "",
            });
        }else{
            this.fetchCustomers();
        }
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.client;
        const props = this.props.client;

        if (!prev) {
            return;
        }
        
        if (prev.customers && props.customers && prev.customers.loading && !props.customers.loading) {
            if (props.customers.error) {
                this.setState({hasError: true});
            } else {
                this.setState({
                    hasError: false,
                    clients: (props.customers.data && props.customers.data.customer) || [],
                    nbPages: (props.customers.data && props.customers.data.nbPages) || 0,
                    currentPage: (props.customers.data && parseInt(props.customers.data.currentPage)) || 1 ,
                });
            }
        }
    }

    fetchCustomers = ()=>{
        this.props.fetchCustomers(this.state.currentPage, this.state.perPage, this.state.query);
    }

    handleItemsPerPageChange = (event) => {
        this.setState({
            perPage: event.target.value,
            currentPage: 1
        }, this.fetchCustomers);
    };

    handlePaginationChange = (page) => {
        this.setState({
            currentPage: page
        },this.fetchCustomers);
    };

    handleQueryChange = (event) => {
        const query = event.target.value;
        this.setState({query});
    };

    handleSearch = () => {
        this.setState({
            currentPage: 1
        },this.fetchCustomers);
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSearch()
        }
    }

    handleSort = () => {
        const direction = this.state.direction === 'asc' ? 'desc' : 'asc';
        let clients = [];

        if (direction === 'asc') {
            clients = [].concat(clients).sort(function(a,b){return b.firstName.localeCompare(a.firstName)});
        } else {
            clients = [].concat(clients).sort(function(a,b){return a.firstName.localeCompare(b.firstName)});
        }
        this.setState({clients, direction});
    };

    handleCheckedChange = (event) => {
        const id = parseInt(event.target.dataset.id);
        const customers = this.state.selectedCustomers;
        if (!customers.includes(id)) {
            customers.push(id);
        } else {
            customers.splice(customers.indexOf(id), 1);
        }
        this.setState({selectedCustomers: customers});
    };

    handleSelectAll = () => {
        let customers = this.state.selectedCustomers;
        const clients = this.state.clients || [];
        if (customers.length === clients.length) {
            customers = [];
        } else {
            customers = [];
            clients.forEach(customer => {
                customers.push(parseInt(customer.id));
            });
        }

        this.setState({selectedCustomers: customers});
    };

    handleFileDownload = (url, filename)=> {
        var a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
    }

    handleExport = () => {
        if (this.state.selectedCustomers.length > 0) {
            this.props.showLoading();
            clientSA.exportCustomers(this.state.selectedCustomers)
            .then(response=>{
                const exportSucces = response && response.status === 200;
                if (exportSucces) {
                    this.handleFileDownload(response.data.csv_file, 'export.csv')
                }
                this.setState({
                    exportSucces,
                })
                this.props.hideLoading();
            })
            .catch(()=>{
                this.props.hideLoading();
                this.setState({
                    exportSucces: false,
                })
            })
        }
    };
    

    showOrders = () => {
        if (this.state.selectedCustomers.length > 0) {
            const customerName = this.state.selectedCustomers.length === 1 ? this.getCustomerName(this.state.selectedCustomers[0]) : '';
            this.props.history.push(`/gestion-commandes?customer=${customerName}`)
        }
    };

    getCustomerName = (id)=>{
        if (!this.state.clients || this.state.clients.length < 1) {
            return ""
        }
        const client = this.state.clients.find(client =>(parseInt(client.id) === parseInt(id)));
        //return (client && `${client.firstName || ''} ${client.lastName || ''}`) || '';
        return client.firstName;
    }

    renderCustomers = () => {
        const {t} = this.props;
        const clients = this.state.clients || [];
        return clients.map((client) =>
            <tr key={client.id}>
                <td className="cnt-check">
                    <label className="checkbox-label">
                        <input type="checkbox"
                            data-id={client.id}
                            onChange={this.handleCheckedChange}
                            checked={this.state.selectedCustomers.includes(parseInt(client.id)) ? "checked" : ""}
                        />
                        <span className="check"></span>
                    </label>
                </td>
                <td>
                    <span className="nameclient clickable" onClick={()=>{this.props.history.push(`/clients-detail/${client.id}`)}}>
                        {client.firstName} {client.lastName}
                    </span>
                </td>
                <td>{client.email} <br/>{client.phone}</td>
                <td>{client.orderQuantity}</td>
                <td className="cnt-link"><span className="clickable">{t("Attribuer un code promotionnel")}</span></td>
            </tr>
        );
    };

    renderPagination = () => {
        const {t}=this.props
        return (
            <div className="pagination">
                <div>
                    <Pagination
                        totalElements={this.state.nbPages * this.state.perPage}
                        changePageCallback={pageNumber =>
                            this.handlePaginationChange(pageNumber)
                        }
                        itemsPerPage={this.state.perPage}
                        activePage={this.state.currentPage}
                    />
                </div>
                <div>
                    {t("Affichage par page")}
                    <select 
                        value={this.state.perPage}
                        onChange={this.handleItemsPerPageChange}
                    >
                      { PAGE_SIZE_LIST.map(size =>
                        <option value={size}>{size}</option>
                      )}
                    </select>
                </div>
            </div>
        );
    };

    render() {
        const {t} = this.props;
        const clients = this.state.clients || [];
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>{t("Clients")}</h1>
                    </div>
                </div>
                <div>
                    {this.state.hasError && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {this.state.exportSucces === false && PopupHandler.showError(t("L'export a échoué."))}
                    {this.state.exportSucces === true && PopupHandler.showSuccess(t("Succès de l'export."))}
                </div>
                <div className="main-client">
                    <div className="wrapper clr">
                        <div className="cnt-filtre">
                            <h3>{t("Nom du client")}</h3>
                            <div className="block-search-client clearfix">
                                <input type="text"
                                       className="input-search"
                                       value= {this.state.query}
                                       placeholder={t("Rechercher…")}
                                       onChange={this.handleQueryChange}
                                       onKeyDown={this.handleSearchKeyDown}
                                />
                                <button className="btnSearch"
                                        onClick={this.handleSearch}
                                >{t("Rechercher")}
                                </button>
                            </div>
                        </div>
                        <div className="table">
                            <div className="table-responsive scrollbar">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            <label className="checkbox-label">
                                                <input type="checkbox"
                                                    className="check-all"
                                                    checked={this.state.selectedCustomers && clients && this.state.selectedCustomers.length === clients.length ? "checked" : ""}
                                                    onChange={this.handleSelectAll}
                                                />
                                                <span className="check"></span>
                                            </label>
                                        </th>
                                        <th>
                                            {t("Client")}
                                            <span style={{color: '#FFF'}} onClick={this.handleSort}>
                                                <span className="filtre">
                                                <i className="material-icons clickable">{this.state.direction === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                                                </span>
                                            </span>
                                        </th>
                                        <th>{t("Contact")}</th>
                                        <th>{t("Prestations commandées")}</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderCustomers()}
                                    </tbody>
                                </table>
                            </div>

                            {this.state.nbPages > 0 && this.renderPagination()}
                            <div className="cnt-Btn">
                                <span 
                                    onClick={this.showOrders}
                                    className={`button${this.state.selectedCustomers.length < 1 ? " disable": ""}`}
                                >
                                    {t("Voir les commandes associées")}
                                </span>
                                <span className={`button${this.state.selectedCustomers.length < 1 ? " disable": ""}`}
                                    onClick={this.handleExport}
                                >
                                    {t("Exporter les clients selectionnés")}
                                </span>
                                <div className="clr"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {client: state.client};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({fetchCustomers, showLoading, hideLoading}, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients)));

import CommonBDL from './common.bdl';
import constants from '../redux/constants/theme';

class ThemeBDL extends CommonBDL {
    updateColor = (data) => {
        return this.post(constants.URL, data);
    };

    getColor = () => {
        return this.get(constants.GET_COLOR_URL);
    };
}

export const  themeBDL = new ThemeBDL();

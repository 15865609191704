import React from 'react';
import {translate} from 'react-i18next';
import  {hidePopUp} from '../popup/popup';
import { getCommissionLabel } from '../../../data/constants/commission.label';

const MESSAGE_LENGTH = 500;

class PartnershipRequestModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            seller: null,
            message: '',
            commission: "1",
        };
    }

    componentDidMount() {
        this.setState({
            seller: this.props.seller, 
        });  
    }

    getCommissionToSave = (commissionType) =>{
        const seller = this.props.seller || {};
        const commissionToSave = {};
        switch (seller.type) {
            case 1:
                commissionToSave.commission_indep = {...this.props.commissions.commission_indep};
                commissionToSave.commission_agence = {...this.props.commissions.commission_agence};
              break
            case 2:
                commissionToSave.commission_societe = {...this.props.commissions.commission_societe};
                if (commissionType === "2") {
                    commissionToSave.free_duration = {...this.props.commissions.free_duration}
                }
              break
            case 3:
                commissionToSave.commission_agence = {...this.props.commissions.commission_agence};
              break
            case 5:
                commissionToSave.commission_restaurant = {...this.props.commissions.commission_restaurant};
                if (commissionType === "2") {
                    commissionToSave.free_duration = {...this.props.commissions.free_duration}
                }
              break
            default:
        }
        return commissionToSave
    }

    handleCommissionChange = (event) => {
        const value = event.target.value;
        this.setState({
            commission: value, 
        }); 
    };

    handleMessageChange = (event) => {
        const message = event.target.value;
        if (message.length > MESSAGE_LENGTH) {
            return;
        }
        this.setState({message});
    };

    handlePartnershipRequest = () => {
        const data = {
            boutique_id: this.state.seller.id,
            commission: parseInt(this.state.commission),
            commissions: this.getCommissionToSave(this.state.commission),
            comment: this.state.message,
            status: this.state.seller.status,
            distance: this.state.seller.distance,
            is_visible: this.state.seller.isVisible,
            type_seller: this.state.seller.type,
            email: this.state.seller.email,
            phone: this.state.seller.address ? this.state.seller.address.phone : '',
            nom_magasin: `${this.state.seller.lastname || ''} ${this.state.seller.firstname || ''}`,
            address: (this.state.seller.address && `${this.state.seller.address.streetAddress1 ? `${this.state.seller.address.streetAddress1},` :''} ${this.state.seller.address.zipCode || ''} ${this.state.seller.address.city || ''} ${this.state.seller.address.country || ''}`) || ""
        };
        this.props.handleValidate(data);
    };

    render() {
        const {t} = this.props;
        const seller = this.state.seller || {};
        return (
            <div className="main-modal">
                <h4 className="stitle">{t("Contact")}</h4>
                <p>{`${seller.lastname || ''} ${seller.firstname || ''}`}<br/>
                {seller.email || ''}<br/>
                {seller.address ? seller.address.phone : ''}</p>

                <h4 className="stitle">{t("Distance")}</h4>
                <p>{seller.distance < 0 ? t("Not specified"): `${parseFloat(seller.distance || 0).toFixed(2)}km`}</p>
                <div className="line-input">
                    {
                        seller.type !== 1 && seller.type !== 3 &&
                        <React.Fragment>
                            <h4 className="stitle">{t("Commission hôtel")}</h4>
                            <div className="btn-check pt-1">
                            {["1", "2", "3"].map(commissionType =>
                                <div key={commissionType}>
                                    <label className="check">
                                        <input
                                            type="radio"
                                            name="commission"
                                            checked={this.state.commission === commissionType}
                                            onChange={this.handleCommissionChange}
                                            value={commissionType}
                                        />
                                        <span className="radiobtn"></span> {getCommissionLabel(seller.type, commissionType, this.props.commissions, t )}
                                    </label>
                                </div>
                            )}
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="line-input">
                    <label>{t("Message pour le prestataire")}</label>
                    <div className="cnt-input"><textarea onChange={this.handleMessageChange} value={this.state.message}></textarea></div>
                </div>
                <div className="cnt-Btn align-right">
                    <button className="btn-cancel" onClick={hidePopUp}>{t("Annuler")}</button>
                    <button className="button" onClick={this.handlePartnershipRequest}>{t("Confirmer")}</button>
                </div>
            </div>
        );
    }
}


export default translate('translation')(PartnershipRequestModal);

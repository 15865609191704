export const translationFR = {
    /*eslint-disable */

    //common translation
    "": "",
    "Une erreur est survenue, veuillez rafraîchir la page.": "Une erreur est survenue, veuillez rafraîchir la page.",
    "Une erreur est survenue, merci de réessayer ultérieurement.": "Une erreur est survenue, merci de réessayer ultérieurement.",
    "Les modifications ont été enregistrées.": "Les modifications ont été enregistrées.",
    "La modification a été enregistrée.": "La modification a été enregistrée.",
    "L'enregistrement des modifications a échoué.": "L'enregistrement des modifications a échoué.",
    "Erreur Stripe : impossible d'enregistrer les modifications.": "Erreur Stripe : impossible d'enregistrer les modifications.",
    "Merci de renseigner les données obligatoires.": "Merci de renseigner les données obligatoires.",
    "Le format des données de ce champ est invalide.": "Le format des données de ce champ est invalide.",
    "Veuillez saisir une adresse e-mail valide.": "Veuillez saisir une adresse e-mail valide.",
    "Veuillez entrer un numéro de téléphone valide.": "Veuillez entrer un numéro de téléphone valide.",
    "Veuillez entrer un caractère texte pour le nom." : "Veuillez entrer un caractère texte pour le nom.",
    "Veuillez entrer un caractère texte pour le prénom.": "Veuillez entrer un caractère texte pour le prénom.",
    "Veuillez entrer un mot de passe valide.": "Veuillez entrer un mot de passe valide.",
    "Modifier": "Modifier",
    "Enregistrer": "Enregistrer",
    "Enregistrer les modifications": "Enregistrer les modifications",
    "Enregistrement en cours": "Enregistrement en cours",
    "Valider": "Valider",
    "Annuler": "Annuler",
    "Supprimer": "Supprimer",
    "Prestataires": "Prestataires",
    "Rechercher": "Rechercher",
    "Rechercher…": "Rechercher…",
    "Réserver": "Réserver",
    "Importer": "Importer",
    "Confirmation": "Confirmation",
    "Confirmer": "Confirmer",
    "Refuser": "Refuser",
    "Accepter": "Accepter",
    "Voir en tant que qu'administrateur": "Voir en tant que qu'administrateur",
    "Nom": "Nom",
    "Prénom": "Prénom",
    "Adresse e-mail": "Adresse e-mail",
    "Numéro de téléphone": "Numéro de téléphone",
    "Rue": "Rue",
    "Code postal": "Code postal",
    "Ville": "Ville",
    "Pays": "Pays",
    "Affichage par page": "Affichage par page",
    "pending": "En attente",
    "processing": "Confirmée",
    "complete": "Réalisée",
    "canceled": "Annulée",
    "Traitement en cours": "Traitement en cours",
    "Chargement en cours": "Chargement en cours",
    "Tous": "Tous",
    "Date": "Date",
    "Sélectionnez une option": "Sélectionnez une option",
    "Oui": "Oui",
    "Non": "Non",
    "à": "à",

    
    //containers translation

    // containers/catalogue/DetailsCategory.js
    "Sélectionnez une catégorie pour afficher ses détails.": "Sélectionnez une catégorie pour afficher ses détails.",
    "L'image doit avoir des dimensions entre {{min}} px et {{max}} px": "L'image doit avoir des dimensions entre {{min}} px et {{max}} px",
    "La taille de l'image doit être au maximum {{max}} Ko": "La taille de l'image doit être au maximum {{max}} Ko",
    "Afficher dans le catalogue": "Afficher dans le catalogue",
    "Voir en tant que client": "Voir en tant que client",
    
    //containers/catalogue/DetailsProduct.js
    "Le prix de la prestation est un nombre obligatoire.": "Le prix de la prestation est un nombre obligatoire.",
    "Le prix de la prestation doit être supérieur ou égal au prix de cession.": "Le prix de la prestation doit être supérieur ou égal au prix de cession.",
    "Le prix doit être supérieur ou égal à": "Le prix doit être supérieur ou égal à",
    "Le supplément doit etre supérieur ou égal au prix de cession": "Le supplément doit etre supérieur ou égal au prix de cession",
    "Prix de la prestation": "Prix de la prestation",
    "Prix de cession": "Prix de cession",
    "Ajouter une option": "Ajouter une option",
    "Supplément": "Supplément",
    "Option": "Option",
    "Ajouter un lieu": "Ajouter un lieu",
    "Modification lieu": "Modification lieu",
    "Ajout lieu": "Ajout lieu",
    "Ajouter": "Ajouter",

    //containers/catalogue/ExternalService.js
    "Catégories externes présentes dans mon catalogue": "Catégories externes présentes dans mon catalogue",
    "Catégories externes invisibles de mon catalogue": "Catégories externes invisibles de mon catalogue",
    "Les produits ne peuvent pas etre activés tant que le compte de paiement de l'hôtel n'est pas actif.": "Les produits ne peuvent pas etre activés tant que le compte de paiement de l'hôtel n'est pas actif.",
    "Les catégories de prestations sans offres ne peuvent etre activées.": "Les catégories de prestations sans offres ne peuvent etre activées.",
    "La catégorie n'est pas activable car il n'y a aucun prestataire partenaire indépendant de l'hotel qui a coché la catégorie ou ses sous-catégories dans ses offres.": "La catégorie n'est pas activable car il n'y a aucun prestataire partenaire indépendant de l'hotel qui a coché la catégorie ou ses sous-catégories dans ses offres.",
    
    //containers/catalogue/index.js
    "Catalogue": "Catalogue",
    "Prestations en chambre": "Prestations en chambre",
    "Prestations externes": "Prestations externes",

    //containers/catalogue/ViewCategory.js
    "+ détails": "+ détails",
    "à partir de": "à partir de",

    //containers/clients/detail-client.js
    "Sélectionnez un pays": "Sélectionnez un pays",
    "Nombre de prestations commandées": "Nombre de prestations commandées",
    "Réinitialiser le mot de passe": "Réinitialiser le mot de passe",
    "Commandes": "Commandes",
    "Voulez-vous vraiment réinitialiser le mot de passe ?": "Voulez-vous vraiment réinitialiser le mot de passe ?",
    "Succes de la sauvegarde du client." : "Succes de la sauvegarde du client.",
    "Erreur de la sauvegarde du client.": "Erreur de la sauvegarde du client.",

    //containers/clients/index.js
    "Attribuer un code promotionnel": "Attribuer un code promotionnel",
    "L'export a échoué.": "L'export a échoué.",
    "Succès de l'export.": "Succès de l'export.",
    "Clients": "Clients",
    "Client": "Client",
    "Nom du client": "Nom du client",
    "Contact": "Contact",
    "Prestations commandées": "Prestations commandées",
    "Voir les commandes associées": "Voir les commandes associées",
    "Exporter les clients selectionnés": "Exporter les clients selectionnés",

    //containers/commandes/AvisClient.js
    "Avis client": "Avis client",
    "Nom seller": "Nom seller",
    "Adresse seller": "Adresse seller",
    "Prestation": "Prestation",
    "Date de la prestation": "Date de la prestation",
    "Avis": "Avis",

    //containers/commandes/DetailsCommande.js
    "Votre commande": "Votre commande",
    "Commande n°": "Commande n°",
    "Transaction n°": "Transaction n°",
    "Adresse de facturation": "Adresse de facturation",
    "Mode de paiement": "Mode de paiement",
    "Recapitulatif de la commande": "Recapitulatif de la commande",
    "Nom produit": "Nom produit",
    "Date de la prestation": "Date de la prestation",
    "Quantité": "Quantité",
    "Prix": "Prix",
    "Montant": "Montant",
    "Rubrique historique": "Rubrique historique",
    "Sous-total": "Sous-total",
    "Montant HT": "Montant HT",
    "TVA": "TVA",
    "Taux TVA": "Taux TVA",
    "Montant TTC": "Montant TTC",
    "Remise": "Remise",
    "Montant remboursé": "Montant remboursé",
    "Total": "Total",
    "Votre facture": "Votre facture",
    "Avis sur la commande": "Avis sur la commande",

    //containers/commandes/Facture.js
    "Facture": "Facture",
    "La génération de facture a échoué.": "La génération de facture a échoué.",
    "Succès de la génération de facture.": "Succès de la génération de facture.",
    "Télécharger votre facture": "Télécharger votre facture",
    "Facture n°": "Facture n°",
    "Payer": "Payer",
    "Catégorie produit": "Catégorie produit",

    //containers/commandes/index.js
    "Gestion commandes": "Gestion commandes",
    "Rembourser": "Rembourser",
    "Annuler la commande": "Annuler la commande",
    "Relancer le seller": "Relancer le seller",
    "La commande a bien été annulée.": "La commande a bien été annulée.",
    "L'annulation de la commande a échoué, veuillez réessayer ultérieurement.": "L'annulation de la commande a échoué, veuillez réessayer ultérieurement.",
    "La relance a bien été envoyée au prestataire concerné.": "La relance a bien été envoyée au prestataire concerné.",
    "L'envoi de la relance a échoué, veuillez réessayer ultérieurement.": "L'envoi de la relance a échoué, veuillez réessayer ultérieurement.",
    "Le remboursement de la commande a bien été effectuée.": "Le remboursement de la commande a bien été effectuée.",
    "Le remboursement de la commande a échoué, veuillez réessayer ultérieurement.": "Le remboursement de la commande a échoué, veuillez réessayer ultérieurement.",
    "Période": "Période",
    "Depuis": "Depuis",
    "Jusqu'à": "Jusqu'à",
    "Seller associé": "Seller associé",
    "Utilisation code promo": "Utilisation code promo",
    "Commandé par l'hôtel": "Commandé par l'hôtel",
    "Date/Heure prestation": "Date/Heure prestation",
    "Statut": "Statut",
    "Code promo": "Code promo",
    "Exporter les commandes en CSV": "Exporter les commandes en CSV",
    "Voulez-vous vraiment annuler cette commande ?": "Voulez-vous vraiment annuler cette commande ?",
    "Voulez-vous vraiment relancer ce prestataire ?": "Voulez-vous vraiment relancer ce prestataire ?",
    "Nom client": "Nom client",

    //containers/commandes/RemboursementCommande.js
    "Rembourser une commande": "Rembourser une commande",
    "Le montant doit être un nombre valide.": "Le montant doit être un nombre valide.",
    "Le montant est obligatoire": "Le montant est obligatoire",
    "Le montant du remboursement doit être inférieur ou égal au montant encaissé.": "Le montant du remboursement doit être inférieur ou égal au montant encaissé.",
    "Le commentaire est obligatoire.": "Le commentaire est obligatoire.",
    "Commentaire": "Commentaire",
    "Carte bancaire": "Carte bancaire",
    "Méthode de paiement": "Méthode de paiement",
    "Montant du remboursement": "Montant du remboursement",
    "Montant encaissé": "Montant encaissé",

    //containers/dashboard/index.js
    "Mes revenus": "Mes revenus",
    "Commandes en": "Commandes en",
    "attente de réponse": "attente de réponse",
    "Voir plus": "Voir plus",
    "Prestataires partenaires": "Prestataires partenaires",
    "Demandes de": "Demandes de",
    "partenariat": "partenariat",
    "Mon établissement": "Mon établissement",
    "Il y a une erreur sur la récupération des données.": "Il y a une erreur sur la récupération des données.",
    "L'invitation a été envoyée avec succès.": "L'invitation a été envoyée avec succès.",
    "Erreur sur l'envoi de l'invitation.": "Erreur sur l'envoi de l'invitation.",
    "Voir mes revenus": "Voir mes revenus",
    "Inviter des prestataires à rejoindre votre plateforme": "Inviter des prestataires à rejoindre votre plateforme",
    "Vous pouvez inviter des prestataires à devenir partenaire et ainsi proposer leurs services à vos clients. En saisissant l'adresse email du prestataire, un mail lui sera automatiquement envoyé avec les instructions pour la création de son profil.": "Vous pouvez inviter des prestataires à devenir partenaire et ainsi proposer leurs services à vos clients. En saisissant l'adresse email du prestataire, un mail lui sera automatiquement envoyé avec les instructions pour la création de son profil.",
    "Saisir l'adresse e-mail du prestataire": "Saisir l'adresse e-mail du prestataire",
    "Envoyer l'invitation": "Envoyer l'invitation",
    "Une invitation a déjà été envoyé à cette adresse email.": "Une invitation a déjà été envoyé à cette adresse email.",
    "Cette adresse email appartient à un prestataire déjà existant.": "Cette adresse email appartient à un prestataire déjà existant.",

    //containers/inscription/index.js
    "Inscription": "Inscription",

    //containers/login/index.js
    "Authentification": "Authentification",
    "Le champ Identifiant est obligatoire.": "Le champ Identifiant est obligatoire.",
    "Le champ Mot de passe est obligatoire.": "Le champ Mot de passe est obligatoire.",
    "Erreur d'authentification": "Erreur d'authentification", 
    "Votre identifiant et / ou mot de passe est erroné, merci de réessayer.": "Votre identifiant et / ou mot de passe est erroné, merci de réessayer.",
    "Erreur": "Erreur",
    "Votre session a expiré, veuillez vous reconnecter.": "Votre session a expiré, veuillez vous reconnecter.",
    "Identifiant ou adresse mail": "Identifiant ou adresse mail",
    "Mot de passe": "Mot de passe",
    "Se connecter": "Se connecter",
    "Ce champ est obligatoire.": "Ce champ est obligatoire.",
    "6 caractères au minimum et doit contenir au moins 1 chiffre, 1 lettre majuscule, 1 lettre minuscule et 1 caractère spécial.": "6 caractères au minimum et doit contenir au moins 1 chiffre, 1 lettre majuscule, 1 lettre minuscule et 1 caractère spécial.",
    "Mot de passe incorrect": "Mot de passe incorrect",
    "Revenu par mois": "Revenu par mois",
    "MES REVENUS": "MES REVENUS",

    //containers/main/Maincontainer.js
    "Chargement": "Chargement",

    //containers/modifier-landing-page/index.js
    "Modifier Image landing page": "Modifier Image landing page",
    "Dimensions invalides, l'image doit avoir des dimensions entre {{min}} px et {{max}} px": "L'image doit avoir des dimensions entre {{min}} px et {{max}} px",
    "L'image doit avoir une dimensions minimum de {{minWidth}} x {{minHeight}} px": "L'image doit avoir une dimensions minimum de {{minWidth}} x {{minHeight}} px",
    "La taille de l'image doit être au maximum {{max}} Ko": "La taille de l'image doit être au maximum {{max}} Ko",
    "Selectioner votre image principale": "Selectioner votre image principale",
    "Choisir un ficher": "Choisir un ficher",
    "L'image doit être au format .jpeg ou .png": "L'image doit être au format .jpeg ou .png",

    //containers/modifier-logo/index.js
    "Modifier le logo de l'hôtel": "Modifier le logo de l'hôtel",
    "Extension invalide, merci de choisir un fichier au format jpg ou png.": "Extension invalide, merci de choisir un fichier au format jpg ou png.",
    "Merci de choisir une image avant d'enregistrer.": "Merci de choisir une image avant d'enregistrer.",
    "Selectioner votre logo": "Selectioner votre logo",

    //containers/mon-hotel/index.js
    "Logo hotel": "Logo hotel",
    "Nom de l'Hotel": "Nom de l'Hotel",
    "Adresse postale": "Adresse postale",
    "Adresse email de l'hôtel": "Adresse email de l'hôtel",
    "Texte personnalisé d'accueil": "Texte personnalisé d'accueil",
    "Description": "Description",
    "Image landing page": "Image landing page",
    "200 caractères maximum": "200 caractères maximum",
    "Modifier les couleurs": "Modifier les couleurs",
    "Mot de passe actuel": "Mot de passe actuel",
    "Nouveau mot de passe": "Nouveau mot de passe",
    "Confirmer nouveau mot de passe": "Confirmer nouveau mot de passe",
    "Les mots de passe ne sont pas indentiques": "Les mots de passe ne sont pas indentiques",

    //containers/parametre-compte/avissignales.js
    "Avis signalés": "Avis signalés",
    "L'avis a bien été conservé.": "L'avis a bien été conservé.",
    "Une erreur est survenue lors de la conservation de l'avis.": "Une erreur est survenue lors de la conservation de l'avis.",
    "L'avis a bien été supprimé.": "L'avis a bien été supprimé.",
    "Une erreur est survenue lors de la suppression de l'avis.": "Une erreur est survenue lors de la suppression de l'avis.",
    "Message signalé": "Message signalé",
    "Utilisateur": "Utilisateur",
    "Voulez-vous vraiment conserver cet avis signalé ?": "Voulez-vous vraiment conserver cet avis signalé ?",
    "Voulez-vous vraiment supprimer cet avis signalé ?": "Voulez-vous vraiment supprimer cet avis signalé ?",
    "Conserver": "Conserver",

    //containers/parametre-compte/gestionadmin.js
    "Nom du siège social": "Nom du siège social",
    "Adresse du siège social": "Adresse du siège social",
    "Ville du siège social": "Ville du siège social",
    "Code postal du siège social": "Code postal du siège social",
    "Pays du siège social": "Pays du siège social",
    "Adresse e-mail du siège social": "Adresse e-mail du siège social",
    "Ville du gérant": "Ville du gérant",
    "Code postale du gérant": "Code postale du gérant",
    "Pays du gérant": "Pays du gérant",
    "Numéro de téléphone du gérant": "Numéro de téléphone du gérant",
    "Nom du gérant": "Nom du gérant",
    "Prénom du gérant": "Prénom du gérant",
    "Adresse postale du gérant": "Adresse postale du gérant",
    "E-mail du gérant": "E-mail du gérant",
    "Date de naissance du gérant": "Date de naissance du gérant",
    "Nom du contact de l’établissement": "Nom du contact de l’établissement",
    "Prénom du contact de l’établissement": "Prénom du contact de l’établissement",
    "Adresse e-mail du contact de l’établissement": "Adresse e-mail du contact de l’établissement",
    "Adresse postale du contact": "Adresse postale du contact",
    "Ville du contact": "Ville du contact",
    "Code postal du contact": "Code postal du contact",
    "Pays du contact": "Pays du contact",
    "Numéro de téléphone du contact de l’établissement": "Numéro de téléphone du contact de l’établissement",
    "Statut juridique": "Statut juridique",
    "SIRET": "SIRET",
    "URL Key": "URL Key",
    "Numéro de TVA": "Numéro de TVA",
    "Nom du contact de l’établissement": "Nom du contact de l’établissement",
    "Nom du compte titulaire": "Nom du compte titulaire",
    "Date de naissance": "Date de naissance",
    "Numéro de l’IBAN": "Numéro de l’IBAN",
    "Numéro du BIC": "Numéro du BIC",
    "Importer un papier d’identité": "Importer un papier d’identité",
    "Importer la photocopie du Kbis": "Importer la photocopie du Kbis",
    "Il y a une erreur sur vos informations de siège social.": "Il y a une erreur sur vos informations de siège social.",
    "Il y a une erreur sur vos informations de contact.": "Il y a une erreur sur vos informations de contact.",
    "Il y a une erreur sur vos informations juridiques.": "Il y a une erreur sur vos informations juridiques.",
    "Il y a une erreur sur vos informations bancaires.": "Il y a une erreur sur vos informations bancaires.",
    "Il y a une erreur sur vos informations de paiement Stripe.": "Il y a une erreur sur vos informations de paiement Stripe.",
    "Votre profil est complété à": "Votre profil est complété à",
    "Attention, certaines fonctionnalités ne sont pas disponibles si des informations obligatoires ne sont pas renseignées.": "Attention, certaines fonctionnalités ne sont pas disponibles si des informations obligatoires ne sont pas renseignées.",
    "Siège social de l’hôtel": "Siège social de l’hôtel",
    "Contact de l’hôtel": "Contact de l’hôtel",
    "Informations juridiques": "Informations juridiques",
    "Informations du compte bancaire": "Informations du compte bancaire",
    "Auto-entrepreneur": "Auto-entrepreneur",
    "EI": "EI",
    "EIRL": "EIRL",
    "EURL": "EURL",
    "SA": "SA",
    "SARL": "SARL",
    "SARLU": "SARLU",
    "SAS": "SAS",
    "SASU": "SASU",
    "SC": "SC",
    "SCA": "SCA",
    "SCS": "SCS",
    "SNC": "SNC",
    "Entreprise individuelle": "Entreprise individuelle",
    "Entreprise individuelle à responsabilité limitée": "Entreprise individuelle à responsabilité limitée",
    "Entreprise unipersonnelle à responsabilité limitée": "Entreprise unipersonnelle à responsabilité limitée",
    "Société anonyme": "Société anonyme",
    "Société à responsabilité limitée": "Société à responsabilité limitée",
    "Société anonyme à responsabilité limité uni-personnelle": "Société anonyme à responsabilité limité uni-personnelle",
    "Société par actions simplifiée": "Société par actions simplifiée",
    "Société par actions simplifiée unipersonnelle": "Société par actions simplifiée unipersonnelle",
    "Société civile": "Société civile",
    "Société en commandite par actions": "Société en commandite par actions",
    "Société en commandite simple": "Société en commandite simple",
    "Société en nom collectif": "Société en nom collectif",
    "En erreur": "En erreur",
    "Numéro du BIC invalide": "Numéro du BIC invalide",
    "Numéro de l’IBAN invalide": "Numéro de l’IBAN invalide",
    "SIRET invalide": "SIRET invalide",
    "Numéro de TVA invalide": "Numéro de TVA invalide",
    "En attente": "En attente",
    "En cours de validation": "En cours de validation",
    "Refusé": "Refusé",
    "Vérifié": "Vérifié",
    "Valide": "Valide",
    "Invalide": "Invalide",
    "Le représentant doit avoir au moins 18ans.": "Le représentant doit avoir au moins 18ans.",
    "Le titulaire du compte doit avoir au moins 18ans.": "Le titulaire du compte doit avoir au moins 18ans.",
    "This file upload is already attached to an account.": "Le fichier uploadé est déjà associé à un compte.",

    //containers/parametre-compte/import-identity-paper.js
    "Importer fichier": "Importer fichier",
    "Une erreur est survenue lors de l'import du fichier.": "Une erreur est survenue lors de l'import du fichier.",
    "Le fichier a bien été importé.": "Le fichier a bien été importé.",
    "Importer un papier d'identité": "Importer un papier d'identité",

    //containers/parametre-compte/import-kbis-photocopy.js
    "Importer la photocopie du Kbis": "Importer la photocopie du Kbis",

    //containers/parametre-compte/index.js
    "Paramètres du compte": "Paramètres du compte",
    "Autoriser les notifications par SMS ?": "Autoriser les notifications par SMS ?",
    "Supprimer mon compte": "Supprimer mon compte",
    "Attention ! Cette action est irréversible, vous ne pourrez plus accéder à vos informations ou à vos prestations.": "Attention ! Cette action est irréversible, vous ne pourrez plus accéder à vos informations ou à vos prestations.",
    
    //containers/parametre-compte/langues.js
    "Votre niveau dans cette langue": "Votre niveau dans cette langue",
    "Listes des langue": "Listes des langue",
    "La langue par défaut ne peut pas être désactivée!": "La langue par défaut ne peut pas être désactivée!",

    //containers/prestataires/index.js
    "Prestataires partenaire(s)": "Prestataires partenaire(s)",
    "Tous les prestataires": "Tous les prestataires",
    "Demandes de partenariat": "Demandes de partenariat",
    "Lien d’affiliation": "Lien d’affiliation",
    "The password entered for the current user is invalid. Verify the password and try again.": "Le mot de passe entré pour l'utilisateur actuel n'est pas valide. Vérifiez le mot de passe et réessayez.",
    "Mot de passe invalide.": "Mot de passe invalide.",
    "Un utilisateur avec la même adresse email existe déjà": "Un utilisateur avec la même adresse email existe déjà",

    //containers/prestataires/Partner.js
    "Institut": "Institut",
    "Agence": "Agence",
    "Indépendant": "Indépendant",
    "Restaurant": "Restaurant",
    "Commandes associées": "Commandes associées",
    "Mettre fin au partenariat": "Mettre fin au partenariat",
    "Renouveler": "Renouveler",
    "La demande a bien été envoyée avec succès.": "La demande a bien été envoyée avec succès.",
    "L'envoi de la demande a échoué, merci de réessayer ultérieurement.": "L'envoi de la demande a échoué, merci de réessayer ultérieurement.",
    "Nom du prestataire": "Nom du prestataire",
    "Type de prestataire": "Type de prestataire",
    "Catégorie": "Catégorie",
    "Masqué du catalogue": "Masqué du catalogue",
    "Visible": "Visible",
    "Prestataire": "Prestataire",
    "Début partenariat": "Début partenariat",
    "Marge hôtel": "Marge hôtel",
    "Mettre fin au partenariat avec ce partenaire ?": "Mettre fin au partenariat avec ce partenaire ?",
    "Pourcentage": "Pourcentage",
    "Fin de partenariat": "Fin de partenariat",
    "Une demande a déjà été envoyée à ce prestataire": "Une demande a déjà été envoyée à ce prestataire",
    "L'email de cet établissement existe déjà dans la base client, merci de contacter le prestataire pour le modifier.": "L'email de cet établissement existe déjà dans la base client, merci de contacter le prestataire pour le modifier.",
    "L'envoi de la demande a échoué, les informations de ce prestataire ne sont pas complètes.": "L'envoi de la demande a échoué, les informations de ce prestataire ne sont pas complètes.",
    "Hotel inexistant": "Hotel inexistant",


    //containers/prestataires/PartnershipRequest.js
    "En attente de votre réponse": "En attente de votre réponse",
    "Votre réponse a bien été envoyée.": "Votre réponse a bien été envoyée.",
    "Distance": "Distance",
    "Marge hôtel": "Marge hôtel",
    "Répondre": "Répondre",
    "Message du prestataire": "Message du prestataire",
    "Votre message": "Votre message",
    "Contact du prestataire": "Contact du prestataire",
    "Marge hôtel proposée": "Marge hôtel proposée",
    "Marge hôtel proposée": "",
    "Faire une contre-proposition de marge hôtel": "Faire une contre-proposition de marge hôtel",
    "Votre contre-proposition": "Votre contre-proposition",
    "Votre contre-proposition": "",
    "Message pour le prestataire": "Message pour le prestataire",
    "HT": "HT",
    "En attente de la réponse du prestataire": "En attente de la réponse du prestataire",
    "Votre réponse": "Votre réponse",
    "Not specified": "Non définie",

    //containers/prestataires/PartnershipRequestModal.js
    "Demande de partenariat": "Demande de partenariat",
    "Marge hôtel suggérée (hors taxe)": "Marge hôtel suggérée (hors taxe)",
    "0% de marge": "0% de marge",
    "Marge par défaut": "Marge par défaut",
    "Commission hôtel": "Commission hôtel",
    "Commission par défaut": "Commission par défaut",
    "par couvert": "par couvert",
    "3 mois gratuit": "3 mois gratuit",
    "Gratuit (pas de commission)": "Gratuit (pas de commission)",
    "Frais de plateforme standard": "Frais de plateforme standard",
    "Frais de plateforme remisé": "Frais de plateforme remisé",
    "Frais de plateforme réduits": "Frais de plateforme réduits",
    "pendant": "pendant",
    "mois": "mois",


    
    //containers/prestataires/Seller.js
    "L'envoi de la demande de partenariat a échoué, merci de réessayer ultérieurement.": "L'envoi de la demande de partenariat a échoué, merci de réessayer ultérieurement.",
    "La demande de partenariat a bien été envoyée.": "La demande de partenariat a bien été envoyée.",
    "La demande a bien été envoyée avec succès.": "La demande a bien été envoyée avec succès.",
    "L'envoi de la demande a échoué, merci de réessayer ultérieurement.": "L'envoi de la demande a échoué, merci de réessayer ultérieurement.",
    "Error enregistrement Partnership": "Erreur sur l'envoi de la demande de partenariat",
    "Erreur sur l'envoi de la demande de partenariat": "Erreur sur l'envoi de la demande de partenariat",

    //containers/prestataires/SellerDetail.js
    "Horaires d’ouverture": "Horaires d’ouverture",
    "Du": "Du",
    "au": "au",
    "de": "de",
    "à": "à",
    "Anonyme": "Anonyme",
    "détails": "détails",

    //containers/theme/index.js
    "Éditer la couleur du thème": "Éditer la couleur du thème",
    "Les champs Couleur du fond et Couleur du texte ne peuvent pas être vides.": "Les champs Couleur du fond et Couleur du texte ne peuvent pas être vides.",
    "Les champs Couleur du fond et Couleur du texte doivent être au format hexadécimal.": "Les champs Couleur du fond et Couleur du texte doivent être au format hexadécimal.",
    "Une erreur est survenue, merci de réessayer plus tard.": "Une erreur est survenue, merci de réessayer plus tard.",
    "Les modifications ont été enregistrées.": "Les modifications ont été enregistrées.",
    "Couleur du fond": "Couleur du fond",
    "Couleur du texte": "Couleur du texte",
    "Carousel": "Carousel",
    "Room Service": "Room Service",
    "Travel Benefit": "Travel Benefit",
    "How It Work": "How It Work",
    "Feedback": "Feedback",
    "Register": "Register",
    "Services": "Services",

    //Component

    //components/dashboard/selectionDate.js
    "Aujourd’hui": "Aujourd’hui",
    "Semaine en cours": "Semaine en cours",
    "Mois en cours": "Mois en cours",
    "Année en cours": "Année en cours",
    "Choisir date": "Choisir date",
    "Janvier": "Janvier",
    "Fevrier": "Fevrier",
    "Mars": "Mars",
    "Avril": "Avril",
    "Mai": "Mai",
    "Juin": "Juin",
    "Juillet": "Juillet",
    "Aout": "Aout",
    "Septembre": "Septembre",
    "Octobre": "Octobre",
    "Novembre": "Novembre",
    "Decembre": "Decembre",
    "Dimanche": "Dimanche",
    "Lundi": "Lundi",
    "Mardi": "Mardi",
    "Mercredi": "Mercredi",
    "Jeudi": "Jeudi",
    "Vendredi": "Vendredi",
    "Samedi": "Samedi",
    "Di": "Di", 
    "Lu": "Lu", 
    "Ma": "Ma", 
    "Me": "Me", 
    "Jeu": "Jeu", 
    "Ve": "Ve", 
    "Sa": "Sa",

    //components/footer/footer.js
    "FAQ": "FAQ",
    "CGU": "CGU",
    "CGV": "CGV",
    "Mentions légales": "Mentions légales",
    "Politiques de confidentialité": "Politiques de confidentialité",
    "contact url": "https://www.myconciergehotel.com/contact/",
    "Politique de Confidentialité url": "https://www.myconciergehotel.com/politique-de-confidentialite-mch/",
    "Mentions Légales url": "https://www.myconciergehotel.com/mentions-legales-mch/",
    "CGV url": "https://www.myconciergehotel.com/conditions-generales-de-vente-mch/",
    "CGS url": "https://www.myconciergehotel.com/conditions-generales-de-service-mch/",
    "CGU url": "https://www.myconciergehotel.com/conditions-generales-d-utilisation-mch/",
    "FAQ url": "https://www.myconciergehotel.com/faq-hotels/",

    //components/headers/headers.js
    "Gestion administration": "Gestion administration",
    "Avis signalés ": "Avis signalés ",
    "Contacter le support": "Contacter le support",
    "Liste des langues": "Liste des langues",
    "Mon hôtel": "Mon hôtel",
    "Tableau de bord": "Tableau de bord",
    "Mon hôtel": "Mon hôtel",
    "Catalogue": "Catalogue",
    "Clients": "Clients",
    "Les prestataires": "Les prestataires",
    "Gestion des commandes": "Gestion des commandes",
    "Voulez-vous vraiment vous déconnecter?" :"Voulez-vous vraiment vous déconnecter?",

    //components/path/path.js
    "Les lieux": "Les lieux",
    "Lieu": "Lieu",
    "Vente": "Vente",
    "Cession": "Cession",
    "Prix de vente": "Prix de vente",
    "Le prix de vente doit être supérieur ou égal à": "Le prix de vente doit être supérieur ou égal à",
    "Le prix": "Le prix",
    "ne doit pas dépasser": "ne doit pas dépasser",
    "doit dépasser": "doit dépasser",
    "doit être supérieur ou égal à": "doit être supérieur ou égal à",
}
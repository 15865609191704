import config from "../../../config";

const baseURL = config.baseURL;

export default {
    LOADING: 'dashboard/LOADING',
    GET_MY_INCOME_URL: `${baseURL}/rest/V1/getMyIncome`,
    GET_MY_INCOME: 'dashboard/GET_MY_INCOME',
    GET_DASHBOARD_VALUES_URL: `${baseURL}/rest/V1/dashboardInfos`,
    GET_DASHBOARD_VALUES: 'GET_DASHBOARD_VALUES',
    GET_MY_MONTHLY_INCOME_URL: `${baseURL}/rest/V1/getIncomeHotel`,
    GET_MY_MONTHLY_INCOME: 'dashboard/GET_MY_MONTHLY_INCOME',
};

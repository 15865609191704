import CommonBDL from './common.bdl';
import constants from '../redux/constants/catalog';
import config from "../../config";

class CatalogBDL extends CommonBDL {

    fetchCategories = (query) => {
        const url = `${constants.FETCH_CATEGORIES_URL}?query=${query}`;
        return this.get(url);
    };
    
    reorderCategory = (data) => {
        return this.post(constants.REORDER_URL, data);
    };
    
    saveImageCategory = (data) => {
        return this.post(constants.SAVE_IMAGE_CATEGORY_URL, data);
    };
    
    toggleCategory = (id, enabled) => {
        let url;
        const data = {category_id: id, type: 0};
        if (enabled === true) {
            url = constants.ENABLE_CATEGORY_URL;
        } else {
            url = constants.DISABLE_CATEGORY_URL;
        }
    
        return this.post(url, data);
    };
    
    getProduct = (id) => {
        const url = `${constants.GET_PRODUCT_URL}?Id=${id}`;
        return this.get(url);
    };

    getAvailableOptions = (id) => {
        const url = `${constants.GET_AVAILABLE_OPTIONS_URL}`;
        return this.get(url);
    };
    
    updateProduct = (param) => {
        return this.post(constants.UPDATE_PRODUCT_URL, param);
    };
    
    fetchExternalCategories = (query) => {
        const url = `${constants.FETCH_EXTERNAL_CATEGORIES_URL}?query=${query}`;
        return this.get(url);
    };
    
    toggleExternalCategory = (id, enabled) => {
        let url = null;
        const data = {category_id: id, type: 1};
    
        if (enabled === true) {
            url = constants.ENABLE_CATEGORY_URL;
        } else {
            url = constants.DISABLE_CATEGORY_URL;
        }
        return this.post(url, data);
    };
    
    fetchExternalSubCategories = (id) => {
        const url = `${constants.FETCH_EXTERNAL_SUB_CATEGORIES_URL}?categoryId=${id}`;
        return this.get(url);
    };
    
    checkStripeStatus = () => {
        return this.get(constants.CHECK_STRIPE_STATUS_URL);
    };

    getEnabledCategories = ()=>{
        return this.get(constants.GET_ENABLED_CATEGORIES_URL);
    }

    getVtcRate = ()=>{
        return this.get(`${constants.GET_VTC_RATE_URL}?categoryId=${config.codeHotel}`);
    }

    saveDeliveryPlace = (data) => {
        return this.post(constants.SAVE_DELIVERY_PLACE_URL, data)
    }

    getAllDeliveryPlace = (idPrestation)=>{
        return this.get(`${constants.GET_ALL_DELIVERY_PLACE_URL}?id_prestation=${idPrestation}`);
    }
   
}

export const catalogBDL = new CatalogBDL();

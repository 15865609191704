
import {isValidIBAN, isValidBIC} from "ibantools";
import {isSIRET} from "siret";
import { checkVAT, france } from 'jsvat';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default class FormHandler {
    static isValidEmail = (email) => {
        if (email.length === 0) {
            return true;
        }
        const regex = RegExp('(?:[a-z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])');
        return regex.test(email);
    };

    static isValidPassword = (password) => {
        //const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*([0-9]|[!@#\\$%\\^&\\*\\?]))(?=.{6,})');
        //CBNV-719: restriction mdp : min 6 caractère dont 1 majuscule, 1 minuscule et 1 caractère spécial et chiffre
        const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9áàâäãéèêëíìîïóòôöõúùûüýÿÁÀÂÄÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝç])(?=.{6,})');
        return regex.test(password);
    };

    static isValidHexFormat = (color) => {
        const regex = RegExp('^(#{0,1})([0-9a-fA-F]{8}|[0-9a-fA-F]{6})$');
        return regex.test(color);
    };

    // static isValidPhoneNumber = (number) => {
    //     const regex = RegExp('^((\\+)33|0)[1-9](\\d{2}){4}$');
    //     return regex.test(number);
    // };

    static hasValidDimensions = (image, dimensions) => {
        if (!image instanceof Image) {
            return false;
        }

        return (image.width >= dimensions.minWidth && image.width <= dimensions.maxWidth) && (image.height >= dimensions.minHeight && image.height <= dimensions.maxHeight);
    };

    static isAlphabetic = (text) => {
        const regex = RegExp(/^[a-zA-Z\u00C0-\u00FF\- ]*$/);
        return regex.test(text);
    };

    static isNumeric = (number) => {
        const regex = RegExp('^[0-9]*$');
        return regex.test(number);
    };

    static isFloat = (number) => {
        return !/^\s*$/.test(number) && !isNaN(number);
    }

    static notVoidError(value) {
        if (!value){
          return "Ce champ est obligatoire";
        }     
        return "";
      }


    static nameErrors = (value)=> {
        if (!value){
            return "Ce champ est obligatoire";
        }else if(!FormHandler.isAlphabetic(value)){
            return 'Le format des données de ce champ est invalide.'
        }else if (value.length > 30)
            return 'Ce champ ne doit pas contenir plus de 30 caractères';      
        else
            return "";
    }

    static nameWithSpecialCharsErrors(value) {
        if (!value){
          return "Ce champ est obligatoire";
        }else if (value.length > 30)
          return 'Ce champ ne doit pas contenir plus de 30 caractères';      
        else
          return "";
      }

    static descriptionErrors = (value)=> {
        if (!value){
            return "Ce champ est obligatoire";
        } else if (value.length > 200) {
            return 'Ce champ ne doit pas contenir plus de 200 caractères';
        }else {
            return "";
        }
    }

    static adressError = (value)=> {
        if (!value){
            return "";
        }else if (value.length > 50) {
            return 'Ce champ ne doit pas contenir plus de 50 caractères';
        }else {
            return "";
        }
    }

    static adressNotVoidError = (value)=> {
        if (!value){
            return "Ce champ est obligatoire";
        }else if (value.length > 50) {
            return 'Ce champ ne doit pas contenir plus de 50 caractères';
        }else {
            return "";
        }
    }

    static numberErrors = (value)=> {
        if (!value) {
            return "Ce champ est obligatoire";
        }else if (isNaN(`${value}`.replace(',','.'))) {
            return 'Montant invalide';
        } else {
            return "";
        }
    }

    static numberIntegerErrors = (value) => {
        if (!value){
            return "";
        }else if (isNaN(value) ) {
            return 'Nombre invalide';
        }
        else if ( !Number.isInteger(value / 1)) {
            return 'Nombre invalide';
        }else {
            return "";
        }
    }

    static numberIntegerNotVoidErrors = (value) => {
        if (!value){
            return "Ce champ est obligatoire";
        }else if (isNaN(value) ) {
            return 'Nombre invalide';
        }
        else if ( !Number.isInteger(value / 1)) {
            return 'Nombre invalide';
        }else {
            return "";
        }
    }

    static confirmNewsPassErrors(password, confirmPassword) {
        if (!confirmPassword) {
            return "Ce champ est obligatoire";
        } else if (password !== confirmPassword) {
            return "Les mots de passe ne sont pas indentiques";
        } else {
            return "";
        }
    }

    static notEmptyInput = (value)=> {
        if (value == null) {
            return "Ce champ est obligatoire";
        } else if (value.length === 0) {
            return "Ce champ est obligatoire";
        } else {
            return "";
        }
    }

    static ibanValid = (value)=>{
        if (value && !isValidIBAN(value)) {
            return "Numéro de l’IBAN invalide";
        }
        return ""; 
    }

    static bicValid = (value)=>{
        if (value && !isValidBIC(value)) {
            return "Numéro du BIC invalide";
        }
        return ""; 
    }

    static siretValid = (value)=>{
        if (value && !isSIRET(value)) {
            return "SIRET invalide";
        }
        return ""; 
    }

    static tvaValid = (value)=>{
        if (value) {
            const checkedVat = checkVAT(value, [france]);
            if (!checkedVat || !checkedVat.isValid) {
                return "Numéro de TVA invalide";
            }
        }
        return ""; 
    }

    static emailError = (value)=>{
        if (!value ) {
            return "Ce champ est obligatoire";
        } else if (!FormHandler.isValidEmail(value)) {
            return "Email invalide";
        } else {
            return "";
        }
    }

    static phoneError = (value)=>{
        if (!value ) {
            return "";
        } else if (!isValidPhoneNumber(value)) {
            return "N° téléphone invalide";
        } else {
            return "";
        }
    }

    static phoneNotVoidError = (value)=>{
        if (!value ) {
            return "Ce champ est obligatoire";
        } else if (!isValidPhoneNumber(value)) {
            return "N° téléphone invalide";
        } else {
            return "";
        }
    }
}

import CommonBDL from './common.bdl';
import constants from '../redux/constants/client';

class ClientBDL extends CommonBDL {

    fetchCustomers = (page, nbPerPage, query) => {
        const url = `${constants.FETCH_URL}?page=${page}&perPage=${nbPerPage}&firstname=${query}`;
        return this.get(url);
    };
    
    resetPassword = (id) => {    
        return this.post(`${constants.RESET_PASSWORD_URL}${id}`);
    };
    
    exportCustomers = (customers) => {
        let ids = customers.join('&customerIds[]=');
        const url = `${constants.EXPORT_URL}?customerIds[]=${ids}`;
        return this.get(url);
    }

    getCustomerDetails = (id) => {
        return this.get(`${constants.DETAILS_CUSTOMER_URL}?customer_id=${id}`);
    };
    
    saveCustomer = (data) => {
        return this.post(constants.SAVE_CUSTOMER_URL, data);
    };
}

export const clientBDL = new ClientBDL();

import { noticeSA } from "../../applicatif/notice.sa";
import constants from '../constants/notice';

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};


export const fetchAll = (query) => dispatch =>{
    showLoading(dispatch, constants.FETCH_ALL_REPORTED_NOTICES)
    const request = noticeSA.fetchAll(query);

    dispatch ({
        type: constants.FETCH_ALL_REPORTED_NOTICES,
        payload: request,
    });
};

export const keepNotice = (data) => dispatch =>{
    showLoading(dispatch, constants.KEEP_NOTICE)
    const request = noticeSA.keepNotice(data);

    dispatch ({
        type: constants.KEEP_NOTICE,
        payload: request,
    });
};

export const deleteNotice = (data) => dispatch =>{
    showLoading(dispatch, constants.DELETE_NOTICE)
    const request = noticeSA.deleteNotice(data);

    dispatch ({
        type: constants.DELETE_NOTICE,
        payload: request,
    });
};

export const getUnreadNoticeNb = () => dispatch =>{
    showLoading(dispatch, constants.GET_UNREAD_NOTICE_NB)
    const request = noticeSA.getUnreadNoticeNb();

    dispatch ({
        type: constants.GET_UNREAD_NOTICE_NB,
        payload: request,
    });
};

export const initializeUnreadNoticeNb = () => dispatch =>{
    showLoading(dispatch, constants.INITIALIZE_UNREAD_NOTICE_NB)
    const request = noticeSA.initializeUnreadNoticeNb();

    dispatch ({
        type: constants.INITIALIZE_UNREAD_NOTICE_NB,
        payload: request,
    });
};



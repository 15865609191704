import constants from '../redux/constants/auth';
import { authenticationBDL } from "../business-delegate/authentication.bdl";
import store from '../redux/store';

class AuthenticationSA {
    async login(username, password) {
        const token = await this.authenticate(username, password);
        if (token && token.code === 200) {
            store.dispatch({
                type: constants.EXPIRES_DATE,
                payload: false,
            })
            await localStorage.setItem(constants.TOKEN, token.access_token);
            return token.code;
        }else{
            return token ? token.code : 500
        }
    };

    async authenticate(username, password) {
        let errorResult = null
        const response = await authenticationBDL.authenticate(username, password)
            .catch(exception=>{
                errorResult = exception.response && exception.response.data;
            })
        if (response && response.data && response.data.code === 200) {
            return response.data;
        }
         
        return errorResult
    }

    logout = () => {
        localStorage.removeItem(constants.TOKEN);
        sessionStorage.clear();
    };

    isAuthenticated = () => {
        const token = localStorage.getItem(constants.TOKEN);
        const success = token;
        const error = !token || !token.trim() ? constants.TOKEN_ERROR : null;
        return {
            success,
            error
        };
    };
}
export const  authenticationSA = new AuthenticationSA();

import constants from '../constants/loading';

const loading = (state = null, action) => {
    switch (action.type) {
        case constants.SHOW_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case constants.HIDE_LOADING:
            return {
                ...state,
                isLoading: false,
            };
       
        default:
            return state;
    }
};

export default loading;

import { dashboarSA } from "../../applicatif/dashboard.sa";
import constants from '../constants/dashboard';

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};

export const getMyIncome = () => dispatch => {
    showLoading(dispatch, constants.GET_MY_INCOME)
    const request = dashboarSA.getMyIncome();
    dispatch ({
        type: constants.GET_MY_INCOME,
        payload: request
    })
};

export const getMyMonthlyIncome = (year) => dispatch => {
    showLoading(dispatch, constants.GET_MY_MONTHLY_INCOME)
    const request = dashboarSA.getMyMonthlyIncome(year);
    dispatch ({
        type: constants.GET_MY_MONTHLY_INCOME,
        payload: request
    })
}

export const getDashboardValues = () => dispatch => {
    showLoading(dispatch, constants.GET_DASHBOARD_VALUES)
    const request = dashboarSA.getDashboardValues();
    dispatch ({
        type: constants.GET_DASHBOARD_VALUES,
        payload: request
    })
}

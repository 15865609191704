import CommonBDL from './common.bdl';
import constants from '../redux/constants/dashboard';
import { SELECTION_DATE } from "../../data/constants/selection.date";

class DashboardBDL extends CommonBDL {
    getMyIncome = (dateInfo) => {
        const range = dateInfo.value === SELECTION_DATE.CHOOSE_DATE.value ? `&rangeFrom=${dateInfo.rangeFrom}&rangeTo=${dateInfo.rangeTo}` : ''
        return this.get(`${constants.GET_MY_INCOME_URL}?dateInfo=${dateInfo.value}${range}`);
    };

    getDashboardValues = () => {
        return this.get(constants.GET_DASHBOARD_VALUES_URL);
    };

    getMyMonthlyIncome = (year) => {
        return this.get(`${constants.GET_MY_MONTHLY_INCOME_URL}?year=${year}`);
    };
}

export const  dashboardBDL = new DashboardBDL();

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import Currency from 'react-currency-formatter';
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import {fetchOrders, exportOrders, cancelOrder, relaunchSeller, refundOrder} from "../../../services/redux/actions/commandes";
import {getPartnersFilters} from "../../../services/redux/actions/seller";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import {withRouter} from 'react-router-dom';
import RemboursementCommande from "../../components/command/RemboursementCommande";
import { ORDER_STATUS } from "../../../data/constants/order.status";
import { sellerSA } from "../../../services/applicatif/seller.sa";
import PopupConfirm from "../../components/popup/popup.confirmation";
import {showPopUp, hidePopUp} from "../../components/popup/popup";
import Pagination from "../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "../../../data/constants/page.size";


const ORDER = {
    PRESTATION_DATE_UP: {icon: 'arrow_drop_up', field: 'booking_start', direction: 'asc'},
    PRESTATION_DATE_DOWN: {icon: 'arrow_drop_down', field: 'booking_start', direction: 'desc'}
}

class Commandes extends Component {
    constructor(props) {
        super(props);
        const prestationDateOrder = ORDER.PRESTATION_DATE_DOWN;
        this.state = {
            error: false,
            cancel: {
                success: null,
                orderNumber: null,
            },
            relaunch: {
                success: null,
                id: null,
            },
            refund: {
                order: null,
                success: null,
            },
            query: {
                customer: '',
                sellerid: '',
                status: '',
                useCodePromo: '',
                orderedByHotel: '',
                offset: 1,
                limit: PAGE_SIZE_LIST[0],
                orderby: prestationDateOrder.field,
                direction: prestationDateOrder.direction
            },
            orders: [],
            statusFilters: Object.keys(ORDER_STATUS).map(key =>({value: ORDER_STATUS[key].value, label: props.t(ORDER_STATUS[key].value)})),
            sellerFilters: [],
            selectedOrders: [],
            from: props.location.from,
            prestationDateOrder,
            nbPages: 1
        };
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
    }

    componentDidMount() {
        this.props.getPartnersFilters();
        if (this.state.from === "details" && this.props.commandes && this.props.commandes.fetchOrders && this.props.commandes.fetchOrders.data && this.props.commandes.fetchOrders.data.orders) {
            this.setState(oldState=>({
                error: false,
                orders: this.props.commandes.fetchOrders.data.orders.list || [],
                totalElements: this.props.commandes.fetchOrders.data.orders.total_order || 0,
                query: this.props.commandes.orderQuery || oldState.query,
            }));
        }else{
            const params = new URLSearchParams(this.props.location.search.replace('?', ''));
            const customer = params.get('customer') || '';
            const sellerid = params.get('sellerid') || '';
            const status = params.get('status') || '';
            const direction = params.get('direction') || 'desc';
            let prestationDateOrder = this.state.prestationDateOrder;
            Object.keys(ORDER).forEach(key=>{
                if (ORDER[key].direction === direction) {
                    prestationDateOrder = ORDER[key]
                }
            });
            const query = {...this.state.query, customer, sellerid, status, direction};
            this.setState({
                query,
                prestationDateOrder
            }, this.fetchOrders);
        }
        
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.commandes;
        const props = this.props.commandes;
        const prevSeller = prevProps.seller;
        const propsSeller = this.props.seller;

        if (!prev) {
            return;
        }
        if (prev.fetchOrders && props.fetchOrders && prev.fetchOrders.loading && !props.fetchOrders.loading) {
            hidePopUp();
            if (props.fetchOrders.error) {
                this.setState({
                    error: true,
                });
            }else{
                const orders = (props.fetchOrders.data && props.fetchOrders.data.orders) || {}
                this.setState({
                    error: false,
                    orders: orders.list || [],
                    totalElements: orders.total_order,
                    nbPages: orders.nbPages
                });
                
            }
        }

        if (prev.exportOrder && props.exportOrder && prev.exportOrder.loading && !props.exportOrder.loading) {
            if (props.exportOrder.error) {
                this.setState({exportSuccess: false});
            }else{
                const exportUrl = props.exportOrder.data && props.exportOrder.data.csvfile
                if (exportUrl) {
                    this.handleFileDownload(exportUrl,"export.csv");
                } else {
                    this.setState({exportSuccess: false});
                }
            }
        }

        if (prev.cancelOrder && props.cancelOrder && prev.cancelOrder.loading && !props.cancelOrder.loading) {
            const cancel = {
                orderNumber: null,
                success: !props.cancelOrder.error,
            };
            this.setState({cancel});
            if (!props.cancelOrder.error) {
                this.fetchOrders();
            }else{
                hidePopUp();
            }
        }

        if (prev.relaunchSeller && props.relaunchSeller && prev.relaunchSeller.loading && !props.relaunchSeller.loading) {
            const relaunch = {
                id: null,
                success: !props.relaunchSeller.error,
            };
            this.setState({relaunch});
            if (!props.relaunchSeller.error) {
                this.fetchOrders();
            }else{
                hidePopUp();
            }
        }

        if (prev.refundOrder && props.refundOrder && prev.refundOrder.loading && !props.refundOrder.loading) {
            const refund = {
                id: null,
                success: !props.refundOrder.error,
            };
            this.setState({refund});
            if (!props.refundOrder.error) {
                this.fetchOrders();
            }else{
                hidePopUp();
            }
        }

        if (prevSeller.partnerFilters && propsSeller.partnerFilters && prevSeller.partnerFilters.loading && !propsSeller.partnerFilters.loading) {
            if (!propsSeller.partnerFilters.error) {
                const sellerFilters = (propsSeller.partnerFilters.data && propsSeller.partnerFilters.data.partenaires) || [];
                this.setState({
                    sellerFilters,
                });
            }
        }
    }

    getSellers = ()=>{
        sellerSA.fetchAll({perPage:200})
        .then(result=>{
            const sellerFilters = (result && result.data && result.data.sellers && result.data.sellers.data) || [];
            this.setState({
                sellerFilters
            })
        })
    }

    handleCustomerChange = (event) => {
        this.setState({
            query: {
                ...this.state.query,
                customer: event.target.value,
            }
        });
    };

    handleFromChange = (value) => {
        const date = value ? moment(new Date(value)).format('YYYY-MM-DD') : ""
        this.setState({
            query: {
                ...this.state.query,
                from: date
            }
        });
    };

    handleToChange = (value) => {
        const date = value ? moment(new Date(value)).format('YYYY-MM-DD') : ""
        this.setState({
            query: {
                ...this.state.query,
                to: date,
            }
        });
    };

    handleSellerChange = (event) => {
        this.setState({
            query: {
                ...this.state.query,
                sellerid: event.target.value,
            }
        });
    };

    handleStatusChange = (event) => {
        this.setState({
            query: {
                ...this.state.query,
                status: event.target.value,
            }
        });
    };

    handleUseCodePromoChange = (event) => {
        this.setState({
            query: {
                ...this.state.query,
                useCodePromo: event.target.checked ? 1 : 0
            }
        });
    };

    handleOrderedByHotelChange = (event) => {
        this.setState({
            query: {
                ...this.state.query,
                orderedByHotel: event.target.checked ? 1 : 0
            }
        });
    };

    handlePaginationChange = (page) => {
        this.setState({
            query: {
                ...this.state.query,
                offset: parseInt(page)
            }
        },this.fetchOrders);
    };

    handleItemsPerPageChange = (event) => {
        const perPage = event.target.value;
        this.setState({
            query: {
                ...this.state.query,
                limit: parseInt(perPage),
                offset: 1
            }
        },this.fetchOrders);
    };

    goToDetailsOrder = (event) => {
        const id = event.target.dataset.id;
        this.props.history.push(`/commande/${id}`)
    };

    handleSearch= () => {
        this.setState(oldState =>({
            query:{...oldState.query, offset: 1},
        }),()=>{
            this.fetchOrders();
        })
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSearch()
        }
    }

    fetchOrders= () => {
        const query = {...this.state.query}
        if (!query.from) {
            delete query.from;
        }
        if (!query.to) {
            delete query.to;
        }
        this.props.fetchOrders(query);
    };

    showCancel = (event) => {
        const orderNumber = event.target.dataset.id;
        const cancel = {
            orderNumber,
            success: null,
        };
        this.setState({cancel});
        showPopUp(
            <PopupConfirm 
                message = {this.props.t("Voulez-vous vraiment annuler cette commande ?")}
                onAccept = { this.handleCancelOrder }
            />,
            this.props.t("Confirmation")
        )
    };

    handleCancelOrder = () => {
        this.props.cancelOrder(this.state.cancel.orderNumber);
        const cancel = {
            orderNumber: null,
            success: null,
        };
        this.setState({cancel});
    };

    showRelaunch = (event) => {
        const id = event.target.dataset.id;
        const relaunch = {
            id: id,
            success: null,
        };
        this.setState({relaunch});
        showPopUp(
            <PopupConfirm 
                message = {this.props.t("Voulez-vous vraiment relancer ce prestataire ?")}
                onAccept = { this.handleRelaunchSeller }
            />,
            this.props.t("Confirmation")
        )
    };

    handleRelaunchSeller = () => {
        const order = this.getOrderByid(this.state.relaunch.id);
        const data = {
            mail_seller: order.sellerEmail,
            name_seller: order.sellerName,
            order_number: order.orderNumber,
        }
        this.props.relaunchSeller(data);
        const relaunch = {
            id: null,
            success: null,
        };
        this.setState({relaunch});
    };

    showRefund = (event) => {
        const id = event.target.dataset.id;
        const order = this.getOrderByid(id);
        const refund = {
            id: id,
            success: null,
        };
        this.setState({refund});
        showPopUp(
            <RemboursementCommande 
                handleValidate={this.handleRefundOrder}
                order={order}
            />,
            this.props.t("Rembourser une commande")
        )
    };

    handleRefundOrder = (data) => {
        this.props.refundOrder(data);
        const refund = {
            id: null,
            success: null,
        };
        this.setState({refund});
    };

    handleCheckedChange = (event) => {
        const id = event.target.dataset.id;
        const orders = this.state.selectedOrders;

        if (!orders.includes(id)) {
            orders.push(id);
        } else {
            orders.splice(orders.indexOf(id), 1);
        }
        this.setState({selectedOrders: orders});
    };

    handleSelectAll = () => {
        let orders = this.state.selectedOrders;
        if (orders.length === this.state.orders.length) {
            orders = [];
        } else {
            orders = [];
            this.state.orders.forEach(order => {
                orders.push(order.id);
            });
        }
        this.setState({selectedOrders: orders});
    };

    getOrderByid = (id) => {
        if (!id) {
            return null
        }
        return this.state.orders.find(order =>parseInt(order.id) === parseInt(id));
    };

    getOrderStatus = (order) =>{
        const status = Object.keys(ORDER_STATUS).find(status=>(
             ORDER_STATUS[status].value === order.status
        ))
       return (status && ORDER_STATUS[status].value) || ""
    }

    handleExport = () => {
        if (this.state.selectedOrders.length > 0) {
            this.props.exportOrders(this.state.selectedOrders);
        }
    };
    
    goToInvoice = (event) => {
        const id = event.target.dataset.id
        const order = this.getOrderByid(id);
        if (!order || order.status !== 'complete') {
            return
        }
        this.props.history.push(`/facture/${order.orderNumber}`);
    };

    handleFileDownload = (url, filename)=> {
        var a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
    }

    handleSortChange = ()=>{
        const prestationDateOrder = this.state.prestationDateOrder === ORDER.PRESTATION_DATE_UP ? ORDER.PRESTATION_DATE_DOWN : ORDER.PRESTATION_DATE_UP;
        const query = {
            ...this.state.query,
            orderby: prestationDateOrder.field,
            direction: prestationDateOrder.direction
        };
        this.setState({
          query,
          prestationDateOrder
        }, this.fetchOrders)
    }

    setDisabledDate = type => {
        const hotelCreationDate = (this.props.hotel && this.props.hotel.hotelInfo.data && this.props.hotel.hotelInfo.data.InfosHotel && this.props.hotel.hotelInfo.data.InfosHotel.length > 0 && this.props.hotel.hotelInfo.data.InfosHotel[0] && this.props.hotel.hotelInfo.data.InfosHotel[0].date_created) || null
        let list = hotelCreationDate ? [{ "before": new Date(hotelCreationDate)}] : [];
        if (type === "from") {
          list = this.state.query.to ? [...list, { "after": new Date(this.state.query.to) }] : list;
        }
        else if (type === "to") {
          list = this.state.query.from ? [...list, { "before": new Date(this.state.query.from) }] : list;
        }
        return list
      }

    renderOrdersList = () => {
        const {t} = this.props;
        return this.state.orders && this.state.orders.map((order) =>
            <tr key={order.id} >
                <td className="cnt-check">
                    <label className="checkbox-label">
                        <input type="checkbox" 
                            data-id={order.id}
                            onChange={this.handleCheckedChange}
                            checked={this.state.selectedOrders.includes(order.id) ? "checked" : ""}
                        />
                        <span className="check"></span>
                    </label>
                </td>
                <td>
                    <span 
                        onClick={this.goToDetailsOrder}
                        data-id={order.id}
                        className="name-prestation clickable"
                    >
                        {(order.description && order.description[0] && order.description[0].product_name) || ''}
                    </span>
                    <p>{order.category}</p>
                    <p>{t("Commande n°")}{order.orderNumber}<br/>
                        {t("Transaction n°")}{order.transaction}
                    </p>
                </td>
                <td>{order.customer}</td>
                <td>{order.bookingStart ? moment(order.bookingStart, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY  HH:mm') : ""}</td>
                <td>
                    {order.sellerName}<br/>
                    {order.sellerPhone}
                </td>
                <td>{t(this.getOrderStatus(order))}</td>
                {/* <td>{order.codePromo}</td> */}
                <td>{order.orderedByHotel && parseInt(order.orderedByHotel) ? t("Oui") : t("Non")}</td>
                <td className="cnt-link">
                    <p className="price">
                        <Currency
                            quantity={order.price ? parseInt(order.price) : 0}
                            currency={order.currency_code || 'EUR'}
                        />
                    </p> 
                    {order && order.status === 'complete' &&
                    <div>
                        <Link to={`/avis-client/${order.id}`}>{t("Voir l'avis client")}</Link>
                        <button data-id={order.id}
                                onClick={this.goToInvoice}
                        >
                            {t("Facture")}
                        </button>
                        <button data-id={order.id}
                                onClick={this.showRefund} className="dark-slate-blue"
                        >{t("Rembourser")}</button>
                    </div>
                    }
                    {((order.status === "processing" && order.flag_order !==2) || order.status === "pending") &&
                        <button data-id={order.orderNumber}
                                onClick={this.showCancel}
                        >{t("Annuler la commande")}</button>
                    }
                    {order.status === "pending" &&
                        <button data-id={order.id} className="dark-slate-blue"
                                onClick={this.showRelaunch}
                        >{t("Relancer le seller")}</button>
                    }
                </td>
            </tr>
        );
    };

    renderPagination = () => {
        const {t}=this.props;
        const itemsPerPage = this.state.query ? this.state.query.limit : PAGE_SIZE_LIST[0];
        return (
            <div className="pagination">
                <div>
                    <Pagination
                        totalElements={this.state.nbPages * itemsPerPage}
                        changePageCallback={pageNumber =>
                            this.handlePaginationChange(pageNumber)
                        }
                        itemsPerPage={itemsPerPage}
                        activePage={this.state.query ? this.state.query.offset : 1}
                    />
                </div>
                <div>
                    {t("Affichage par page")}
                    <select 
                        value={(this.state.query && this.state.query.limit) || PAGE_SIZE_LIST[0]}
                        onChange={this.handleItemsPerPageChange}
                    >
                      { PAGE_SIZE_LIST.map(size =>
                        <option value={size}>{size}</option>
                      )}
                    </select>
                </div>
            </div>
        );
    };

    render() {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>{t("Gestion commandes")}</h1>
                    </div>
                </div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {this.state.cancel.success === true && PopupHandler.showSuccess(t("La commande a bien été annulée."))}
                    {this.state.cancel.success === false && PopupHandler.showError(t("L'annulation de la commande a échoué, veuillez réessayer ultérieurement."))}
                    {this.state.relaunch.success === true && PopupHandler.showSuccess(t("La relance a bien été envoyée au prestataire concerné."))}
                    {this.state.relaunch.success === false && PopupHandler.showError(t("L'envoi de la relance a échoué, veuillez réessayer ultérieurement."))}
                    {this.state.exportSuccess === false && PopupHandler.showError(t("L'export a échoué."))}
                    {this.state.exportSuccess && PopupHandler.showSuccess(t("Succès de l'export."))}
                    {this.state.refund.success === true && PopupHandler.showSuccess(t("Le remboursement de la commande a bien été effectuée."))}
                    {this.state.refund.success === false && !this.state.refund.errorMessage && PopupHandler.showError(t("Le remboursement de la commande a échoué, veuillez réessayer ultérieurement."))}
                </div>
                <div className="main-commande">
                    <div className="wrapper clr">
                        <div className="cnt-filtre">
                            <div className="block-search-filter">
                                <div>
                                    <h3>{t("Client")}</h3>
                                    <input type="text"
                                           className="input-search"
                                           placeholder={`${t("Nom client")}...`}
                                           value={this.state.query.customer}
                                           onChange={this.handleCustomerChange}
                                           onKeyDown={this.handleSearchKeyDown}
                                    />
                                </div>
                                <div>
                                    <h3>{t("Période")}</h3>
                                    <div className="cnt-periode">
                                        <div className="cnt-periode-item">
                                            <div className="txt">{t("Depuis")}</div>
                                            <div className="select select-down">
                                                <i className="material-icons arrow-down">keyboard_arrow_down</i>
                                                <DayPickerInput
                                                    inputProps={{ readOnly: true }}
                                                    format="DD/MM/YYYY"
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    placeholder={"jj/mm/aaaa"}
                                                    value={this.state.query.from ? new Date(this.state.query.from): null}
                                                    initialMonth={new Date()}
                                                    onDayChange={this.handleFromChange}
                                                    getDayPicker={(e) => this.handleFromChange(e.target.value)}
                                                    dayPickerProps={{
                                                        modifiers: {
                                                        disabled: this.setDisabledDate("from")
                                                        },
                                                        localeUtils: MomentLocaleUtils, 
                                                        locale: "fr"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="cnt-periode-item">
                                            <div className="txt">{t("Jusqu'à")}</div>
                                            <div className="select select-down">
                                                <i className="material-icons arrow-down">keyboard_arrow_down</i>
                                                <DayPickerInput
                                                    inputProps={{ readOnly: true }}
                                                    format="DD/MM/YYYY"
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    placeholder={"jj/mm/aaaa"}
                                                    value={this.state.query.to ? new Date(this.state.query.to): null}
                                                    initialMonth={new Date()}
                                                    onDayChange={this.handleToChange}
                                                    getDayPicker={(e) => this.handleToChange(e.target.value)}
                                                    dayPickerProps={{
                                                        modifiers: {
                                                        disabled: this.setDisabledDate("to")
                                                        },
                                                        localeUtils: MomentLocaleUtils, 
                                                        locale: "fr"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3>{t("Seller associé")}</h3>
                                    <div className="select">
                                        <select value={this.state.query.sellerid}
                                                onChange={this.handleSellerChange}
                                        >
                                            <option value="">{t("Tous")}</option>
                                            {this.state.sellerFilters && this.state.sellerFilters.map(seller =>
                                                <option key={seller.id} value={seller.id}>{seller.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <h3>{t("Statut")}</h3>
                                    <div className="select">
                                        <select value={this.state.query.status}
                                                onChange={this.handleStatusChange}
                                        >
                                            <option value="">{t("Tous")}</option>
                                            {this.state.statusFilters.map(status =>
                                                <option key={status.value} value={status.value}>{status.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {/* <div>
                                    <h3>{t("Utilisation code promo")}</h3>
                                    <label className="checkbox-label">
                                        <input type="checkbox"
                                           checked={this.state.query.useCodePromo}
                                           onChange={this.handleUseCodePromoChange}
                                        />
                                        <span className="check"></span>
                                    </label>
                                </div> */}
                                <div>
                                    <h3>{t("Commandé par l'hôtel")}</h3>
                                    <label className="checkbox-label">
                                        <input type="checkbox"
                                               checked={this.state.query.orderedByHotel}
                                               onChange={this.handleOrderedByHotelChange}
                                        />
                                        <span className="check"></span>
                                    </label>
                                </div>
                                <div className="cnt-btn">
                                    <button className="btnSearch"
                                    onClick={this.handleSearch}
                                    >{t("Rechercher")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table  table-commande">
                            <div className="table-responsive scrollbar">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            <label className="checkbox-label">
                                                <input type="checkbox" 
                                                    className="check-all"
                                                    checked={this.state.orders && this.state.selectedOrders && this.state.selectedOrders.length === this.state.orders.length ? "checked" : ""}
                                                    onChange={this.handleSelectAll}
                                                /><span
                                                className="check"></span>
                                            </label>
                                        </th>
                                        <th>{t("Prestation")}</th>
                                        <th>{t("Client")}</th>
                                        <th>
                                            {t("Date/Heure prestation")}
                                            <span className="filtre clickable" onClick={this.handleSortChange}>
                                                <i className="material-icons">
                                                {this.state.prestationDateOrder.icon}
                                                </i>
                                            </span>
                                        </th>
                                        <th>{t("Seller associé")}</th>
                                        <th>{t("Statut")}</th>
                                        {/* <th>{t("Code promo")}</th> */}
                                        <th>{t("Commandé par l'hôtel")}</th>
                                        <th>{t("Prix")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderOrdersList()}
                                    </tbody>
                                </table>
                            </div>
                            <div>{this.state.totalElements > 0 && this.renderPagination()}</div>
                            <div className="cnt-Btn">
                                <span 
                                    className= {`button ${this.state.selectedOrders.length < 1 ? 'disable': ''}`}  
                                    onClick={this.handleExport}
                                >
                                    {t("Exporter les commandes en CSV")}
                                </span>
                                <div className="clr"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        commandes: state.commandes,
        seller: state.seller, 
        hotel: state.hotel
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrders,
        exportOrders,
        cancelOrder, 
        relaunchSeller,
        refundOrder,
        getPartnersFilters
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Commandes)));

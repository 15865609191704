import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import moment from 'moment';
import Currency from 'react-currency-formatter';
import {getOrderDetails, getCustomerReview} from "../../../services/redux/actions/commandes";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import {withRouter} from 'react-router-dom';
import { ORDER_STATUS } from "../../../data/constants/order.status";

class DetailsCommande extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            error: false,
            order: {}
        };
    }

    componentDidMount() {
        this.props.getOrderDetails(this.state.id);
        this.props.getCustomerReview(this.state.id);
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.commandes;
        const props = this.props.commandes;
        if (!prev) {
            return;
        }

        if (prev.detailsOrders && props.detailsOrders && prev.detailsOrders.loading && !props.detailsOrders.loading) {
            if (props.detailsOrders.error) {
                this.setState({
                    error: true,
                });
            }else{
                this.setState({
                    error: false,
                    order: (props.detailsOrders.data && props.detailsOrders.data.orders) || {},
                },this.setOrderStatus);
                
            }
        }
        if (prev.customerReview && props.customerReview && prev.customerReview.loading && !props.customerReview.loading) {
            if (props.customerReview.error) {
                this.setState({
                    error: true,
                });
            }else{
                const data = (props.customerReview.data && props.customerReview.data.data) || null
                const review =  data && !Array.isArray(data) && Object.keys(data).length > 0 ? data : null;
                this.setState({
                    error: false,
                    review
                });
            }
        }
    }

    setOrderStatus = () =>{
        const orderStatus = (this.state.order && this.state.order.status) || "";
        const status = Object.keys(ORDER_STATUS).find(status=>(
             ORDER_STATUS[status].value === orderStatus
        ))
        this.setState({
            status:  (status && ORDER_STATUS[status].value) || "",
        }) 
    }

    render() {
        const {t} = this.props;
        const order = this.state.order || {};
        const customer = this.state.order.customer || {};
        const seller = this.state.order.seller || {};
        const items = this.state.order.description || [];
        const currencyCode = order.currency_code  || 'EUR';
        const status = (this.state.order && this.state.order.status) || "";
        
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>
                            <Link to={{pathname: "/gestion-commandes", from: "details"}}>
                                <span className="back"><i className="material-icons icon-back">arrow_back</i></span>
                            </Link> {t("Votre commande")}
                        </h1>
                    </div>
                </div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                </div>
                <div className="wrapper main-commande">
                    <div className="mainBody">
                        <div className="infos-commande">
                            <div className="head-det-commande">
                                <p className="nameClient">
                                    <span className="ico">
                                        <i className="material-icons icon-person">person_outline</i>
                                    </span> 
                                    <p className="nameClient">{seller.seller_name || ""}</p>
                                    <span className="statut">
                                        {t(this.state.status)}
                                    </span>
                                </p>
                                <p className="date-commande mb-0"><span className="ico"><i className="material-icons icon-person">date_range</i></span>{order.date ? moment(order.date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : ""} </p>
                                <p className="hour-commande"><span className="ico"><i className="material-icons">schedule</i></span> {order.date ? moment(order.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : ""}</p>
                                <p className="num-commande">{t("Commande n° ")}{order.order_number || ""} </p>
                                <p className="transaction-Commande">{t("Transaction n° ")}{(order.payment_info && order.payment_info.transaction) || ""}</p>
                            </div>
                            <div className="row">
                                <div className="col col-6 info-client-commande">
                                    <h4 className="titre">{t("Adresse de facturation")}</h4>
                                    <p className="nameClient">{customer.customer_name || ""}</p>
                                    <p className="mailClient">{customer.customer_email || ""}</p>
                                    <p className="adressClient">{`${customer.street || ""}, ${customer.postcode || ""} ${customer.region || ""} ${customer.city || ""}`}</p>
                                    <p className="telClient">{customer.telephone || ""}</p>
                                </div>
                                <div className="col col-6 info-client-commande">
                                    <h4 className="titre">{t("Mode de paiement")}</h4>
                                    <p className="mode-paiment"><span className="ico"><i className="material-icons icon-card">credit_card</i></span> {(order.payment_info && order.payment_info.payement_methode) || ""}</p>
                                </div>
                            </div>
                        </div>
                        <div className="recap-commande">
                            <div className="titre-recap-commande">
                                {t("Recapitulatif de la commande")}
                            </div>
                            <div className="table table-commande">
                                <div className="table-responsive scrollbar">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{t("Nom produit")}</th>
                                                <th className="th-date">{t("Date de la prestation")}</th>
                                                <th className="th-qtt">{t("Quantité")}</th>
                                                <th className="th-montant">{t("Prix")}</th>
                                                <th className="th-reponse">{t("Montant")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items && items.map(item =>(
                                                <tr>
                                                    <td><span className="name-prestation">{item.prestation}</span><p>{item.type_prestation}</p></td>
                                                    <td>
                                                        {item.date_start_prestation ? moment(item.date_start_prestation, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : ""}
                                                        <p>{item.date_start_prestation ? moment(item.date_start_prestation, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : ""}</p>
                                                    </td>
                                                    <td>{(item.qty_invoiced && parseFloat(item.qty_invoiced) > 0 && parseFloat(item.qty_invoiced).toFixed(2)) || 1}</td>
                                                    <td> 
                                                        <Currency
                                                            quantity={item.price ? parseFloat(item.price) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                    <td className="align-right"> 
                                                        <Currency
                                                            quantity={item.row_total ? parseFloat(item.row_total) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-6">
                                    <h3 className="title-et">{t("Rubrique historique")}</h3>
                                    <p>{order.historicalSection}</p>
                                </div>
                                <div className="col col-6 table table-commande">
                                    <div className="table-responsive scrollbar">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="font-bold">{t("Sous-total")} </td>
                                                    <td className="align-right"> 
                                                        <Currency
                                                            quantity={order.subtotal ? parseFloat(order.subtotal) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold">{t("Montant HT")}</td>
                                                    <td className="align-right"> 
                                                        <Currency
                                                            quantity={order.price_HT ? parseFloat(order.price_HT) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold">{t("TVA")}</td>
                                                    <td className="align-right"> 
                                                        <Currency
                                                            quantity={order.tax_amount ? parseFloat(order.tax_amount) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold">{t("Taux TVA")}</td>
                                                    <td className="align-right">{(order.taux_tva && parseFloat(order.taux_tva).toFixed(2)) || 0} %</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold">{t("Montant TTC")}</td>
                                                    <td className="align-right"> 
                                                        <Currency
                                                            quantity={order.price_TTC ? parseFloat(order.price_TTC) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold">{t("Remise")}</td>
                                                    <td className="align-right">{(order.discount_amount && parseFloat(order.discount_amount).toFixed(2)) || 0} %</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold">{t("Montant remboursé")}</td>
                                                    <td className="align-right"> 
                                                        <Currency
                                                            quantity={order.total_refunded ? parseFloat(order.total_refunded) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr className="total-commande">
                                                    <td className="font-bold">{t("Total")}</td>
                                                    <td className="align-right font-bold">
                                                        <Currency
                                                            quantity={order.total ? parseFloat(order.total) : 0}
                                                            currency={currencyCode}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-Btn align-right">
                                <button 
                                    disabled={status !== 'complete'} 
                                    className="button btn-facture" 
                                    onClick={()=>{this.props.history.push(`/facture/${order.order_number}`)}}
                                >
                                    {t("Votre facture")}
                                </button>
                                <button 
                                    className="button"
                                    disabled= {!this.state.review}
                                    onClick={()=>{this.props.history.push({pathname: `/avis-client/${order.id}`, from: "details"})}}
                                >
                                    {t("Avis sur la commande")}
                                </button>
                                <div className="clr"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {commandes: state.commandes};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({getOrderDetails, getCustomerReview}, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsCommande)));

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Select, { components } from "react-select";
import en from 'react-phone-number-input/locale/en.json';
import PhoneInput from 'react-phone-number-input/input';
import {getHotelUser} from "../../../services/redux/actions/hotel";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import FormHandler from "../../../services/error-handlers/formHandler";
import { hotelSA } from "../../../services/applicatif/hotel.sa";

import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";

const NAME_LENGTH = 50;
const EMAIL_LENGTH = 50;

const SingleValue = props => (
    <components.SingleValue {...props}>
      {props.data.chipLabel}
    </components.SingleValue>
);

const options = getCountries().map((country) => ({
    label: en[country] + ' +'+getCountryCallingCode(country),
    chipLabel: '+'+getCountryCallingCode(country),
    value: country
})); 

class ParametreCompte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: false,
            firstName: '',
            lastName: '',
            email: '',
            newEmail: '',
            password: '',
            phone: '',
            hasError: false,
            firstNameError: false,
            lastNameError: false,
            isValidEmail: true,
            isFormError: true,
            hasValidPhoneNumber: false,
            isValidPassword: true,
            currentCountry: 'FR',
            displayedPassword: 'Aa1#ClickButtler',
            passwordChanged: false,
            showPassword: false,
            showMailAdress: false,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            currentPasswordError: "",
            newPasswordError: "",
            confirmNewPasswordError: "",
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmNewPassword: false,
            changePasswordError: false,
            emptyField: props.t("Ce champ est obligatoire."),
            errorPassword: props.t("6 caractères au minimum et doit contenir au moins 1 chiffre, 1 lettre majuscule, 1 lettre minuscule et 1 caractère spécial."),
            errorEmail: props.t("Veuillez saisir une adresse e-mail valide.")
        };
    }

    componentDidMount() {
        if (this.props.hotel && this.props.hotel.hotelUser && this.props.hotel.hotelUser.data && this.props.hotel.hotelUser.data.userData && this.props.hotel.hotelUser.data.userData.length > 0 ) {
            this.setHotelUserState(this.props.hotel.hotelUser.data.userData[this.props.hotel.hotelUser.data.userData.length-1]);
        }else{
            this.props.getHotelUser();
        }
    }

    componentDidUpdate(props) {
        const prev = props.hotel;
        const curr = this.props.hotel;
        if (!prev) {
            return;
        }
        if (prev.hotelUser && curr.hotelUser && prev.hotelUser.loading === true && curr.hotelUser.loading === false) {
            if (curr.hotelUser.error) {
                this.setState({hasError: true});
            }else{
                const hotelUser = curr.hotelUser && curr.hotelUser.data && curr.hotelUser.data.userData && curr.hotelUser.data.userData.length > 0 ? curr.hotelUser.data.userData[curr.hotelUser.data.userData.length-1] : {};
                this.setHotelUserState(hotelUser);
            }
        }
    }


    handleNotificationChange = (event) => {
        this.setState({
            notification: event.target.value === 'yes'
        }, this.saveNotificationStatus);
    };

    handleFirstNameChange = (event) => {
        const value = event.target.value;
        if (value.length > NAME_LENGTH) {
            return;
        }
        this.setState({firstName: value}, this.validateForm);
    };

    handleLastNameChange = (event) => {
        const value = event.target.value;
        if (value.length > NAME_LENGTH) {
            return;
        }
        this.setState({lastName: value}, () =>{
            this.validateForm();
        });
    };

    handleEmailChange = (event) => {
        const value = event.target.value;
        if (value.length > EMAIL_LENGTH) {
            return;
        }
        this.setState({newEmail: event.target.value}, this.validateForm);
    };

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value,
            passwordChanged: true
        }, this.validateForm);
    };

    handlePhoneChange = (value) => {        
        const phoneEmpty = !value || !value.trim();
        const phoneError = !phoneEmpty && isValidPhoneNumber(value) === false;
        const notification = phoneEmpty || phoneError ? false : this.state.notification;
        this.setState({
            phone: value,
            phoneEmpty,
            phoneError,
            notification
        }, this.validateForm);
    };

    handleContrySelect (value) {
        this.setState({
            currentCountry: value.value,
            phone: ""
        }, this.validateForm);
    }

    updateState =  (event) => {
        let name = event.target.name;
        this.setState({
             [name]: event.target.value
        }, this.validatePasswordEmailChange);
    }

    setHotelUserState = (hotelUser)=>{
        this.setState(oldstate =>({
            id: hotelUser.id,
            notification: hotelUser.sms_notififcation === 1 ? true : false,
            firstName: hotelUser.nom || "",
            lastName: hotelUser.prenom || "",
            email: hotelUser.email || "",
            newEmail: "",
            password: hotelUser.hasPassword ? oldstate.displayedPassword : '',
            phone: hotelUser.tel || "",
            currentCountry: (hotelUser.tel && isValidPhoneNumber(hotelUser.tel)) ? parsePhoneNumber(hotelUser.tel).country : "FR",
            hasError: false,
        }), this.validateForm);
    }

    validateForm = () => {
        const firstNameError = FormHandler.nameErrors(this.state.firstName);
        const lastNameError = FormHandler.nameErrors(this.state.lastName); 
        const phoneError = FormHandler.phoneNotVoidError(this.state.phone);        
        const isFormError = firstNameError || lastNameError || phoneError;
        this.setState({
            firstNameError,
            lastNameError,
            phoneError,
            isFormError
        })
    };

    validatePasswordEmailChange = () => {
        const {t}=this.props;
        const currentPasswordError = (!this.state.currentPassword && this.state.emptyField) || (!FormHandler.isValidPassword(this.state.currentPassword) && this.state.errorPassword) || "";
        let newPasswordError= "";
        let confirmNewPasswordError= "";
        let changePasswordError = false;
        let emailError="";

        if (this.state.showPassword) {
            newPasswordError= (!this.state.newPassword && this.state.emptyField) || (!FormHandler.isValidPassword(this.state.newPassword) && this.state.errorPassword) || "";
            confirmNewPasswordError= (!this.state.confirmNewPassword && this.state.emptyField) || (this.state.confirmNewPassword !== this.state.newPassword && t("Les mots de passe ne sont pas indentiques"))
                || (!FormHandler.isValidPassword(this.state.confirmNewPassword) && this.state.errorPassword) || "";
            changePasswordError =  newPasswordError || confirmNewPasswordError;
        }
        if (this.state.showMailAdress) {
            emailError = (!this.state.newEmail && this.state.emptyField) || (!FormHandler.isValidEmail(this.state.newEmail) && this.state.errorEmail) || "";
        }
        
        this.setState({
            currentPasswordError,
            newPasswordError,
            confirmNewPasswordError,
            changePasswordError,
            emailError,
        })
    }

    handleSubmit = (event) => {
        if (!this.state.isFormError && !this.state.changePasswordError && !this.state.emailError && !this.state.currentPasswordError) {
            event.preventDefault();
            const data = {
                id: this.state.id,
                nom: this.state.firstName,
                prenom: this.state.lastName,
                tel: this.state.phone,
                email: this.state.email,
                newEmail: this.state.newEmail,
                sms_notififcation: this.state.notification ? "1" : "0",
                password: this.state.currentPassword,
                newPassword: this.state.newPassword,
                confirmNewPassword: this.state.confirmNewPassword,
            }
            
            this.props.showLoading();
            hotelSA.saveUserHotel({data})
            .then(response=>{
                this.setState({
                    updateSuccess: true,
                    newEmail: "",
                    currentPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                })
                this.props.getHotelUser();
                this.props.hideLoading();
            })
            .catch(exception=>{
                this.props.hideLoading();
                const error = exception.response && exception.response.data;
                const {t}=this.props;
                if (error && error.message) {
                    let message = t(error.message);
                    if (error.message.includes("is not a valid email address in the basic format")) {
                        message = t("Veuillez saisir une adresse e-mail valide.")
                    }else if (error.message.includes("Le mot de passe doit contenir au minimum 7 caractères")) {
                        message = t("Mot de passe invalide.")
                    }else if (error.message.includes("adresse email existe déjà")) {
                        message = t("Un utilisateur avec la même adresse email existe déjà")
                    }
                    showPopupExtra(<PopupError title={t("Erreur")} message={message}/>)
                }      
                this.setState({
                    updateSuccess: false
                })
            })
        }
    };

    saveNotificationStatus = () => {
        const status = this.state.notification ? 1 : 0;
        const data = {
            status,
            phone: this.state.phone
        }
        this.props.showLoading();
        hotelSA.saveNotificationStatus(data)
        .then(response=>{
            this.setState({
                saveNotificationSuccess: true
            })
            this.props.hideLoading();
        })
        .catch(()=>{
            this.props.hideLoading();
            this.setState({
                saveNotificationSuccess: false
            })
        })
    };

    showHideComponent(name) {
        let showEditEmailPassword = false;
        switch (name) { 
            case "showHideEditEmailPassword":
                this.setState(oldState=>({
                    showEditEmailPassword: false,
                    showPassword: false,
                    showMailAdress: false,
                    currentPassword: "", 
                    newPassword: "", 
                    confirmNewPassword: "", 
                    currentPasswordError: "",
                    newPasswordError: "",
                    confirmNewPasswordError: "",
                    changePasswordError: false,
                    emailError: '',
                    newEmail: "",
                }));
                break;
            case "showHidePassword":
                showEditEmailPassword = (this.state.showPassword && this.state.showMailAdress) || !this.state.showPassword;
                this.setState(oldState=>({
                    showPassword: !oldState.showPassword, 
                    showEditEmailPassword,
                    currentPassword: showEditEmailPassword ? oldState.currentPassword : "", 
                    newPassword: "", 
                    confirmNewPassword: "", 
                    currentPasswordError: showEditEmailPassword ? oldState.currentPasswordError : "", 
                    newPasswordError: "",
                    confirmNewPasswordError: "",
                    changePasswordError: false,
                }));
                break;
            case "showHideMailAdress":
                showEditEmailPassword= (this.state.showMailAdress && this.state.showPassword) || !this.state.showMailAdress;
                this.setState(oldState=>({ 
                    showMailAdress: !oldState.showMailAdress,
                    showEditEmailPassword,
                    currentPassword: showEditEmailPassword ? oldState.currentPassword : "", 
                    emailError: '',
                    newEmail: '',
                    currentPasswordError: showEditEmailPassword ? oldState.currentPasswordError : "", 
                }));
                break;
            default:
                break;
        }
    }


  togglePasswordVisibility = (identity) => {
    switch (identity) {
        case "currentPassword":
            this.setState(oldState => ({
                showCurrentPassword: !oldState.showCurrentPassword
            }));
            break;
        case "newPassword":
            this.setState(oldState => ({
                showNewPassword: !oldState.showNewPassword
            }));
            break;
        case "confirmNewPassword":
            this.setState(oldState => ({
                showConfirmNewPassword: !oldState.showConfirmNewPassword
            }));
            break;
        default:
            break;
    }

  };

    render() {
        const {t} = this.props;
        const { showPassword, showMailAdress, showEditEmailPassword } = this.state;
        const isFormError = this.state.isFormError || this.state.changePasswordError || this.state.emailError || this.state.currentPasswordError;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>{t("Paramètres du compte")}</h1>
                    </div>
                </div>
                <div>
                    {this.state.hasError && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {(this.state.updateSuccess || this.state.saveNotificationSuccess) && PopupHandler.showSuccess(t("Les modifications ont été enregistrées."))}
                    {(this.state.updateSuccess === false || this.state.saveNotificationSuccess === false) && PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
                </div>
                <div className="main monHotel">
                    <div className="wrapper clr">
                        <div className="row mainBody">
                            <div className="col col-6 line-input">
                                <label>{t("Nom")}*</label>
                                <div className="cnt-input">
                                    <input type="text" 
                                        className={this.state.firstNameError ? "hasError" : ""} 
                                        value={this.state.firstName} 
                                        onChange={this.handleFirstNameChange}
                                    />
                                </div>
                                {
                                    this.state.firstNameError && 
                                    <span className="error">{this.state.firstNameError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Prénom")}*</label>
                                <div className="cnt-input">
                                    <input type="text" 
                                        className={this.state.lastNameError ? "hasError" : ""} 
                                        value={this.state.lastName} 
                                        onChange={this.handleLastNameChange}
                                    />
                                </div>
                                {
                                    this.state.lastNameError && 
                                    <span className="error">{this.state.lastNameError}</span>
                                }
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Adresse e-mail")}*</label>
                                <div className="cnt-input with-icon">
                                    <input type="email" 
                                        value={this.state.email} 
                                        onChange={this.handleEmailChange}
                                        disabled
                                    />
                                    <span className="edit" onClick={() => this.showHideComponent("showHideMailAdress")}>
                                        <i className="material-icons icon-edit">edit</i>
                                    </span>
                                </div>
                            </div>
                            <div className="col col-6 line-input">
                                <label>{t("Mot de passe")}*</label>
                                <div className="cnt-input with-icon">
                                    <input type="password" 
                                        className={this.state.passwordError || this.state.passwordEmpty ? "hasError" : ""} 
                                        value={this.state.password} 
                                        disabled
                                    />
                                    <span className="edit" onClick={() => this.showHideComponent("showHidePassword")}>
                                        <i className="material-icons icon-edit">edit</i>
                                    </span>
                                </div>
                            </div>
                            {showEditEmailPassword && 
                                <div className="col col-12 blc-show-hide">
                                    <div className="remove-btn">
                                        <span 
                                            className="popup-close lpicto-close icon clickable" 
                                            onClick={() => this.showHideComponent("showHideEditEmailPassword")}
                                        >
                                            <i className="material-icons icon-edit">
                                                close
                                            </i>
                                        </span>
                                    </div>
                                    <div className="row">
                                        <div className="col col-7 line-input">
                                            <label>{t("Mot de passe actuel")}*</label>
                                            <div className="cnt-input with-icon">
                                                <input
                                                    type={this.state.showCurrentPassword ? "text" : "password"}
                                                    className={this.state.currentPasswordError ? "hasError" : ""} 
                                                    value={this.state.currentPassword}
                                                    onChange={this.updateState}
                                                    name="currentPassword"
                                                />
                                                <span
                                                    className={`edit clickable`}
                                                    onClick={() => this.togglePasswordVisibility("currentPassword")}
                                                    name='currentPassword'
                                                >
                                                    <i className="material-icons icon-edit">{!this.state.showCurrentPassword ? 'lock' : 'lock_open'}</i>
                                                </span>
                                            </div>
                                            {
                                                this.state.currentPasswordError &&
                                                <span className='error'>{this.state.currentPasswordError}</span>
                                            }
                                        </div>
                                        {showPassword && (
                                            <React.Fragment>
                                                <div className="col col-7 line-input">
                                                    <label>{t("Nouveau mot de passe")}*</label>
                                                    <div className="cnt-input with-icon">
                                                    <input
                                                        type={this.state.showNewPassword ? "text" : "password"}
                                                        className={this.state.newPasswordError  ? "hasError" : ""} 
                                                        value={this.state.newPassword}
                                                        onChange={this.updateState}
                                                        name="newPassword"
                                                    />
                                                    <span
                                                        className={`edit clickable`}
                                                        onClick={() => this.togglePasswordVisibility("newPassword")}
                                                        name='newPassword'
                                                    >
                                                        <i className="material-icons icon-edit">{!this.state.showNewPassword ? 'lock' : 'lock_open'}</i>
                                                    </span>
                                                    </div>
                                                    {
                                                        this.state.newPasswordError &&
                                                        <span className='error'>{this.state.newPasswordError}</span>
                                                    }
                                                </div>
                                                <div className="col col-7 line-input">
                                                    <label>{t("Confirmer nouveau mot de passe")}*</label>
                                                    <div className="cnt-input with-icon">
                                                        <input
                                                            type={this.state.showConfirmNewPassword ? "text" : "password"}
                                                            className={this.state.confirmNewPasswordError ? "hasError" : ""} 
                                                            value={this.state.confirmNewPassword}
                                                            onChange={this.updateState}
                                                            name="confirmNewPassword"
                                                        />
                                                        <span
                                                            className={`edit clickable`}
                                                            onClick={() => this.togglePasswordVisibility("confirmNewPassword")}
                                                            name='confirmNewPassword'
                                                        >
                                                            <i className="material-icons icon-edit">{!this.state.showConfirmNewPassword ? 'lock' : 'lock_open'}</i>
                                                        </span>
                                                    </div>
                                                    {
                                                        this.state.confirmNewPasswordError &&
                                                        <span className='error'>{this.state.confirmNewPasswordError}</span>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {showMailAdress && (
                                            <div className="col col-7 line-input">
                                                <label>{t("Adresse e-mail")}*</label>
                                                <div className="cnt-input">
                                                    <input
                                                        className={this.state.emailError ? "hasError" : ""} 
                                                        type="email"
                                                        value={this.state.newEmail} 
                                                        onChange={this.updateState}
                                                        name="newEmail"
                                                    />
                                                </div>
                                                {
                                                    this.state.emailError &&
                                                    <span className='error'>{this.state.emailError}</span>
                                                }
                                            </div>
                                        )}
                                    </div>   
                                </div>
                            }
                            
                            <div className="col col-12 line-input">
                                <label>{t("Numéro de téléphone")}</label>
                                <div className="PhoneInput">
                                    <Select
                                        onChange={(value) => this.handleContrySelect(value)}
                                        options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                                        components={{ SingleValue }}
                                        value={options.find(op => { return op.value === (this.state.currentCountry) })}
                                    />
                                    <PhoneInput
                                        className={this.state.phoneError ? 'hasError' : ''}
                                        country={this.state.currentCountry}
                                        international
                                        value={this.state.phone ? this.state.phone : ""}
                                        onChange={this.handlePhoneChange}
                                        name='phone'
                                    />
                                </div>
                                {
                                    this.state.phoneError && 
                                    <span className="error">{this.state.phoneError}</span>
                                }
                            </div>
                            <div className="col col-12 line-input">
                                <label>{t("Autoriser les notifications par SMS ?")} </label>
                                <div className="btn-check">
                                    <label className="check">
                                        <input type="radio"
                                               checked={this.state.notification}
                                               value="yes"
                                               onChange={this.handleNotificationChange}
                                               name="radio"
                                               disabled={this.state.phoneError}
                                        />
                                        <span className="radiobtn"></span> {t("Oui")}
                                    </label>
                                    <label className="check">
                                        <input type="radio"
                                               checked={!this.state.notification}
                                               value="no"
                                               onChange={this.handleNotificationChange}
                                               name="radio"
                                               disabled={this.state.phoneError}
                                        />
                                        <span className="radiobtn"></span> {t("Non")}
                                    </label>
                                </div>
                            </div>
                            <div className="col col-12 delete-compte">
                                <p><span className="link-delete">{t("Supprimer mon compte")}</span></p>
                                <p className="info-delete">
                                    {t("Attention ! Cette action est irréversible, vous ne pourrez plus accéder à vos informations ou à vos prestations.")}
                                </p>
                            </div>
                        </div>
                        <div className="cnt-Btn">
                            <span 
                                className= {`button ${isFormError ? 'disable': ''}`}  
                                onClick={this.handleSubmit}
                            >
                                {t("Enregistrer")}
                            </span>
                            <div className="clr"></div>
                        </div>
                        <div className="clr"></div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {hotel: state.hotel};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({getHotelUser, showLoading, hideLoading}, dispatch);
}

export default translate('translation')(connect(mapStateToProps, mapDispatchToProps)(ParametreCompte));

import config from "../../../../src/config";

const baseURL = config.baseURL;

export default {
    FETCH_URL: `${baseURL}/rest/V1/getInfoHotel`,
    FETCH: 'hotel/FETCH-INFO',
    LOADING: 'hotel/LOADING',
    LOADING_HOTEL_USER: 'hotel/LOADING_HOTEL_USER',
    UPDATE_URL: `${baseURL}/rest/V1/saveInfo`,
    UPDATE: 'hotel/UPDATE',
    GET_HOTEL_USER_URL: `${baseURL}/rest/V1/getHotelUser`,
    GET_HOTEL_USER: 'hotel/GET_HOTEL_USER',
    SAVE_HOTEL_USER_URL: `${baseURL}/rest/V1/saveUserHotel`,
    SAVE_HOTEL_USER: 'hotel/SAVE_HOTEL_USER',
    UPDATE_CAROUSEL: 'hotel/UPDATE-CAROUSEL',
    UPDATE_CAROUSEL_URL: `${baseURL}/rest/V1/saveCarousel`,
    GET_CAROUSEL: 'hotel/GET_CAROUSEL',
    GET_CAROUSEL_URL: `${baseURL}/rest/V1/getCarousel`,
    GET_PROFIL_PROGRESSION_URL: `${baseURL}/rest/V1/rateProfilHotel`,
    GET_PROFIL_PROGRESSION: 'GET_PROFIL_PROGRESSION',
    SAVE_HEAD_OFFICE_INFOS_URL: `${baseURL}/rest/V1/saveHeadOfficeInfos`,
    SAVE_HEAD_OFFICE_INFOS: 'hotel/SAVE_HEAD_OFFICE_INFOS',
    SAVE_CONTACT_INFOS_URL: `${baseURL}/rest/V1/saveContactInfos`,
    SAVE_LEGAL_INFOS_URL: `${baseURL}/rest/V1/saveLegalInfos`,
    SAVE_ACCOUNT_INFOS_URL: `${baseURL}/rest/V1/saveAccountInfos`,
    SAVE_ACCOUNT_INFOS: 'hotel/SAVE_ACCOUNT_INFOS',
    UPLOAD_IDENTITY_PAPER_URL: `${baseURL}/rest/V1/uploadIdentityPaper`,
    UPLOAD_IDENTITY_PAPER: 'hotel/UPLOAD_IDENTITY_PAPER',
    UPLOAD_KBIS_PHOTOCOPY_URL: `${baseURL}/rest/V1/uploadKbisPhotocopy`,
    UPLOAD_KBIS_PHOTOCOPY: 'hotel/UPLOAD_KBIS_PHOTOCOPY',
    SAVE_LOGO:'hotel/SAVE_LOGO',
    SAVE_LOGO_URL: `${baseURL}/rest/V1/saveLogo`,
    GET_LOGO:'hotel/GET_LOGO',
    GET_LOGO_URL: `${baseURL}/rest/V1/getLogo`,
    GET_STRIPE_STATUS:'hotel/GET_STRIPE_STATUS',
    GET_STRIPE_STATUS_URL: `${baseURL}/rest/V1/getStatuStripe`,
    SAVE_SMS_NOTIFICATION_STATUS_URL: `${baseURL}/rest/V1/smsnotificationstatus`,
    GET_ACTIVITY_ZONE_URL: `${baseURL}/rest/V1/getActivityZones`,
    GET_ACTIVITY_ZONE: 'GET_ACTIVITY_ZONE',
    GET_DEFAULT_COMMISSION_URL: `${baseURL}/rest/V1/getDefaultCommision`,
    //GET_DEFAULT_COMMISSION_URL: `http://localhost:3001/rest/V1/getDefaultCommision`,
    GET_DEFAULT_COMMISSION: 'GET_DEFAULT_COMMISSION',
    GET_PRICE_PER_CUTERLY_URL: `${baseURL}/rest/V1/getPricePerCuterly`,
    GET_PRICE_PER_CUTERLY: 'GET_PRICE_PER_CUTERLY',
    MIN_WIDTH: 262,
    MAX_WIDTH:562,
    MIN_HEIGHT:262,
    MAX_HEIGHT:562,
}
import React from 'react';
import '../../../index.css';
import {Link} from 'react-router-dom';
import 'react-dropdown/style.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Rating from 'react-rating';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {translate} from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input/input';
import PopupHandler from '../../../services/error-handlers/popupHandler';
import SelectionDate from "../../components/dashboard/selectionDate";
import { dashboarSA } from "../../../services/applicatif/dashboard.sa";
import { ORDER_STATUS } from "../../../data/constants/order.status";
import FormHandler from '../../../services/error-handlers/formHandler';
import { sellerSA} from "../../../services/applicatif/seller.sa";
import {fetchInfos, getStripeStatus } from "../../../services/redux/actions/hotel";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import { getMyMonthlyIncome, getDashboardValues } from "../../../services/redux/actions/dashboard";
import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";


const EMAIL_LENGTH = 50;
am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

class agenda extends React.Component {
    constructor(props) {
        super(props);
        const {t} =props;
        const data = [];  
        const months = [
            t("Janvier"),
            t("Février"),
            t("Mars"),
            t("Avril"),
            t("Mai"),
            t("Juin"),
            t("Juillet"),
            t("Aout"),
            t("Septembre"),
            t("Octobre"),
            t("Novembre"),
            t("Décembre"),
        ];
        for (var i = 0; i < months.length; i++) {
            data.push({ category: months[i], value: 0 });
        }
        this.state = {
            loading: true,
            myIncome: 0,
            waitingResponseOrdersNb: 0,
            providersNb: 0,
            partnershipRequestsNb: 0,
            appreciationsNb: 0,
            dashboardError: false,
            email: '',
            data,
            months,
            hotel: {}
        };
    }

    componentDidMount() {
        let data = [...this.state.data];
        if (this.props.hotel && this.props.hotel.hotelInfo && this.props.hotel.hotelInfo.data && this.props.hotel.hotelInfo.data.InfosHotel && this.props.hotel.hotelInfo.data.InfosHotel.length > 0 ) {
            this.setState({
                hotel: this.props.hotel.hotelInfo.data.InfosHotel[0]
            });
            if (this.props.hotel.stripeStatus && this.props.hotel.stripeStatus.status) {
                this.setState({
                    paymentInformationError: this.props.hotel.stripeStatus.data && this.props.hotel.stripeStatus.data.status === "verified" ? false : true
                })
            }else{
                this.props.getStripeStatus(this.props.hotel.hotelInfo.data.InfosHotel[0].info_id);
            }
        }

        if (this.props.dashboard && this.props.dashboard.incomeHotel && this.props.dashboard.incomeHotel.data && this.props.dashboard.incomeHotel.data.data) {
            const result = this.props.dashboard.incomeHotel.data.data || [];   
            data = this.dataToDataChart(result);
        }else{
            this.getMyMonthlyIncome();
        }
        
        this.props.getDashboardValues();
        this.chart = am4core.create("chartdiv", am4charts.XYChart);
        this.chart.data = data;
        const title = this.chart.titles.create();
        title.text = `${this.props.t("MES REVENUS")} (€)`;
        title.fontSize = 12;
        title.marginBottom = 20;

        this.chart.legend = new am4charts.Legend();
        this.chart.legend.useDefaultMarker = true;
        this.chart.legend.contentAlign = "left";

        var marker = this.chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);

        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.minGridDistance = 15;
        categoryAxis.renderer.grid.template.location = 0.5;
        categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
        categoryAxis.renderer.labels.template.rotation = -90;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.location = 0;

        categoryAxis.renderer.labels.template.adapter.add("dx", function(dx, target) {
            return -target.maxRight / 2;
        })

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.axisFills.template.disabled = true;
        valueAxis.min = 0;

        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "category";
        series.dataFields.valueY = "value";
        series.tooltipText = "{valueY.value}";
        series.sequencedInterpolation = true;
        series.fillOpacity = 0;
        series.strokeOpacity = 1;
        series.strokeDashArray = "1,3";
        series.columns.template.width = 0.01;
        series.tooltip.pointerOrientation = "horizontal";
        series.name = this.props.t("Revenu par mois");

        series.bullets.create(am4charts.CircleBullet);

        this.chart.cursor = new am4charts.XYCursor();

        // this.chart.scrollbarX = new am4core.Scrollbar();
        // this.chart.scrollbarY = new am4core.Scrollbar();

    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.hotel;
        const props = this.props.hotel;

        if (!prev) {
            return;
        }
        if (prev.hotelInfo && props.hotelInfo && prev.hotelInfo.loading && !props.hotelInfo.loading) {
            if (props.hotelInfo.error) {
                this.setState({getHotelError: true, paymentInformationError: true});
            } else {
                const hotel = (props.hotelInfo.data && props.hotelInfo.data.InfosHotel && props.hotelInfo.data.InfosHotel.length > 0 && props.hotelInfo.data.InfosHotel[0]) || {}
                this.setState({
                    hotel,
                    getHotelError: false
                });
                this.props.getStripeStatus(hotel.info_id);
            }
        }

        if (prev.stripeStatus && props.stripeStatus &&  prev.stripeStatus.loading && !props.stripeStatus.loading ) {
            if (props.stripeStatus.error) {
                this.setState({
                    paymentInformationError: true
                })
            } else {
                this.setState({
                    paymentInformationError: props.stripeStatus.data && props.stripeStatus.data.status === "verified" ? false : true
                })
            }
        }
        const prevDashboard = prevProps.dashboard;
        const propsDashboard = this.props.dashboard;
        if (!prevDashboard) {
            return;
        }
        
        if (prevDashboard.incomeHotel && propsDashboard.incomeHotel && prevDashboard.incomeHotel.loading && !propsDashboard.incomeHotel.loading) {
            if (propsDashboard.incomeHotel.error) {
                this.setState({getMyMonthlyIncomeError: true});
            } else {
                const data = (propsDashboard.incomeHotel.data && propsDashboard.incomeHotel.data.data) || [];   
                this.dataToDataChart(data);
                this.setState({
                    getMyMonthlyIncomeError: false
                })
            }
        }
        if (prevDashboard.dashboardValues && propsDashboard.dashboardValues && prevDashboard.dashboardValues.loading && !propsDashboard.dashboardValues.loading) {
            if (propsDashboard.dashboardValues.error) {
                this.setState({
                    dashboardError: true
                })
            } else {
                const data = propsDashboard.dashboardValues.data|| {}
                this.setState({
                    waitingResponseOrdersNb: data.nb_commandes || 0,
                    providersNb: data.nb_prestataires || 0,
                    partnershipRequestsNb: data.nb_demandes_partenariat || 0,
                    appreciationsNb: data.nb_avis || 0,
                    dashboardError: false
                })
            }
        }
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    getMyIncome = (dateInfo) => {
        this.props.showLoading();
        dashboarSA.getMyIncome(dateInfo)
        .then(result =>{
            const data = result.data || {}
            this.setState({
                myIncome: data.myIncome,
                loading:false
            })
            this.props.hideLoading();
        })
        .catch(error=>{
            this.props.hideLoading();
            this.setState({
                dashboardError: true
            })
        })
    }

    getMyMonthlyIncome = () => {
        const year = new Date().getFullYear();
        this.props.getMyMonthlyIncome(year)
    }

    dataToDataChart = (result) =>{ 
        if (!result || result.length < 1) {
            return
        }
        const data = this.state.months.map((month, index) => ({
            category: month,
            value: result[`${index + 1}`] ? result[`${index + 1}`].total_revenu_hotel : 0
        }))
        this.setState({
            data
        })
        if (this.chart) {
            this.chart.data = data;
        }
        return data
        
    }

    handleEmailChange = (event) => {
        const email = event.target.value;
        if (email.length > EMAIL_LENGTH) {
            return;
        }
        const emailError = email.length !== 0 && !FormHandler.isValidEmail(email);
        const emailValid = email.length !== 0 && FormHandler.isValidEmail(email);
        this.setState({email, emailError, emailValid});
    };

    sendInvitation = () => {
        const {t}=this.props;
        const email = this.state.email;
        const emailValid = email.length !== 0 && FormHandler.isValidEmail(email);
        this.setState({emailValid});
        if (!emailValid) {
            return;
        }
        const data = {
            email,
        };
        this.props.showLoading();
        sellerSA.sendInvitation(data)
        .then(() =>{
            this.props.hideLoading();
            this.setState({
                sendInvitationSuccess: true,
                email: ''
            });
        })
        .catch((exception) =>{
            this.props.hideLoading();
            this.setState({
                email: ''
            })
            const error = exception.response && exception.response.data;
            if (error.message) {
                if (error.message === "INVITATION_ALREADY_SEND") {
                    showPopupExtra(<PopupError title={t("Erreur")} message={t("Une invitation a déjà été envoyé à cette adresse email.")}/>)
                    return
                }
                if (error.message === "EMAIL_EXIST") {
                    showPopupExtra(<PopupError title={t("Erreur")} message={t("Cette adresse email appartient à un prestataire déjà existant.")}/>)
                    return
                }
            }       
            this.setState({
                sendInvitationSuccess: false,
            });
        })
    };

    // openSendInvitation = ()=>{
    //     showPopUp(
    //         <Invitation 
    //             handleValidate = { this.endPartnership }
    //             pricePerCuterly = {this.state.pricePerCuterly}
    //             defaultCommission = {this.state.defaultCommission}
    //         />,
    //         this.props.t("Invitation")
    //     )
    // }

    goToGestionAdmin = ()=>{
       this.props.history.push("gestion_administration?activeTab=accountStatus")
    }

    render() {
        const hotelCreationDate = (this.props.hotel && this.props.hotel.hotelInfo.data && this.props.hotel.hotelInfo.data.InfosHotel && this.props.hotel.hotelInfo.data.InfosHotel.length > 0 && this.props.hotel.hotelInfo.data.InfosHotel[0] && this.props.hotel.hotelInfo.data.InfosHotel[0].date_created) || null
        const { t } = this.props;
        return (
        <div className="page">
            <div className="bandeaux">
                <div className="wrapper clr">
                    <h1>{t("Tableau de bord")}</h1>
                </div>
            </div>
            <div className="top-main">
                <div className="item">
                    <div className="itemTop">
                        <span className="titleItem">{t("Mes revenus")}</span>
                        <span className="price">{`${this.state.myIncome || 0} €`}</span>
                        <SelectionDate selectDate={this.getMyIncome} hotelCreationDate={hotelCreationDate}/>
                    </div>
                </div>
                <div className="item">
                    <div className="itemTop">
                        <span className="titleItem">{t("Commandes en")} <br/> {t("attente de réponse")}</span>
                    </div>
                    <div className="infosDash"><span className="nbr">{this.state.waitingResponseOrdersNb || 0}</span>
                        <Link to={`/gestion-commandes?status=${ORDER_STATUS.PENDING.value}&direction=asc`} className="linkplus">{t("Voir plus")}</Link>
                    </div>
                </div>
                <div className="item">
                    <div className="itemTop">
                        <span className="titleItem">{t("Prestataires partenaires")}</span>
                    </div>
                    <div className="infosDash">
                        <span className="nbr">{this.state.providersNb || 0}</span>
                        <Link to={{pathname: "/prestataires", tab: "partenaires"}} className="linkplus">{t("Voir plus")}</Link>
                    </div>
                </div>
                <div className="item">
                    <div className="itemTop">
                        <span className="titleItem">{t("Demandes de")} <br/> {t("partenariat")}</span>
                    </div>
                    <div className="infosDash"><span className="nbr">{this.state.partnershipRequestsNb || 0}</span>
                    <Link to={{pathname: "/prestataires", tab: "demandes"}} className="linkplus">{t("Voir plus")}</Link></div>
                </div>
                {/* <div className="item">
                    <div className="itemTop">
                        <span className="titleItem">Appréciations</span>
                        <div className="ratings">
                            <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down jaune">star</i></span>
                            <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down jaune">star</i></span>
                            <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down jaune">star</i></span>
                            <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down jaune">star</i></span>
                            <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down jaune star-empty">star</i></span>
                        </div>
                    </div>
                    <div className="infosDash"><span className="nbr">{`${this.state.appreciationsNb || 0} avis`}</span><Link to="/" className="linkplus">Voir plus</Link></div>
                </div> */}
            </div>
            {this.state.paymentInformationError && PopupHandler.showError(t("Il y a une erreur sur vos informations de paiement Stripe."), 'page', this.goToGestionAdmin) }
            {(this.state.dashboardError || this.state.getHotelError || this.state.getMyMonthlyIncomeError) && PopupHandler.showError(t("Il y a une erreur sur la récupération des données.")) }
            {this.state.emailError && PopupHandler.showError(t("Veuillez saisir une adresse e-mail valide."))}
            {this.state.sendInvitationSuccess === true && PopupHandler.showSuccess(t("L'invitation a été envoyée avec succès."))}
            {this.state.sendInvitationSuccess === false && PopupHandler.showError(t("Erreur sur l'envoi de l'invitation."))}
            <div className="main">
                <div className="wrapper clr">
                    <div className="row mainBody">
                        <div className="col sidebar-left">
                            <h2>{t("Mon établissement")}</h2>
                            <div>
                                <h3 className="title-et">{this.state.hotel.nomhotel}</h3>
                                <div className="star-hotel">
                                    <Rating
                                        readonly
                                        placeholderRating={(this.state.hotel && this.state.hotel.nombre_etoile) || 0}
                                        emptySymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down star-empty">star</i></span>}
                                        placeholderSymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">star</i></span>}
                                    />
                                </div>
                                <p>{`${this.state.hotel.rue ? `${this.state.hotel.rue},` : ''} ${this.state.hotel.codepostal || ''} ${this.state.hotel.ville || ''}`}</p>
                                <p>{this.state.hotel.email || ''}<br />{this.state.hotel.telephone ? formatPhoneNumberIntl(this.state.hotel.telephone): ""}</p>
                            </div>
                            <div className="cnt-Btn">
                                <Link to="MonHotel" className="button">{t("Modifier")}</Link>
                            </div>
                        </div>
                        <div className="col main-content">
                            <h2>{t("Mes revenus")}</h2>
                            <div className="cnt-graph">
                                <div id="chartdiv" style={{ width: "100%", height: "300px" }}></div>
                            </div>
                            {/* <div className="cnt-Btn">
                                <Link to="/" className="button">{t("Voir mes revenus")}</Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="bottom-main">
                        <h3>{t("Inviter des prestataires à rejoindre votre plateforme")} </h3>
                        <p>{t("Vous pouvez inviter des prestataires à devenir partenaire et ainsi proposer leurs services à vos clients. En saisissant l'adresse email du prestataire, un mail lui sera automatiquement envoyé avec les instructions pour la création de son profil.")}</p>
                        <div className="row">
                            <div className="col left-part">
                                <input 
                                    type="email" 
                                    placeholder={t("Saisir l'adresse e-mail du prestataire")}
                                    className={`afiliat-mail${this.state.emailError ? " hasError" : ""}`}
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                />
                            </div>
                            <div className="col right-part">
                                <button className="button"
                                    disabled = {!this.state.emailValid}
                                    onClick={this.sendInvitation}
                                >
                                    {t("Envoyer l'invitation")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
    hotel: state.hotel,
    dashboard: state.dashboard
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchInfos,
        getStripeStatus,
        showLoading, 
        hideLoading,
        getMyMonthlyIncome,
        getDashboardValues
    }, dispatch);
};
export default translate('translation')(connect(mapStateToProps, mapDispatchToProps)(agenda));


import { catalogBDL } from "../business-delegate/catalog.bdl";

class CatalogSA {
    fetchCategories = (query) => {
        return catalogBDL.fetchCategories(query);
    };
    
    reorderCategory = (data) => {
        return catalogBDL.reorderCategory(data); 
    };
    
    saveImageCategory = (data) => {
        return catalogBDL.saveImageCategory(data);
    };
    
    toggleCategory = (id, enabled) => {
        return catalogBDL.toggleCategory(id, enabled);
    };
    
    getProduct = (id) => {
        return catalogBDL.getProduct(id);
    };

    getAvailableOptions = () => {
        return catalogBDL.getAvailableOptions();
    };
    
    updateProduct = (param) => {
        return catalogBDL.updateProduct(param);
    };
    
    fetchExternalCategories = (query) => {
        return catalogBDL.fetchExternalCategories(query);
    };
    
    toggleExternalCategory = (id, enabled) => {
        return catalogBDL.toggleExternalCategory(id, enabled);
    };
    
    fetchExternalSubCategories = (id) => {
        return catalogBDL.fetchExternalSubCategories(id);
    };
    
    checkStripeStatus = () => {
        return catalogBDL.checkStripeStatus();
    };

    getEnabledCategories = ()=>{
        return catalogBDL.getEnabledCategories();
    }

    getVtcRate = ()=>{
        return catalogBDL.getVtcRate();
    }

    saveDeliveryPlace = (data) => {
        return catalogBDL.saveDeliveryPlace(data);
    };

    getAllDeliveryPlace = (idPrestation)=>{
        return catalogBDL.getAllDeliveryPlace(idPrestation);
    }

}

export const catalogSA = new CatalogSA();

import React from 'react';
import {withRouter} from 'react-router-dom';
import {ReactSortable} from "react-sortablejs";
import {scroller} from 'react-scroll';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {
    fetchCategories,
    getProduct,
    getAvailableOptions,
} from "../../../services/redux/actions/catalog";
import {fetchInfos, getStripeStatus, getDefaultCommision } from "../../../services/redux/actions/hotel";
import {getAllDeliveryPlace } from "../../../services/redux/actions/catalog";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from "../../../services/error-handlers/popupHandler";
import DetailsCategory from './DetailsCategory';
import Collapse from './Collapse';
import ViewCategory from './ViewCategory';
import DetailsProduct from './DetailsProduct';
import { catalogSA } from "../../../services/applicatif/catalog.sa";
import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";

const CATEGORY = 'CATEGORY';
const PRODUCT = 'PRODUCT';

class RoomService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            stripe: {
                status: false,
                error: false,
            },
            error: false,
            errorStripe: false,
            query: '',
            success: null,
            panel: {
                active: '',
                category: null,
                product: null,
            },
            preview: {
                visible: false,
                category: null,
            },
        };
    }

    componentDidMount() {
        this.props.getDefaultCommision()
        this.props.fetchCategories(this.state.query);
        if (this.props.hotel && this.props.hotel.stripeStatus && this.props.hotel.stripeStatus.status) {
            this.setState({
                stripeError: this.props.hotel.stripeStatus.data && this.props.hotel.stripeStatus.data.status === "verified" ? false : true
            })
        }else{
            if (this.props.hotel.hotelInfo && this.props.hotel.hotelInfo.data && this.props.hotel.hotelInfo.data.InfosHotel && this.props.hotel.hotelInfo.data.InfosHotel.length > 0 ) {
                const infoId = this.props.hotel.hotelInfo.data.InfosHotel[0].info_id
                this.props.getStripeStatus(infoId);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.catalog;
        const props = this.props.catalog;

        if (!prev) {
            return;
        }

        if (prev.categories && props.categories &&  prev.categories.loading && !props.categories.loading ) {
            if (props.categories.error) {
                this.setState({
                    error: true
                })
            } else {
                let categories = this.categorySubCategoryToList((props.categories.data && props.categories.data.categories) || []);
                categories = this.sortCategories(categories);
                if (this.state.isSearch) {
                    categories = this.showHideCategorySubCategoryByName(categories);
                }
                const isActive = (categories && categories.length > 0 && categories[0].isActive) || "0";
                this.setState({
                    categories,
                    error: false,
                    isSearch: false,
                    isActive
                })
            }
        }

        if (prev.saveImageCategory && props.saveImageCategory &&  prev.saveImageCategory.loading && !props.saveImageCategory.loading ) {
            if (props.saveImageCategory.error) {
                const categories = this.sortCategories((props.saveImageCategory.data && props.saveImageCategory.data.categories) || []);
                this.setState({
                    categories,
                    error: false
                })
            } 
        }

        const prevHotel = prevProps.hotel;
        const propsHotel = this.props.hotel;

        if (prevHotel.hotelInfo && propsHotel.hotelInfo && prevHotel.hotelInfo.loading && !propsHotel.hotelInfo.loading) {
            if (!propsHotel.hotelInfo.error) {
                const infoId = (propsHotel.hotelInfo.data && propsHotel.hotelInfo.data.InfosHotel && propsHotel.hotelInfo.data.InfosHotel.length > 0 && propsHotel.hotelInfo.data.InfosHotel[0] && propsHotel.hotelInfo.data.InfosHotel[0].info_id) || {}
                this.props.getStripeStatus(infoId);
            } 
        }

        if (prevHotel.stripeStatus && propsHotel.stripeStatus &&  prevHotel.stripeStatus.loading && !propsHotel.stripeStatus.loading ) {
            if (propsHotel.stripeStatus.error) {
                this.setState({
                    stripeError: true
                })
            } else {
                this.setState({
                    stripeError: propsHotel.stripeStatus.data && propsHotel.stripeStatus.data.status === "verified" ? false : true
                })
            }
        }
    }

    objectToList =(object)=>{
        if (!object || Object.keys(object).length === 0) {
            return []
        }
        return Object.keys(object).map(key=>{
            return {...object[key]}
        })
    }

    productToList =(object)=>{
        if (!object || Object.keys(object).length === 0) {
            return []
        }
        return Object.keys(object).map(key=>{
            return {products: {...object[key]}}
        })
    }

    categorySubCategoryToList = (categories) => {
        if (!categories) {
            return []
        }
        if (Array.isArray(categories)) {
            return categories;
        }
        if (Object.keys(categories).length === 0) {
            return []
        }
        let categoryList = this.objectToList(categories);
        for (let index = 0; index < categoryList.length; index++) {
            const category = categoryList[index];
            if (category.sousCategories) {
                if (category.sousCategories.products) {
                    category.sousCategories = this.productToList(category.sousCategories.products)
                }else{
                    category.sousCategories = this.objectToList(category.sousCategories);
                }
                for (let index = 0; index < category.sousCategories.length; index++) {
                    const subCategory = category.sousCategories[index];
                    if (subCategory.sousCategories) {
                        if (subCategory.sousCategories.products) {
                            subCategory.sousCategories = this.productToList(subCategory.sousCategories.products)
                        }else{
                            subCategory.sousCategories = this.objectToList(subCategory.sousCategories)
                        }
                    }
                }
            }
        }
        return categoryList;
    };

    toogleCategorySubCategoryById = (id, enable) => {
        if (!id || this.state.categories.length < 1) {
            return
        }
        let found = false;
        let disableSubCategories = false;
        let disableSubSubCategories = false;
        const categories = [...this.state.categories];
        for (let index = 0; index < categories.length; index++) {
            if (found ||disableSubCategories) {
                break;
            }
            const category = categories[index];
            if (category.id === id) {
                category.enabled = enable;
                if (!enable) {
                    disableSubCategories = true
                }else{
                    break;
                }
            }
            if (category.sousCategories && category.sousCategories.length > 0) {
                for (let index = 0; index < category.sousCategories.length; index++) {
                    const subCategory = category.sousCategories[index];
                    if (disableSubSubCategories) {
                        found = true;
                        break
                    }
                    if (subCategory.id === id || disableSubCategories) {
                        subCategory.enabled = enable;
                        if (!disableSubCategories) {
                            if (subCategory.id === id && !enable) {
                                disableSubSubCategories = true
                            }else{
                                found = true;
                                break;
                            }
                        }
                    }
                    if (subCategory.products && (subCategory.products.id === id || disableSubCategories)) {
                        subCategory.products.enabled = enable;
                        if (!disableSubCategories) {
                            found = true;
                            break;
                        }
                    }
                    
                    if (subCategory.sousCategories && subCategory.sousCategories.length > 0) {
                        for (let index = 0; index < subCategory.sousCategories.length; index++) {
                            const product = subCategory.sousCategories && subCategory.sousCategories[index] && subCategory.sousCategories[index].products;
                            if (product && (product.id === id || disableSubCategories || disableSubSubCategories)) {
                                product.enabled = enable;
                                if (!disableSubCategories && !disableSubSubCategories) {
                                    found = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        this.setState({
            categories
        })
    };

    getProductCategory = (id) => {
        if (!id || this.state.categories.length < 1) {
            return null
        }
        const categories = [...this.state.categories];
        let found = false;
        let productCategory = null;
        for (let index = 0; index < categories.length; index++) {
            if (found) {
                break;
            }
            const category = categories[index];
            if (category.sousCategories && category.sousCategories.length > 0) {
                for (let index = 0; index < category.sousCategories.length; index++) {
                    if (found) {
                        break;
                    }
                    const subCategory = category.sousCategories[index];
                    const product = subCategory.products;
                    if ((subCategory && subCategory.id === id) || (product && product.id === id)) {
                        found = true;
                        productCategory = category;
                        break
                    }
                    if (subCategory.sousCategories && subCategory.sousCategories.length > 0) {
                        for (let index = 0; index < subCategory.sousCategories.length; index++) {
                            const product = subCategory.sousCategories && subCategory.sousCategories[index] && subCategory.sousCategories[index].products;
                            if (product && product.id === id) {
                                found = true;
                                productCategory = category;
                                break
                            }
                        }
                    }
                }
            }
        }
        return productCategory;
    };

    showHideCategorySubCategoryByName = (data) => {
        if (data.length < 1) {
            return
        }
        const query = !this.state.query ? "" : this.state.query.toLowerCase();
        const categories = [...data];
        for (let index = 0; index < categories.length; index++) {
            const category = categories[index];
            category.show = false;
            if(category.sousCategories && category.sousCategories.products && query && category.sousCategories.products.name && category.sousCategories.products.name.toLowerCase().includes(query)){
                category.show = true;
            }
            if (category.sousCategories && category.sousCategories.length > 0) {
                for (let index = 0; index < category.sousCategories.length; index++) {
                    const subCategory = category.sousCategories[index];
                    const categoryproduct = subCategory && subCategory.products;
                    subCategory.show = false;
                    if (query && ((categoryproduct && categoryproduct.name && categoryproduct.name.toLowerCase().includes(query)) || (subCategory && subCategory.name && subCategory.name.toLowerCase().includes(query)))) {
                        category.show = true;
                    }
                    if (subCategory.sousCategories && subCategory.sousCategories.length > 0) {
                        for (let index = 0; index < subCategory.sousCategories.length; index++) {
                            const subSubCategory = subCategory.sousCategories[index];
                            const product = subSubCategory && subSubCategory.products;
                            if (query && ((product && product.name && product.name.toLowerCase().includes(query)) || (subSubCategory && subSubCategory.name && subSubCategory.name.toLowerCase().includes(query)))) {
                                category.show = true;
                                subCategory.show = true;
                            }
                        }
                    }
                }
            }
        }
        return categories
    };

    sortCategories = (categories) => {
        return [].concat(categories).sort((a, b) => {
            return (parseInt(a.position) > parseInt(b.position)) ? 1 : -1;
        });
    };

    handleQueryChange = (event) => {
        this.setState({query: event.target.value});
    };

    handleSearch = () => {
        this.setState({
            isSearch: true
        })
        this.props.fetchCategories(this.state.query);
    };

    fetchCategories = () => {
        this.props.fetchCategories(this.state.query);
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSearch()
        }
    }

    handleSort = (event) => {
        const newIndex = event.newIndex;
        const previousCategory = newIndex > 0 ? this.state.categories[newIndex -1] : null;
        const newPosition = previousCategory ? parseInt(previousCategory.position) + 1 : 1;
        const data = {
            category_id: event.item.dataset.id,
            new_position: parseInt(newPosition)
        }
        this.props.showLoading();
        catalogSA.reorderCategory(data)
        .then(()=>{
            this.props.hideLoading();
            this.handleSearch();
            this.setState({
                success: true,
            })
        })
        .catch(()=>{
            this.props.hideLoading();
            this.setState(oldState => ({
                success: false,
                categories: this.sortCategories(oldState.categories)
            }))
        })
    };

    handleCategoryClick = (event) => {
        const id = event.target.dataset.id;
        const category = this.state.categories.filter((e) => parseInt(e.id) === parseInt(id), id);
        if (category.length > 0) {
            const panel = {
                ...this.state.panel,
                active: CATEGORY,
                category: category[0],
                product: null,
            };
            this.setState({
                panel,
                selectedCategory: id
            });
            this.scrollTo();
        }
    };

    scrollTo() {
        if (window.matchMedia("(max-width: 767px)").matches) {
            scroller.scrollTo('scroll-to-anchor', {
                duration: 1000,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -120
            })
        }
    }

    showViewCategory = (event) => {
        const id = event.target.dataset.id;
        const category = this.state.categories.filter((e) => parseInt(e.id) === parseInt(id), id);
        if (category.length > 0) {
            const preview = {
                visible: true,
                category: category[0],
            };
            this.setState({preview});
        }
    };

    closeViewCategory = () => {
        const preview = {
            visible: false,
            category: null,
        };
        this.setState({preview});
    };

    handleProductClick = (id) => {
        const panel = {
            active: PRODUCT,
            category: null,
            product: parseInt(id),
        };
        const selectedProductCategory= this.getProductCategory(id);
        this.setState({
            panel,
            selectedProduct: id,
            selectedProductCategory
        });
        this.props.getProduct(id);
        if (selectedProductCategory && selectedProductCategory.flag && selectedProductCategory.flag ==='vtc') {
            this.props.getAllDeliveryPlace(id);
        }
        this.scrollTo();
    };

    handleCheckedChange = (event) => {
        const checked = event.target.checked;
        const id = event.target.dataset.id;
        this.checkCategory(id, checked);
    };

    checkCategory = (id, checked) => {
        const {t} =this.props;
        if (this.state.stripeError && checked) {
            //const message = this.state.stripeError ? t("Les produits ne peuvent pas etre activés tant que le compte de paiement de l'hôtel n'est pas actif.") : t("Les catégories de prestations sans offres ne peuvent etre activées.")
            const message = t("Les produits ne peuvent pas etre activés tant que le compte de paiement de l'hôtel n'est pas actif.");
            showPopupExtra(<PopupError title={t("Erreur")} message={message}/>)
            return
        }
        const categories = JSON.parse(JSON.stringify( this.state.categories)); 
        this.toogleCategorySubCategoryById(id, checked ? 1 : 0);
        
        this.props.showLoading();
        catalogSA.toggleCategory(id, checked)
        .then(response=>{
            this.props.hideLoading();
            this.setState({
                success: true,
            })
        })
        .catch((exception)=>{
            this.props.hideLoading();
            const error = exception.response && exception.response.data;
            if (error) {
                if (error.message === "The category has no partner having service !" ) {
                    showPopupExtra(<PopupError title={t("Erreur")} message={t("La catégorie n'est pas activable car il n'y a aucun prestataire partenaire indépendant de l'hotel qui a coché la catégorie ou ses sous-catégories dans ses offres.")}/>)
                }
            }                 
            this.setState({
                success: false,
                categories
            })
        })
    }

    goToGestionAdmin = ()=>{
        this.props.history.push("gestion_administration?activeTab=accountStatus")
    }

    hasProductCategory = id =>{
        if (!this.state.categories || this.state.categories.length < 1) {
            return false
        }
        const category = this.state.categories.find(categorie =>categorie.id === id && (!categorie.sousCategories || categorie.sousCategories.length < 1));
        return !category;
    }

    renderCategorie = (category) => {
        const hasProductCategory = true;
        let enabled = false;
        if (this.state.stripeError) {
            enabled = 0;
        }else{
            const isActive = category.isActive ? parseInt(category.isActive) : 0;
            enabled = (isActive && category.enabled && parseInt(category.enabled)) ? 1 : 0;
        }
        const header = (
            <div
                className={this.state.panel.category && this.state.panel.category.id === category.id ? "wrapper-menuParent active" : "wrapper-menuParent"}>
                <span className="ico"><i className="material-icons icon-menu clickable handle">menu</i></span>
                <div className="menu-parent not-draggable">
                    <label className="checkbox-label" >
                        <input type="checkbox"
                            data-id={category.id}
                            checked={enabled}
                            onChange={this.handleCheckedChange}
                        />
                        <span className="check"></span>
                    </label>
                    <span onClick={this.handleCategoryClick}  
                        data-id={category.id} 
                        className={`titre-menu clickable ${hasProductCategory ? '' : 'category-disable'}`}>
                        {category.name} ({category.count_product})
                    </span>
                </div>
                
            </div>
        );

        return (
            <div className="content-menu" data-id={category.id} key={category.id}>
                <Collapse
                    title={header}
                    noChildren={!category.sousCategories || category.sousCategories.length < 1}
                    show={category.show ? true : false}
                >
                    {category.sousCategories && [].concat(category.sousCategories).map(sousCategorie => {
                        if (sousCategorie.products) {
                            return this.renderProduct(sousCategorie.products)
                        }else{
                            return this.renderSousCategorie(sousCategorie)
                        }
                    })}
                </Collapse>
            </div>
        );
    };

    renderSousCategorie = (sousCategorie) => {
        let enabled = false;
        if (this.state.stripeError) {
            sousCategorie.enabled = 0;
        }else{
            const isActive = this.state.isActive ? parseInt(this.state.isActive) : 0;
            enabled = (isActive && sousCategorie.enabled && parseInt(sousCategorie.enabled)) ? 1 : 0;
        }
        const header = (
            <div className="cnt-menu-sub">
                <label className="checkbox-label">
                    <input type="checkbox"
                           data-id={sousCategorie.id}
                           checked={enabled}
                           onChange={this.handleCheckedChange}
                    />
                    <span className="check"></span>
                </label>
                <span className="titre-menu">{sousCategorie.name} ({sousCategorie.count_product})</span>
            </div>
        );
        return (
            <div className="not-draggable menu-sub"
                 key={sousCategorie.id}
            >
                <Collapse 
                    title={header}
                    show={sousCategorie.show ? true : false}
                >
                    {sousCategorie.sousCategories && [].concat(sousCategorie.sousCategories).map(sousCategorie => {
                        if (sousCategorie.products) {
                            return this.renderProduct(sousCategorie.products)
                        }else{
                            return this.renderSousCategorie(sousCategorie)
                        }
                    })}
                </Collapse>
            </div>
        );
    };

    renderProduct = (product) => {
        let enabled = false;
        if (this.state.stripeError) {
            enabled = 0;
        }else if (isNaN(product.enabled)) {
            enabled = 0
        }else{
            const isActive = this.state.isActive ? parseInt(this.state.isActive) : 0;
            enabled = (isActive && product.enabled && parseInt(product.enabled)) ? 1 : 0;
        }
        return (
            <div key={product.id} 
                 className={`not-draggable ${this.state.panel.product === product.id ? "sub-category active" : "sub-category"}`}
            >
                <label className="checkbox-label">
                    <input type="checkbox"
                           data-id={product.id}
                           checked={enabled}
                           onChange={this.handleCheckedChange}
                    />
                    <span className="check"></span>
                </label>
                <span 
                    className="titre-menu-sub clickable"
                    data-id={product.id}
                    onClick={()=>this.handleProductClick(product.id)}
                >
                    <span>{product.name}</span> <span className="cnt-price"><span
                    className="price-b">{product.price ? parseFloat(product.price).toFixed(2) : 0} €</span> <span
                    className="price-t">{product.sales_price ? parseFloat(product.sales_price).toFixed(2): 0} €</span></span>
                </span>
            </div>
        );
    };

    render() {
        const {t} = this.props
        const categories = this.state.categories || [];
        if (this.state.preview.visible === false) {
            return (
                <div>
                    <div>
                        {this.state.error  && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                        {this.state.stripeError && PopupHandler.showError(t("Il y a une erreur sur vos informations de paiement Stripe."), 'page', this.goToGestionAdmin) }
                        {this.state.success === true  && PopupHandler.showSuccess(t("La modification a été enregistrée."))}
                        {this.state.success === false && PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
                        {this.state.stripe.error && PopupHandler.showError(t("Erreur Stripe : impossible d'enregistrer les modifications."))}
                    </div>
                    <div className="block-search">
                        <input type="text"
                               placeholder={t("Rechercher…")}
                               value={this.state.query}
                               onChange={this.handleQueryChange}
                               onKeyDown={this.handleSearchKeyDown}
                        />
                        <input type="button"
                               value={t("Rechercher")}
                               className="btnSearch"
                               onClick={this.handleSearch}
                        />
                    </div>
                    <div className="row main-tabs">
                        <div className="col col-5 menu-catalogue">
                            <ReactSortable
                                handle=".handle"
                                list={categories}
                                setList={newState => this.setState({categories: newState})}
                                onEnd={this.handleSort}
                                //filter= ".not-draggable,.openaccordeon"
                            >
                                {categories && categories.map(category => this.renderCategorie(category))}
                            </ReactSortable>
                        </div>
                        <div className="col col-7" id="cont" name="scroll-to-anchor">
                            {this.state.panel.active.match(CATEGORY) &&
                            <DetailsCategory 
                                key={this.state.selectedCategory}
                                category={this.state.panel.category}
                                showViewCategory={this.showViewCategory}
                                checkCategory = {this.checkCategory}
                                stripeError = {this.state.stripeError}
                            />}
                            {this.state.panel.active.match(PRODUCT) &&
                            <DetailsProduct 
                                key={this.state.selectedProduct}
                                toogleCategorySubCategoryById = {this.toogleCategorySubCategoryById}
                                category={this.state.selectedProductCategory}
                            />}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <ViewCategory category={this.state.preview.category}
                                 closeViewCategory={this.closeViewCategory}
            />
        }
    }
}

const mapStateToProps = (state) => {
    return {
        catalog: state.catalog,
        hotel: state.hotel
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCategories,
        getProduct,
        getAvailableOptions,
        showLoading, 
        hideLoading,
        getStripeStatus,
        fetchInfos,
        getAllDeliveryPlace,
        getDefaultCommision
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomService)));

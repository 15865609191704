import fileDownload from 'js-file-download';

export const downloadByUrl = async (url, fileName, mime) => {
    const response = await fetch(url)
    const blob = await response.blob()
    fileDownload(blob, fileName, mime)
}

export const downloadByteArray = (byteArray, fileName, mime) => {
    fileDownload(byteArray, fileName, mime)
}

import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import moment from 'moment';
import {fetchAllPartners, fetchAll, fetchPartnershipRequest} from "../../../services/redux/actions/seller";
import { getDashboardValues } from "../../../services/redux/actions/dashboard";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import { getEnabledCategories } from "../../../services/redux/actions/catalog";
import {getDefaultCommision} from "../../../services/redux/actions/hotel";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import PartnershipRequestModal from '../../components/partnership/PartnershipRequest';
import { sellerSA } from "../../../services/applicatif/seller.sa";
import PopupConfirm from "../../components/popup/popup.confirmation";
import {showPopUp, hidePopUp} from "../../components/popup/popup";
import Pagination from "../../components/pagination/pagination.component";
import { PAGE_SIZE_LIST } from "../../../data/constants/page.size";
import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";
import { getCommissionLabel } from '../../../data/constants/commission.label';
import seller from '../../../services/redux/reducers/seller';

class Seller extends React.Component {
    constructor(props) {
        super(props);
        const {t}=props;
        this.state = {
            types: [
                {value: '', label: t("Tous")},
                {value: 1, label: t("Indépendant")},
                {value: 2, label: t("Institut")},
                {value: 3, label: t("Agence")},
                {value: 5, label: t("Restaurant")},
            ],
            categories: [],
            sellers: [],
            error: false,
            query: {
                name: '',
                type: '',
                category: '',
                //hiddenInCatalog: false,
                page: 1,
                perPage: PAGE_SIZE_LIST[0]

            },
            endPartnership: {
                visible: false,
                id: null,
                name: null,
                success: null,
            },
            totalElements: 0,
            from: props.location.from,
            hiddenInCatalog: false,
        };
    }

    componentDidMount() {
        this.props.getDefaultCommision();
        if (this.state.from === "details" && this.props.seller && this.props.seller.partners && this.props.seller.partners.data && this.props.seller.partners.data.sellers) {
            const result = this.props.seller.partners.data.sellers || {}
            this.setState(oldState =>({
                sellers: result.data || [],
                totalElements: result.total || 0,
                query: this.props.seller.partnerQuery || oldState.query
            }));
        }else{
            this.fetchAllPartners();
        }
        if (this.state.from === "details" && this.props.catalog && this.props.catalog.enabledCategories && this.props.catalog.enabledCategories.data && this.props.catalog.enabledCategories.data.categories) {
            this.setState({
                categories: this.props.catalog.enabledCategories.data.categories || [],
            });
        }else{
            this.props.getEnabledCategories();
        }
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.seller;
        const props = this.props.seller;
        const prevCatalog = prevProps.catalog;
        const propsCatalog = this.props.catalog;
        const prevHotel = prevProps.hotel;
        const propsHotel = this.props.hotel;
        if (!prev) {
            return;
        }
        if (prev.partners && props.partners && prev.partners.loading && !props.partners.loading) {
            if (props.partners.error) {
                this.setState({error: true});
            } else {
                const result = (props.partners.data && props.partners.data.sellers) || {}
                this.setState({
                    sellers: result.data || [],
                    totalElements: result.total || 0,
                });
            }
            hidePopUp();
        }

        if (prevCatalog.enabledCategories && propsCatalog.enabledCategories && prevCatalog.enabledCategories.loading && !propsCatalog.enabledCategories.loading) {
            if (propsCatalog.enabledCategories.error) {
                this.setState({error: true});
            } else {
                this.setState({
                    categories: (propsCatalog.enabledCategories.data && propsCatalog.enabledCategories.data.categories) || [],
                });
            }
        }

        if (prevHotel.getDefaultCommision && propsHotel.getDefaultCommision && prevHotel.getDefaultCommision.loading && !propsHotel.getDefaultCommision.loading) {
            if (propsHotel.getDefaultCommision.error) {
                this.setState({
                    successCommission: false,
                });
            } else {
                this.setState({
                    successCommission: true,
                    commissions: (propsHotel.getDefaultCommision.data && propsHotel.getDefaultCommision.data.commissions) || {}
                });
            }
        }
    }

    fetchAllPartners() {
        this.props.fetchAllPartners(this.state.query);
    }

    fetchAllSellers() {
        this.props.fetchAll({
            name: '',
            type: '',
            category: '',
            page: 1,
            perPage: PAGE_SIZE_LIST[0]

        });
    }

    handleNameChange = (event) => {
        const name = event.target.value;
        const query = {...this.state.query, name};
        this.setState({query});
    };

    handleTypeChange = (event) => {
        const type = event.target.value;
        const query = {...this.state.query, type};
        this.setState({query});
    };

    handleCategoryChange = (event) => {
        const category = event.target.value;
        const query = {...this.state.query, category};
        this.setState({query});
    };

    handleSearch = () => {
        const query = {...this.state.query, page: 1};
        this.setState({
            query
        }, this.fetchAllPartners)
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSearch()
        }
    }

    handleHiddenInCatalogChange = (event) => {
        this.setState({
            hiddenInCatalog: event.target.checked
        });
    };

    handlePaginationChange = (page) => {
        this.setState(oldState=>({
            query: {
                ...oldState.query, 
                page: parseInt(page)
            }
        }),this.fetchAllPartners)
    };

    handleItemsPerPageChange = (event) => {
        const perPage = event.target.value;
        this.setState(oldState=>({
            query: {
                ...oldState.query, 
                perPage: parseInt(perPage),
                page: 1
            }
        }),this.fetchAllPartners)
    };

    renewPartnership = (event) => {
        const id = event.target.dataset.id;
        const seller = this.getSellerById(id)
        showPopUp(
            <PartnershipRequestModal 
                handleValidate={this.sendPartnershipRequest}
                seller={seller}
                commissions = {this.state.commissions}
            />,
            this.props.t("Demande de partenariat"),
            seller ? `${seller.lastname || ''} ${seller.firstname || ''}` : ''
        )
    };

    getSellerById = id =>{
        return this.state.sellers.find(seller =>parseInt(seller.id) === parseInt(id))
    }

    sendPartnershipRequest = (data) => {
        const {t}=this.props;
        this.props.showLoading();
        sellerSA.sendPartnershipRequest(data)
            .then(response=>{
                this.setState({
                    sendRequestSuccess: true
                })
                hidePopUp();
                this.props.hideLoading();
                this.fetchAllPartners();
                this.fetchAllSellers();
                this.props.fetchPartnershipRequest();
                this.props.getDashboardValues();
            })
            .catch(exception=>{  
                hidePopUp();
                this.props.hideLoading();
                const error = exception.response && exception.response.data;
                this.setState({
                    sendRequestSuccess: false
                })
                if (error) {
                    if (error.messages) {
                        if (error.messages.error && Array.isArray(error.messages.error) && error.messages.error[0]) {
                            showPopupExtra(<PopupError title={t("Erreur")} message={t(error.messages.error[0].message)}/>)
                            return
                        }
                        showPopupExtra(<PopupError title={t("Erreur")} message={t("Erreur sur l'envoi de la demande de partenariat")}/>)
                        return
                    }
                    if (error.includes("PARTNERSHIP_EXIST")) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t("Une demande a déjà été envoyée à ce prestataire")}/>)
                        return
                    }
                    if (error.includes("already exists in an associated website") ) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t("L'email de cet établissement existe déjà dans la base client, merci de contacter le prestataire pour le modifier.")}/>)
                        return
                    }
                    if (error.includes("hotel: entity not found")) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t("Hotel inexistant")}/>)
                        return
                    }
                    if (error.includes("INCOMPLETE_PROFIL")) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t("L'envoi de la demande a échoué, les informations de ce prestataire ne sont pas complètes.")}/>)
                        return
                    }
                }             
            })
    };

    confirmEndPartnership = (event) => {
        this.setState({
            endPartnership: {
                id: event.target.dataset.id,
                name: event.target.dataset.name,
            }
        });
        showPopUp(
            <PopupConfirm 
                message = {this.props.t("Mettre fin au partenariat avec ce partenaire ?")}
                onAccept = { this.endPartnership }
            />,
            this.props.t("Confirmation")
        )
    };
    endPartnership = () => {
        this.props.showLoading();
        sellerSA.endPartnership(this.state.endPartnership.id)
        .then(() =>{
            this.setState(oldstate =>({
                endPartnership: {...oldstate.endPartnership, success: true}
            }));
            this.props.hideLoading();
            this.fetchAllPartners();
            this.fetchAllSellers();
            this.props.fetchPartnershipRequest();
            this.props.getDashboardValues();
        })
        .catch(() =>{
            this.setState(oldstate =>({
                endPartnership: {...oldstate.endPartnership, success: false},
            }));
            this.props.hideLoading();
            hidePopUp();
        })
    };

    renderPagination = () => {
        const {t}=this.props;
        return (
            <div className="pagination">
                <div>
                    <Pagination
                        totalElements={this.state.totalElements}
                        changePageCallback={pageNumber =>
                            this.handlePaginationChange(pageNumber)
                        }
                        itemsPerPage={this.state.query ? this.state.query.perPage : 0}
                        activePage={this.state.query ? this.state.query.page : 1}
                    />
                </div>
                <div>
                    {t("Affichage par page")}
                    <select 
                        value={(this.state.query && this.state.query.perPage) || PAGE_SIZE_LIST[0]}
                        onChange={this.handleItemsPerPageChange}
                    >
                      { PAGE_SIZE_LIST.map(size =>
                        <option value={size}>{size}</option>
                      )}
                    </select>
                </div>
            </div>
        );
    };

    getCommissions = (seller)=>{
        return {
            commission_societe: {
                taux_mch: seller.taux_mch,
                taux_hotel: seller.taux_hotel
            },
            commission_restaurant: {
                commission_mch: seller.commission_restaurant_mch,
                commission_hotel: seller.commission_restaurant_hotel
            },
            commission_indep: {
                taux_mch: seller.taux_mch,
            },
            commission_agence: {
                taux_agence: seller.commission_agence
            },
            free_duration: {
                month_number: seller.free_month_number
            }
        }
    }

    renderSellers = () => {
        const {t} = this.props;
        return this.state.sellers.map(seller => {
            const type = this.state.types.find(type => type.value === parseInt(seller.type));
            const now = new Date();
            const isEndPartnership = (seller.partnerEnd && new Date(seller.partnerEnd) <= now) || false;
            return (
                <tr key={seller.id}>
                    <td>
                        <i className={`material-icons arrow-eye ${seller.isVisible ? '' : 'inactive'}`}>
                            remove_red_eye
                        </i>
                    </td>
                    <td>
                        <span className="nameclient clickable" onClick={()=>{this.props.history.push({pathname: `/prestataire/${seller.id}`, from: "partenaires"})}}>
                            {`${seller.lastname || ''} ${seller.firstname || ''}`}
                        </span><br/>
                        { (seller.address && `${seller.address.streetAddress1 || ''} ${seller.address.zipCode || ''} ${seller.address.city || ''}`) || ""}
                    </td>
                    <td>{seller.email}<br/>{(seller.address && seller.address.phone) || ""}</td>
                    <td>{type ? type.label : 'N/A'}</td>
                    <td>{seller.partnerStart ? moment(seller.partnerStart, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY  HH:mm') : ""}</td>
                    <td>
                        {isEndPartnership && 
                            <React.Fragment>
                                <span className="nameclient">
                                    {t("Fin de partenariat")}
                                </span>
                                <br/>
                            </React.Fragment>
                        }
                        {isEndPartnership ? seller.partnerEnd : t(getCommissionLabel(seller.type, seller.commission, this.getCommissions(seller), t ))} 
                    </td>
                    <td className="cnt-link">
                        {!isEndPartnership &&
                            <div>
                                <span  
                                    onClick={()=>{this.props.history.push(`/gestion-commandes?sellerid=${seller.id}`)}}
                                >
                                    {t("Commandes associées")}
                                </span>
                                <br/>
                                <span
                                    onClick={this.confirmEndPartnership}
                                    data-id={seller.partenershipId}
                                    data-name={`${seller.firstname} ${seller.lastname}`}
                                >
                                    {t("Mettre fin au partenariat")}
                                </span>
                            </div>
                        }
                        {isEndPartnership &&
                            <div>
                                <span
                                    onClick={this.renewPartnership}
                                    data-id={seller.id}
                                >
                                    {t("Renouveler")}
                                </span>
                            </div>
                        }
                    </td>
                </tr>);
        });
    };

    render() {
        const {t} = this.props;
        return (
            <div>
                {(this.state.error || this.state.successCommission === false || this.state.successCuterly === false) && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                {(this.state.endPartnership.success === true || this.state.renewPartnershipSuccess === true) && PopupHandler.showSuccess(t("La demande a bien été envoyée avec succès."))}
                {(this.state.endPartnership.success === false || this.state.renewPartnershipSuccess === false) && PopupHandler.showError(t("L'envoi de la demande a échoué, merci de réessayer ultérieurement."))}
                <div className="cnt-filtre">
                    <div className="block-search-filter">
                        <div>
                            <h3>{t("Nom du prestataire")}</h3>
                            <input type="text"
                                   className="input-search"
                                   placeholder={t("Rechercher…")}
                                   value={this.state.query.name}
                                   onChange={this.handleNameChange}
                                   onKeyDown={this.handleSearchKeyDown}
                            />
                        </div>
                        <div>
                            <h3>{t("Type de prestataire")}</h3>
                            <select className="MyclassName"
                                    value={this.state.query.type}
                                    onChange={this.handleTypeChange}
                            >
                                {this.state.types.map(type => 
                                    <option key={type.value} value={type.value}>
                                        {type.label}
                                    </option>)
                                }
                            </select>
                        </div>
                        <div>
                            <h3>{t("Catégorie")}</h3>
                            <select className="MyclassName"
                                    value={this.state.query.categories}
                                    onChange={this.handleCategoryChange}
                            >
                                <option value="">{t("Tous")}</option>
                                {this.state.categories.map(category => 
                                    <option key={category.id} value={category.id}>
                                        {category.label}
                                    </option>)
                                }
                            </select>
                        </div>
                        <div>
                            <h3>{t("Masqué du catalogue")}</h3>
                            <div className="cnt-check">
                                <label className="checkbox-label">
                                    <input type="checkbox" 
                                        value={this.state.hiddenInCatalog}
                                        checked={this.state.hiddenInCatalog}
                                        onChange={this.handleHiddenInCatalogChange}
                                    />
                                    <span className="check"></span>
                                </label>
                            </div>
                        </div>
                        <div className="cnt-btn">
                            <input type="button" className="btnSearch" value={t("Rechercher")} onClick={this.handleSearch}/>
                        </div>
                    </div>
                </div>
                <div className="table table-prestataire">
                    <div className="table-responsive scrollbar">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t("Visible")}</th>
                                    <th>{t("Prestataire")}</th>
                                    <th>{t("Contact")}</th>
                                    <th>{t("Type de prestataire")}</th>
                                    <th>{t("Début partenariat")}</th>
                                    <th>{t("Marge hôtel")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderSellers()}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.totalElements > 0 && this.renderPagination()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        seller: state.seller, 
        catalog: state.catalog,
        hotel: state.hotel
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAllPartners, 
        fetchAll,
        fetchPartnershipRequest,
        showLoading, 
        hideLoading,
        getEnabledCategories,
        getDefaultCommision, 
        getDashboardValues
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Seller)));

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import FormHandler from '../../../services/error-handlers/formHandler';
import PopupHandler from '../../../services/error-handlers/popupHandler';
import { sellerSA} from "../../../services/applicatif/seller.sa";
import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";

const EMAIL_LENGTH = 50;

class AffiliateLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            success: null,
        };
    }

    handleEmailChange = (event) => {
        const email = event.target.value;
        if (email.length > EMAIL_LENGTH) {
            return;
        }
        const emailError = email.length !== 0 && !FormHandler.isValidEmail(email);
        const emailValid = email.length !== 0 && FormHandler.isValidEmail(email);
        this.setState({email, emailError, emailValid});
    };

    handleSubmit = () => {
        const {t}=this.props;
        const email = this.state.email;
        const emailValid = email.length !== 0 && FormHandler.isValidEmail(email);
        this.setState({emailValid});
        if (!emailValid) {
            return;
        }
        const data = {
            email,
        };
        this.props.showLoading();
        sellerSA.sendInvitation(data)
        .then(() =>{
            this.props.hideLoading();
            this.setState({
                success: true,
                email: ''
            });
        })
        .catch(exception =>{
            this.props.hideLoading();
            this.setState({
                email: ''
            })
            const error = exception.response && exception.response.data;
            if (error.message) {
                if (error.message === "INVITATION_ALREADY_SEND") {
                    showPopupExtra(<PopupError title={t("Erreur")} message={t("Une invitation a déjà été envoyé à cette adresse email.")}/>)
                    return
                }
                if (error.message === "EMAIL_EXIST") {
                    showPopupExtra(<PopupError title={t("Erreur")} message={t("Cette adresse email appartient à un prestataire déjà existant.")}/>)
                    return
                }
            }  
            this.setState({
                success: false,
            });
        })
    };

    render() {
        const {t} = this.props;
        return (
            <div className="cnt-affiliation">
                {this.state.emailError && PopupHandler.showError(t("Veuillez saisir une adresse e-mail valide."))}
                {this.state.success === true && PopupHandler.showSuccess(t("L'invitation a été envoyée avec succès."))}
                {this.state.success === false && PopupHandler.showError(t("Erreur sur l'envoi de l'invitation."))}
                <h1 className="title">{t("Inviter des prestataires à rejoindre votre plateforme")}</h1>
                <div className="detail">
                    <p>
                        {t("Vous pouvez inviter des prestataires à devenir partenaire et ainsi proposer leurs services à vos clients. En saisissant l'adresse email du prestataire, un mail lui sera automatiquement envoyé avec les instructions pour la création de son profil.")}
                    </p>
                </div>
                <div>
                    <div className="cnt-Btn align-right">
                        <input 
                            type="email" 
                            placeholder={t("Saisir l'adresse e-mail du prestataire")}
                            className={`afiliat-mail${this.state.emailError ? " hasError" : ""}`}
                            name="email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                        />
                        <button className="button"
                            disabled = {!this.state.emailValid}
                            onClick={this.handleSubmit}
                        >{t("Envoyer l'invitation")}</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showLoading, hideLoading}, dispatch);
}

export default translate('translation')(connect(null, mapDispatchToProps)(AffiliateLink));

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { Redirect } from 'react-router';
import {translate} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import FormHandler from '../../../services/error-handlers/formHandler';
import PopupHandler from '../../../services/error-handlers/popupHandler';
import { authenticationSA } from '../../../services/applicatif/authentication.sa';
import authenticationConstants from "../../../services/redux/constants/auth";


import './styles.css'

class login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorPassword: 'default',
            errorEmail: 'default',
            formError: true,
            responseCode: 200,
            showPassword: false,
            redirectToReferrer: false,
            isLoading: false
        }
    }

    handleEmailChange = (event) => {
        const value = event.target.value;
        this.setState({
            email: value,
        }, this.setFormError);
    }

    handlePasswordChange = (event) => {
        const value = event.target.value;
        this.setState({
            password: value,
        }, this.setFormError);
    }

    setFormError = ()=>{
        let errorPassword = '';
        let errorEmail = '';
        const {t} =this.props;
        if (!this.state.password) {
            errorPassword = t("Ce champ est obligatoire");
        }else if (!FormHandler.isValidPassword(this.state.password)) {
            errorPassword = t("Mot de passe incorrect");
        }
        if (!this.state.email) {
            errorEmail = t("Ce champ est obligatoire");
        }else if (!FormHandler.isValidEmail(this.state.email)) {
            errorEmail = t("Email incorrect");
        }
        this.setState({
            errorPassword,
            errorEmail,
            formError: errorPassword || errorEmail
        })
    }

    handleSubmit = async(event) =>{
        event.preventDefault();
        this.setState({
            responseCode: 200
        });

        if (!this.state.formError) {
            this.setState({
                isLoading: true
            })
            const responseCode = await authenticationSA.login(this.state.email, this.state.password);
            this.setState({
                isLoading: false
            })
            if (responseCode === 200) {
                this.setState({
                    redirectToReferrer: true
                })
            }else{
                this.setState({responseCode});
            }
        }
    }

    togglePasswordVisibility = e => {
        e.preventDefault();
        this.setState(oldState => ({
            showPassword: !oldState.showPassword
            }));
      };

    render() {
        const {t} = this.props;
        const authenticationError = this.props.location.state && this.props.location.state.authenticationError;
        const from = this.props.location && 
            this.props.location.state && 
            this.props.location.state.from && 
            this.props.location.state.from.pathname &&
            this.props.location.state.from.pathname !== '/login' 
                ? this.props.location.state.from.pathname
                : '/';
        if (this.state.redirectToReferrer || (authenticationSA.isAuthenticated() && authenticationSA.isAuthenticated().success)) {
            return <Redirect to={from} />
        }
        return (
            <LoadingOverlay
                active = {this.state.isLoading}
                spinner
                text={t("Chargement")}
            >
                <div>
                    <div className="header">
                        <div className="wrapper clr">
                            <div className="logo"><Link to="#" className="img-logo">&nbsp;</Link></div>
                        </div>
                    </div>
                    <div className="page">
                        <div className="bandeaux">
                            <div className="wrapper clr">
                                <h1>{t("Authentification")}</h1>
                            </div>
                        </div>
                    </div>
                    {this.state.emailEmpty && PopupHandler.showError(t("Le champ Identifiant est obligatoire."))}
                    {this.state.passwordEmpty && PopupHandler.showError(t("Le champ Mot de passe est obligatoire."))}
                    {(this.state.responseCode === 401 || this.state.responseCode === 400) && PopupHandler.showPopupError(t("Erreur d'authentification"), t("Votre identifiant et / ou mot de passe est erroné, merci de réessayer."))}
                    {this.state.responseCode !== 200 && this.state.responseCode !== 401 && this.state.responseCode !== 400 && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {(from !== '/' || authenticationError === authenticationConstants.EXPIRES_DATE_ERROR) && PopupHandler.showPopupError(t("Erreur"), t("Votre session a expiré, veuillez vous reconnecter."))}
                    <div className="main">
                        <div className="wrapper clr">
                            <form onSubmit={this.handleSubmit} className="block-form" action="">
                                <div className="line-input">
                                    <label>{t("Identifiant ou adresse mail")}</label>
                                    <div className="cnt-input with-icon">
                                        <input type="email"
                                            className={this.state.errorEmail && this.state.errorEmail !== 'default' ? "hasError" : ""}
                                            required={true}
                                            value={this.state.email}
                                            placeholder={t("Identifiant ou adresse mail")}
                                            onChange={this.handleEmailChange}
                                        />
                                        <span className="edit">
                                            {this.state.emailValid === false ?
                                                <i className="material-icons icon-arrow-down">error</i> :
                                                <i className="material-icons icon-edit">person</i>}
                                        </span>
                                        {
                                            this.state.errorEmail &&
                                            this.state.errorEmail !== 'default' &&
                                            <span className='error'>{this.state.errorEmail}</span>
                                        }
                                    </div>
                                </div>
                                <div className="line-input">
                                    <label>{t("Mot de passe")}</label>
                                    <div className="cnt-input with-icon">
                                        <input type={this.state.showPassword ? "text" : "password"}
                                            value={this.state.password}
                                            className={this.state.errorPassword && this.state.errorPassword !== 'default' ? "hasError" : ""}
                                            required={true}
                                            placeholder={t("Mot de passe")}
                                            onChange={this.handlePasswordChange}
                                        />
                                        <span 
                                            className={`edit clickable`}
                                            onClick={this.togglePasswordVisibility}
                                        >
                                            <i className="material-icons icon-edit">{!this.state.showPassword ? 'lock' : 'lock_open'}</i>
                                        </span>
                                        {
                                            this.state.errorPassword &&
                                            this.state.errorPassword !== 'default' &&
                                            <span className='error'>{t(this.state.errorPassword)}</span>
                                        }
                                        {
                                            this.state.errorPassword === 'Mot de passe incorrect' &&
                                                <p className='error'>
                                                    {t("6 caractères au minimum et doit contenir au moins 1 chiffre, 1 lettre majuscule, 1 lettre minuscule et 1 caractère spécial")}
                                                </p>
                                        }
                                    </div>
                                </div>
                                <div className="cnt-Btn">
                                    <button className="button"
                                        disabled={this.state.formError}
                                    >
                                        {this.state.isLoading ? `${t("Traitement en cours")}...` : t("Se connecter")}
                                    </button>
                                    <div className="clr"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}

export default translate('translation')(withRouter(login));

import { languageSA } from "../../applicatif/language.sa";
import constants from '../constants/language';

export const initLoading = () => {
    return {
        type: constants.LOADING,
        payload: null
    }
};

export const fetchLanguages = () => {
    const request = languageSA.fetchLanguages();

    return {
        type: constants.FETCH_LANGUAGES,
        payload: request
    }
};


import config from "../../../../src/config";

const baseURL = config.baseURL;

export default {
    URL: `${baseURL}/rest/V1/saveColor`,
    GET_COLOR_URL: `${baseURL}/rest/V1/getColor` ,
    GET_COLOR: 'GET_COLOR' ,
    UPDATE: 'theme/UPDATE',
    LOADING: 'theme/LOADING',
};

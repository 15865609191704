import React, { Component } from 'react'
import {translate} from 'react-i18next';
import { hidePopupExtra } from '../popup/popup';

class FileSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            filename: '',
        }
    }

    cancel = ()=>{
        hidePopupExtra();
    }

    handleFileInput = () => {
        document.getElementById('fileInput').click();
    }

    selectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const result = e.target.files[0];                            
            this.setState({
                file: result,
                filename: result.name,
            })
        } else {
            this.setState({
                file: null,
                filename: '',
            })
        }
    }

    onFileSelected = () => {
        this.props.onFileSelected(this.state.file);
    };
    

    render() {
        const { t } = this.props;
        return (
            <div className="popup-full popup-full-m limg-cropper">
                
                <div className="limgCropper-body">
                    {this.state.filename &&
                        <span className="filename">{this.state.filename}</span>
                    }
                </div>
                <div className='limgcropper-footer'>
                    <div className="limgCropper-file">
                        <input
                            id="fileInput"
                            className="file-input"
                            type="file"
                            data-multiple-caption="{count} files selected"
                            multiple = {this.props.isMultiple}
                            accept="application/pdf, image/jpeg, image/png"
                            onChange={this.selectFile}
                        />
                        
                        <button
                            className="lbtn lbtn-senary"
                            onClick={this.handleFileInput}>{t("Selectioner votre fichier")}
                        </button>
                    </div>
                    <div className='limgcropper-footer'>
                        <button className="lbtn lbtn-link" onClick={this.cancel}>{t('Annuler')}</button>
                        <button 
                            className={`button ${!this.state.file ? 'disable' : ""}`} 
                            onClick={this.onFileSelected} disabled={!this.state.file}
                        >
                            {t('Valider')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('translation')(FileSelect)

import config from "../../../../src/config";

const baseURL = config.baseURL;

export default {
    LOADING: 'client/LOADING',
    FETCH_URL: `${baseURL}/rest/V1/getCustomer`,
    FETCH: 'client/FETCH',
    RESET_PASSWORD_URL: `${baseURL}/rest/V1/forgotPassword/`,  
    RESET_PASSWORD: 'client/RESET_PASSWORD',
    EXPORT_URL: `${baseURL}/rest/V1/exportCsvCustomer`,
    EXPORT: 'client/EXPORT',
    DETAILS_CUSTOMER_URL: `${baseURL}/rest/V1/getCustomerDetails`,
    DETAILS_CUSTOMER: 'client/DETAILS_CUSTOMER',
    SAVE_CUSTOMER_URL: `${baseURL}/rest/V1/editCustomer`,
    SAVE_CUSTOMER: 'client/SAVE_CUSTOMER',
    CUSTOMER_QUERY: 'seller/CUSTOMER_QUERY',
}

import constants from "../constants/loading";
export const showLoading = () => {
    return {
        type: constants.SHOW_LOADING,
        payload: null
    }
};

export const hideLoading = () => {
    return {
        type: constants.HIDE_LOADING,
        payload: null
    }
};
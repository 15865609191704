import constants from '../constants/auth';

const initialState = {
  	expiresDate: false,
};

const authentification = (state = initialState, action) => {
  switch (action.type) {
    case constants.EXPIRES_DATE:
		return {
			...state,
			expiresDate: action.payload,
		};
    default:
      	return state;
  }
};

export default authentification;

import React from "react";
import Dropdown from "react-dropdown";
import {Helmet} from "react-helmet";
import DayPicker, { DateUtils } from "react-day-picker";
import {translate} from 'react-i18next';
import "react-day-picker/lib/style.css";
import "../../../index.css";
import moment from 'moment';
import { SELECTION_DATE } from "../../../data/constants/selection.date";


class selectionDate extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = this.getInitialState();
        this.state = {
            isVisible: false,
            value: SELECTION_DATE.TODAY.value,
            oldValue: SELECTION_DATE.TODAY.value,
            from: "",
            to: "",
        };
    }

    componentDidMount() {
        this.props.selectDate({value: SELECTION_DATE.TODAY.value});
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleDayClick(day) {
        const date = new Date(moment(day).format('YYYY-MM-DD'));
        const hotelCreationDate = this.props.hotelCreationDate;
        if (date > new Date(moment().format('YYYY-MM-DD')) || (hotelCreationDate && date < new Date(moment(hotelCreationDate).format('YYYY-MM-DD')))) {
            return
        }
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
        // range.to && console.log("to", range.to.toLocaleString().split(" ")[0]);
        // range.from && console.log("from", range.from.toLocaleString().split(" ")[0]);
        console.log("range.from:",range.from)   
        console.log("range.to:",range.to) 
        if(range.from && range.to) {
            this.setState({ isVisible: false });
        }
        if(range.from && range.to && range.from.toDateString() && range.to.toDateString()) {
            const rangeFrom = range.from.toLocaleString().split(" ")[0];
            const rangeTo = range.to.toLocaleString().split(" ")[0]
            this.setState({ value: `${rangeFrom} à ${rangeTo}` });
            this.props.selectDate({value: this.state.value, rangeFrom, rangeTo})
        }
    }

    handleResetClick() {
        this.setState(this.getInitialState());
    }


    selectDate = (value) => {
        const dateValue = value.value;
        const oldValue = this.state.value;
        this.setState({ value: dateValue, oldValue });
        if (dateValue === SELECTION_DATE.CHOOSE_DATE.value) {
            this.setState({ isVisible: true });
        }else{
            this.props.selectDate({value: dateValue})
        }
    }

    setDisabledDate = () => {
        const hotelCreationDate = this.props.hotelCreationDate;
        let list = hotelCreationDate ? [{ "before": new Date(moment(hotelCreationDate).format('YYYY-MM-DD'))}] : [];
        list = [...list, { "after": new Date() }];
        return list
    }

    handleClickOutside = event => {
        if (event.target.offsetParent&& !event.target.offsetParent.matches(".DayPicker-wrapper")) {
            let value = this.state.value;
            if (value === SELECTION_DATE.CHOOSE_DATE.value) {
                value = this.state.oldValue;
            }
            this.setState({
                isVisible: false,
                value,
            });
        }
    };

    render() {
        const { from, to, isVisible } = this.state;
        const modifiers = { start: from, end: to };
        const {t} = this.props;
        const options = [
            { value: SELECTION_DATE.TODAY.value, label: t(SELECTION_DATE.TODAY.label) },
            { value: SELECTION_DATE.CURRENT_WEEK.value, label: t(SELECTION_DATE.CURRENT_WEEK.label) },
            { value: SELECTION_DATE.CURRENT_MONTH.value, label: t(SELECTION_DATE.CURRENT_MONTH.label) },
            { value: SELECTION_DATE.CURRENT_YEAR.value, label: t(SELECTION_DATE.CURRENT_YEAR.label) },
            { value: SELECTION_DATE.CHOOSE_DATE.value, label: t(SELECTION_DATE.CHOOSE_DATE.label) },
        ];

        const MONTHS = [
            t("Janvier"),
            t("Fevrier"),
            t("Mars"),
            t("Avril"),
            t("Mai"),
            t("Juin"),
            t("Juillet"),
            t("Aout"),
            t("Septembre"),
            t("Octobre"),
            t("Novembre"),
            t("Decembre"),
          ];
          const WEEKDAYS_LONG = [
            t("Dimanche"),
            t("Lundi"),
            t("Mardi"),
            t("Mercredi"),
            t("Jeudi"),
            t("Vendredi"),
            t("Samedi"),
          ];
          const WEEKDAYS_SHORT = [t("Di"), t("Lu"), t("Ma"), t("Me"), t("Jeu"), t("Ve"), t("Sa")];

        return (
            <div className="select-down">
                <Dropdown
                    className="MyclassName"
                    options={options}
                    onChange={this.selectDate}
                    value={this.state.value}
                    placeholder="Select an option"
                    controlClassName="myControlClassName"
                    arrowClassName="arrow-down"
                    arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                    arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                />
                {
                    isVisible && <DayPicker
                        className="Selectable"
                        numberOfMonths={this.props.numberOfMonths}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        style={{position : "relative", background : "#3e82cf" ,}}
                        onDayClick={this.handleDayClick}
                        locale="fr"
                        months={MONTHS}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                        firstDayOfWeek={1}
                        disabledDays={this.setDisabledDate()}
                    />
                }
                <Helmet><style>{`
                        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                        }
                        .Selectable .DayPicker-Day {
                            border-radius: 0 !important;
                        }
                        .Selectable .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                        }
                        .Selectable .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                        }
                        .Selectable .DayPicker-wrapper { background:#ffffff}

                        Selectable .DayPicker-wrapper { background:#ffffff; box-shadow:0 0 5px #000;}
                        
                        
                        
                `}</style>
                </Helmet>
            </div>
        );
    }
}
export default translate('translation')(selectionDate);
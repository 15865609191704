import { applyMiddleware, createStore, compose } from 'redux';

import ThunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import ReduxPromise from 'redux-promise';

const middlewares = [ReduxPromise, ThunkMiddleware];

const createStoreWithMiddleware = compose(
    applyMiddleware(...middlewares)
)(createStore);

const store = createStoreWithMiddleware(rootReducer);

export default store;

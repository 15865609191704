import React from 'react';
import {Link} from 'react-router-dom';
import {translate} from 'react-i18next';
import ToggleDisplay from 'react-toggle-display';

class ViewCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: [],
        };
    }
    handleClick =(event)=>{
        const id = event.target.dataset.id;
        const show = [...this.state.show]
        if (!show.includes(id)) {
            show.push(id);
        } else {
            show.splice(show.indexOf(id), 1);
        }
        this.setState({
            show
        });
    }
    isEnabled = product =>{
        return product && product.enabled && parseInt(product.enabled)
    }

    renderProducts = (category) => {
        const {t} = this.props
        let products = [];
        if (category.sousCategories && category.sousCategories.length > 0) {
            category.sousCategories.forEach((sousCategorie) => {
                if (this.isEnabled(sousCategorie.products)) {
                    products.push(sousCategorie.products);
                }else if(sousCategorie.sousCategories && sousCategorie.sousCategories.length > 0){
                    sousCategorie.sousCategories && [].concat(sousCategorie.sousCategories).forEach(sousSousCategorie => {
                        if (this.isEnabled(sousSousCategorie.products)) {
                            products.push(sousSousCategorie.products);
                        }
                    })
                }
            });
        }
        if (category.products && category.products.length > 0) {
            category.products && [].concat(category.products).forEach(product => {
                if (this.isEnabled(product)) {
                    products.push(product);
                }
            })
        }

        return products.map((product) =>
            <div key={product.id} className="cnt-line-product">
                <div className="line-product">
                    <div className="infos-product">
                        <h3>{product.name}</h3>
                        <p>{product.duration || 0} mn</p>
                        <p><span data-id={product.id} className="link" onClick={this.handleClick}>{t("+ détails")}</span></p>
                    </div>
                    <div className="action-product">
                        <p className="price">{product.hasOption ? `${t("à partir de")} `:''}{product.price ? parseFloat(product.price).toFixed(2) : 0} €</p>
                        <div className="cnt-btn">
                            <Link to="#" className="button">{t("Réserver")}</Link>
                        </div>
                    </div>
                </div>
                <ToggleDisplay show={this.state.show.includes(`${product.id}`)} tag="section">
                    {product.description}
                </ToggleDisplay>
            </div>
        );
    };

    render() {
        const category = this.props.category;
        const {t} = this.props;
        return (
            <div>
                <div className="btn-vue align-right">
                    <button  onClick={this.props.closeViewCategory} className="button">
                        <span className="vue"><i className="material-icons arrow-eye">remove_red_eye</i></span> 
                        {t("Voir en tant que qu'administrateur")}
                    </button>
                </div>
                <div className="content-client">
                    <div className="banner-client" style={{backgroundImage: `url(${category.image})`}}>
                        {/* <img src={category.image} alt=""/> */}
                    </div>

                    {/* category.name */}
                    <div className="liste-product">
                        {this.renderProducts(category)}
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translation')(ViewCategory);

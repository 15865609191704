import React from 'react';
import { Provider } from 'react-redux';
import store from "./services/redux/store";
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';

//import { PersistGate } from 'redux-persist/integration/react';
import InitialScreen from './presentations/containers/main';
import './App.css';

function App() {
  return (
    <Provider store={store}>
        <I18nextProvider i18n={i18next}> 
            <InitialScreen/>
        </I18nextProvider>  
    </Provider>
  );
}

export default App;

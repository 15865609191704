import { themeSA } from "../../applicatif/theme.sa";
import constants from '../constants/theme';

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};

export const updateColor = (data) => dispatch => {
    showLoading(dispatch, constants.UPDATE)
    const request = themeSA.updateColor(data);

    dispatch({
        type: constants.UPDATE,
        payload: request
    })
};

export const getColor = () => dispatch => {
    showLoading(dispatch, constants.GET_COLOR)
    const request = themeSA.getColor();

    dispatch({
        type: constants.GET_COLOR,
        payload: request
    })
};

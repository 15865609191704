import React from 'react';
import countryList from 'react-select-country-list';
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import PhoneInput from 'react-phone-number-input/input';
import Rating from 'react-rating';
import Select, { components } from "react-select";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import FormHandler from "../../../services/error-handlers/formHandler";
import './style.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {fetchInfos, getLogo, getCarousel, updateInfos, uploadLogo, updateCarousel, getActivityZones} from "../../../services/redux/actions/hotel";
import ImageSelect from "../../components/image-select/image-select";
import {showPopupExtra, hidePopupExtra} from "../../components/popup/popup";


const NAME_LENGTH = 30;
const EMAIL_LENGTH = 50;
const DESCRIPTION_LENGTH = 200;
const TEXT_LENGTH = 200;

const SingleValue = props => (
    <components.SingleValue {...props}>
      {props.data.chipLabel}
    </components.SingleValue>
);

const options = getCountries().map((country) => ({
    label: en[country] + ' +'+getCountryCallingCode(country),
    chipLabel: '+'+getCountryCallingCode(country),
    value: country
}));  

class Monhotel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            street: '',
            zipCode: '',
            city: '',
            country: '',
            email: '',
            phone: '',
            description: '',
            text: '',
            logo: '',
            carousel: '',
            landingImage: '',
            hasError: false,
            hasLoadingError: false,
            isValidPhoneNumber: true,
            isValidEmail: true,
            isUpdateSuccess: null,
            isValid: false,
            currentCountry: 'FR',
            countries: countryList().setEmpty(props.t('Sélectionnez un pays')).getData(),
            zipCodes: [],
            nbStar: 0,
        };
    }

    onNameChange = (event) => {
        const value = event.target.value;
        if (value.length > NAME_LENGTH) {
            return;
        }
        this.setState({name: value}, this.isValid);
    };

    onEmailChange = (event) => {
        const value = event.target.value;
        if (value.length > EMAIL_LENGTH) {
            return;
        }
        this.setState({email: value}, this.isValid);
    };

    onPhoneChange = (value) => {
        this.setState({phone: value}, this.isValid);
    };

    onDescriptionChange = (event) => {
        const value = event.target.value;
        const {t} = this.props;
        if (value.length > DESCRIPTION_LENGTH) {
            this.setState({
                descriptionInfo: t("200 caractères maximum")
            })
            return;
        }
        this.setState({
            description: value,
            descriptionInfo: ''
        }, this.isValid);
    };

    onTextChange = (event) => {
        const value = event.target.value;
        const {t} = this.props;
        if (value.length > TEXT_LENGTH) {
            this.setState({
                textInfo: t("200 caractères maximum")
            })
            return;
        }
        this.setState({
            text: value,
            textInfo: ''
        }, this.isValid);
    };

    isValid = ()=>{
        const nameError = FormHandler.nameWithSpecialCharsErrors(this.state.name);
        const streetError = FormHandler.adressNotVoidError(this.state.street.trim());
        const zipCodeError = FormHandler.notEmptyInput(this.state.zipCode);
        const cityError = FormHandler.adressNotVoidError(this.state.city);
        const countryError = FormHandler.notEmptyInput(this.state.country);
        const emailError = FormHandler.emailError(this.state.email);
        const phoneError = FormHandler.phoneNotVoidError(this.state.phone);
        const descriptionError = FormHandler.descriptionErrors(this.state.description);
        const textError = FormHandler.descriptionErrors(this.state.text);
        const isValid = !nameError && !streetError && !zipCodeError && !cityError && !countryError && !emailError && !phoneError && !descriptionError && !textError;
        this.setState({
            nameError,
            streetError,
            zipCodeError,
            cityError,
            countryError,
            emailError,
            phoneError,
            descriptionError,
            textError,
            isValid
        })
    }

    handleContrySelect (value) {     
        this.setState({
            currentCountry: value.value,
            phone: ""
        }, this.isValid);
    }

    handleStreetChange = (event) => {
        const street = event.target.value;
        if (street.length > TEXT_LENGTH) {
            return;
        }
        this.setState({street},this.isValid);
    };

    handleZipCodeChange = (event) => {
        const zipCode = event.target.value;
        this.setState({zipCode},this.isValid);
    };

    handleCityChange = (event) => {
        const city = event.target.value;
        if (city.length > TEXT_LENGTH) {
            return;
        }
        this.setState({city},this.isValid);
    };

    handleCountryChange = (event) => {
        const country = event.target.value;
        this.setState({country},this.isValid);
    };

    handleSubmit = () => {
        if (!this.state.isValid) {
            return
        }
        const data = {
            info_id: this.state.infoId,
            nomhotel: this.state.name || '',
            rue: this.state.street || '',
            codepostal: this.state.zipCode || '',
            ville: this.state.city || '',
            pays: this.state.country ? countryList().getLabel(this.state.country) : '',
            email: this.state.email || '',
            telephone: this.state.phone || '',
            description: this.state.description || '',
            texteaccueil: this.state.text || '',
            adresse: `${this.state.street}, ${this.state.city} ${this.state.zipCode} ${this.state.country}`
        }
        this.props.updateInfos(data);
    };

    componentDidMount() {
        
        if (this.props.hotel && this.props.hotel.hotelInfo && this.props.hotel.hotelInfo.data && this.props.hotel.hotelInfo.data.InfosHotel && this.props.hotel.hotelInfo.data.InfosHotel.length > 0 ) {
            this.setInfoHotelState(this.props.hotel.hotelInfo.data.InfosHotel[0]);
        }
        if (this.props.hotel && this.props.hotel.logoImage && this.props.hotel.logoImage.data && this.props.hotel.logoImage.data.data) {
            this.setState({
                logo: this.props.hotel.logoImage.data.data || '',
                logoError: false,
            })
        }
        if (this.props.hotel && this.props.hotel.carouselImage && this.props.hotel.carouselImage.data && this.props.hotel.carouselImage.data.data && this.props.hotel.carouselImage.data.data.length > 0 ) {
            this.setState({
                carousel: this.props.hotel.carouselImage.data.data[0].image,
                carouselError: false
            })
        }else{
            this.props.getCarousel();
        }
        if (this.props.hotel && this.props.hotel.activityZones && this.props.hotel.activityZones.data && this.props.hotel.activityZones.data.ActivityZones) {
            this.setState({
                zipCodes: this.props.hotel.activityZones.data.ActivityZones || [],
            })
        }else{
            this.props.getActivityZones();
        }
    }

    componentDidUpdate(props) {
        const prev = props.hotel;
        const curr = this.props.hotel;

        if (!prev) {
            return;
        }
        if (prev.hotelInfo && curr.hotelInfo && prev.hotelInfo.loading && !curr.hotelInfo.loading) {
            if (prev.hotelInfo.error) {
                this.setState({hasLoadingError: true});
            } else {
                const infosHotel = (curr.hotelInfo.data && curr.hotelInfo.data.InfosHotel && curr.hotelInfo.data.InfosHotel.length > 0 && curr.hotelInfo.data.InfosHotel[0]) || {}
                this.setInfoHotelState(infosHotel);
            }
        }

        if (prev.logoImage && curr.logoImage &&  prev.logoImage.loading && !curr.logoImage.loading ) {
            if (curr.logoImage.error) {
                this.setState({
                    logoError: true
                })
                
            } else {
                const logo= (curr.logoImage.data && curr.logoImage.data.data) || "";
                this.setState({
                    logo,
                    logoError: false
                })
            }
        }

        if (prev.carouselImage && curr.carouselImage &&  prev.carouselImage.loading && !curr.carouselImage.loading ) {
            if (curr.carouselImage.error) {
                this.setState({
                    carouselError: true
                })
                
            } else {
                const carousel = (curr.carouselImage.data && curr.carouselImage.data.data && curr.carouselImage.data.data[0].image) || "";
                this.setState({
                    carousel,
                    carouselError: false
                })
            }
        }

        if (prev.hotelUpdate && curr.hotelUpdate && prev.hotelUpdate.loading && !curr.hotelUpdate.loading) {
            if (curr.hotelUpdate.error) {
                this.setState({isUpdateSuccess: false});
            }else{
                this.setState({isUpdateSuccess: true});
                this.props.fetchInfos();
            }
        }

        if (prev.uploadLogo && curr.uploadLogo &&  prev.uploadLogo.loading && !curr.uploadLogo.loading ) {
            hidePopupExtra();
            if (curr.uploadLogo.error) {
                this.setState({
                    uploadLogoSucess: false
                })
                
            } else {
                this.setState({
                    uploadLogoSucess: true,
                    logo: (curr.uploadLogo.data && curr.uploadLogo.data.image) || ""
                })
            }
        }

        if (prev.updateCarousel && curr.updateCarousel &&  prev.updateCarousel.loading && !curr.updateCarousel.loading ) {
            hidePopupExtra();
            if (curr.updateCarousel.error) {
                this.setState({
                    updateCarouselSuccess: false
                })
                
            } else {
                this.setState({
                    updateCarouselSuccess: true,
                    carousel: (curr.updateCarousel.data && curr.updateCarousel.data.carouselPath) || ""
                })
            }
        }

        if (prev.activityZones && curr.activityZones &&  prev.activityZones.loading && !curr.activityZones.loading ) {
            if (curr.activityZones.error) {
                this.setState({
                    hasLoadingError: true
                })
                
            } else {
                this.setState({
                    zipCodes: (curr.activityZones.data && curr.activityZones.data.ActivityZones ) || [],
                })
            }
        }
    }

    setInfoHotelState = (infosHotel)=>{
        this.setState({
            infoId: infosHotel.info_id,
            name: infosHotel.nomhotel || '',
            street: infosHotel.rue || '',
            zipCode: infosHotel.codepostal || '',
            city: infosHotel.ville || '',
            country: infosHotel.pays ? countryList().getValue(infosHotel.pays) : '',
            phone: infosHotel.telephone || '',
            description: infosHotel.description ? infosHotel.description.substring(0, 200) : '',
            email: infosHotel.email || '', 
            text: infosHotel.texteaccueil ? infosHotel.texteaccueil.substring(0, 200) : '',
            codeHotel: infosHotel.code_hotel,
            hasLoadingError: false,
            currentCountry: (infosHotel.telephone && isValidPhoneNumber(infosHotel.telephone)) ? parsePhoneNumber(infosHotel.telephone).country : "FR",
            nbStar: infosHotel.nombre_etoile
        }, this.isValid);
    }

    openLogoPopup = () => {
        showPopupExtra(<ImageSelect onPictureValidate={this.saveLogo} currentPictureUrl={this.state.logo}/>);
    };
    openLandingPagePopup = () => {
        showPopupExtra(<ImageSelect onPictureValidate={this.saveCarousel} currentPictureUrl={this.state.carousel} isLandingPage/>, "landing-page");
    };

    saveLogo = ({croppedFile, croppedImageUrl}) => {
        if (!croppedFile) {
            return;
        }
        this.setState({
            croppedImageUrl
        })
        const data = new FormData();
        data.append('image', croppedFile);
        this.props.uploadLogo(data);
    };
    saveCarousel = ({croppedFile, croppedImageUrl}) => {
        if (!croppedFile) {
            return;
        }
        this.setState({
            croppedImageUrl
        })
        const data = new FormData();
        data.append('image', croppedFile);
        data.append('carousel_id', "1");
        this.props.updateCarousel(data);
    };

    render() {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>{t("Mon hôtel")} </h1>
                    </div>
                </div>
                <div className="no-margin-error">
                    {(this.state.hasLoadingError || this.state.logoError || this.state.carouselError) && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {(this.state.isUpdateSuccess === true || this.state.uploadLogoSucess === true || this.state.updateCarouselSuccess === true) && 
                        PopupHandler.showSuccess(t("Les modifications ont été enregistrées."))}
                    {(this.state.isUpdateSuccess === false || this.state.uploadLogoSucess === false || this.state.updateCarouselSuccess === false) && 
                        PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
                    
                </div>
                <div className="main monHotel">
                    <div className="line-input img-pr cb_carousel" style={{backgroundImage: `url(${this.state.carousel})`}}>
                        {/* <div className="cnt-input">
                            <img src={this.state.carousel} alt={t("Image landing page")} />
                        </div> */}
                        <div className="blc-edit">
                            <span className="clickable" onClick={this.openLandingPagePopup}>
                                <span className="icon-edit"><i className="material-icons icon-edit">edit</i></span> 
                                {t("Modifier")}
                            </span>
                        </div>
                    </div>
                    <div className="wrapper clr">
                        <div className="row mainBody">
                            <div className="col col-5">
                                <p className="titlephoto">{t("Logo hotel")}</p>
                                <div className="cnt-photo">
                                    <div className="blc-photo">
                                        <img src={this.state.logo} alt={t("Logo hotel")}/>
                                        {
                                            (this.state.nbStar) &&
                                            <div className="star-hotel">
                                                <Rating
                                                    readonly
                                                    placeholderRating={(this.state.nbStar && parseInt(this.state.nbStar)) || 0}
                                                    emptySymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down star-empty">star</i></span>}
                                                    placeholderSymbol={<span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">star</i></span>}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="blc-edit">
                                        <span className="clickable" onClick={this.openLogoPopup}>
                                            <span className="icon-edit"><i className="material-icons icon-edit">edit</i></span> 
                                            {t("Modifier")}
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button 
                                        className="button" 
                                        onClick={()=>{this.props.history.push("/editer-theme")}}
                                    >
                                        {t("Modifier les couleurs")}
                                    </button>
                                </div>
                            </div>
                            <div className="col col-7">
                                <div className="row">
                                    <div className="col col-6 line-input">
                                        <label>{t("Nom de l'Hotel")} <sup>*</sup></label>
                                        <div className="cnt-input">
                                            <input 
                                                type="text" 
                                                className={`input-name-hotel${this.state.nameError ? ' hasError' :  ''}`}
                                                value={this.state.name}
                                                onChange={this.onNameChange}
                                                required={true}
                                            />
                                        </div>
                                        {
                                            this.state.nameError && 
                                            <span className="error">{this.state.nameError}</span>
                                        }
                                    </div>
                                    <div className="col col-6 line-input">
                                        <label>{t("Rue")}</label>
                                        <div className="cnt-input">
                                            <input type="text"
                                                className={this.state.streetError? "hasError" : ""}
                                                value={this.state.street}
                                                onChange={this.handleStreetChange}
                                            />
                                        </div>
                                        {
                                            this.state.streetError && 
                                            <span className="error">{this.state.streetError}</span>
                                        }
                                    </div>
                                    <div className="col col-6 line-input">
                                        <label>{t("Code postal")}</label>
                                        {/* <div className="cnt-input">
                                            <input type="number"
                                                className={this.state.zipCodeError === true ? "hasError" : ""}
                                                value={this.state.zipCode}
                                                onChange={this.handleZipCodeChange}
                                            />
                                        </div> */}
                                        <div className="select">
                                            <select 
                                                className={this.state.zipCodeError ? "hasError" : ""}
                                                value={this.state.zipCode}
                                                onChange={this.handleZipCodeChange}
                                            >
                                                {this.state.zipCodes.map(zipCode =>
                                                    <option key={zipCode.postal_code} value={zipCode.postal_code}>{zipCode.postal_code}</option>
                                                )}
                                            </select>
                                        </div>
                                        {
                                            this.state.zipCodeError && 
                                            <span className="error">{this.state.zipCodeError}</span>
                                        }
                                    </div>
                                    <div className="col col-6 line-input">
                                        <label>{t("Ville")}</label>
                                        <div className="cnt-input">
                                            <input type="text"
                                                className={this.state.cityError ? "hasError" : ""}
                                                value={this.state.city}
                                                onChange={this.handleCityChange}
                                            />
                                        </div>
                                        {
                                            this.state.cityError && 
                                            <span className="error">{this.state.cityError}</span>
                                        }
                                    </div>
                                    <div className="col col-6 line-input">
                                        <label>{t("Pays")}</label>
                                        <div className="cnt-input">
                                            <select 
                                                onChange={this.handleCountryChange}
                                                className={this.state.countryError ? "hasError" : ""}
                                                value={this.state.country}
                                            >
                                                {this.state.countries.map((country) => <option
                                                    value={country.value} key={country.value}>{country.label}</option>)}
                                            </select>
                                        </div>
                                        {
                                            this.state.countryError && 
                                            <span className="error">{this.state.countryError}</span>
                                        }
                                    </div>
                                    <div className="col col-6 line-input">
                                        <label>{t("Adresse email de l'hôtel")} <sup>*</sup></label>
                                        <div className="cnt-input">
                                            <input 
                                                className={`input-adress-hotel${this.state.emailError ? ' hasError' :  ''}`}
                                                type="email"
                                                value={this.state.email}
                                                onChange={this.onEmailChange}
                                                required={true}
                                            />
                                        </div>
                                        {
                                            this.state.emailError && 
                                            <span className="error">{this.state.emailError}</span>
                                        }
                                    </div>
                                    <div className="col col-6 line-input">
                                        <label>{t("Numéro de téléphone")} <sup>*</sup></label>
                                        <div className="PhoneInput">
                                            <Select
                                                onChange={(value) => this.handleContrySelect(value)}
                                                options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                                                components={{ SingleValue }}
                                                value={options.find(op => { return op.value === (this.state.currentCountry) })}
                                            />
                                            <PhoneInput
                                                className={this.state.phoneError ? 'hasError' : ''}
                                                country={this.state.currentCountry}
                                                international
                                                value={this.state.phone ? this.state.phone : ""}
                                                onChange={this.onPhoneChange}
                                                name='phone'
                                            />
                                        </div>
                                        {
                                            this.state.phoneError && 
                                            <span className="error">{this.state.phoneError}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 line-desc">
                                <label>{t("Description")} <sup>*</sup></label>
                                <textarea 
                                    value={this.state.description}
                                    onChange={this.onDescriptionChange}
                                    required={true}
                                    className={this.state.descriptionError ? 'hasError' : ''}
                                ></textarea>
                                {
                                    this.state.descriptionInfo && 
                                    <span className="error">{this.state.descriptionInfo}</span>
                                }
                                {
                                    this.state.descriptionError && 
                                    <span className="error">{this.state.descriptionError}</span>
                                }
                            </div>
                            <div className="col col-12 line-desc">
                                <label>{t("Texte personnalisé d'accueil")} <sup>*</sup></label>
                                <textarea 
                                    value={this.state.text}
                                    onChange={this.onTextChange}
                                    required={true}
                                    className={this.state.textError ? 'hasError' : ''}
                                ></textarea>
                                {
                                    this.state.textInfo && 
                                    <span className="error">{this.state.textInfo}</span>
                                }
                                {
                                    this.state.textError && 
                                    <span className="error">{this.state.textError}</span>
                                }
                            </div>
                        </div>
                        <div className="cnt-Btn">
                            <button 
                                disabled = {!this.state.isValid}
                                className="button" 
                                onClick={this.handleSubmit}
                            >
                                {t("Enregistrer les modifications")}
                            </button>
                        </div>
                        <div className="clr"></div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {hotel: state.hotel};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({fetchInfos,
        getLogo, 
        getCarousel, 
        updateInfos, 
        uploadLogo, 
        updateCarousel,
        getActivityZones
    }, dispatch);
};

export default translate('translation')(connect(mapStateToProps, mapDispatchToProps)(Monhotel));

import config from "../../../../src/config";

const baseURL = config.baseURL;

export default {
    LOADING: 'catalog/LOADING',
    INIT_STATE: 'catalog/INIT_STATE',
    LOAD_CATEGORIES: 'catalog/LOAD-CATEGORIES',
    FETCH_CATEGORIES_URL: `${baseURL}/rest/V1/internalServiceCategory`,
    FETCH_CATEGORIES: 'catalog/FETCH-CATEGORIES',
    REORDER_URL: `${baseURL}/rest/V1/reorderCategory`,
    REORDER: 'catalog/REORDER',
    SAVE_IMAGE_CATEGORY_URL: `${baseURL}/rest/V1/saveImageCategory`,
    SAVE_IMAGE_CATEGORY: 'catalog/SAVE-IMAGE-CATEGORY',
    ENABLE_CATEGORY_URL: `${baseURL}/rest/V1/enableCategory`,
    DISABLE_CATEGORY_URL: `${baseURL}/rest/V1/disableCategory`,
    TOGGLE_CATEGORY: 'catalog/TOGGLE-CATEGORY',
    GET_PRODUCT_URL: `${baseURL}/rest/V1/getOptionsCategory`,
    GET_AVAILABLE_OPTIONS_URL: `${baseURL}/rest/V1/getAvailableOptions`,
    GET_AVAILABLE_OPTIONS: 'catalog/GET_AVAILABLE_OPTIONS',
    INIT_PRODUCT: 'catalog/INIT_PRODUCT',
    GET_PRODUCT: 'catalog/GET-PRODUCT',
    UPDATE_PRODUCT_URL: `${baseURL}/rest/V1/saveOptionsCategory`,
    UPDATE_PRODUCT: 'catalog/UPDATE-PRODUCT',
    FETCH_EXTERNAL_CATEGORIES_URL: `${baseURL}/rest/V1/externalServiceCategory`,
    FETCH_EXTERNAL_CATEGORIES: 'catalog/FETCH-EXTERNAL-CATEGORIES',
    FETCH_EXTERNAL_SUB_CATEGORIES_URL: `${baseURL}/rest/V1/externalServiceSubCategory`,
    FETCH_EXTERNAL_SUB_CATEGORIES: 'catalog/FETCH-EXTERNAL-SUB-CATEGORIES',
    TOGGLE_EXTERNAL_CATEGORY: 'catalog/TOGGLE-EXTERNAL-CATEGORY',
    CHECK_STRIPE_STATUS_URL: `${baseURL}/rest/V1/checkStripeStatus`,
    CHECK_STRIPE_STATUS: 'catalog/CHECK-STRIPE-STATUS',
    LOADING_STRIPE: 'catalog/LOADING-STRIPE',
    GET_ENABLED_CATEGORIES_URL: `${baseURL}/rest/V1/getMainCategories`,
    GET_ENABLED_CATEGORIES: 'GET_ENABLED_CATEGORIES',
    GET_VTC_RATE_URL: `${baseURL}/rest/V1/getVtcRate`,
    GET_VTC_RATE: 'GET_VTC_RATE',
    SAVE_DELIVERY_PLACE_URL: `${baseURL}/rest/V1/saveDeliveryPlace`,
    SAVE_DELIVERY_PLACE: 'SAVE_DELIVERY_PLACE',
    GET_ALL_DELIVERY_PLACE_URL: `${baseURL}/rest/V1/getAllDeliveryPlace`,
    GET_ALL_DELIVERY_PLACE: 'GET_ALL_DELIVERY_PLACE'
};

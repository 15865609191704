import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {updateColor, getColor} from "../../../services/redux/actions/theme";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import PopupHandler from '../../../services/error-handlers/popupHandler';
import FormHandler from '../../../services/error-handlers/formHandler';
import {CompactPicker} from 'react-color';

class Theme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: '#FFFFFF',
            textColor: '#000000',
            carousel: false,
            roomService: false,
            travelBenefit: false,
            howItWork: false,
            feedback: false,
            register: false,
            services: false,
            hasEmptyError: false,
            hasFormatError: false,
            hasError: false,
            success: false,
        };
    }

    componentDidMount (){
        if (this.props.theme && this.props.theme.getColor && this.props.theme.getColor.data && this.props.theme.getColor.data.data) {
            this.setStateFromData(this.props.theme.getColor.data.data);
        }else{
            this.props.getColor();
        }
    }

    resetErrors = () => {
        this.setState({
            hasEmptyError: false,
            hasFormatError: false,
            hasError: false,
            success: false,
        });
    };

    onBackgroundColorChange = (event) => {
        const value = event.target.value.toUpperCase();
        this.setState({backgroundColor: value});
    };

    onTextColorChange = (event) => {
        const value = event.target.value.toUpperCase();
        this.setState({textColor: value});
    };

    onSubmit = (event) => {
        event.preventDefault();
        const data = {
            background_color: this.state.backgroundColor,
            text_color: this.state.textColor,
            carousel: this.state.carousel,
            roomservice: this.state.roomService,
            travelbenefit: this.state.travelBenefit,
            howitwork: this.state.howItWork,
            feedback: this.state.feedback,
            register: this.state.register,
            services: this.state.services,
        }
        this.resetErrors();
        if (!this.isValid()) {
            return;
        }
        this.props.updateColor(data);
    };

    isValid = () => {
        if (this.state.backgroundColor.length === 0 || this.state.textColor.length === 0) {
            this.setState({hasEmptyError: true});
            return false;
        }
        if (!FormHandler.isValidHexFormat(this.state.backgroundColor) || !FormHandler.isValidHexFormat(this.state.textColor)) {
            this.setState({hasFormatError: true});
            return false;
        }

        return true;
    };

    componentDidUpdate(props) {
        const prev = props.theme;
        const curr = this.props.theme;

        if (!prev) {
            return;
        }
        if (!prev) {
            return;
        }
        if (prev.getColor && curr.getColor && prev.getColor.loading && !curr.getColor.loading) {
            if (curr.getColor.error) {
                this.setState({error: true});
            } else {
                const data = (curr.getColor.data && curr.getColor.data.data) || {}
                this.setStateFromData(data);
            }
        }
        if(prev.update && curr.update && prev.update.loading && !curr.update.loading) {
            if (!curr.update.error) {
                const code = (curr.update.data && curr.update.data.code) || 500
                switch (code) {
                    case 200:
                        this.setState({success: true});
                        this.props.getColor();
                        break;
                    case 401:
                        this.setState({hasFormatError: true});
                        break;
                    case 404:
                        this.setState({hasEmptyError: true});
                        break;
                    default:
                        this.setState({hasError: true});
                        break;
                }
            } else {
                this.setState({hasError: true});
            }
        }
    }

    setStateFromData = (data)=>{
        this.setState({
            backgroundColor: data.background_color ? data.background_color.toUpperCase() : '#FFFFFF',
            textColor: data.text_color ? data.text_color.toUpperCase() : '#000000',
            carousel: data.carousel === "1" ? true : false ,
            roomService: data.roomservice === "1" ? true : false,
            travelBenefit: data.travelbenefit === "1" ? true : false,
            howItWork: data.howitwork === "1" ? true : false,
            feedback: data.feedback === "1" ? true : false,
            register: data.register === "1" ? true : false,
            services: data.services === "1" ? true : false,
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>
                            <Link to="/MonHotel">
                                <span className="back">
                                    <i className="material-icons icon-back">arrow_back</i>
                                </span>
                            </Link>
                            {t("Éditer la couleur du thème")}
                        </h1>
                    </div>
                </div>
                <div id="errors">
                    {this.state.hasEmptyError && PopupHandler.showError(t("Les champs Couleur du fond et Couleur du texte ne peuvent pas être vides."))}
                    {this.state.hasFormatError && PopupHandler.showError(t("Les champs Couleur du fond et Couleur du texte doivent être au format hexadécimal."))}
                    {this.state.hasError && PopupHandler.showError(t("Une erreur est survenue, merci de réessayer plus tard."))}
                    {this.state.success && PopupHandler.showSuccess(t("Les modifications ont été enregistrées."))}
                </div>
                <div className="main">
                    <div className="wrapper clr">
                        <div className="row">
                            <div className="col col-6 line-input">
                                <label htmlFor="backgroundColor">{t("Couleur du fond")}</label>
                                <div className="cnt-input">
                                    <input type="text"
                                           id="backgroundColor"
                                           required={true}
                                           value={this.state.backgroundColor}
                                           onChange={this.onBackgroundColorChange}
                                    />
                                </div>
                                    <CompactPicker color={this.state.backgroundColor}
                                                  onChangeComplete={(color) => {this.setState({backgroundColor: color.hex.toUpperCase()})}}
                                    />
                            </div>
                            <div className="col col-6 line-input">
                                <label htmlFor="textColor">{t("Couleur du texte")}</label>
                                <div className="cnt-input">
                                    <input type="text"
                                           id="textColor"
                                           required={true}
                                           value={this.state.textColor}
                                           onChange={this.onTextColorChange}
                                    />
                                </div>
                                <CompactPicker color={this.state.textColor}
                                              onChangeComplete={(color) => {this.setState({textColor: color.hex.toUpperCase()})}}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3">
                                <label className="check" htmlFor="carousel">
                                    <input type="checkbox"
                                           id="carousel"
                                           checked={this.state.carousel }
                                           onChange={(event) => {this.setState({carousel: event.target.checked})}}/>
                                    {t("Carousel")}
                                </label>
                            </div>
                            <div className="col col-3">
                                <label htmlFor="roomService" className="check">
                                    <input type="checkbox"
                                           id="roomService"
                                           checked={this.state.roomService }
                                           onChange={(event) => {this.setState({roomService: event.target.checked})}}/>
                                    {t("Room Service")}
                                </label>
                            </div>
                            <div className="col col-3">
                                <label htmlFor="travelBenefit" className="check">
                                    <input type="checkbox"
                                           id="travelBenefit"
                                           checked={this.state.travelBenefit }
                                           onChange={(event) => {this.setState({travelBenefit: event.target.checked})}}/>
                                    {t("Travel Benefit")}
                                </label>
                            </div>
                            <div className="col col-3">
                                <label htmlFor="howItWork" className="check">
                                    <input type="checkbox"
                                           id="howItWork"
                                           checked={this.state.howItWork }
                                           onChange={(event) => {this.setState({howItWork: event.target.checked})}}/>
                                    {t("How It Work")}
                                </label>
                            </div>
                            <div className="col col-3">
                                <label htmlFor="feedback" className="check">
                                    <input type="checkbox"
                                           id="feedback"
                                           checked={this.state.feedback}
                                           onChange={(event) => {this.setState({feedback: event.target.checked})}}/>
                                    {t("Feedback")}
                                </label>
                            </div>
                            <div className="col col-3">
                                <label htmlFor="register" className="check">
                                    <input type="checkbox"
                                           id="register"
                                           checked={this.state.register}
                                           onChange={(event) => {this.setState({register: event.target.checked})}}/>
                                    {t("Register")}
                                </label>
                            </div>
                            <div className="col col-3">
                                <label htmlFor="services" className="check">
                                    <input type="checkbox"
                                           id="services"
                                           checked={this.state.services}
                                           onChange={(event) => {this.setState({services: event.target.checked})}}/>
                                    {t("Services")}
                                </label>
                            </div>
                        </div>
                        <div className="cnt-Btn">
                            <button 
                                className="button" 
                                onClick={this.onSubmit}
                            >
                                {t("Enregistrer")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({updateColor, getColor}, dispatch);
};

const mapStateToProps = (state) => {
    return {theme: state.theme};
};

export default translate('translation')(connect(mapStateToProps, mapDispatchToProps)(Theme));

import React from 'react';
import {translate} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {isMobile} from 'react-device-detect';
import MenuMobile from "../../components/menu-mobile/menu-mobile";
import {getUnreadNoticeNb} from "../../../services/redux/actions/notice";
import { authenticationSA } from "../../../services/applicatif/authentication.sa";
import PopupConfirm from "../../components/popup/popup.confirmation";
import {showPopUp, hidePopUp} from "../../components/popup/popup";
import { getDashboardValues } from "../../../services/redux/actions/dashboard";


class Headers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeNb: 0,
            isShown: false,
            waitingResponseOrdersNb: 0,
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    
    componentDidMount() {
        if (this.props.notice && this.props.notice.unreadNoticeNb && this.props.notice.unreadNoticeNb.data) {
            this.setState({
                noticeNb: this.props.notice.unreadNoticeNb.data.unseenReport || 0,
            });
        }else{
            this.props.getUnreadNoticeNb();
        }
        if (this.props.dashboard && this.props.dashboard.dashboardValues && this.props.dashboard.dashboardValues.data) {
            const data = this.props.dashboard.dashboardValues.data || {}
            this.setState({
                waitingResponseOrdersNb: data.nb_commandes || 0,
            })
        }else{
            this.props.getDashboardValues();
        }
        document.addEventListener('click', this.handleClickOutside);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.notice;
        const props = this.props.notice;
        const prevDashboard = prevProps.dashboard;
        const propsDashboard = this.props.dashboard;
        if (!prev) {
            return;
        }
        if (prev.unreadNoticeNb && props.unreadNoticeNb && prev.unreadNoticeNb.loading && !props.unreadNoticeNb.loading) {
            if (props.unreadNoticeNb.error) {
                this.setState({
                    noticeNb: 0,
                });
            }else{
                this.setState({
                    noticeNb: (props.unreadNoticeNb.data && props.unreadNoticeNb.data.unseenReport) || 0,
                });
            }
        }
        if (prevDashboard.dashboardValues && propsDashboard.dashboardValues && prevDashboard.dashboardValues.loading && !propsDashboard.dashboardValues.loading) {
            if (propsDashboard.dashboardValues.error) {
                this.setState({
                    dashboardError: true
                })
            } else {
                const data = propsDashboard.dashboardValues.data|| {}
                this.setState({
                    waitingResponseOrdersNb: data.nb_commandes || 0,
                    dashboardError: false
                })
            }
        }
    }

    confirmDisconnect = () => {
        showPopUp(
            <PopupConfirm 
                message = {this.props.t("Voulez-vous vraiment vous déconnecter?")}
                onAccept = { this.disconnect }
            />,
            this.props.t("Confirmation")
        )
    };

    disconnect = ()=>{
        hidePopUp();
        authenticationSA.logout();
        this.props.history.push('/login');
    }

    showMenuProfil = ()=>{
        this.setState({ isShown: true });
    }

    hideMenuProfil = (event)=>{
        event.stopPropagation();
        this.setState({ isShown: false });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ isShown: false });
        }
    }

    toggleMenuProfil = (e)=> {
        e.stopPropagation();
        this.setState({
            isShown: !this.state.isShown
        });
    }

    render() {
        const { noticeNb }  = this.state;
        const { t } = this.props;
        const logo = (this.props.hotel && this.props.hotel.logoImage && this.props.hotel.logoImage.data && this.props.hotel.logoImage.data.data) || "";
        
        return (
            <div className="header">
            <div>
                {/* <button onClick={() => i18n.changeLanguage('de")}>fr</button>
                <button onClick={() => i18n.changeLanguage('en")}>en</button> */}
            </div>
            <div className="wrapper clr">
                <MenuMobile/>
                {
                    logo &&
                        <div className="logo">
                            <a href={t("contact url")} target="_blank" rel="noopener noreferrer" className="img-logo">
                                <img src={logo} alt={t("Image logo")} className="imgLogo" />
                            </a>
                        </div>
                }
                <div className="mainMenu">
                    <div className={`profil ${this.state.isShown? 'show': ''}`}
                        onMouseEnter={isMobile ? () => { return false; } : this.showMenuProfil}
                        onMouseLeave={isMobile ? () => { return false; } : this.hideMenuProfil}
                        onClick={!isMobile ? () => { return false; } : this.toggleMenuProfil}
                        ref={this.setWrapperRef}
                    >
                        <span className="imgProfil" style={{backgroundImage: `url(${logo})`}}></span>
                        {this.state.isShown && (
                            <ul className="submenu">
                                <li onClick={(event) => this.hideMenuProfil(event)}><Link to="/parametrecompte">{t("Paramètres du compte")}</Link></li>
                                <li onClick={(event) => this.hideMenuProfil(event)}><Link to="/gestion_administration">{t("Gestion administration")}</Link></li>
                                <li onClick={(event) => this.hideMenuProfil(event)}>
                                    <Link to="/avis_signales">
                                        {t("Avis signalés")}
                                        {
                                            noticeNb > 0  &&
                                            <span className="nbr">{noticeNb}</span>
                                        }
                                        
                                    </Link>
                                </li>
                                <li onClick={(event) => this.hideMenuProfil(event)}><a href="mailto:admin@myconciergehotel.com">{t("Contacter le support")}</a></li>
                                <li onClick={(event) => this.hideMenuProfil(event)}><a href={t("FAQ url")} target="_blank" rel="noopener noreferrer">{t("FAQ")}</a></li>
                                <li onClick={(event) => this.hideMenuProfil(event)}><Link to="/liste_langues">{t("Liste des langues")}</Link></li>
                                <li><span className="link" onClick={this.confirmDisconnect}>{t("Déconnexion")}</span></li>
                            </ul>
                        )}
                    </div>
                    <ul className="menu">
                        <li><Link to="/">{t("Tableau de bord")}</Link></li>
                        <li><Link to="/MonHotel">{t("Mon hôtel")}</Link></li>
                        <li><Link to="/catalogue">{t("Catalogue")}</Link></li>
                        <li><Link to="/clients">{t("Clients")}</Link></li>
                        <li><Link to="/prestataires">{t("Les prestataires")}</Link></li>
                        <li><Link to="/gestion-commandes">{t("Gestion des commandes")} <span className="nbr">{this.state.waitingResponseOrdersNb}</span></Link></li>
                    </ul>
					<div className="clr"></div>
                </div>
            </div>
          </div>
        )
    }

}
const mapStateToProps = (state) => ({
    notice: state.notice,
    hotel: state.hotel,
    dashboard: state.dashboard
})


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getUnreadNoticeNb,
        getDashboardValues
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Headers)));

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {
    fetchExternalCategories,
} from "../../../services/redux/actions/catalog";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from "../../../services/error-handlers/popupHandler";
import {ReactSortable} from "react-sortablejs";
import Collapse from "./Collapse";
import { catalogSA } from "../../../services/applicatif/catalog.sa";
import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";

class ExternalService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            visibleCategories: [],
            hiddenCategories: [],
            error: false,
            success: null,
            loading: true,
            stripeError: true,
        };
    }

    componentDidMount() {
        this.props.fetchExternalCategories(this.state.query);
        if (this.props.hotel && this.props.hotel.stripeStatus && this.props.hotel.stripeStatus.status) {
            this.setState({
                stripeError: this.props.hotel.stripeStatus.data && this.props.hotel.stripeStatus.data.status === "verified" ? false : true
            })
        }
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.catalog;
        const props = this.props.catalog;

        if (!prev) {
            return;
        }

        if (prev.externalCategories && props.externalCategories &&  prev.externalCategories.loading && !props.externalCategories.loading ) {
            if (props.externalCategories.error) {
                this.setState({
                    error: true
                })
            } else {
                let visibleCategories = this.categorySubCategoryToList(props.externalCategories.data && props.externalCategories.data.category_in_catalog);
                visibleCategories = this.sortCategories(visibleCategories);
                if (this.state.isSearch) {
                    visibleCategories = this.showHideCategorySubCategoryByName(visibleCategories);
                }
                let hiddenCategories = this.categorySubCategoryToList(props.externalCategories.data && props.externalCategories.data.category_not_in_catalog);
                hiddenCategories = this.sortCategories(hiddenCategories);
                this.setState({
                    visibleCategories, 
                    hiddenCategories, 
                    error: false,
                    isSearch: false
                });
            }
        }

        const prevHotel = prevProps.hotel;
        const propsHotel = this.props.hotel;

        if (prevHotel.stripeStatus && propsHotel.stripeStatus &&  prevHotel.stripeStatus.loading && !propsHotel.stripeStatus.loading ) {
            if (propsHotel.stripeStatus.error) {
                this.setState({
                    stripeError: true
                })
            } else {
                this.setState({
                    stripeError: propsHotel.stripeStatus.data && propsHotel.stripeStatus.data.status === "verified" ? false : true
                })
            }
        }

    }

    sortCategories = (categories) => {
        return [].concat(categories).sort((a, b) => {
            return (parseInt(a.position) > parseInt(b.position)) ? 1 : -1;
        });
    };

    objectToList =(object)=>{
        if (!object || Object.keys(object).length === 0) {
            return []
        }
        return Object.keys(object).map(key=>{
            return {...object[key]}
        })
    }

    productToList =(object)=>{
        if (!object || Object.keys(object).length === 0) {
            return []
        }
        return Object.keys(object).map(key=>{
            return {products: {...object[key]}}
        })
    }

    categorySubCategoryToList = (categories) => {
        if (!categories) {
            return []
        }
        if (Array.isArray(categories)) {
            return categories;
        }
        if (Object.keys(categories).length === 0) {
            return []
        }
        let categoryList = this.objectToList(categories);
        for (let index = 0; index < categoryList.length; index++) {
            const category = categoryList[index];
            if (category.sousCategories) {
                if (category.sousCategories.products) {
                    category.sousCategories = this.productToList(category.sousCategories.products)
                }else{
                    category.sousCategories = this.objectToList(category.sousCategories);
                }
                for (let index = 0; index < category.sousCategories.length; index++) {
                    const subCategory = category.sousCategories[index];
                    if (subCategory.sousCategories) {
                        if (subCategory.sousCategories.products) {
                            subCategory.sousCategories = this.productToList(subCategory.sousCategories.products)
                        }else{
                            subCategory.sousCategories = this.objectToList(subCategory.sousCategories)
                        }
                    }
                }
            }
        }
        return categoryList;
    };

    showHideCategorySubCategoryByName = (data) => {
        if (data.length < 1) {
            return
        }
        const query = !this.state.query ? "" : this.state.query.toLowerCase();
        const categories = [...data];
        for (let index = 0; index < categories.length; index++) {
            const category = categories[index];
            category.show = false;
            if(category.sousCategories && category.sousCategories.products && query && category.sousCategories.products.name && category.sousCategories.products.name.toLowerCase().includes(query)){
                category.show = true;
            }
            if (category.sousCategories && category.sousCategories.length > 0) {
                for (let index = 0; index < category.sousCategories.length; index++) {
                    const subCategory = category.sousCategories[index];
                    const categoryproduct = subCategory && subCategory.products;
                    subCategory.show = false;
                    if (query && ((categoryproduct && categoryproduct.name && categoryproduct.name.toLowerCase().includes(query)) || (subCategory && subCategory.name && subCategory.name.toLowerCase().includes(query)))) {
                        category.show = true;
                    }
                    if (subCategory.sousCategories && subCategory.sousCategories.length > 0) {
                        for (let index = 0; index < subCategory.sousCategories.length; index++) {
                            const subSubCategory = subCategory.sousCategories[index];
                            const product = subSubCategory && subSubCategory.products;
                            if (query && ((product && product.name && product.name.toLowerCase().includes(query)) || (subSubCategory && subSubCategory.name && subSubCategory.name.toLowerCase().includes(query)))) {
                                category.show = true;
                                subCategory.show = true;
                            }
                        }
                    }
                }
            }
        }
        return categories
    };

    handleQueryChange = (event) => {
        this.setState({query: event.target.value});
    };

    handleSearch = () => {
        this.setState({
            isSearch: true
        })
        this.props.fetchExternalCategories(this.state.query);
    };

    handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSearch()
        }
    }

    handleSort = (event) => {
        const newIndex = event.newIndex;
        const previousCategory = newIndex > 0 ? this.state.visibleCategories[newIndex -1] : null;
        const newPosition = previousCategory ? parseInt(previousCategory.position) + 1 : 1;
        const data = {
            category_id: event.item.dataset.id,
            new_position: parseInt(newPosition)
        }
        this.props.showLoading();
        catalogSA.reorderCategory(data)
        .then(response=>{
            this.props.hideLoading();
            this.handleSearch();
            this.setState({
                success: true,
            })
        })
        .catch(()=>{
            this.props.hideLoading();
            this.setState(oldState => ({
                success: false,
                visibleCategories: this.sortCategories(oldState.visibleCategories)
            }))
        })
    };

    handleCategoryEnable = (event) => {
        const {t} =this.props;
        const id = event.target.dataset.id;
        event.preventDefault();
        if (this.state.stripeError) {
            //const message = this.state.stripeError ? t("Les produits ne peuvent pas etre activés tant que le compte de paiement de l'hôtel n'est pas actif.") : t("Les catégories de prestations sans offres ne peuvent etre activées.")
            const message = t("Les produits ne peuvent pas etre activés tant que le compte de paiement de l'hôtel n'est pas actif.");
            showPopupExtra(<PopupError title={t("Erreur")} message={message}/>)
            return
        }
        if (!event.target.checked) {
            return;
        }
        this.toggleExternalCategory(id, true);
    };

    handleCategoryDisable = (event) => {
        event.preventDefault();
        const id = event.target.dataset.id;

        if (event.target.checked) {
            return;
        }

        this.toggleExternalCategory(id, false);
    };

    hasProductCategory = id =>{
        if (!this.state.hiddenCategories || this.state.hiddenCategories.length < 1) {
            return false
        }
        const category = this.state.hiddenCategories.find(categorie =>categorie.id === id && (!categorie.sousCategories || categorie.sousCategories.length < 1));
        return !category;
    }

    toogleCategoryById = (id, enable) => {
        if (!id) {
            return
        }
        let categories = this.sortCategories([...this.state.hiddenCategories, ...this.state.visibleCategories]);
        for (let index = 0; index < categories.length; index++) {
            const category = categories[index];
            if (category.id === id) {
                category.enabled = enable;
                break;
            }
        }
        const visibleCategories = categories.filter(category=>category.enabled === "1");
        const hiddenCategories = categories.filter(category=>category.enabled === "0");     
        this.setState({
            visibleCategories,
            hiddenCategories
        })
    };
    

    toggleExternalCategory = (id, checked) => {
        this.props.showLoading();
        catalogSA.toggleExternalCategory(id, checked)
        .then(response=>{
            this.toogleCategoryById(id, checked ? "1" : "0")
            this.props.hideLoading();
            this.setState({
                success: true,
            })
        })
        .catch(()=>{
            this.props.hideLoading();
            this.setState({
                success: false,
            })
        })
    };

    goToGestionAdmin = ()=>{
        this.props.history.push("gestion_administration?activeTab=accountStatus")
     }

    renderVisibleCategories = () => {
        return (
            <ReactSortable
                handle=".handle"
                list={this.state.visibleCategories || []}
                setList={newState => this.setState({visibleCategories: newState})}
                onEnd={this.handleSort}
                //filter= ".not-draggable,.openaccordeon,.blc-presta"
            >
                {this.state.visibleCategories && this.state.visibleCategories.map(category =>
                    <div className="content-menu" data-id={category.id} key={category.id}>
                        <Collapse
                            title={
                                <div className="wrapper-menuParent">
                                    <span className="ico"><i className="material-icons icon-menu clickable handle">menu</i></span>
                                    <div className="menu-parent not-draggable">
                                        <label className="not-draggable checkbox-label">
                                            <input type="checkbox"
                                                data-id = {category.id}
                                                defaultChecked={true}
                                                onChange={this.handleCategoryDisable}
                                            />
                                            <span className="check"></span>
                                        </label>
                                        <span className="not-draggable titre-menu">{category.name} ({category.count_product})</span>
                                        <div className="blc-presta">
                                            <span className="liste-presta">
                                                <span className="item-presta">presta 1</span>
                                                <span className="item-presta">presta 2</span>
                                                <span className="item-presta">presta 3</span>
                                            </span>
                                            <span className="nomber-presta">+11
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                            noChildren={!category.sousCategories || category.sousCategories.length < 1}
                            show={category.show ? true : false}
                        >
                            {category.sousCategories && [].concat(category.sousCategories).map(subCategory =>{
                                let subCategoryOrProduct = {} 
                                if (subCategory.products) {
                                    subCategoryOrProduct = subCategory.products
                                }else{
                                    subCategoryOrProduct = subCategory;
                                }
                                return this.renderSubCategories(subCategoryOrProduct)
                            })}
                        </Collapse>
                    </div>
                )}
            </ReactSortable>
        );
    };

    renderSubCategories = (subCategory) => {
        const hasChildren = subCategory.sousCategories && subCategory.sousCategories.length > 0
        const header = (
            <div className={`not-draggable sub-category ${!hasChildren ? 'last-level' : ''}`} key={subCategory.id}> 
                <span className="titre-menu">{`${subCategory.name} ${hasChildren ? `(${subCategory.count_product})`: ''}`} </span>
                {
                    hasChildren && 
                    <div className="blc-presta">
                        <span className="liste-presta">
                            <span className="item-presta">presta 1</span>
                            <span className="item-presta">presta 2</span>
                            <span className="item-presta">presta 3</span>
                        </span>
                        <span className="nomber-presta">+11
                        </span>
                    </div>
                }
                
            </div>
        );
        return (
            <div className="not-draggable menu-sub"
                 key={subCategory.id}
            >
                <Collapse 
                    title={header}
                    noChildren={!hasChildren}
                    show={subCategory.show ? true : false}
                >
                    {hasChildren && subCategory.sousCategories && [].concat(subCategory.sousCategories).map(subSubCategory => {
                        let subSubCategoryOrProduct = {};
                        if (subSubCategory.products) {
                            subSubCategoryOrProduct = subSubCategory.products;
                        }else{
                            subSubCategoryOrProduct = subSubCategory;
                        }
                        return this.renderSubCategories(subSubCategoryOrProduct)
                    })}
                </Collapse>
            </div>
        );
    };

    renderHiddenCategories = () => {
        return this.state.hiddenCategories.map(category =>
            <div key={category.id} className="menu-parent">
                <label className="checkbox-label">
                    <input type="checkbox"
                        data-id = {category.id}
                        defaultChecked={false}
                        onChange={this.handleCategoryEnable}
                    />
                    <span className="check"></span>
                </label>
                <span>{category.name}</span>
            </div>
        );
    };

    render() {
        const {t} = this.props
        return (
            <div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {this.state.stripeError && PopupHandler.showError(t("Il y a une erreur sur vos informations de paiement Stripe."), 'page', this.goToGestionAdmin) }
                    {this.state.success === true && PopupHandler.showSuccess(t("La modification a été enregistrée."))}
                    {this.state.success === false && PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
                </div>
                <div className="block-search">
                    <input type="text"
                           placeholder={t("Rechercher…")}
                           value={this.state.query}
                           onChange={this.handleQueryChange}
                           onKeyDown={this.handleSearchKeyDown}
                    />
                    <input type="button"
                           value={t("Rechercher")}
                           className="btnSearch"
                           onClick={this.handleSearch}
                    />
                </div>
                <div className="cnt-presentationext">
                    <div className="menu-catalogue">
                        <h2 className="title-catalogue-cat">{t("Catégories externes présentes dans mon catalogue")}</h2>
                        <div className="content-menu">
                            {this.renderVisibleCategories()}
                        </div>
                    </div>
                    <div className="menu-catalogue">
                        <h2 className="title-catalogue-cat">{t("Catégories externes invisibles de mon catalogue")}</h2>
                        <div className="categorie-invisible">
                            {this.renderHiddenCategories()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        catalog: state.catalog,
        hotel: state.hotel
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchExternalCategories,
        showLoading, 
        hideLoading,
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExternalService)));

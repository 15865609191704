import React from "react";
import {
    Router,
    Switch,
    Route,
} from "react-router-dom";
import history from '../../history';
import { PrivateRoute } from '../../../src/PrivateRoute'
import Agenda from "./accueil/index"
import Inscription from "./inscription/index";
import Login from "./login/index";
import Dashboard from "./dashboard/index";
import MonHotel from "./mon-hotel/index";
import ParametreCompte from './parametre-compte/index';
import GestionAdministration from './parametre-compte/gestionadmin';
import AvisSignales from './parametre-compte/avissignales';
import Langues from './parametre-compte/langues';
import Catalogue from './catalogue/index';
import Clients from './clients/index';
import DetailsClient from './clients/detail-client';
import Prestataires from './prestataires/index';
import Theme from './theme';
import Commandes from './commandes';
import SellerDetail from "./prestataires/SellerDetail";
import DetailsCommande from "./commandes/DetailsCommande";
import AvisClient from "./commandes/AvisClient";
import Facture from "./commandes/Facture";
import ImportIdentityPaper from "./parametre-compte/import-identity-paper";
import ImportKbisPhotocopy from "./parametre-compte/import-kbis-photocopy";

export default function ParamsExample() {
    return (
        <React.Fragment>
            <div id='popUp-container' />
            <div id='popUpExtra-container' />
            <Router history={history}>
                <Switch>
                    <Route exact path="/inscription" component={Inscription}/>
                    <Route exact path="/login" component={Login}/>
                    <PrivateRoute exact path="/" withHeaderFooter component={Dashboard} />
                    <PrivateRoute exact path="/accueil" component={Agenda} />
                    <PrivateRoute exact path="/monhotel" withHeaderFooter component={MonHotel}/>
                    <PrivateRoute exact path="/parametrecompte" withHeaderFooter component={ParametreCompte}/>
                    <PrivateRoute exact path="/gestion_administration" withHeaderFooter component={GestionAdministration}/>
                    <PrivateRoute exact path="/avis_signales" withHeaderFooter component={AvisSignales}/>
                    <PrivateRoute exact path="/liste_langues" withHeaderFooter component={Langues}/>
                    <PrivateRoute exact path="/catalogue" withHeaderFooter component={Catalogue}/>
                    <PrivateRoute exact path="/clients" withHeaderFooter component={Clients}/>
                    <PrivateRoute path="/clients-detail/:id" withHeaderFooter component={DetailsClient}/>
                    <PrivateRoute exact path="/prestataires" withHeaderFooter component={Prestataires}/>
                    <PrivateRoute exact path="/editer-theme" withHeaderFooter component={Theme}/>
                    <PrivateRoute exact path="/gestion-commandes" withHeaderFooter component={Commandes}/>
                    <PrivateRoute path="/prestataire/:id" withHeaderFooter component={SellerDetail}/>
                    <PrivateRoute path="/commande/:id" withHeaderFooter component={DetailsCommande}/>
                    <PrivateRoute path="/avis-client/:id" withHeaderFooter component={AvisClient}/>
                    <PrivateRoute path="/facture/:id" withHeaderFooter component={Facture}/>
                    <PrivateRoute path="/import-identity" withHeaderFooter component={ImportIdentityPaper}/>
                    <PrivateRoute path="/import-kbis" withHeaderFooter component={ImportKbisPhotocopy}/>
                </Switch>
            </Router>
        </React.Fragment>
    );
}

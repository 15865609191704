import React from 'react';

export default class Collapse extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hidden: !props.show,
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidUpdate(prev){
        if (this.props.show !== prev.show) {
            this.setState({hidden: !this.props.show});
        }
    }

    toggle = () => {
        this.setState({hidden: !this.state.hidden});
    };

    render() {
        return (
            <div>
                <div className={this.state.hidden ? 'titleMenu' : 'titleMenu open'}>
                    {this.props.title}
                    <span className={this.props.noChildren ? "" : "openaccordeon"} onClick={this.toggle}></span>
                </div>
                <div hidden={this.state.hidden}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

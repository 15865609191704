import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {fetchAll, keepNotice, deleteNotice, initializeUnreadNoticeNb, getUnreadNoticeNb} from "../../../services/redux/actions/notice";
import {showPopUp, hidePopUp} from "../../components/popup/popup";
import PopupConfirm from "../../components/popup/popup.confirmation";
import PopupHandler from '../../../services/error-handlers/popupHandler';

class ReportedNotice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notices: [],
            error: false,
        };
    }

    componentDidMount() {
        this.fetchAllNotices();
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.notice;
        const props = this.props.notice;
        if (!prev) {
            return;
        }
        if (prev.fetchAll && props.fetchAll && prev.fetchAll.loading && !props.fetchAll.loading) {
            hidePopUp();
            if (props.fetchAll.error) {
                this.setState({
                    error: true,
                });
            }else{
                this.setState({
                    error: false,
                    notices: (props.fetchAll.data && props.fetchAll.data.avis && props.fetchAll.data.avis.data) || {},
                });
                this.props.getUnreadNoticeNb();
            }
        }

        if (prev.keepNotice && props.keepNotice && prev.keepNotice.loading && !props.keepNotice.loading) {
            if (props.keepNotice.error) {
                this.setState({
                    keepNoticeError: true,
                });
                hidePopUp();
            }else{
                this.setState({
                    keepNoticeError: false,
                    keepNoticeId: null,
                });
                this.fetchAllNotices();
            }
        }

        if (prev.deleteNotice && props.deleteNotice && prev.deleteNotice.loading && !props.deleteNotice.loading) {
            if (props.deleteNotice.error) {
                this.setState({
                    deleteNoticeError: true,
                });
                hidePopUp();
            }else{
                this.setState({
                    deleteNoticeError: false,
                    deleteNoticeId: null,
                });
                this.fetchAllNotices();
            }
        }
    }

    fetchAllNotices=()=> {
        this.props.fetchAll();
    }    

    showKeepNoticeModal = (event) => {
        const keepNoticeId = event.target.dataset.id;
        const keepReportingId = event.target.dataset.reviewreportingid;
        const keepNoticeSaleOrder = event.target.dataset.saleorder;
        this.setState({
            keepNoticeId,
            keepReportingId,
            keepNoticeSaleOrder
        });
        showPopUp(
            <PopupConfirm 
                message = {this.props.t("Voulez-vous vraiment conserver cet avis signalé ?")}
                onAccept = {this.keepNotice}
            />,
            this.props.t("Confirmation")
        )
    };

    showDeleteNoticeModal = (event) => {
        const deleteNoticeId = event.target.dataset.id;
        const deleteReportingId = event.target.dataset.reviewreportingid;
        const deleteNoticeSaleOrder = event.target.dataset.saleorder;
        this.setState({
            deleteNoticeId,
            deleteReportingId,
            deleteNoticeSaleOrder
        });
        showPopUp(
            <PopupConfirm 
                message = {this.props.t("Voulez-vous vraiment supprimer cet avis signalé ?")}
                onAccept = {this.deleteNotice}
            />,
            this.props.t("Confirmation")
        )
    };

    keepNotice = () => {
        if (this.state.keepNoticeId) {
            const data={
                id: this.state.keepNoticeId,
                idReport: this.state.keepReportingId,
                saleOrder: this.state.keepNoticeSaleOrder
            }
            this.props.keepNotice(data);
        }
    };

    deleteNotice = () => {
        if (this.state.deleteNoticeId) {
            const data={
                id: this.state.deleteNoticeId,
                idReport: this.state.deleteReportingId,
                saleOrder: this.state.deleteNoticeSaleOrder
            }
            this.props.deleteNotice(data);
        }
    };

    render() {
        const notices = this.state.notices || [];
        const {t} = this.props;

        return (
        <div className="page">
            <div className="bandeaux">
                <div className="wrapper clr">
                    <h1>{t("Avis signalés")}</h1>
                </div>
            </div>
            <div>
                {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                {this.state.keepNoticeError === false && PopupHandler.showSuccess(t("L'avis a bien été conservé."))}
                {this.state.keepNoticeError && PopupHandler.showError(t("Une erreur est survenue lors de la conservation de l'avis."))}
                {this.state.deleteNoticeError === false && PopupHandler.showSuccess(t("L'avis a bien été supprimé."))}
                {this.state.deleteNoticeError && PopupHandler.showError(t("Une erreur est survenue lors de la suppression de l'avis."))}
            </div>
            <div className="main">
                <div className="wrapper clr main-avis">
                    { notices.map(notice =>{
                        const seller = notice.seller || {}
                        return (
                            <React.Fragment key={notice.id}>
                                <div className="line-avis" >
                                    <div className="top-avis">
                                        <div className="row">
                                            <div className="col col-5c">
                                                <p className="title-avis">{seller.name}</p>
                                                <p>{seller.address ? `${notice.seller.address.street || ''} ${notice.seller.address.postalCode || ''} ${notice.seller.address.city || ''}` : ''}</p>
                                            </div>
                                            <div className="col col-5c">
                                                <p>{seller.email}</p>
                                                <p>{seller.phone}</p>
                                            </div>
                                            <div className="col col-5c">
                                                <p className="ss-title">Prestation</p>
                                                <p>{seller.product}</p>
                                            </div>
                                            <div className="col">
                                                <p className="ss-title">Signalement</p>
                                                <p>{notice.date}</p>
                                            </div>
                                            <div  className="col">
                                                <span className="btn btn-conserver" onClick={this.showKeepNoticeModal} data-id={notice.id} data-reviewreportingid={notice.reviewReportingId} data-saleorder={notice.saleOrder}>
                                                    {t("Conserver")}
                                                </span>
                                                <span className="btn btn-delete" onClick={this.showDeleteNoticeModal} data-id={notice.id} data-reviewreportingid={notice.reviewReportingId} data-saleorder={notice.saleOrder}>
                                                    {t("Supprimer")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-avis">
                                        <p className="s-title">{t("Message signalé")}</p>
                                        <p><span className="ss-title">{t("Utilisateur")} : </span>{notice.nickname}</p>
                                        <p><span className="ss-title">{t("Date")} : </span>{notice.dateMessage}</p>
                                        <p>{notice.detail}</p>
                                    </div>
                                </div>                    
                                <div className="clr"></div>
                            </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({notice: state.notice})


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAll, 
        keepNotice, 
        deleteNotice, 
        initializeUnreadNoticeNb,
        getUnreadNoticeNb
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportedNotice)));

import { commandesBDL } from "../business-delegate/commandes.bdl";
import { downloadByteArray } from '../technical/download.techical'

class CommandesSA {
    fetchOrders = query => {
        return commandesBDL.fetchOrders(query);
    }

    getOrderDetails = id => {
        return commandesBDL.getOrderDetails(id)
    }

    getOrderInvoice = orderNumber => {
        return commandesBDL.getOrderInvoice(orderNumber)
    }

    cancelOrder = orderNumber => {
        return commandesBDL.cancelOrder(orderNumber)
    }

    
    relaunchSeller = (data) => {
        return commandesBDL.relaunchSeller(data)
    };
    
    exportOrders = (orders) => {
        return commandesBDL.exportOrders(orders)
    };
    
    generateInvoice = (id, filename) => {
        return new Promise(async(success, error) => {
            const invoice = await commandesBDL.generateInvoice(id).catch(exception => error(exception));
            invoice && downloadByteArray(invoice.data, filename, (invoice.headers && invoice.headers["content-type"]) ||'application/pdf');
            invoice && success(invoice.data);
        })
    };
    
    refundOrder = (data) => {
        return commandesBDL.refundOrder(data)
    };
    
    getCustomerReview = (id) => {
        return commandesBDL.getCustomerReview(id)
    }

}

export const commandesSA = new CommandesSA();

import React from 'react';
import { Route, Redirect } from 'react-router';
import { authenticationSA } from './services/applicatif/authentication.sa';

import Main from "./presentations/containers/main/Maincontainer"

export const PrivateRoute = ({ component: Component, withHeaderFooter, ...rest }) => (
    <Route {...rest} render={(props) => {
        if (!authenticationSA.isAuthenticated() || !authenticationSA.isAuthenticated().success) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: props.location, authenticationError : authenticationSA.isAuthenticated().error}
            }} />
        }
        else {
            return <Main Component={Component} componentProps={props} withHeaderFooter= {withHeaderFooter}/>
        }
    }} />
)
import React, {Component} from 'react';
import {translate} from 'react-i18next';
import  {hidePopUp} from './popup';

class PopupConfirm extends Component {

    validPopup = () => {
        this.props.onAccept()
    }

    render = ()  => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <div  className="main-modal">
                    <p>{this.props.message}</p>
                    <div className="cnt-Btn align-right">
                        <span className="btn-cancel" onClick={hidePopUp}>{t("Annuler")}</span>
                        <button className="button" onClick={this.validPopup}>{t("Valider")}</button>
                        <div className="clr"></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    
}

export default translate('translation')(PopupConfirm)
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {fetchPartnershipRequest, sendResponse, fetchAll, fetchAllPartners} from "../../../services/redux/actions/seller";
import { getDashboardValues } from "../../../services/redux/actions/dashboard";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from "../../../services/error-handlers/popupHandler";
import { Accordion, AccordionItem } from 'react-light-accordion';
import {showPopUp, hidePopUp} from "../../components/popup/popup";
import PartnershipResponse from "../../components/partnership/PartnershipResponse";
import { PAGE_SIZE_LIST } from "../../../data/constants/page.size";
import { getCommissionLabel } from "../../../data/constants/commission.label";
import { SELLER_TYPES } from '../../../data/constants/seller.type';

class PartnershipRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hotelRequest: [],
            sellerRequest: [],
            error: false,            
            success: null,
            from: props.location.from,
            query: {
                name: '',
                type: '',
                category: '',
                page: 1,
                perPage: PAGE_SIZE_LIST[0]
            },
        };
    }

    componentDidMount() {
        if (this.state.from === "details" && this.props.seller && this.props.seller.partnershipRequest && this.props.seller.partnershipRequest.data) {
            this.setState({
                hotelRequest: this.props.seller.partnershipRequest.data.hotelRequest || [],
                sellerRequest: this.props.seller.partnershipRequest.data.sellerRequest || [],
            });
        }else{
            this.fetchPartnershipRequest();
        }
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.seller;
        const props = this.props.seller;
        const prevHotel = prevProps.hotel;
        const propsHotel = this.props.hotel;

        if (!prev) {
            return;
        }

        if (prev.partnershipRequest && props.partnershipRequest && prev.partnershipRequest.loading && !props.partnershipRequest.loading) {
            if (props.partnershipRequest.error) {
                this.setState({error: true});
            } else {
                this.setState({
                    hotelRequest: props.partnershipRequest.data.hotelRequest || [],
                    sellerRequest: props.partnershipRequest.data.sellerRequest || [],
                });
            }
        }

        if (prev.sendResponse && props.sendResponse && prev.sendResponse.loading && !props.sendResponse.loading) {
            hidePopUp();
            if (props.sendResponse.error) {
                this.setState({
                    success: false,
                });
            } else {
                this.fetchPartnershipRequest();
                this.props.fetchAll(this.state.query);
                this.props.fetchAllPartners(this.state.query);
                this.props.getDashboardValues();
                this.setState({
                    success: true,
                });
            }
        }

        if (prevHotel.getDefaultCommision && propsHotel.getDefaultCommision && prevHotel.getDefaultCommision.loading && !propsHotel.getDefaultCommision.loading) {
            if (propsHotel.getDefaultCommision.error) {
                this.setState({
                    successCommission: false,
                });
            } else {
                this.setState({
                    successCommission: true,
                    commissions: (propsHotel.getDefaultCommision.data && propsHotel.getDefaultCommision.data.commissions) || {}
                });
            }
        }
    }

    fetchPartnershipRequest() {
        this.props.fetchPartnershipRequest();
    }

    getRequestById = (id) => {
        return this.state.sellerRequest.filter(e => parseInt(e.id) === parseInt(id), id).pop();
    };

    showModal = (event) => {
        const request = this.getRequestById(event.target.dataset.id);
        showPopUp(
            <PartnershipResponse 
                sendResponse={this.sendResponse}
                commissions = {this.state.commissions}
                request={request}
            />,
            request.sellerName,
            request.sellerAddress || '',
            "popup-450"
        )
    };

    sendResponse = (data) => {
        this.props.sendResponse(data);
    };

    render() {
        const {t} = this.props;
        return (
            <div>
                <div>
                    {this.state.error && PopupHandler.showError(t("Une erreur est survenue, veuillez rafraîchir la page."))}
                    {this.state.success === true && PopupHandler.showSuccess(t("Votre réponse a bien été envoyée."))}
                    {(this.state.success === false || this.state.successCommission === false) && PopupHandler.showError(t("Une erreur est survenue, merci de réessayer ultérieurement."))}
                </div>

                <div className="main-tabs content-accordeon">
                    <Accordion atomic={false}>
                        <AccordionItem title={t("En attente de votre réponse")}>
                            <div className="cnt-tab cnt-tab-reps">
                                <div className="main-reps">
                                    <div className="main-infos-hotel">
                                        <div className="table-responsive scrollbar">
                                            <table>
                                                <tbody>
                                                    {this.state.sellerRequest.map(seller =>
                                                        <React.Fragment key={seller.id}>
                                                            <tr>
                                                                <td>
                                                                    <div className="td-content">
                                                                        <p className="name-product">{seller.sellerName}</p>{seller.sellerAddress}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <p>{seller.sellerEmail}</p>
                                                                    <p>{seller.sellerPhone}</p>
                                                                </td>
                                                                <td><span className="txt-distance">{t("Type de prestataire")}</span> {(SELLER_TYPES[seller.type] && SELLER_TYPES[seller.type].label) || ""}</td>
                                                                <td><span className="txt-distance">{t("Distance")}</span>{seller.distance === "Not specified" ? t("Not specified"):  `${parseFloat(seller.distance || 0).toFixed(2)}km`}</td>
                                                                <td><span className="txt-distance">{t("Marge hôtel")}</span>{getCommissionLabel((SELLER_TYPES[seller.type] && SELLER_TYPES[seller.type].value) || 0, seller.commission, this.state.commissions, t )}</td>
                                                                <td className="td-btn">
                                                                    <div className="cnt-btn-strock">
                                                                        <button data-id={seller.id}
                                                                        onClick={this.showModal} className="button"
                                                                        >{t("Répondre")}</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="wrapper-cnt-message" colSpan="6">
                                                                    <div className="cnt-message">
                                                                        <p className="title-msg">{t("Message du prestataire")}</p>
                                                                        <p>{seller.message}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem title={t("En attente de la réponse du prestataire")}>
                            <div className="cnt-tab cnt-tab-reps">
                                <div className="main-reps">
                                    <div className="main-infos-hotel">
                                        <div className="table-responsive scrollbar">
                                            <table>
                                                <tbody>
                                                    {this.state.hotelRequest.map(seller =>
                                                    <React.Fragment key={seller.id}>
                                                        <tr>
                                                            <td>
                                                                <div className="td-content">
                                                                    <p className="name-product">{seller.sellerName}</p>{seller.sellerAddress}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p>{seller.sellerEmail}</p>
                                                                <p>{seller.sellerPhone}</p>
                                                            </td>
                                                            <td><span className="txt-distance">{t("Type de prestataire")}</span> {(SELLER_TYPES[seller.type] && SELLER_TYPES[seller.type].label) || ""}</td>
                                                            <td><span className="txt-distance">{t("Distance")}</span> {seller.distance === "Not specified" ? t("Not specified"):  `${parseFloat(seller.distance || 0).toFixed(2)}km`}</td>
                                                            <td><span className="txt-distance">{t("Marge hôtel")}</span>{t(getCommissionLabel((SELLER_TYPES[seller.type] && SELLER_TYPES[seller.type].value) || 0, seller.commission, this.state.commissions, t ))}</td>
                                                            <td className="td-btn">
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="wrapper-cnt-message" colSpan="6">
                                                                <div className="cnt-message">
                                                                    <p className="title-msg">{t("Votre message")}</p>
                                                                    <p>{seller.message}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        seller: state.seller,
        hotel: state.hotel
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchPartnershipRequest, 
        sendResponse,
        showLoading, 
        hideLoading,
        fetchAll, 
        fetchAllPartners,
        getDashboardValues
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnershipRequest)));

import { languageBDL } from "../business-delegate/language.bdl";

class LanguageSA {
    
    fetchLanguages = () => {    
        return languageBDL.fetchLanguages();
    };
    
    enableDisableLanguage = (name) => {    
        return languageBDL.enableDisableLanguage(name);
    };
    
    orderLanguage = (locales) => {    
        return languageBDL.orderLanguage(locales);
    };
}

export const languageSA = new LanguageSA();

import React, { Component } from 'react'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {hidePopupExtra} from '../popup/popup';
import {translate} from 'react-i18next';
import moment from 'moment';
import PopupHandler from "../../../services/error-handlers/popupHandler";

const MIN_WIDTH = 262;
const MIN_HEIGHT = 262;
const MAX_WIDTH = 562;
const MAX_HEIGHT = 562;
const MAX_SIZE = 500;

const MIN_WIDTH_LANDING_PAGE = 1440;
const MIN_HEIGHT_LANDING_PAGE = 357;

class ImageSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            src: null,
            crop: {
                width: props.isLandingPage ? MIN_WIDTH_LANDING_PAGE : MIN_WIDTH,
                height: props.isLandingPage ? MIN_HEIGHT_LANDING_PAGE : MIN_HEIGHT,
                minHeight: props.isLandingPage ? MIN_HEIGHT_LANDING_PAGE : MIN_HEIGHT,
                minWidth: props.isLandingPage ? MIN_WIDTH_LANDING_PAGE : MIN_WIDTH,
            },
            name: `${moment(new Date()).format('YYYY_MM_DD_HH_MM_SS')}`,
            minWidth: props.isLandingPage ? MIN_WIDTH_LANDING_PAGE : MIN_WIDTH,
            minHeight: props.isLandingPage ? MIN_HEIGHT_LANDING_PAGE : MIN_HEIGHT,
        }
    }

    componentDidMount(){
        this.showButtonScroll();
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {  
            const reader = new FileReader();
            const file = e.target.files[0];
            const imgSizeError= file.size / 1024 > MAX_SIZE;
            const filename = file.name.split(".");
            const extension = filename.length > 1 ? filename[filename.length - 1].toLowerCase() : "";
            if (extension !== "jpeg" && extension !== "jpg" && extension !== "png") {
                this.setState({
                    formatError: true
                });
                return
            }
            if (imgSizeError) {
                this.setState({
                    imgSizeError
                });
                return
            }
            this.setState({
                formatError: false,
                imgSizeError: false,
                imgDimensionError: false,
            });
            const name =  `${filename[filename.length-2].replace(/[^a-zA-Z0-9]/g,'_')}_${moment(new Date()).format('YYYY_MM_DD_HH_MM_SS_mmm')}.${filename[filename.length-1]}`
            this.setState({
                file,
                name
            })
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(file);
            //Tester si les dimensions sont inférieur a MIN_WIDTH et MIN_HEIGHT
            const img = new Image();
            img.addEventListener('load', () => {
                const imgDimensionError = img.width < this.state.minWidth || img.height < this.state.minHeight;
                this.setState({
                    imgDimensionError,
                    firstImgDimensionError: imgDimensionError,
                })
            });
            img.src = URL.createObjectURL(file);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const {croppedFile, croppedImageUrl} = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.name
            );
            //Tester la taille du crop
            const img = new Image();
            img.addEventListener('load', () => {
                const imgDimensionError = this.state.firstImgDimensionError || 
                    img.width < this.state.minWidth || 
                    (!this.props.isLandingPage && img.width > MAX_WIDTH) || 
                    img.height < this.state.minHeight || 
                    (!this.props.isLandingPage && img.height) > MAX_HEIGHT
                const imgSizeError= croppedFile.size / 1024 > MAX_SIZE;
                this.setState({
                    imgDimensionError,
                    imgSizeError
                });
                this.showButtonScroll();
            });
            img.src = URL.createObjectURL(croppedFile);
            this.setState({ croppedFile, croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                const croppedFile = new File([blob], fileName);
                resolve({croppedFile, croppedImageUrl:this.fileUrl});
            }, this.state.file.type);
        });
    }

    handleFileInput = () => {
        document.getElementById('fileInput').click();
    }

    cancel = () => {
        hidePopupExtra();
    }

    validate = () => {
        this.props.onPictureValidate({croppedFile: this.state.croppedFile, croppedImageUrl: this.state.croppedImageUrl});
    }

    scrollToLeft = () =>{
        const container = document.getElementById('container');
        this.sideScroll(container,'left',25,100,10);
    };

    scrollToRight = () =>{
        const container = document.getElementById('container');
        this.sideScroll(container,'right',25,100,10);
    };

    showButtonScroll = ()=>{
        let showButtonScroll = false;
        const container = document.getElementById('container');
        if (this.state.file || this.props.currentPictureUrl) {
            const img = new Image();
            img.addEventListener('load', () => {
                showButtonScroll = true;
                if (!container || container.offsetWidth >= img.width) {
                    showButtonScroll= false
                }
                this.setState({
                    showButtonScroll
                })
            });
            if (this.state.file) {
                img.src = URL.createObjectURL(this.state.file);
            }else{
                img.src = this.props.currentPictureUrl
            }
        }
        this.setState({
            showButtonScroll
        })
    }

    sideScroll = (element,direction,speed,distance,step)=>{
        let scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction === 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;
        const { t, isLandingPage } = this.props;
        return (
            <React.Fragment>
                <span className="popup-close lpicto-close icon clickable" onClick={() => hidePopupExtra()}>
                    <i className="material-icons icon-edit">
                        close
                    </i>
                </span>
                <div className="popup-full popup-full-m limg-cropper">
                    {this.state.imgDimensionError && !isLandingPage && PopupHandler.showError(t("L'image doit avoir des dimensions entre {{min}} px et {{max}} px",{min: MIN_HEIGHT,max: MAX_HEIGHT}))}
                    {this.state.imgDimensionError && isLandingPage && PopupHandler.showError(t("L'image doit avoir une dimensions minimum de {{minWidth}} x {{minHeight}} px",{minWidth: MIN_WIDTH_LANDING_PAGE,minHeight: MIN_HEIGHT_LANDING_PAGE}))} 
                    {this.state.imgSizeError && PopupHandler.showError(t("La taille de l'image doit être au maximum {{max}} Ko",{max: MAX_SIZE}))}
                    {this.state.formatError && PopupHandler.showError(t("L'image doit être au format .jpeg ou .png"))}
                    <div className={!isLandingPage ? "limgCropper-body-562 limgCropper-body scrollbar" : "limgCropper-body scrollbar"} id="container">
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                minWidth={isLandingPage ? MIN_WIDTH_LANDING_PAGE : MIN_WIDTH}
                                minHeight={isLandingPage ? MIN_HEIGHT_LANDING_PAGE : MIN_HEIGHT}
                            />
                        )}
                        {
                            (this.props.currentPictureUrl && !src) && <img alt="" className="limgCropper-result" src={this.props.currentPictureUrl} />
                        }
                        {croppedImageUrl && (
                            <img alt="Crop" className='limgCropper-render' style={{ maxWidth: "100%" }} src={croppedImageUrl} id="croppedImage" />
                        )}
                    </div>
                    {
                        this.state.showButtonScroll && 
                        <div className="wrapper-btnScroll">
                            <button type="button" onClick={this.scrollToLeft}><span className="material-icons clickable">arrow_back</span></button>
                            <button type="button" onClick={this.scrollToRight}><span className="material-icons clickable">arrow_forward</span></button>
                        </div>
                    }
                    
                    <div className='limgcropper-footer'>
                        <div className="limgCropper-file">
                            <input
                                id="fileInput"
                                className="file-input"
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                onChange={this.onSelectFile}
                            />
                            <button
                                className="lbtn lbtn-senary"
                                onClick={this.handleFileInput}>{t('Choisir une image')}
                            </button>
                        </div>
                        <div className='limgcropper-footer'>
                            <button className="lbtn lbtn-link" onClick={this.cancel}>{t('Annuler')}</button>
                            <button 
                                className="button" 
                                onClick={this.validate} disabled={!src || this.state.imgDimensionError || this.state.imgSizeError}
                            >
                                {t('Valider')}
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default translate('translation')(ImageSelect)

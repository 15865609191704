import constants from '../constants/catalog';
const fields=[
    "categories",
    "externalCategories",
    "product",
    "saveImageCategory",
    "updateProduct",
    "enabledCategories",
    "availableOptions",
    "getVtcRate",
    "saveDeliveryPlace",
    "allDeliveryPlaces"
]
const initState = {}

fields.forEach(field =>{
    initState[field] = {};
})

const hotel = (state = initState, action) => {
    let field = null;
    const newState = {...state};
    const type = action.type !== constants.LOADING ? action.type : action.payload;
    let data = (action.payload && action.payload.data) || null
    switch (type) {
        case constants.LOADING:
            break;
        case constants.FETCH_CATEGORIES:
            field = fields[0];
            break;
        case constants.FETCH_EXTERNAL_CATEGORIES:
            field = fields[1];
            break;
        case constants.GET_PRODUCT:
            field = fields[2];
            break;
        case constants.SAVE_IMAGE_CATEGORY:
            if (action.payload && action.payload.data && action.payload.status === 200) {
                
                const id = action.payload.data.category_id;
                let updatedCategory = {};
                const categories = newState.categories &&
                    newState.categories.data && 
                    newState.categories.data && 
                    newState.categories.data.categories &&
                    newState.categories.data.categories.filter(categorie=>{
                        if (categorie.id === id) {
                            categorie.image = action.payload.data.image;
                            updatedCategory = {...categorie}
                        }
                        return categorie;
                    })
                data = {categories, updatedCategory}
            }
            field = fields[3];
            break;
        case constants.UPDATE_PRODUCT:
            field = fields[4];
            break;
        case constants.GET_ENABLED_CATEGORIES:
            field = fields[5];
            break;
        case constants.GET_AVAILABLE_OPTIONS:
            field = fields[6];
            break;
        case constants.GET_VTC_RATE:
            field = fields[7];
            break;
        case constants.SAVE_DELIVERY_PLACE:
            field = fields[8];
            break;
        case constants.GET_ALL_DELIVERY_PLACE:
            field = fields[9];
            break;
        case constants.INIT_PRODUCT:
            return{
                ...state,
                product: {}
            }
        default:
            if(action.type !== constants.LOADING){
                return state
            }
    }
    const loading = fields.find(element =>(
        element !== field && newState[element].loading === true
    ));
        
    if (action.type === constants.LOADING) {
        if (field) {
            newState[field].loading = true;
        }
        newState.loading = true;
        return newState;
    }else if (action.payload && action.payload.status === 200 && action.payload.data) {
        newState[field] = {
            error: false,
            data,
            loading: false
        }
        return { ...newState, loading: !!loading};   
    } else {
        newState[field] = {
            error: true,
            data: (action.payload && action.payload.response && action.payload.response.data) || null,
            loading: false
        }
        return { ...newState, loading: !!loading};
    }
};

export default hotel;

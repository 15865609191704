import constants from '../constants/client';
import { clientSA } from "../../applicatif/client.sa";

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};

export const fetchCustomers = (page, nbPerPage, query) => dispatch => {
    showLoading(dispatch, constants.FETCH)
    const request = clientSA.fetchCustomers(page, nbPerPage, query);

    const response = dispatch ({
        type: constants.FETCH,
        payload: request,
    });

    response.then(() => {
        dispatch({
            type: constants.CUSTOMER_QUERY,
            payload: {page, nbPerPage, query}
        })
    })
};

export const resetPassword = (customer) => dispatch => {
    showLoading(dispatch, constants.RESET_PASSWORD)
    const request = clientSA.resetPassword(customer);

    dispatch ({
        type: constants.RESET_PASSWORD,
        payload: request,
    })
};

export const exportCustomers = (customers) => dispatch => {
    showLoading(dispatch, constants.EXPORT)
    const request = clientSA.exportCustomers(customers);

    dispatch ({
        type: constants.EXPORT,
        payload: request,
    })
};


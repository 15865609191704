import React from 'react';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import {translate} from 'react-i18next';
import RoomService from "./RoomService";
import ExternalService from "./ExternalService";

class Catalogue extends React.Component {
  render() {
    const {t} = this.props
    return (
      <div className="page">
        <div className="bandeaux">
            <div className="wrapper clr">
                <h1>{t("Catalogue")}</h1>
            </div>
        </div>
        <div className="main-catalogue">
        <TabProvider defaultTab="PresChambre">
            <section className="my-tabs">
                <TabList className="my-tablist">
                    <div className="wrapper">
                        <Tab tabFor="PresChambre">{t("Prestations en chambre")}</Tab>
                        <Tab tabFor="PresExterne">{t("Prestations externes")}</Tab>
                    </div>
                </TabList>
                <div className="wrapper cnt-tabs">
                    <TabPanel tabId="PresChambre">
                        <RoomService/>
                    </TabPanel>
                    <TabPanel tabId="PresExterne">
                        <ExternalService/>
                    </TabPanel>
                </div>
            </section>
        </TabProvider>
        </div>
      </div>

    )
  }
}
export default translate('translation')(Catalogue);

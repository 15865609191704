import React from 'react';

import './styles.css'

class accueil extends React.Component {
  render() {
    return (
      <div className="accueil">
        <h1>Accueil</h1>
      </div>
    )
  }
}
export default accueil;

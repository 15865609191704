import React from 'react';
import {translate} from 'react-i18next';
import FormHandler from "../../../services/error-handlers/formHandler";
import  {hidePopUp} from '../../components/popup/popup';

class RemboursementCommande extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMode: 1,
            amount: '',
            comment: '',
            amountError: '',
            commentError: '',
            loading: false,
            totalRefunded:  props.order.total_refunded || 0
        };
    }

    componentDidMount (){
        this.isValid();
    }

    handlePaymentModeChange = (event) => {
        this.setState({paymentMode: event.target.value});
    };
    handleAmountChange = (event) => {
        const amount = event.target.value 
        const totalRefunded = parseFloat(this.props.order.total_refunded || 0) + parseFloat(amount || 0);
        this.setState({
            amount,
            totalRefunded
        }, this.isValid);
    };
    handleCommentChange = (event) => {
        this.setState({comment: event.target.value},this.isValid);
    };
    handleSubmit = () => {
        const valid = this.isValid();
        if (!valid) {
            return;
        }
        const data = {
            order_id: this.props.order.id,
            payment_id: this.state.paymentMode,
            amount: parseFloat(this.state.amount),
            comment: this.state.comment,
        };
        this.props.handleValidate(data);
    };

    isValid = () => {
        const amountError = this.getAmountError();
        const commentError = this.getCommentError();
        const isValid = !commentError && !amountError;
        this.setState({
            isValid,
            amountError,
            commentError
        })
        return isValid;
    };

    getAmountError = () => {
        const {t} = this.props;
        const amount = parseFloat(this.state.amount || 0);
        let amountError = ''
        if (!FormHandler.isFloat(amount)) {
            amountError = t("Le montant doit être un nombre valide.");
        }else if (amount <= 0) {
            amountError = t("Le montant est obligatoire");
        } else if (this.props.order.amount_paid < this.state.totalRefunded) {
            amountError = t("Le montant du remboursement doit être inférieur ou égal au montant encaissé.");
        }
        return amountError;
    };

    getCommentError = () => {
        const {t} = this.props;
        let commentError = ''
        if (!this.state.comment || !this.state.comment.trim()) {
            commentError = t("Le commentaire est obligatoire.");
        }
        return commentError;
    };

    render = ()  => {
        const order = this.props.order || {};
        const {t} = this.props;
        return (
            <div className="main-modal">
                <div>
                    <h4 className="stitle">{t("Prestation")}:</h4> <p>{(order.description && order.description[0] && order.description[0].product_name) || ''}</p>
                    <h4 className="stitle">{t("Commande n°")}:</h4> <p>{order.orderNumber}</p>
                    <h4 className="stitle">{t("Transaction n°")}:</h4> <p>{order.transaction}</p>
                    <h4 className="stitle">{t("Date")}:</h4> <p>{order.date}</p>
                    <h4 className="stitle">{t("Client")}:</h4> <p>{order.customer}</p>
                    <h4 className="stitle">{t("Seller associé")}:</h4> <p>{order.sellerName} {order.sellerPhone ? `(${order.sellerPhone})`:''}</p>
                    <h4 className="stitle">{t("Prix")}:</h4><p>{order.price ? parseFloat(order.price).toFixed(2) : 0}€</p>
                    <h4 className="stitle">{t("Montant du remboursement")}:</h4><p>{this.state.totalRefunded}€</p>
                    <h4 className="stitle">{t("Montant encaissé")}:</h4><p>{order.amount_paid ? parseFloat(order.amount_paid).toFixed(2) : 0}€</p>
                </div>
                <div className="line-input">
                    <label>{t("Méthode de paiement")}</label>
                    <div className="select">
                        <select value={this.state.paymentMode}
                                onChange={this.handlePaymentModeChange}
                        >
                            <option value="1">{t("Carte bancaire")}</option>
                        </select>
                    </div>
                </div>
                <div className="line-input">
                    <label>{t("Montant")}</label>
                    <div className="cnt-input">
                        <input type="number"
                                value={this.state.amount}
                                onChange={this.handleAmountChange}
                        />
                        <span className="error">{this.state.amountError}</span>
                    </div>
                </div>
                <div className="line-input">
                    <label>{t("Commentaire")}</label>
                    <div className="cnt-input">
                        <textarea value={this.state.comment}
                                    onChange={this.handleCommentChange}
                        ></textarea>
                        <div className="error">{this.state.commentError}</div>
                    </div>
                </div>
                <div className="cnt-Btn align-right">
                    <span className="btn-cancel" onClick={hidePopUp}>{t("Annuler")}</span>
                    <button className="button" onClick={this.handleSubmit} disabled={!this.state.isValid}>{t("Valider")}</button>
                    <div className="clr"></div>
                </div>
            </div>
        )
    }
}

export default translate('translation')(RemboursementCommande);

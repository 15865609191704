import { themeBDL } from "../business-delegate/theme.bdl";

class ThemeSA {
    updateColor = (data) => {
        return themeBDL.updateColor(data);
    };

    getColor = () => {
        return themeBDL.getColor();
    };
}

export const themeSA = new ThemeSA();

import constants from '../constants/catalog';
import { catalogSA } from "../../applicatif/catalog.sa";

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};

export const fetchCategories = (query) => dispatch => {
    showLoading(dispatch, constants.FETCH_CATEGORIES)
    const request = catalogSA.fetchCategories(query);

    dispatch ({
        type: constants.FETCH_CATEGORIES,
        payload: request,
    })
};

export const fetchExternalCategories = (query) => dispatch => {
    showLoading(dispatch, constants.FETCH_EXTERNAL_CATEGORIES)
    const request = catalogSA.fetchExternalCategories(query);
    dispatch ({
        type: constants.FETCH_EXTERNAL_CATEGORIES,
        payload: request,
    });
};

export const getProduct = (id) => dispatch => {
    dispatch ({
        type: constants.INIT_PRODUCT,
        payload: null,
    });
    showLoading(dispatch, constants.GET_PRODUCT)
    const request = catalogSA.getProduct(id);

    dispatch ({
        type: constants.GET_PRODUCT,
        payload: request,
    });
};

export const getAvailableOptions = () => dispatch => {
    showLoading(dispatch, constants.GET_AVAILABLE_OPTIONS)
    const request = catalogSA.getAvailableOptions();

    dispatch ({
        type: constants.GET_AVAILABLE_OPTIONS,
        payload: request,
    });
};

export const saveImageCategory = (data) => dispatch => {
    showLoading(dispatch, constants.SAVE_IMAGE_CATEGORY)
    const request = catalogSA.saveImageCategory(data);

    dispatch ({
        type: constants.SAVE_IMAGE_CATEGORY,
        payload: request,
    });
};

export const updateProduct = (param) => dispatch =>  {
    showLoading(dispatch, constants.UPDATE_PRODUCT)
    const request = catalogSA.updateProduct(param);

    dispatch ({
        type: constants.UPDATE_PRODUCT,
        payload: request,
    });
};

export const getEnabledCategories = () => dispatch =>  {
    showLoading(dispatch, constants.GET_ENABLED_CATEGORIES)
    const request = catalogSA.getEnabledCategories();

    dispatch ({
        type: constants.GET_ENABLED_CATEGORIES,
        payload: request,
    });
};

export const getVtcRate = () => dispatch =>  {
    showLoading(dispatch, constants.GET_VTC_RATE)
    const request = catalogSA.getVtcRate();

    dispatch ({
        type: constants.GET_VTC_RATE,
        payload: request,
    });
};

export const saveDeliveryPlace = (data) => dispatch =>  {
    showLoading(dispatch, constants.SAVE_DELIVERY_PLACE)
    const request = catalogSA.saveDeliveryPlace(data);

    dispatch ({
        type: constants.SAVE_DELIVERY_PLACE,
        payload: request,
    });
};

export const getAllDeliveryPlace = (idPrestation) => dispatch =>  {
    showLoading(dispatch, constants.GET_ALL_DELIVERY_PLACE)
    const request = catalogSA.getAllDeliveryPlace(idPrestation);

    dispatch ({
        type: constants.GET_ALL_DELIVERY_PLACE,
        payload: request,
    });
};






import React, {Component} from 'react';
import {translate} from 'react-i18next';

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            filename: '',
            isLoading: false,
        };
    }

    onFileSelected = e => {
        if (e.target.files && e.target.files.length > 0) {
            const result = e.target.files[0];                            
            this.setState({
                file: result,
                filename: result.name,
            })
        } else {
            this.setState({
                file: null,
                filename: '',
            })
        }
    }

    onSubmit = () => {
        this.props.onSubmit(this.props.file);
    };

    render() {
        const {t} = this.props;
        return (
            <div className="main">
                <div className="wrapper clr">
                    <div className="row">
                        <div className="col col-7">
                            <h2>{t("Selectioner votre fichier")}</h2>
                            <div className="cnt-Btn btn-file align-left">
                                <input type="file" name="file" id="file" className="inputfile" data-multiple-caption="{count} files selected"
                                    multiple = {this.props.isMultiple}
                                    onChange={this.onFileSelected}
                                    //accept="image/jpeg,image/png"
                                />
                                <label htmlFor="file">{t("Choisir un ficher")}</label>
                                {this.state.filename &&
                                <span className="filename">{this.state.filename}</span>
                                }
                            </div>
                        </div>
                        <div className="col col-5">
                            <h2>&nbsp;</h2>
                            <div className="cnt-Btn align-right">
                                <button  
                                    className="button" 
                                    disabled={!this.state.file}
                                    onClick={this.onSubmit}
                                >
                                    {t("Enregistrer")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translation')(FileUpload);
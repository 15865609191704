import { dashboardBDL } from "../business-delegate/dashboard.bdl";

class DashboarSA {
    getMyIncome = (dateInfo) => {
        return dashboardBDL.getMyIncome(dateInfo);
    };

    getDashboardValues = () => {
        return dashboardBDL.getDashboardValues();
    };

    getMyMonthlyIncome = (year) => {
        return dashboardBDL.getMyMonthlyIncome(year);
    };
}

export const dashboarSA = new DashboarSA();

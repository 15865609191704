import config from "../../../config";

const baseURL = config.baseURL;

export default {
    LOADING: 'language/LOADING',
    INIT_STATE: 'language/INIT_STATE',
    FETCH_LANGUAGES_URL: `${baseURL}/rest/V1/ListLocales`,
    FETCH_LANGUAGES: 'language/FETCH_LANGUAGES',
    ORDER_LANGUAGES_URL: `${baseURL}/rest/V1/reorderLocales`,
    ORDER_LANGUAGES: 'language/ORDER_LANGUAGES',
    ENABLE_DISABLE_LANGUAGES_URL: `${baseURL}/rest/V1/enableDisableLocale`,
    ENABLE_DISABLE_LANGUAGES: 'language/ENABLE_DISABLE_LANGUAGES',
}

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import LoadingOverlay from 'react-loading-overlay';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import Headers from "../../components/headers/headers";
import Footer from "../../components/footer/footer";
import {authenticationSA} from "../../../services/applicatif/authentication.sa";
import authenticationConstants from "../../../services/redux/constants/auth";
import {hidePopUp, hidePopupExtra} from "../../components/popup/popup";
import {fetchInfos, getLogo} from "../../../services/redux/actions/hotel";



class MainContainer extends React.Component {

    componentDidMount(){
        const {state} = this.props;
        if (!(state.hotel && state.hotel.hotelInfo && state.hotel.hotelInfo.data && state.hotel.hotelInfo.data.InfosHotel && state.hotel.hotelInfo.data.InfosHotel.length > 0)) {
            this.props.fetchInfos();
        }
        if (!(state.hotel && state.hotel.logoImage && state.hotel.logoImage.data && state.hotel.logoImage.data.data)) {
            this.props.getLogo();
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
        const {state} = this.props;
        if (state.hotel && state.hotel.hotelInfo && state.hotel.hotelInfo.data && state.hotel.hotelInfo.data.InfosHotel && state.hotel.hotelInfo.data.InfosHotel.length > 0) {
            document.title = state.hotel.hotelInfo.data.InfosHotel[0].nomhotel
        }
        if (state.authentification && state.authentification.expiresDate) {
            hidePopUp();
            hidePopupExtra();
            authenticationSA.logout();
            this.props.history.push({pathname: "/login", state: {from: this.props.location, authenticationError: authenticationConstants.EXPIRES_DATE_ERROR}});
        }
    }

    render() {
        const { t } = this.props;
        const {Component, withHeaderFooter, componentProps, state} = this.props;
        const loading = (state.loading && state.loading.isLoading) ||
            (state.theme && state.theme.loading) ||
            (state.hotel && state.hotel.loading) ||
            (state.client && state.client.loading) ||
            (state.commandes && state.commandes.loading) ||
            (state.seller && state.seller.loading) ||
            (state.catalog && state.catalog.loading) ||
            (state.notice && state.notice.loading) ||
            (state.language && state.language.isLoading)
        return (
            <LoadingOverlay
                active = {loading}
                spinner
                text={t("Chargement")}
            >
                { withHeaderFooter && <Headers/> }
                <Component {...componentProps} />
                { withHeaderFooter && <Footer/> }
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = (state) => {
    return {state};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchInfos,
        getLogo
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer)));

import constants from '../constants/language';

const language = (state = null, action) => {
    switch (action.type) {
        case constants.LOADING:
            return {
                isLoading: true,
            };

        case constants.FETCH_LANGUAGES:
            if (action.payload && action.payload.status === 200) {
                return {
                    isLoading: false,
                    hasError: false,
                    data: action.payload.data,
                }
            } else {
                return {
                    isLoading: false,
                    hasError: true,
                    data: null,
                }
            }
        default:
            return state;
    }
};

export default language;

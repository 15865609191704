import React from 'react';
import { Progress } from 'react-sweet-progress';
import {withRouter} from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import moment from 'moment';
import 'moment/locale/fr';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import countryList from 'react-select-country-list';
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import PhoneInput from 'react-phone-number-input/input';
import Select, { components } from "react-select";
import {translate} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isValidIBAN, isValidBIC} from "ibantools";
import {isSIRET} from "siret";
import { checkVAT, france } from 'jsvat';
import { getProfilProgression, fetchInfos, saveHeadOfficeInfos, saveAccountInfos, getStripeStatus} from "../../../services/redux/actions/hotel";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import { hotelSA } from "../../../services/applicatif/hotel.sa";
import FormHandler from "../../../services/error-handlers/formHandler";
import FileSelect from "../../components/file-upload/file-select";
import {showPopupExtra, hidePopupExtra} from "../../components/popup/popup";
import PopupError from "../../components/popup/popup.error";
import config from "../../../config";

const headOfficeFields = {
    "legal_office_name": "headOfficeName",
    "legal_office_address":"headOfficeAddress",
    "legal_office_city":"headOfficeCity",
    "legal_office_postcode":"headOfficePostcode",
    "legal_office_country":"headOfficeCountry",
    "legal_office_email": "headOfficeEmail",
    "legal_representative_name": "headOfficeRepresentativeFirstName",
    "legal_representative_lastname": "headOfficeRepresentativeLastName",
    "legal_representative_address": "headOfficeRepresentativeAddress",
    "legal_representative_city": "headOfficeRepresentativeCity",
    "legal_representative_state": "headOfficeRepresentativeState",
    "legal_representative_postcode": "headOfficeRepresentativePostcode",
    "legal_representative_phone": "headOfficeRepresentativePhone",
    "legal_representative_email": "headOfficeRepresentativeEmail",
    "legal_representative_birthday": "headOfficeRepresentativeBirthDate"
}
    
const contactFields = {
    "nom_contact": "contactLastName",
    "prenom_contact": "contactFirstName",
    "adresse_contact": "contactAddress",
    "city_contact": "contactCity",
    "postcode_contact": "contactPostcode",
    "country_contact": "contactCountry",
    "email_contact": "contactEmail",
    "phone_contact": "contactPhone"
}
    
const legalInformationFields = {
    "legal_status": "legalStatus",
    "legal_siret": "legalSiret",
    "legal_url_key": "legalUrlKey",
    "legal_tva_number": "legalTvaNumber"
}
const accountFields = {
    "account_name": "accountHolderName",
    "account_dob": "accountBirthDate",
    "account_IBAN": "accountIbanNb",
    "account_BIC": "accountBicNb",
    "account_state": "accountStatus",
    "file_identity": "identityFile",
    "back_file_identity": "kbisFile"
}

const NAME_LENGTH = 30;
const SIRET_LENGTH = 14;
const BIC_LENGTH = 11;

const SingleValue = props => (
    <components.SingleValue {...props}>
      {props.data.chipLabel}
    </components.SingleValue>
);

const options = getCountries().map((country) => ({
    label: en[country] + ' +'+getCountryCallingCode(country),
    chipLabel: '+'+getCountryCallingCode(country),
    value: country
})); 

class GestionAdministration extends React.Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search.replace('?', ''));
        const activeTab = params ? params.get('activeTab') : null;
        const statusOptions =[
            {
                value: "Auto-entrepreneur",
                abbreviation: "Auto-entrepreneur",
                label: props.t("Auto-entrepreneur")
            },
            {
                value: "EI",
                abbreviation: props.t("EI"),
                label: props.t("Entreprise individuelle")
            },
            {
                value: "EIRL",
                abbreviation: props.t("EIRL"),
                label: props.t("Entreprise individuelle à responsabilité limitée")
            },
            {
                value: "EURL",
                abbreviation: props.t("EURL"),
                label: props.t("Entreprise unipersonnelle à responsabilité limitée")
            },
            {
                value: "SA",
                abbreviation: props.t("SA"),
                label: props.t("Société anonyme")
            },
            {
                value: "SARL",
                abbreviation: props.t("SARL"),
                label: props.t("Société à responsabilité limitée")
            },
            {
                value: "SARLU",
                abbreviation: props.t("SARLU"),
                label: props.t("Société anonyme à responsabilité limité uni-personnelle")
            },
            {
                value: "SAS",
                abbreviation: props.t("SAS"),
                label: props.t("Société par actions simplifiée")
            },
            {
                value: "SASU",
                abbreviation: props.t("SASU"),
                label: props.t("Société par actions simplifiée unipersonnelle")
            },
            {
                value: props.t("SC"),
                abbreviation: props.t("SC"),
                label: props.t("Société civile")
            },
            {
                value: "SCA",
                abbreviation: props.t("SCA"),
                label: props.t("Société en commandite par actions")
            },
            {
                value: "SCS",
                abbreviation: props.t("SCS"),
                label: props.t("Société en commandite simple")
            },
            {
                value: "SNC",
                abbreviation: props.t("SNC"),
                label: props.t("Société en nom collectif")
            }
        ]
        this.state = {
            loading: true,
            error: false,
            profil: {},
            progression: {},

            headOfficeName:'',
            headOfficeAddress:'',
            headOfficeEmail:'',
            headOfficeRepresentativeCity:'',
            headOfficeRepresentativeState:'',
            headOfficeRepresentativePostcode:'',
            headOfficeRepresentativeFirstName:'',
            headOfficeRepresentativeLastName:'',
            headOfficeRepresentativeAddress:'',
            headOfficeRepresentativeEmail:'',
            headOfficeRepresentativePhone: "",
            headOfficeRepresentativeBirthDate: '',

            contactFirstName:'',
            contactLastName:'',
            contactAddress:'',
            contactEmail:'',
            contactPhone:'',

            legalStatus:statusOptions[0],
            legalSiret:'',
            legalUrlKey:'',
            legalRepresentativeName:'',

            accountHolderName:'',
            accountBirthDate:'',
            accountIbanNb:'',
            accountBicNb:'',
            accountStatus:props.t("En erreur"),

            contactCurrentCountry: 'FR',
            representativeCurrentCountry: 'FR',
            activeTab,
            countries: countryList().setEmpty(props.t("Sélectionnez un pays")).getData(),
            infoId: "0",
            statusOptions,
            stripeStatus: {
                pending: props.t("En attente") ,
                processing: props.t("En cours de validation"),
                refused: props.t("Refusé"),
                verified: props.t("Vérifié"),
                unverified: props.t("Non vérifié"),
            }
        };
    }

    componentDidMount() {
        if (this.props.hotel){
            if (this.props.hotel && this.props.hotel.hotelInfo && this.props.hotel.hotelInfo.data && this.props.hotel.hotelInfo.data.InfosHotel && this.props.hotel.hotelInfo.data.InfosHotel.length > 0) {
                this.setStateInfosFromResult(this.props.hotel.hotelInfo.data.InfosHotel[0]);
            }
            if (this.props.hotel.progression && this.props.hotel.progression.data) {
                const progression =  this.props.hotel.progression.data || {};
                this.setState({
                    progression,
                    errorProgression: false
                })
                progression && progression.errors && this.setErrorStateFromResult(progression.errors);
            }else{
                this.getProfilProgression();
            }
        }    
        this.state.activeTab && this.state.activeTab.trim() && this.expandCollapse(null, 3, `#${this.state.activeTab}`)
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.hotel;
        const props = this.props.hotel;
        const { t } = this.props;

        if (!prev) {
            return;
        }
       
        if (prev.hotelInfo && props.hotelInfo && prev.hotelInfo.loading && !props.hotelInfo.loading) {
            if (props.hotelInfo.error) {
                this.setState({
                    profil: {},
                    errorProfil: true
                })
            } else {
                const infosHotel = (props.hotelInfo.data && props.hotelInfo.data.InfosHotel && props.hotelInfo.data.InfosHotel.length > 0 && props.hotelInfo.data.InfosHotel[0]) || {}
                this.setStateInfosFromResult(infosHotel);
                // if (!props.stripeStatus || !props.stripeStatus.data) {
                //     this.props.getStripeStatus(this.props.hotel.hotelInfo.data.InfosHotel[0].info_id);
                // }
            }
        }
        if (prev.progression && props.progression &&  prev.progression.loading && !props.progression.loading) {
            if (props.progression.error) {
                this.setState({
                    progression: {},
                    errorProgression: true
                })
            } else {
                const progression =  props.progression.data || {};
                this.setState({
                    progression,
                    errorProgression: false
                })
                //progression && progression.errors && this.setErrorStateFromResult(progression.errors);
            }
        }

        if (prev.saveHeadOfficeInfos && props.saveHeadOfficeInfos && prev.saveHeadOfficeInfos.loading && !props.saveHeadOfficeInfos.loading) {
            if (props.saveHeadOfficeInfos.error) {
                const error = props.saveHeadOfficeInfos.data;
                if (error) {
                    if (error.message) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t(error.message)}/>)
                    }
                }  
                this.setState({
                    saveSuccess: false,
                })
            } else {
                this.setState({
                    saveSuccess: true,
                })
                this.getInformations();
            }
        }

        if (prev.saveAccountInfos && props.saveAccountInfos && prev.saveAccountInfos.loading && !props.saveAccountInfos.loading) {
            if (props.saveAccountInfos.error) {
                const error = props.saveAccountInfos.data;
                if (error) {
                    if (error.message) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t(error.message)}/>)
                    }
                }  
                this.setState({
                    saveSuccess: false,
                })
            } else {
                this.setState({
                    saveSuccess: true,
                })
                this.getInformations();
            }
        }
    }

    expandCollapse = (event, index, item)=>{
        const url = event ? event.target.dataset.url : item;
        const element = document.getElementsByClassName('accordion-item')[index];
        const el = document.querySelector(url);
        if (element && element.lastChild.clientHeight===0) {
            event && event.preventDefault();
            element.firstChild.click();    
            setTimeout(()=>{
                el.scrollIntoView()
            },500)          
        }
    }

    fetchInfos = ()=>{
        this.props.fetchInfos();
    }

    getProfilProgression = ()=>{
        this.props.getProfilProgression();
    }

    getInformations=()=>{
        this.fetchInfos();
        this.getProfilProgression();
    };

    setStateInfosFromResult = (result)=>{
        let newState = {};

        Object.keys(headOfficeFields).forEach(field => {
            const value = result[field] || ''
            newState[headOfficeFields[field]] = value;
            if (value && value.trim() && field === 'legal_representative_birthday') {
                newState.headOfficeRepresentativeBirthDate = moment(new Date(value)).format('YYYY-MM-DD');
            }else if (value && field === 'legal_representative_state'){
                newState[headOfficeFields[field]] = countryList().getValue(value);
            }else if (value && field === 'legal_office_country'){
                newState[headOfficeFields[field]] = countryList().getValue(value);
            }
        })

        Object.keys(contactFields).forEach(field => {
            const value = result[field] || ''
            newState[contactFields[field]] = value;
            if (value && field === 'country_contact'){
                newState[contactFields[field]] = countryList().getValue(value);
            }
        })

        Object.keys(legalInformationFields).forEach(field => {
            newState[legalInformationFields[field]] = result[field] || '';
        })

        Object.keys(accountFields).forEach(field => {
            const value = result[field] || ''
            newState[accountFields[field]] = value;
            if (value && value.trim() && field === 'account_dob') {
                newState.accountBirthDate = moment(new Date(value)).format('YYYY-MM-DD');
            }
        })
        const contactCurrentCountry = (result.contactPhone && isValidPhoneNumber(result.contactPhone)) ? parsePhoneNumber(result.contactPhone).country : "FR";
        const representativeCurrentCountry = (result.headOfficeRepresentativePhone && isValidPhoneNumber(result.headOfficeRepresentativePhone)) ? parsePhoneNumber(result.headOfficeRepresentativePhone).country : "FR";
        
        this.setState({
            ...newState,
            contactCurrentCountry,
            representativeCurrentCountry,
            infoId: result.info_id,
            kbisPhotocopy: null,
            identityPaper: null,
        },()=>{
            this.isValidHeadOffice();
            this.isValidContact();
            this.isValidLegalInfos();
            this.isValidBankInfos();
        })
    }

    setErrorStateFromResult = (result)=>{
        let newStateError = {};
        let headOfficeError = false;
        let contactError = false;
        let legalInformationError = false;
        let bankInformationError = false;
        let isValidSaveAcountButton = true;

        Object.keys(headOfficeFields).forEach(field => {
            const fieldName = `${headOfficeFields[field]}Error`;
            const value = result[field];
            newStateError[fieldName] = value;
            if (value) {
                headOfficeError = true;
            }
        })

        Object.keys(contactFields).forEach(field => {
            const fieldName = `${contactFields[field]}Error`;
            const value = result[field];
            newStateError[fieldName] = value;
            if (value) {
                contactError = true;
            }
        })

        Object.keys(legalInformationFields).forEach(field => {
            const fieldName = `${legalInformationFields[field]}Error`;
            const value = result[field];
            newStateError[fieldName] = value;
            if (value) {
                legalInformationError = true;
            }
        })

        Object.keys(accountFields).forEach(field => {
            const fieldName = `${accountFields[field]}Error`;
            if (fieldName !== 'accountStatusError') {
                const value = result[field];
                newStateError[fieldName] = value;
                if (value) {
                    bankInformationError = true;
                }
                
                if (value && fieldName !== 'identityFileError' && fieldName !== 'kbisFileError') {
                    isValidSaveAcountButton = false
                }
            }
            
        })
        this.setState({
            ...newStateError,
            headOfficeError,
            contactError,
            legalInformationError,
            bankInformationError,
            isValidSaveAcountButton
        })
    }

    handleCountryChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        }, ()=>{
            this.isValidHeadOffice();
            this.isValidContact();
        });
    };

    handleHeadOfficeInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name!=='headOfficeRepresentativeAddress' && name!=='headOfficeRepresentativeBirthDate' && value.length > NAME_LENGTH) {
            return
        }
        this.setState({
            [name]: value,
        }, this.isValidHeadOffice);
    }

    handleHeadOfficeBirthDateChange = (value) => {
        const date = value ? moment(value).format('YYYY-MM-DD') : "";
        const name = 'headOfficeRepresentativeBirthDate';
        this.setState({
            [name]: date,
        }, this.isValidHeadOffice);
    }

    handleContactInputChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        if(name !=='contactAddress' && name !=='contactPhone' && value.length > NAME_LENGTH){
            return
        }
        this.setState({
            [name]: value,
        }, this.isValidContact);
    }

    handlecontactPhoneChange = (contactPhone) => {
        this.setState({contactPhone}, this.isValidContact);
    };

    handleRepresentativePhoneChange = (headOfficeRepresentativePhone) => {
        this.setState({headOfficeRepresentativePhone}, this.isValidHeadOffice);
    };

    handleLegalInfosInfoInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name ==='legalSiret' && value.length > SIRET_LENGTH) {
           return
        }
        this.setState({
            [name]: value,
        }, this.isValidLegalInfos);
    }

    handleBankInfosInfoInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name ==='accountHolderName' && value.length > NAME_LENGTH) {
            return
        }
        if (name ==='accountBicNb' && value.length > BIC_LENGTH) {
            return
        }
        this.setState({
            [name]: value,
        }, this.isValidBankInfos);
    }

    handleBankInfosInfoBirthDateChange = (value) => {
        const date = value ? moment(new Date(value)).format('YYYY-MM-DD') : "";
        const name = 'accountBirthDate';
        this.setState({
            [name]: date,
        }, this.isValidBankInfos);
    }


    handleLegalStatusChange = (event) => {
        this.setState({
            legalStatus: event.value,
        }, this.isValidLegalInfos);
    };

    handleContactContrySelect (value) {
        this.setState({
            contactCurrentCountry: value.value,
            contactPhone: ""
        }, this.isValidContact);
    }

    handleRepresentativeContrySelect (value) {
        this.setState({
            representativeCurrentCountry: value.value,
            headOfficeRepresentativePhone: ""
        }, this.isValidHeadOffice);
    }

    handleSubmitHeadOffice = () => {
        if (this.isValidHeadOffice()) {
            const data = {};
            Object.keys(headOfficeFields).forEach(field => {
                let value = this.state[headOfficeFields[field]];
                if (value && (field === 'legal_representative_state' || field === 'legal_office_country')){
                    value = countryList().getLabel(value)
                }
                data[headOfficeFields[field]] = value;
            })
            data.code_hotel = config.codeHotel;
            data.info_id = this.state.infoId
            data.profile_url = "";
            this.props.saveHeadOfficeInfos(data)
        }
    };

    handleSubmitContact = () => {
        if (this.isValidContact()) {
            this.props.showLoading();
            const data = {};
            const {t} = this.props;
            Object.keys(contactFields).forEach(field => {
                const baseField = contactFields[field];
                data[baseField] = this.state[baseField];

                let value = this.state[contactFields[field]];
                if (value && (field === 'country_contact')){
                    value = countryList().getLabel(value)
                }
                data[contactFields[field]] = value;
            });
            data.code_hotel = config.codeHotel;
            hotelSA.saveContactInfos(data)
            .then(()=>{
                this.setState({
                    saveSuccess: true,
                })
                this.props.hideLoading();
                this.getInformations();
            })
            .catch(exception=>{
                this.props.hideLoading();
                const error = exception.response && exception.response.data;
                if (error) {
                    if (error.message) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t(error.message)}/>)
                    }
                }      
                this.setState({
                    saveSuccess: false,
                })
            })
        }
    };

    handleSubmitLegalInfos = () => {
        if (this.isValidLegalInfos()) {
            this.props.showLoading();
            const data = {};
            const {t} = this.props;
            Object.keys(legalInformationFields).forEach(field => {
                const baseField = legalInformationFields[field];
                data[baseField] = this.state[baseField];
            });
            data.code_hotel = config.codeHotel;
            hotelSA.saveLegalInfos(data)
            .then(()=>{
                this.setState({
                    saveSuccess: true,
                })
                this.getInformations();
                this.props.hideLoading();
            })
            .catch(exception=>{
                this.props.hideLoading();
                const error = exception.response && exception.response.data;
                if (error) {
                    if (error.message) {
                        showPopupExtra(<PopupError title={t("Erreur")} message={t(error.message)}/>)
                    }
                }      
                this.setState({
                    saveSuccess: false,
                })
            })
        }
    };

    handleSubmitAccount = () => {
        if (this.isValidBankInfos()) {
            const data = new FormData();
            Object.keys(accountFields).forEach(field => {
                if (field !== "account_state" && field !== "back_file_identity" && field !== "file_identity") {
                    data.append(field, this.state[accountFields[field]]);
                }
            })
            data.append("code_hotel", config.codeHotel);
            data.append("info_id", this.state.infoId);
            data.append("profile_url", "");
            if (this.state.identityPaper) {
                data.append("file_identity", this.state.identityPaper);
            }
            if (this.state.kbisPhotocopy) {
                data.append("file_kbis", this.state.kbisPhotocopy);
            }
            this.props.saveAccountInfos(data);
        }
    };

    handleFileChange = e => {
        if (e.target.files.length) {
          this.setState({
            fileUrl: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
          });
        }
      };

    isValidHeadOffice = ()=>{
        const newState = {};
        let headOfficeError = false;
        Object.values(headOfficeFields).forEach(field => {
            const value = this.state[field];
            const isEmpty = FormHandler.notEmptyInput(value);
            let error = isEmpty;
            if (!isEmpty){
                if (!isEmpty && (field === 'headOfficeEmail' || field === 'headOfficeRepresentativeEmail')) {
                    error = FormHandler.emailError(value);
                }else if(field === 'headOfficeRepresentativePhone') {
                    error =  FormHandler.phoneError(value);
                }else if(field === 'headOfficeRepresentativeBirthDate') {
                    const birthDay = moment().add('year', -18);
                    error =  new Date(value) > birthDay ? this.props.t("Le représentant doit avoir au moins 18ans.") : "";
                }
            }
            newState[`${field}Error`] = error;
            if (error) {
                headOfficeError = true;
            }
        })
        this.setState({
            ...newState,
            headOfficeError
        });
        return !headOfficeError;
    }

    isValidContact = ()=>{
        const newState = {};
        let contactError = false;
        Object.values(contactFields).forEach(field => {
            const value = this.state[field];
            const isEmpty =  FormHandler.notEmptyInput(value);;
            let error = isEmpty;
            if (!isEmpty){
                if(field === 'contactEmail') {
                    error = FormHandler.emailError(value);
                }else if(field === 'contactPhone') {
                    error = FormHandler.phoneError(value);
                }
            } 
            newState[`${field}Error`] = error;
            if (error) {
                contactError = true;
            }
        })
        this.setState({
            ...newState,
            contactError
        });
        return !contactError;
    }

    isValidLegalInfos = ()=>{
        const newState = {};
        const {t}=this.props;
        let legalInformationError = false;
        Object.values(legalInformationFields).forEach(field => {
            const value = this.state[field];
            const isEmpty =  FormHandler.notEmptyInput(value);
            let error = isEmpty;
            if (!isEmpty && field === 'legalSiret' && !isSIRET(value)){
                error = t("SIRET invalide");
            } 
            if (!isEmpty && field === 'legalTvaNumber'){
                const checkedVat = checkVAT(value, [france]);
                if (!checkedVat || !checkedVat.isValid) {
                    error = t("Numéro de TVA invalide");
                }
            }
            newState[`${field}Error`] = error;
            if (error) {
                legalInformationError = true;
            }
        })
        this.setState({
            ...newState,
            legalInformationError
        });
        return !legalInformationError;
    }

    isValidBankInfos = ()=>{
        const newState = {};
        const {t} = this.props;
        let bankInformationError = false;
        let isValidSaveAcountButton = true;
        Object.values(accountFields).forEach(field => {
            const value = this.state[field];
            let error = false;
            if (field !=='accountStatus') {
                const isEmpty = FormHandler.notEmptyInput(value);
                error = isEmpty;
                if (!isEmpty){
                    if (field ==='accountHolderName') {
                        error =  FormHandler.nameErrors(value);;
                    }
                    if (field ==='accountBicNb' && !isValidBIC(value)) {
                        error = t("Numéro du BIC invalide");
                    }
                    if (field ==='accountIbanNb' && !isValidIBAN(value)) {
                        error = t("Numéro de l’IBAN invalide");;
                    }
                    if(field === 'accountBirthDate') {
                        const birthDay = moment().add('year', -18);
                        error =  new Date(value) > birthDay ? this.props.t("Le titulaire du compte doit avoir au moins 18ans.") : "";
                    }
                }
                if (error && field !=='accountStatus' && field !== 'identityFile' && field !== 'kbisFile') {
                    isValidSaveAcountButton = false
                }
            }
            // else{
            //     error = value === this.props.t("Vérifié") ? false : true
            // }
            newState[`${field}Error`] = error;
            if (error) {
                bankInformationError = true;
            }
        })
        this.setState({
            ...newState,
            bankInformationError,
            isValidSaveAcountButton
        });
        return isValidSaveAcountButton;
    }

    getIdentityPaper = () => {
        showPopupExtra(<FileSelect onFileSelected={(file)=>{this.setState({identityPaper: file}); hidePopupExtra()}} />);
    };

    getKbisPhotocopy = () => {
        showPopupExtra(<FileSelect onFileSelected={(file)=>{this.setState({kbisPhotocopy: file}); hidePopupExtra()}} />);
    };

    getOptionsStatus =()=>{
        return this.state.statusOptions.map(status=>(
            status.abbreviation === 'Auto-entrepreneur' ? 
                {
                    value: status.value,
                    label: status.abbreviation,
                }
            : 
                {
                    value: status.value,
                    label: `${status.abbreviation} : ${status.label}`
                }
        ))
    }

    renderSiegeHotel = () => {
        const {t} = this.props;
        return (
            <div id="headOffice" className="cnt-tab">
                <div className="cnt-form">
                    <div className="row">
                        <div id="headOfficeName" className="col col-4 line-input">
                            <label>{t("Nom du siège social")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-nom-siege${this.state.headOfficeNameError ? ' hasError' : ''}`}
                                    name='headOfficeName'
                                    value={this.state.headOfficeName}
                                    type='text'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeNameError && 
                                <span className="error">{this.state.headOfficeNameError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Adresse du siège social")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-siege${this.state.headOfficeAddressError ? ' hasError' : ''}`}
                                    name='headOfficeAddress'
                                    value={this.state.headOfficeAddress}
                                    type='text'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeAddressError && 
                                <span className="error">{this.state.headOfficeAddressError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Ville du siège social")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-siege${this.state.headOfficeCityError ? ' hasError' : ''}`}
                                    name='headOfficeCity'
                                    value={this.state.headOfficeCity}
                                    type='text'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeCityError && 
                                <span className="error">{this.state.headOfficeCityError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Code postal du siège social")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-siege${this.state.headOfficePostcodeError ? ' hasError' : ''}`}
                                    name='headOfficePostcode'
                                    value={this.state.headOfficePostcode}
                                    type='number'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficePostcodeError && 
                                <span className="error">{this.state.headOfficePostcodeError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Pays du siège social")}</label>
                            <div className="cnt-input">
                                <div className="cnt-input">
                                    <select onChange={this.handleCountryChange}
                                            name="headOfficeCountry"
                                            value={this.state.headOfficeCountry}
                                            className={this.state.headOfficeCountryError ? "hasError" : ""}
                                    >
                                        {this.state.countries.map((country) => <option
                                            value={country.value} key={country.value}>{country.label}</option>)}
                                    </select>
                                </div>
                            </div>
                            {
                                this.state.headOfficeCountryError && 
                                <span className="error">{this.state.headOfficeCountryError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Adresse e-mail du siège social")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-mail-siege${this.state.headOfficeEmailError ? ' hasError' : ''}`}
                                    name='headOfficeEmail'
                                    value={this.state.headOfficeEmail}
                                    type='email'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeEmailError && 
                                <span className="error">{this.state.headOfficeEmailError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Nom du gérant")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-nom-presta${this.state.headOfficeRepresentativeLastNameError ? ' hasError' : ''}`}
                                    name='headOfficeRepresentativeLastName'
                                    value={this.state.headOfficeRepresentativeLastName}
                                    type='text'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativeLastNameError && 
                                <span className="error">{this.state.headOfficeRepresentativeLastNameError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Prénom du gérant")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-prenom-presta${this.state.headOfficeRepresentativeFirstNameError ? ' hasError' : ''}`}
                                    name='headOfficeRepresentativeFirstName'
                                    value={this.state.headOfficeRepresentativeFirstName}
                                    type='text'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativeFirstNameError && 
                                <span className="error">{this.state.headOfficeRepresentativeFirstNameError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Adresse postale du gérant")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-presta${this.state.headOfficeRepresentativeAddressError ? ' hasError' : ''}`}
                                    name='headOfficeRepresentativeAddress'
                                    value={this.state.headOfficeRepresentativeAddress}
                                    type='text'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativeAddressError && 
                                <span className="error">{this.state.headOfficeRepresentativeAddressError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Ville du gérant")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-siege${this.state.headOfficeRepresentativeCityError ? ' hasError' : ''}`}
                                    name='headOfficeRepresentativeCity'
                                    value={this.state.headOfficeRepresentativeCity}
                                    type='text'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativeCityError && 
                                <span className="error">{this.state.headOfficeRepresentativeCityError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Code postal du gérant")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-siege${this.state.headOfficeRepresentativePostcodeError ? ' hasError' : ''}`}
                                    name='headOfficeRepresentativePostcode'
                                    value={this.state.headOfficeRepresentativePostcode}
                                    type='number'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativePostcodeError && 
                                <span className="error">{this.state.headOfficeRepresentativePostcodeError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Pays du gérant")}</label>
                            <div className="cnt-input">
                                <div className="cnt-input">
                                    <select onChange={this.handleCountryChange}
                                            name="headOfficeRepresentativeState"
                                            value={this.state.headOfficeRepresentativeState}
                                            className={this.state.headOfficeRepresentativeStateError === true ? "hasError" : ""}
                                    >
                                        {this.state.countries.map((country) => <option
                                            value={country.value} key={country.value}>{country.label}</option>)}
                                    </select>
                                </div>
                            </div>
                            {
                                this.state.headOfficeRepresentativeStateError && 
                                <span className="error">{this.state.headOfficeRepresentativeStateError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("E-mail du gérant")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-presta${this.state.headOfficeRepresentativeEmailError ? ' hasError' : ''}`}
                                    name='headOfficeRepresentativeEmail'
                                    value={this.state.headOfficeRepresentativeEmail}
                                    type='email'
                                    onChange={this.handleHeadOfficeInputChange}
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativeEmailError && 
                                <span className="error">{this.state.headOfficeRepresentativeEmailError}</span>
                            }
                        </div>
                        <div id="headOfficeRepresentativePhone" className="col col-4 line-input">
                            <label>{t("Numéro de téléphone du gérant")}</label>
                            <div className="PhoneInput">
                                <Select
                                    onChange={(value) => this.handleRepresentativeContrySelect(value)}
                                    options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                                    components={{ SingleValue }}
                                    value={options.find(op => { return op.value === (this.state.representativeCurrentCountry) })}
                                />
                                <PhoneInput
                                    className={`${this.state.headOfficeRepresentativePhoneError ? ' hasError' : ''}`}
                                    country={this.state.representativeCurrentCountry}
                                    international
                                    value={this.state.headOfficeRepresentativePhone ? this.state.headOfficeRepresentativePhone : ""}
                                    onChange={this.handleRepresentativePhoneChange}
                                    name='headOfficeRepresentativePhone'
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativePhoneError && 
                                <span className="error">{this.state.headOfficeRepresentativePhoneError}</span>
                            }
                        </div>
                        <div id="headOfficeRepresentativeBirthDate" className="col col-4 line-input">
                            <label>{t("Date de naissance du gérant")}</label>
                            <div className="select">
                                {/* <input type="date"
                                    className={`input-birth-date-presta${this.state.headOfficeRepresentativeBirthDateError ? ' hasError' : ''}`}
                                    name="headOfficeRepresentativeBirthDate"
                                    value={this.state.headOfficeRepresentativeBirthDate}
                                    onChange={this.handleHeadOfficeInputChange}
                                /> */}
                                <DatePickerInput
                                    disabled={this.state.disabled}
                                    displayFormat='DD/MM/YYYY'
                                    returnFormat='YYYY-MM-DD'
                                    value={this.state.headOfficeRepresentativeBirthDate ? this.state.headOfficeRepresentativeBirthDate : null}
                                    className={`my-react-component input-birth-date-presta${this.state.headOfficeRepresentativeBirthDateError ? ' hasError' : ''}`}
                                    onChange={this.handleHeadOfficeBirthDateChange}
                                    showOnInputClick
                                    placeholder='Sélectionner'
                                    locale='fr'
                                    maxDate={new Date()}
                                    onClear={this.handleHeadOfficeBirthDateChange}
                                    readOnly={true}
                                    name="headOfficeRepresentativeBirthDate"
                                />
                            </div>
                            {
                                this.state.headOfficeRepresentativeBirthDateError && 
                                <span className="error">{this.state.headOfficeRepresentativeBirthDateError}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="cnt-Btn">
                    <span
                        className= {`button ${this.state.headOfficeError ? 'disable': ''}`}  
                        onClick={this.handleSubmitHeadOffice}
                    >
                        {t("Enregistrer")}
                    </span>
                    <div className="clr"></div>
                </div>
            </div>
        )
    }

    renderContactHotel = () => {
        const {t} = this.props;
        return (
            <div id="contact" className="cnt-tab">
                <div className="cnt-form">
                    <div className="row">
                        <div id="contactLastName" className="col col-6 line-input">
                            <label>{t("Nom du contact de l’établissement")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-nom-contact-et${this.state.contactLastNameError ? ' hasError' : ''}`}
                                    name='contactLastName'
                                    value={this.state.contactLastName}
                                    type='text'
                                    onChange={this.handleContactInputChange}
                                />
                            </div>
                            {
                                this.state.contactLastNameError && 
                                <span className="error">{this.state.contactLastNameError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("Prénom du contact de l’établissement")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-prenom-contact-et${this.state.contactFirstNameError ? ' hasError' : ''}`}
                                    name='contactFirstName'
                                    value={this.state.contactFirstName}
                                    type='text'
                                    onChange={this.handleContactInputChange}
                                />
                            </div>
                            {
                                this.state.contactFirstNameError && 
                                <span className="error">{this.state.contactFirstNameError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("Adresse postale du contact")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-contact-et${this.state.contactAddressError ? ' hasError' : ''}`}
                                    name='contactAddress'
                                    value={this.state.contactAddress}
                                    type='text'
                                    onChange={this.handleContactInputChange}
                                />
                            </div>
                            {
                                this.state.contactAddressError && 
                                <span className="error">{this.state.contactAddressError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("Ville du contact")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-contact${this.state.contactCityError ? ' hasError' : ''}`}
                                    name='contactCity'
                                    value={this.state.contactCity}
                                    type='text'
                                    onChange={this.handleContactInputChange}
                                />
                            </div>
                            {
                                this.state.contactCityError && 
                                <span className="error">{this.state.contactCityError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("Code postal du contact")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-adress-contact${this.state.contactPostcodeError ? ' hasError' : ''}`}
                                    name='contactPostcode'
                                    value={this.state.contactPostcode}
                                    type='number'
                                    onChange={this.handleContactInputChange}
                                />
                            </div>
                            {
                                this.state.contactPostcodeError && 
                                <span className="error">{this.state.contactPostcodeError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("Pays du contact")}</label>
                            <div className="cnt-input">
                                <div className="cnt-input">
                                    <select onChange={this.handleCountryChange}
                                            name="contactCountry"
                                            value={this.state.contactCountry}
                                            className={this.state.contactCountryError === true ? "hasError" : ""}
                                    >
                                        {this.state.countries.map((country) => <option
                                            value={country.value} key={country.value}>{country.label}</option>)}
                                    </select>
                                </div>
                            </div>
                            {
                                this.state.contactCountryError && 
                                <span className="error">{this.state.contactCountryError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("Adresse e-mail du contact de l’établissement")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-mail-contact-et${this.state.contactEmailError ? ' hasError' : ''}`}
                                    name='contactEmail'
                                    value={this.state.contactEmail}
                                    type='email'
                                    onChange={this.handleContactInputChange}
                                />
                            </div>
                            {
                                this.state.contactEmailError && 
                                <span className="error">{this.state.contactEmailError}</span>
                            }
                        </div>
                        <div id="contactPhone" className="col col-6 line-input">
                            <label>{t("Numéro de téléphone du contact de l’établissement")}</label>
                            <div className="PhoneInput">
                                <Select
                                    onChange={(value) => this.handleContactContrySelect(value)}
                                    options={options && options.sort((a, b) => ('' + a.label).localeCompare(b.label))}
                                    components={{ SingleValue }}
                                    value={options.find(op => { return op.value === (this.state.contactCurrentCountry) })}
                                />
                                <PhoneInput
                                    className={`${this.state.contactPhoneError ? ' hasError' : ''}`}
                                    country={this.state.contactCurrentCountry}
                                    international
                                    value={this.state.contactPhone ? this.state.contactPhone : ""}
                                    onChange={this.handlecontactPhoneChange}
                                    name='contactPhone'
                                />
                            </div>
                            {
                                this.state.contactPhoneError && 
                                <span className="error">{this.state.contactPhoneError}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="cnt-Btn">
                    <span
                        className= {`button ${this.state.contactError ? 'disable': ''}`}  
                        onClick={this.handleSubmitContact}
                    >
                        {t("Enregistrer")}
                    </span>
                    <div className="clr"></div>
                </div>
            </div>
        )
    }

    renderInfosJuridique = () => {
        const {t} = this.props;
        return (
            <div id="legalInfos" className="cnt-tab">
                <div className="cnt-form">
                    <div className="row">
                        <div id="legalStatus" className="col col-6 line-input">
                            <label>{t("Statut juridique")}</label>
                            <div className="select-down">
                                <Dropdown
                                    className="MyclassName"
                                    options={this.getOptionsStatus()}
                                    onChange={this.handleLegalStatusChange}
                                    value={this.state.legalStatus}
                                    placeholder={t("Sélectionnez une option")}
                                    controlClassName='myControlClassName'
                                    arrowClassName='arrow-down'
                                    arrowClosed={<i className="material-icons arrow-down">keyboard_arrow_down</i>}
                                    arrowOpen={<i className="material-icons arrow-down">keyboard_arrow_up</i>}
                                />
                            </div>
                            {
                                this.state.legalStatusError && 
                                <span className="error">{this.state.legalStatusError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("SIRET")}</label>
                            <div className="cnt-input">
                                <div className="cnt-input">
                                    <input
                                        className={`input-sirett${this.state.legalSiretError ? ' hasError' : ''}`}
                                        name='legalSiret'
                                        value={this.state.legalSiret}
                                        type='number'
                                        onChange={this.handleLegalInfosInfoInputChange}
                                    />
                                    
                                </div>
                            </div>
                            {
                                this.state.legalSiretError && 
                                <span className="error">{this.state.legalSiretError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("URL Key")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-url-key${this.state.legalUrlKeyError ? ' hasError' : ''}`}
                                    name='legalUrlKey'
                                    value={this.state.legalUrlKey}
                                    type='text'
                                    onChange={this.handleLegalInfosInfoInputChange}
                                />
                            </div>
                            {
                                this.state.legalUrlKeyError && 
                                <span className="error">{this.state.legalUrlKeyError}</span>
                            }
                        </div>
                        <div id="legalTvaNumber" className="col col-6 line-input">
                            <label>{t("Numéro de TVA")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-nom-contact-et${this.state.legalTvaNumberError ? ' hasError' : ''}`}
                                    name='legalTvaNumber'
                                    value={this.state.legalTvaNumber}
                                    type='text'
                                    onChange={this.handleLegalInfosInfoInputChange}
                                />
                                
                            </div>
                            {
                                this.state.legalTvaNumberError && 
                                <span className="error">{this.state.legalTvaNumberError}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="cnt-Btn">
                    <span
                        className= {`button ${this.state.legalInformationError ? 'disable': ''}`}  
                        onClick={this.handleSubmitLegalInfos}
                    >
                        {t("Enregistrer")}
                    </span>
                    <div className="clr"></div>
                </div>
            </div>
        )
    }

    renderInfosBank = () => {
        const {t} = this.props;
        return (
            <div id="accountInfos" className="cnt-tab">
                <div className="cnt-form">
                    <div className="row">
                        <div id="accountHolderName" className="col col-5 line-input">
                            <label>{t("Nom du compte titulaire")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-nom-titulaire${this.state.accountHolderNameError ? ' hasError' : ''}`}
                                    name='accountHolderName'
                                    value={this.state.accountHolderName}
                                    type='text'
                                    onChange={this.handleBankInfosInfoInputChange}
                                />
                            </div>
                            {
                                this.state.accountHolderNameError && 
                                <span className="error">{this.state.accountHolderNameError}</span>
                            }
                        </div>
                        <div className="col col-4 line-input">
                            <label>{t("Date de naissance")}</label>
                            <div className="select">
                                {/* <input type="date"
                                    className={`input-date-naissance-titulaire${this.state.accountBirthDateError ? ' hasError' : ''}`}
                                    name="accountBirthDate"
                                    value={this.state.accountBirthDate}
                                    onChange={this.handleBankInfosInfoInputChange}
                                /> */}
                                <DatePickerInput
                                    disabled={this.state.disabled}
                                    displayFormat='DD/MM/YYYY'
                                    returnFormat='YYYY-MM-DD'
                                    value={this.state.accountBirthDate ? this.state.accountBirthDate : null}
                                    className={`my-react-component input-date-naissance-titulaire${this.state.accountBirthDateError ? ' hasError' : ''}`}
                                    onChange={this.handleBankInfosInfoBirthDateChange}
                                    showOnInputClick
                                    placeholder='Sélectionner'
                                    locale='fr'
                                    maxDate={new Date()}
                                    onClear={this.handleBankInfosInfoBirthDateChange}
                                    readOnly={true}
                                    name="accountBirthDate"
                                />
                            </div>
                            {
                                this.state.accountBirthDateError && 
                                <span className="error">{this.state.accountBirthDateError}</span>
                            }
                        </div>
                        <div className="col col-6 line-input">
                            <label>{t("Numéro de l’IBAN")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-num-iban${this.state.accountIbanNbError ? ' hasError' : ''}`}
                                    name='accountIbanNb'
                                    value={this.state.accountIbanNb}
                                    type='text'
                                    onChange={this.handleBankInfosInfoInputChange}
                                />
                                
                            </div>
                            {
                                this.state.accountIbanNbError && 
                                <span className="error">{this.state.accountIbanNbError}</span>
                            }
                        </div>
                        <div className="col col-3 line-input">
                            <label>{t("Numéro du BIC")}</label>
                            <div className="cnt-input">
                                <input
                                    className={`input-bic${this.state.accountBicNbError ? ' hasError' : ''}`}
                                    name='accountBicNb'
                                    value={this.state.accountBicNb}
                                    type='text'
                                    onChange={this.handleBankInfosInfoInputChange}
                                />
                            </div>
                            {
                                this.state.accountBicNbError && 
                                <span className="error">{this.state.accountBicNbError}</span>
                            }
                        </div>
                        <div className="col col-3 line-input with-icon">
                            <label>{t("Statut")}</label>
                            <div id="accountStatus" className={`cnt-input${this.state.accountStatus === "unverified" ? ' erreur' : ''}`}>
                                <input
                                    className={`input-statut${(!this.state.accountStatus || this.state.accountStatus === "unverified") ? ' hasError' : ''}`}
                                    name='accountStatus'
                                    value={(this.state.accountStatus === 'verified' && t("Valide")) || t("Invalide")}
                                    type='text'
                                    onChange={this.handleBankInfosInfoInputChange}
                                    disabled
                                />
                                <span className={`edit ${(!this.state.accountStatus || this.state.accountStatus === "unverified") || (this.state.accountStatus === "verified" && 'valid') || ""}`}>
                                    <i className="material-icons">
                                        {(!this.state.accountStatus || this.state.accountStatus === "unverified") || (this.state.accountStatus === "verified" && 'check_circle') || ""}
                                    </i>
                                </span>
                            </div>
                        </div>
                        <div className="col col-5 line-input" id="nom_contact_ets">
                            <label>{t("Importer un papier d’identité")}</label>
                            <div className="cnt-input cnt-btn-import">
                                <button 
                                    className="btn" 
                                    onClick={this.getIdentityPaper}
                                >
                                    {t("Importer")}
                                </button>
                                <span className={`icon ${((!this.state.accountStatus || this.state.accountStatus === "unverified") && 'error') || (this.state.accountStatus === "verified" && 'valid') || (this.state.accountStatus === "processing" && 'in-progress') || (this.state.accountStatus === "pending" && 'pending') || ""}`}>
                                    <i className="material-icons">
                                        {((!this.state.accountStatus || this.state.accountStatus === "unverified") && 'error') || (this.state.accountStatus === "verified" && 'check_circle') || (this.state.accountStatus === "processing" && 'cached') || (this.state.accountStatus === "pending" && 'hourglass_empty') || ""}
                                    </i>
                                </span>
                            </div>
                            <span>
                                {this.state.identityPaper ? this.state.identityPaper.name : ""}
                            </span>
                        </div>
                        <div id="kbisImport" className="col col-4 line-input">
                            <label>{t("Importer la photocopie du Kbis")}</label>
                            <div className="cnt-input cnt-btn-import">
                                <button 
                                    className="btn" 
                                    onClick={this.getKbisPhotocopy}
                                >
                                    {t("Importer")}
                                </button>
                                <span className={`icon ${((!this.state.accountStatus || this.state.accountStatus === "unverified") && 'error') || (this.state.accountStatus === "verified" && 'valid') || (this.state.accountStatus === "processing" && 'in-progress') || (this.state.accountStatus === "pending" && 'pending') || ""}`}>
                                    <i className="material-icons">
                                    {((!this.state.accountStatus || this.state.accountStatus === "unverified") && 'error') || (this.state.accountStatus === "verified" && 'check_circle') || (this.state.accountStatus === "processing" && 'cached') || (this.state.accountStatus === "pending" && 'hourglass_empty') || ""}
                                    </i>
                                </span>
                            </div>
                            <span>
                                {this.state.kbisPhotocopy ? this.state.kbisPhotocopy.name : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="cnt-Btn">
                    <span
                        className= {`button ${this.state.isValidSaveAcountButton ? '': 'disable'}`}  
                        onClick={this.handleSubmitAccount}
                    >
                        {t("Enregistrer")}
                    </span>
                    <div className="clr"></div>
                </div>
            </div>
        );
    }

    render() {
        const progression = this.state.progression || {};
        //const paymentInformationError =  this.props.hotel && this.props.hotel.stripeStatus && this.props.hotel.stripeStatus.data && this.props.hotel.stripeStatus.data.status === "verified" ? false : true
        const {t} = this.props;
        return (
        <div className="page">
            <div className="bandeaux">
                <div className="wrapper clr">
                    <h1>{t("Gestion administration")}</h1>
                </div>
            </div>
            {(this.state.errorProfil || this.state.errorProgression) && PopupHandler.showError(t("Une erreur est survenue, merci de réessayer ultérieurement."))}
            {this.state.headOfficeError && PopupHandler.showError(t("Il y a une erreur sur vos informations de siège social."), '#headOffice', (e)=>{this.expandCollapse(e, 0)}) }
            {this.state.contactError && PopupHandler.showError(t("Il y a une erreur sur vos informations de contact."), '#contact', (e)=>{this.expandCollapse(e, 1)}) }
            {this.state.legalInformationError && PopupHandler.showError(t("Il y a une erreur sur vos informations juridiques."), '#legalInfos', (e)=>{this.expandCollapse(e, 2)}) }
            {this.state.bankInformationError && PopupHandler.showError(t("Il y a une erreur sur vos informations bancaires."), '#accountInfos', (e)=>{this.expandCollapse(e, 3)}) }
            {this.state.accountStatus !== 'verified' && PopupHandler.showError(t("Il y a une erreur sur vos informations de paiement Stripe."), '#accountStatus', (e)=>{this.expandCollapse(e, 3)}) }
            {this.state.saveSuccess === true && PopupHandler.showSuccess(t("Les modifications ont été enregistrées."))}
            {this.state.saveSuccess === false && PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
            <div className="main monHotel">
                <div className="wrapper clr">
                    <div className="row mainBody">
                        <div className="col col-12">
                            <div className="title_progress">{`${t("Votre profil est complété à")} ${progression.progression || 0}%`}</div>
                            <div className="progress">
                                <Progress percent={progression.progression || 0} status="success" />
                            </div>
                            <div className="infos-detail">
                                <span className="dashicons dashicons-star-filled"><i className="material-icons arrow-down">error_outline</i></span> 
                                {t("Attention, certaines fonctionnalités ne sont pas disponibles si des informations obligatoires ne sont pas renseignées.")}
                            </div>
                        </div>
                    </div>
                    <div className="clr"></div>
                </div>
                <div className="wrapper clr cnt-gestion content-accordeon">
                    <Accordion atomic={false}>
                        <AccordionItem title={t("Siège social de l’hôtel")}>
                            {this.renderSiegeHotel()}
                        </AccordionItem>
                        <AccordionItem title={t("Contact de l’hôtel")}>
                            {this.renderContactHotel()}
                        </AccordionItem>
                        <AccordionItem title={t("Informations juridiques")}>
                            {this.renderInfosJuridique()}
                        </AccordionItem>
                        <AccordionItem title={t("Informations du compte bancaire")}>
                            {this.renderInfosBank()}
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>

        )
    }
}
const mapStateToProps = (state) => ({hotel: state.hotel});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProfilProgression,
        fetchInfos,
        saveHeadOfficeInfos, 
        saveAccountInfos,
        getStripeStatus,
        showLoading, 
        hideLoading
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(GestionAdministration)));

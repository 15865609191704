import config from "../../../../src/config";

const baseURL = config.baseURL;
const TOKEN = 'auth_token';
const EXPIRES_DATE = 'expires_date';
const AUTH_URL = `${baseURL}/rest/V1/login`;
const TOKEN_ERROR = 'TOKEN_ERROR';
const EXPIRES_DATE_ERROR = 'EXPIRES_DATE_ERROR'
const LOGOUT = 'LOGOUT'

export default {
    TOKEN,
    AUTH_URL,
    EXPIRES_DATE,
    TOKEN_ERROR,
    EXPIRES_DATE_ERROR,
    LOGOUT,
}

import { combineReducers } from 'redux';
import crud from './crud'
import theme from './theme';
import hotel from './hotel';
import client from './client';
import commandes from './commandes';
import seller from './seller';
import catalog from './catalog';
import notice from './notice';
import language from './language';
import loading from './loading';
import dashboard from './dashboard';
import authentification from './authentification';

const reducers = combineReducers({
    crud,
    theme,
    hotel,
    client,
    commandes,
    seller,
    catalog,
    notice,
    language,
    loading,
    dashboard,
    authentification
});

export default reducers;

import constants from '../constants/commandes';
import { commandesSA } from "../../applicatif/commandes.sa";

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};

export const fetchOrders = (query) => dispatch =>{
    showLoading(dispatch, constants.FETCH)
    const request = commandesSA.fetchOrders(query);

    const response = dispatch ({
        type: constants.FETCH,
        payload: request,
    });

    response.then(() => {
        dispatch({
            type: constants.ORDERS_QUERY,
            payload: query
        })
    })
};

export const getOrderDetails = (id) => dispatch =>{
    showLoading(dispatch, constants.DETAILS_ORDER)
    const request = commandesSA.getOrderDetails(id)

    dispatch ({
        type: constants.DETAILS_ORDER,
        payload: request,
    });
};

export const getOrderInvoice = (orderNumber) => dispatch =>{
    showLoading(dispatch, constants.ORDER_INVOICE)
    const request = commandesSA.getOrderInvoice(orderNumber)

    dispatch ({
        type: constants.ORDER_INVOICE,
        payload: request,
    });
};

export const cancelOrder = (orderNumber) => dispatch =>{
    showLoading(dispatch, constants.CANCEL_ORDER)
    const request = commandesSA.cancelOrder(orderNumber)

    dispatch ({
        type: constants.CANCEL_ORDER,
        payload: request,
    });
};

export const relaunchSeller = (data) => dispatch =>{
    showLoading(dispatch, constants.RELAUNCH_SELLER)
    const request = commandesSA.relaunchSeller(data)

    dispatch ({
        type: constants.RELAUNCH_SELLER,
        payload: request,
    });
};

export const exportOrders = (orders) => dispatch =>{ 
    showLoading(dispatch, constants.EXPORT)  
     const request = commandesSA.exportOrders(orders)

     dispatch ({
        type: constants.EXPORT,
        payload: request,
    })
};

export const generateInvoice = (id) => dispatch =>{
    showLoading(dispatch, constants.GENERATE_INVOICE)
    const request = commandesSA.generateInvoice(id)

    dispatch ({
        type: constants.GENERATE_INVOICE,
        payload: request,
    })
};

export const refundOrder = (data) => dispatch =>{
    showLoading(dispatch, constants.REFUND_ORDER)
    const request = commandesSA.refundOrder(data)

    dispatch ({
        type: constants.REFUND_ORDER,
        payload: request,
    });
};

export const getCustomerReview = (id) => dispatch =>{
    showLoading(dispatch, constants.CUSTOMER_REVIEW)
    const request = commandesSA.getCustomerReview(id)

    dispatch ({
        type: constants.CUSTOMER_REVIEW,
        payload: request,
    });

};

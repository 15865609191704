import { sellerBDL } from "../business-delegate/seller.bdl";

class SellerSA {
    fetchAll = (query) => {
        return sellerBDL.fetchAll(query);
    };
    
    fetchAllPartnerSellers = (query) => {
        return sellerBDL.fetchAllPartnerSellers(query);
    };
    
    getSellerDetails = (query) => {
        return sellerBDL.getSellerDetails(query);
    };
    
    getSellerInfo = (id) => {
        return sellerBDL.getSellerInfo(id);
    };
    
    sendPartnershipRequest = (data) => {
        return sellerBDL.sendPartnershipRequest(data);
    };
    
    endPartnership = (id) => {
        return sellerBDL.endPartnership(id);
    };
    
    sendInvitation = (data) => {
        return sellerBDL.sendInvitation(data);
    };
    
    fetchPartnershipRequest = () => {
        return sellerBDL.fetchPartnershipRequest();
    };
    
    sendResponse = (data) => {
        return sellerBDL.sendResponse(data);
    };

    getPartnersFilters = () => {
        return sellerBDL.getPartnersFilters();
    };
}

export const sellerSA = new SellerSA();

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {ReactSortable} from "react-sortablejs";
import { initLoading, fetchLanguages } from "../../../services/redux/actions/language";
import { showLoading, hideLoading} from "../../../services/redux/actions/loading";
import { languageSA } from "../../../services/applicatif/language.sa";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import PopupError from "../../components/popup/popup.error";
import { showPopupExtra } from "../../components/popup/popup";

class langues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            languages: []
        };
    }

    componentDidMount() {
        if (this.props.language && this.props.language.data && this.props.language.data.locales && this.props.language.data.locales.length>0) {
            this.setState({
                languages: this.sortLanguages(this.props.language.data.locales),
            });
        }else{
            this.fetchLanguages();
        }
        
    }

    componentDidUpdate(props) {
        const prev = props.language;
        const curr = this.props.language;
        if (!prev) {
            return;
        }
        if (prev.isLoading === true && curr.isLoading === false && curr.data !== undefined) {
            if (!curr.hasError) {
                const languages = curr.data && curr.data.locales ? curr.data.locales : [];
                this.setState({
                    hasError: false, 
                    languages: this.sortLanguages(languages),
                    loading: false
                });
            } else {
                this.setState({
                    hasError: true, 
                    loading: false
                });
            }
        }
    }

    fetchLanguages = () => {
        this.props.initLoading();
        this.props.fetchLanguages();
    }


    handleCheckedChange = (event) => {
        const name = event.target.dataset.id;
        const {t} = this.props;
        this.props.showLoading();
        languageSA.enableDisableLanguage(name)
            .then(response=>{
                this.setState({
                    toogleLanguageSuccess: true,
                })
                this.fetchLanguages();
                this.props.hideLoading();
            })
            .catch(exception=>{
                this.props.hideLoading();
                const error = exception.response && exception.response.data;
                if (error && error.message === 'Default locale cannot be disabled !') {
                    showPopupExtra(<PopupError title={t("Erreur")} message={t("La langue par défaut ne peut pas être désactivée!")}/>)
                    return
                }           
                this.setState({
                    toogleLanguageSuccess: false,
                })
            })
    };

    handleSort = () => {
        const locales = {}
        this.state.languages && this.state.languages.forEach((language, index) =>{
            language.order = index + 1;
            locales[index + 1] = language
        });

        this.props.showLoading();
        languageSA.orderLanguage(locales)
        .then(response=>{
            this.setState({
                reorderLanguageSuccess: true,
            })
            this.fetchLanguages();
            this.props.hideLoading();
        })
        .catch(()=>{
            this.props.hideLoading();
            this.setState({
                reorderLanguageSuccess: false,
            })
        })
    };

    sortLanguages = (languages) => {
        return [].concat(languages).sort((a, b) => {
            return (a.order > b.order) ? 1 : -1;
        });
    };

    renderLanguage = (language) =>{
        const levelArray = [1,2,3,4,5];
        const {t} = this.props;
        return (
            <div className="line-langue">
                <div className="blc-langue">
                    <span className="ico"><i className="material-icons icon-menu">menu</i></span>
                    <label className="checkbox-label" for={language.name}>
                    <input type="checkbox"
                        id = {language.name}
                        data-id={language.name}
                        checked={parseInt(language.is_active)}
                        onChange={this.handleCheckedChange}
                    />
                    <span className="check"></span>
                        {language.name}
                    </label>
                </div>
                {/* <div className="txt-niveau-langue">
                    {t("Votre niveau dans cette langue")}
                </div>
                <div className="blc-niveau-langue">
                    {
                        levelArray.map(element =>(
                            <React.Fragment>
                                {
                                    language.level && element <= language.level &&
                                        <span className="icon-rond"></span>
                                }
                                {
                                    (!language.level || element > language.level) &&
                                        <span className="icon-rond  star-empty"></span>
                                }
                            </React.Fragment>
                        ))
                    }
                </div>
                <div className="remove-langue"><span className="clickable"><i className="material-icons icons-cancel">cancel</i></span></div> */}
            </div>
        )
    }

    render() {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>{t("Listes des langues")}</h1>
                    </div>
                </div>
                <div>
                    {this.state.hasError && PopupHandler.showError(t("Une erreur est survenue, merci de réessayer ultérieurement."))}
                    {(this.state.toogleLanguageSuccess === true || this.state.reorderLanguageSuccess === true) && PopupHandler.showSuccess(t("La modification a été enregistrée."))}
                    {(this.state.toogleLanguageSuccess === false || this.state.reorderLanguageSuccess === false) && PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
                </div>
                <div className="main">
                    <div className="wrapper clr">
                        <div className="cnt-langue">
                            <ReactSortable
                                list={this.state.languages}
                                setList={newState => this.setState({languages: newState})}
                                onEnd={this.handleSort}
                            >
                                {this.state.languages && this.state.languages.map(language => this.renderLanguage(language))}
                            </ReactSortable>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {language: state.language};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ initLoading, fetchLanguages, showLoading, hideLoading}, dispatch);
}

export default translate('translation')(connect(mapStateToProps, mapDispatchToProps)(langues));

import React from 'react';
import {TabProvider, Tab, TabPanel, TabList} from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import 'react-dropdown/style.css';
import {withRouter} from 'react-router-dom';
import {translate} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Partner from "./Partner";
import Seller from "./Seller";
import AffiliateLink from "./AffiliateLink";
import PartnershipRequest from "./PartnershipRequest";
import PopupHandler from '../../../services/error-handlers/popupHandler';
import { getDashboardValues } from "../../../services/redux/actions/dashboard";

class Prestataires extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabId: props.location.tab || 'partenaires',
            partnershipRequestsNb: 0,
        }
    }

    componentDidMount() {
        if (this.props.dashboard && this.props.dashboard.dashboardValues && this.props.dashboard.dashboardValues.data) {
            const data = this.props.dashboard.dashboardValues.data || {}
            this.setState({
                partnershipRequestsNb: data.nb_demandes_partenariat || 0,
            })
        }else{
            this.props.getDashboardValues();
        }
        document.addEventListener('click', this.handleClickOutside);
    }

    componentDidUpdate(prevProps){
        const prev = prevProps.dashboard;
        const props = this.props.dashboard;

        if (prev.dashboardValues && props.dashboardValues && prev.dashboardValues.loading && !props.dashboardValues.loading) {
            if (props.dashboardValues.error) {
                this.setState({
                    dashboardError: true
                })
            } else {
                const data = props.dashboardValues.data|| {}
                this.setState({
                    partnershipRequestsNb: data.nb_demandes_partenariat || 0,
                    dashboardError: false
                })
            }
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className="page">
                <div className="bandeaux">
                    <div className="wrapper clr">
                        <h1>{t("Prestataires")}</h1>
                    </div>
                </div>
                <div className="main-prestataires">
                {(this.state.dashboardError || this.state.getHotelError || this.state.getMyMonthlyIncomeError) && PopupHandler.showError(t("Il y a une erreur sur la récupération des données.")) }
                    <TabProvider defaultTab={this.state.tabId}>
                        <section className="my-tabs">
                            <TabList className="my-tablist">
                                <div className="wrapper">
                                    <Tab tabFor="partenaires">{t("Prestataires partenaire(s)")}</Tab>
                                    <Tab tabFor="tous">{t("Tous les prestataires")}</Tab>
                                    <Tab tabFor="demandes">
                                        {t("Demandes de partenariat")}
                                        <span className="notif-nbr">{this.state.partnershipRequestsNb}</span>
                                    </Tab>
                                    <Tab tabFor="affiliation">{t("Lien d’affiliation")}</Tab>
                                </div>
                            </TabList>
                            <div className="wrapper cnt-tabs">
                                <TabPanel tabId="partenaires">
                                    <Partner/>
                                </TabPanel>
                                <TabPanel tabId="tous">
                                    <Seller/>
                                </TabPanel>
                                <TabPanel tabId="demandes">
                                    <PartnershipRequest/>
                                </TabPanel>
                                <TabPanel tabId="affiliation">
                                    <AffiliateLink/>
                                </TabPanel>
                            </div>
                        </section>
                    </TabProvider>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getDashboardValues
    }, dispatch);
};

export default translate('translation')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Prestataires)));

export const getCommissionLabel = (type, commissionType, allCommissions, t)  => {
    const typeCommission = ((!commissionType || commissionType === true || type === 1 || type === 3) && '1') || commissionType;
    let commissionLabel = "";
    let commissionValueStandard = 0;
    let commissionValueReduced = 0;
    const commissions = allCommissions || {}
    switch (type) {
      case 1:
        commissionValueStandard = commissions.commission_indep ? parseFloat(commissions.commission_indep.taux_mch || 0).toFixed(2) : 0;
        commissionValueReduced = commissions.commission_indep ? parseFloat(commissions.commission_indep.taux_mch || 0).toFixed(2) : 0;
        break
      case 2:
        commissionValueStandard = commissions.commission_societe ? (parseFloat(commissions.commission_societe.taux_mch || 0) + parseFloat(commissions.commission_societe.taux_hotel || 0)).toFixed(2) : 0;
        commissionValueReduced = commissions.commission_societe ? parseFloat(commissions.commission_societe.taux_mch || 0).toFixed(2) : 0;
        break
      case 3:
        commissionValueStandard = commissions.commission_agence ? parseFloat(commissions.commission_agence.taux_agence || 0).toFixed(2) : 0;
        commissionValueReduced =  commissions.commission_agence ? parseFloat(commissions.commission_agence.taux_agence || 0).toFixed(2) : 0;
        break
      case 5:
        commissionValueStandard = commissions.commission_restaurant ? (parseFloat(commissions.commission_restaurant.commission_mch || 0) + parseFloat(commissions.commission_restaurant.commission_hotel || 0)).toFixed(2): 0;
        commissionValueReduced = commissions.commission_restaurant ? parseFloat(commissions.commission_restaurant.commission_mch || 0).toFixed(2) : 0;
        break
      default:
    }
    switch (`${typeCommission}`) {
      case '1':
        commissionLabel = `${t('Frais de plateforme standard')} (${commissionValueStandard}${type === 5 ? '€' : '%' })`;
        break
      case '2':
        commissionLabel = `${t('Frais de plateforme remisé')} (${commissionValueReduced}${type === 5 ? '€' : '%' } ${t('pendant')} ${commissions.free_duration ? commissions.free_duration.month_number || 0 : 0} ${t('mois')})`;
        break
      case '3':
        commissionLabel = `${t('Frais de plateforme réduits')} (${commissionValueReduced}${type === 5 ? '€' : '%' })`;
        break
      default:
    }
    return commissionLabel
}

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translate} from 'react-i18next';
import {saveImageCategory} from "../../../services/redux/actions/catalog";
import PopupHandler from "../../../services/error-handlers/popupHandler";
import ImageSelect from "../../components/image-select/image-select";
import {showPopupExtra, hidePopupExtra} from "../../components/popup/popup";
import PopupError from "../../components/popup/popup.error";

class DetailsCategory extends React.Component {
    constructor(props) {
        super(props);
        this.file = React.createRef();
        const category= props.category;
        let enabled = 0;
        if (props.stripeError) {
            enabled = 0;
        }else{
            const isActive = category.isActive ? parseInt(category.isActive) : 0;
            enabled = (isActive && category.enabled && parseInt(category.enabled)) ? 1 : 0;
        }
        this.state = {
            file: null,
            hasDimensionError: false,
            hasSizeError: false,
            saveSuccess: null,
            category: props.category,
            image: (props.category && props.category.image) || "",
            showInCatalog: enabled
        };
    }

    handleClick = () => {
        showPopupExtra(<ImageSelect onPictureValidate={this.handleSaveImage} currentPictureUrl={this.state.image}/>);
    };

    handleSaveImage = ({croppedFile, croppedImageUrl}) => {
        if (!this.props.category.id && !croppedFile) {
            return;
        }
        this.setState({
            croppedImageUrl
        })
        const data = new FormData();
        data.append('category_id', this.props.category.id);
        data.append('image', croppedFile);

        this.props.saveImageCategory(data);
    };

    handleCheckedChange = (event) => {
        const checked = event.target.checked;
        const showInCatalog = checked ? 1 : 0;
        const {t} =this.props;
        if (this.props.stripeError && checked) {
            const message = t("Les produits ne peuvent pas etre activés tant que le compte de paiement de l'hôtel n'est pas actif.");
            showPopupExtra(<PopupError title={t("Erreur")} message={message}/>)
            return
        }
        this.setState({
            showInCatalog
        })
        
    };

    handleSave = () => {
        if (this.isButtonEnabled()) {
            this.props.checkCategory(this.state.category.id, this.state.showInCatalog === 1 ? true : false)
        }
    };

    isButtonEnabled = () => {
        const enabled = this.state.category.enabled && parseInt(this.state.category.enabled) ? 1 : 0
        return enabled !== this.state.showInCatalog;
    };

    componentDidUpdate(prevProps) {
        const prev = prevProps.catalog;
        const props = this.props.catalog;

        if (!prev) {
            return;
        }
        if (prev.saveImageCategory && props.saveImageCategory &&  prev.saveImageCategory.loading && !props.saveImageCategory.loading ) {
            hidePopupExtra();
            if (props.saveImageCategory.error) {
                this.setState({
                    saveSuccess: false,
                })
                
            } else {
                const category = (props.saveImageCategory.data && props.saveImageCategory.data.updatedCategory) || this.state.category || {};
                this.setState({
                    image: category.image || "",
                    saveSuccess: true,
                })
            }
        }
    }

    render() {
        const category = this.state.category;
        const {t} = this.props
        if (!category) {
            return (<div>{t("Sélectionnez une catégorie pour afficher ses détails.")}</div>)
        } else {
            return (
                <div className="cnt-right">
                    {this.state.saveSuccess === true && PopupHandler.showSuccess(t("Les modifications ont été enregistrées."))}
                    {this.state.saveSuccess === false && PopupHandler.showError(t("L'enregistrement des modifications a échoué."))}
                    <div>
                        <div className="wrapper-imgCategory">
                            <img src={this.state.image || ""} alt=""/>
                        </div>
                        <div className="edit-image align-right pt-1">
                            <span className="clickable" onClick={this.handleClick}  ><span className="ico"><i className="material-icons icon-edit">edit</i></span> {t("Modifier")}</span>
                        </div>
                    </div>
                    <div className="cnt-det-category">
                        <div className="cntDetCategory-flex">
                            <div className="blc-presta">
                                <span className="t-presta"> {t("Prestataires")}</span>
                                <span className="liste-presta">
                                    <span className="item-presta">presta 1</span>
                                    <span className="item-presta">presta 2</span>
                                    <span className="item-presta">presta 3</span>
                                </span>
                                <span className="nomber-presta">+11
                                </span>
                            </div>
                            <h1 className="title-product">{category.name}</h1>
                        </div>
                        <label className="checkbox-label" htmlFor="enabled">
                            <input type="checkbox"
                                checked={this.state.showInCatalog}
                                onChange={this.handleCheckedChange}
                                value = {this.state.showInCatalog}
                                id="enabled"
                            />
                            <span className="check"></span>
                            {t("Afficher dans le catalogue")}
                        </label>
                    </div>
                    <div className="bottom-category">
                        <div className="btn-vue align-center">
                            <button onClick={this.props.showViewCategory} data-id={category.id} className="button">
                                <span className="vue"><i className="material-icons arrow-eye">remove_red_eye</i></span> 
                                {t("Voir en tant que client")}
                            </button>
                        </div>
                        <div className="cnt-Btn align-center">
                            <span 
                                className= {`button ${!this.isButtonEnabled() ? 'disable' : ''}`}
                                onClick={this.handleSave}
                            > 
                                {t("Enregistrer")}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        catalog: state.catalog,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveImageCategory,
    }, dispatch);
};

export default translate('translation')(connect(mapStateToProps, mapDispatchToProps)(DetailsCategory));

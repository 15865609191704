import CommonBDL from './common.bdl';
import constants from '../redux/constants/notice';

class NoticeBDL extends CommonBDL {

    fetchAll = () => {
        return this.get(constants.FETCH_ALL_REPORTED_NOTICES_URL);
    };
    
    keepNotice = (data) => {
        const url = `${constants.KEEP_NOTICE_URL}`;
        return this.put(url, data);
    };
    
    deleteNotice = (data) => {
        const url = `${constants.DELETE_NOTICE_URL}?id=${data.id}&idReport=${data.idReport}&saleOrder=${data.saleOrder}`;
        return this.delete(url);
    };
    
    getUnreadNoticeNb = () => {    
        return this.get(`${constants.GET_UNREAD_NOTICE_NB_URL}`);
    };
    
    initializeUnreadNoticeNb = () => {    
        return this.post(constants.INITIALIZE_UNREAD_NOTICE_NB_URL);
    }
}

export const noticeBDL = new NoticeBDL();

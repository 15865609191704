const origin = window.location.origin;
const codeHotel = (origin.includes('localhost:') && 'base') ||
    (origin.includes('admin-hoteltest-dev.myconciergehotel.com') && 'base') || 
    (origin.includes('admin-hoteltest-qualif.myconciergehotel.com') && 'base') || 
    origin
        .replace(`${window.location.protocol}//`, '')
        .replace(".myconciergehotel.com", "")
        .replace("admin-", "")
        .replace("-qualif", "qualif")
        .replace("-dev", "dev")

export default {
    codeHotel,
    sellerUrl: 'https://seller-api-dev.myconciergehotel.com',
    baseURL: (origin.includes('localhost:') && 'https://magentodev.myconciergehotel.com') || 
        (origin.includes('admin-hoteltest-qualif.myconciergehotel.com') && 'https://magentoqualif.myconciergehotel.com') || 
        (origin.includes('admin-hoteltest-dev.myconciergehotel.com') && 'https://magentodev.myconciergehotel.com') || 
        `https://${codeHotel}.myconciergehotel.com`
};
import CommonBDL from './common.bdl';
import constants from '../redux/constants/commandes';

class CommandesBDL extends CommonBDL {

    fetchOrders = (filter) => {
        const query = filter || {};
        let params = '?';
        Object.keys(query).forEach(key=>{
            params = `${params}${params === '?' ? '' : '&'}${key !== 'direction' && key !== 'offset' && key !== 'limit' && key !== 'orderby' ? `filtre[${key}]` : key}=${query[key] || (key === 'offset' ? 1 : '')}`
        })
        const url = `${constants.URL}${params}`;
        return this.get(url);
    }

    getOrderDetails = id => {
        const url = `${constants.DETAILS_ORDER_URL}?id=${id}`;
        return this.get(url);
    }

    getOrderInvoice = orderNumber => {
        const url = `${constants.ORDER_INVOICE_URL}?number=${orderNumber}`;
        return this.get(url);
    }

    cancelOrder = orderNumber => {
        const url = `${constants.CANCEL_ORDER_URL}`;
        return this.post(url,{increment_id: orderNumber});
    }

    
    relaunchSeller = (data) => {
        const url = `${constants.RELAUNCH_SELLER_URL}`;
        return this.post(url, data);
    };
    
    exportOrders = (orderIds) => {
        let ids = orderIds.join('&ids[]=');
        const url = `${constants.EXPORT_URL}?ids[]=${ids}`;
        return this.get(url);
    };
    
    generateInvoice = (id) => {
        const url = `${constants.GENERATE_INVOICE_URL}?id=${id}`;
        return this.download(url);
    };
    
    refundOrder = (data) => {
        return this.post(constants.REFUND_ORDER_URL, data);
    };
    
    getCustomerReview = (id) => {
        const url = `${constants.CUSTOMER_REVIEW_URL}?id=${id}`;
        return this.get(url);
    }
}

export const commandesBDL = new CommandesBDL();

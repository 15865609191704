import { hotelBDL } from "../business-delegate/hotel.bdl";

class HotelSA {
    
    fetchInfos = () => {    
        return hotelBDL.fetchInfos();
    };
    
    updateInfos = (data) => {    
        return hotelBDL.updateInfos(data);
    };
    
    updateCarousel = (data) => {    
        return hotelBDL.updateCarousel(data);
    };

    getCarousel = () => {
        return hotelBDL.getCarousel();
    };

    getHotelUser =() => {    
        return hotelBDL.getHotelUser();
    };

    saveUserHotel =(data) => {    
        return hotelBDL.saveUserHotel(data);
    };

    getProfilProgression = () => {
        return hotelBDL.getProfilProgression();
    };

    saveHeadOfficeInfos = (data) => {
        return hotelBDL.saveHeadOfficeInfos(data);
    };

    saveContactInfos = (data) => {
        return hotelBDL.saveContactInfos(data);
    };

    saveLegalInfos = (data) => {
        return hotelBDL.saveLegalInfos(data);
    };

    saveAccountInfos = (data) => {
        return hotelBDL.saveAccountInfos(data);
    };

    uploadIdentityPaper = (file) => {
        return hotelBDL.uploadIdentityPaper(file);
    };

    uploadKbisPhotocopy = (file) => {
        return hotelBDL.uploadKbisPhotocopy(file);
    };

    uploadLogo = (data) => {   
        return hotelBDL.uploadLogo(data);
    };

    getLogo = () => {
        return hotelBDL.getLogo();
    };

    getStripeStatus = (infoId) => {
        return hotelBDL.getStripeStatus(infoId);
    };

    saveNotificationStatus = (status) => {
        return hotelBDL.saveNotificationStatus(status);
    };

    getActivityZones = () => {
        return hotelBDL.getActivityZones();
    };

    getDefaultCommision = () => {
        return hotelBDL.getDefaultCommision();
    };

    getPricePerCuterly = () => {
        return hotelBDL.getPricePerCuterly();
    };
}

export const hotelSA = new HotelSA();

import constants from '../constants/theme';
const fields=[
    "update",
    "getColor"
]
const initState = {}

fields.forEach(field =>{
    initState[field] = {};
})

const dashboard = (state = initState, action) => {
    let field = null;
    const newState = {...state};
    const type = action.type !== constants.LOADING ? action.type : action.payload;
    let data = (action.payload && action.payload.data) || null
    switch (type) {
        case constants.LOADING:
            break;
        case constants.UPDATE:
            field = fields[0];
            break;
        case constants.GET_COLOR:
            field = fields[1];
            break;
        default:
            if(action.type !== constants.LOADING){
                return state
            }
    }
    const loading = fields.find(element =>(
        element !== field && newState[element].loading === true
    ));
    if (action.type === constants.LOADING) {
        if (field) {
            newState[field].loading = true;
        }
        newState.loading = true;
        return newState;
    }else if (action.payload && action.payload.status === 200 && action.payload.data) {
        newState[field] = {
            error: false,
            data,
            loading: false
        }
        return { ...newState, loading: !!loading};   
    } else {
        newState[field] = {
            error: true,
            data: null,
            loading: false
        }
        return { ...newState, loading: !!loading};
    }
};

export default dashboard;

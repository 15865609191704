import React from 'react';
import {translate} from 'react-i18next';

import './styles.css'

class Inscription extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <div className="inscription">
        <h1>{t("Inscription")}</h1>
      </div>
    )
  }
}
export default translate('translation')(Inscription);
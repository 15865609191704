import { noticeBDL } from "../business-delegate/notice.bdl";

class NoticeSA {
    fetchAll = () => {
        return noticeBDL.fetchAll();
    };
    
    keepNotice = (data) => {
        return noticeBDL.keepNotice(data);
    };
    
    deleteNotice = (data) => {
        return noticeBDL.deleteNotice(data);
    };
    
    getUnreadNoticeNb = () => {    
        return noticeBDL.getUnreadNoticeNb();
    };
    
    initializeUnreadNoticeNb = () => {    
        return noticeBDL.initializeUnreadNoticeNb();
    }
}

export const noticeSA = new NoticeSA();

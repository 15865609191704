import { hotelSA } from "../../applicatif/hotel.sa";
import constants from '../constants/hotel';

const showLoading = (dispatch, service) => {
    dispatch ({
        type: constants.LOADING,
        payload: service
    })
};

export const fetchInfos = () => dispatch => {
    showLoading(dispatch, constants.FETCH)
    const request = hotelSA.fetchInfos();
    dispatch ({
        type: constants.FETCH,
        payload: request
    })
};


export const updateInfos = (data) => dispatch => {
    showLoading(dispatch, constants.UPDATE)
    const request = hotelSA.updateInfos(data);

    dispatch ({
        type: constants.UPDATE,
        payload: request,
    })
};

export const updateCarousel = (data) => dispatch => {
    showLoading(dispatch, constants.UPDATE_CAROUSEL)
    const request = hotelSA.updateCarousel(data);

    dispatch ({
        type: constants.UPDATE_CAROUSEL,
        payload: request,
    });
};

export const getCarousel = () => dispatch => {
    showLoading(dispatch, constants.GET_CAROUSEL)
    const request = hotelSA.getCarousel();

    dispatch ({
        type: constants.GET_CAROUSEL,
        payload: request,
    });
};

export const getHotelUser = () => dispatch => {
    showLoading(dispatch, constants.GET_HOTEL_USER)
    const request = hotelSA.getHotelUser();
    dispatch ({
        type: constants.GET_HOTEL_USER,
        payload: request,
    });
};

export const uploadKbisPhotocopy = (file) => dispatch =>{
    showLoading(dispatch, constants.UPLOAD_KBIS_PHOTOCOPY)
    const request = hotelSA.uploadKbisPhotocopy(file);

    dispatch ({
        type: constants.UPLOAD_KBIS_PHOTOCOPY,
        payload: request
    })
};

export const uploadIdentityPaper = (file) => dispatch =>{
    showLoading(dispatch, constants.UPLOAD_IDENTITY_PAPER)
    const request = hotelSA.uploadIdentityPaper(file);

    dispatch({
        type: constants.UPLOAD_IDENTITY_PAPER,
        payload: request
    })
};

export const getProfilProgression = () => dispatch =>{
    showLoading(dispatch, constants.GET_PROFIL_PROGRESSION)
    const request = hotelSA.getProfilProgression();

    dispatch ({
        type: constants.GET_PROFIL_PROGRESSION,
        payload: request
    })
};

export const uploadLogo = (data) => dispatch =>{
    showLoading(dispatch, constants.SAVE_LOGO)
    const request = hotelSA.uploadLogo(data);

    dispatch ({
        type: constants.SAVE_LOGO,
        payload: request
    })
};

export const getLogo = () => dispatch =>{
    showLoading(dispatch, constants.GET_LOGO)
    const request = hotelSA.getLogo();

    dispatch ({
        type: constants.GET_LOGO,
        payload: request
    })
};

export const getStripeStatus = (infoId) => dispatch =>{
    showLoading(dispatch, constants.GET_STRIPE_STATUS)
    const request = hotelSA.getStripeStatus(infoId);

    dispatch ({
        type: constants.GET_STRIPE_STATUS,
        payload: request
    })
};

export const saveHeadOfficeInfos = (data) => dispatch =>{
    showLoading(dispatch, constants.SAVE_HEAD_OFFICE_INFOS)
    const request = hotelSA.saveHeadOfficeInfos(data);

    dispatch ({
        type: constants.SAVE_HEAD_OFFICE_INFOS,
        payload: request
    })
};

export const saveAccountInfos = (data) => dispatch =>{
    showLoading(dispatch, constants.SAVE_ACCOUNT_INFOS)
    const request = hotelSA.saveAccountInfos(data);

    dispatch ({
        type: constants.SAVE_ACCOUNT_INFOS,
        payload: request
    })
};

export const getActivityZones = () => dispatch =>{
    showLoading(dispatch, constants.GET_ACTIVITY_ZONE)
    const request = hotelSA.getActivityZones();

    dispatch ({
        type: constants.GET_ACTIVITY_ZONE,
        payload: request
    })
};

export const getDefaultCommision = () => dispatch =>{
    showLoading(dispatch, constants.GET_DEFAULT_COMMISSION)
    const request = hotelSA.getDefaultCommision();

    dispatch ({
        type: constants.GET_DEFAULT_COMMISSION,
        payload: request
    })
};

export const getPricePerCuterly = () => dispatch =>{
    showLoading(dispatch, constants.GET_PRICE_PER_CUTERLY)
    const request = hotelSA.getPricePerCuterly();

    dispatch ({
        type: constants.GET_PRICE_PER_CUTERLY,
        payload: request
    })
};


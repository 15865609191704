import React from 'react';
import {translate} from 'react-i18next';
import { hidePopUp } from "../popup/popup";
import { SELLER_TYPES } from "../../../data/constants/seller.type";
import { getCommissionLabel } from '../../../data/constants/commission.label';


class PartnershipResponse extends React.Component {
    constructor(props) {
        super(props);
        const type = (this.props.request && SELLER_TYPES[this.props.request.type].value) || 1;
        this.state = {
            response: 1,
            message: '',
            type,
            commission: "1",
        };
    }

    handleCommissionChange = (event) => {
        const value = event.target.value;
        this.setState({
            commission: value
        }); 
    };

    handleResponseChange = (event) => {
        this.setState({
            response: event.target.value,
        });
    };

    handleMessageChange = (event) => {
        this.setState({
            message: event.target.value,
        });
    };

    getCommissionToSave = (commissionType) =>{
        const commissionToSave = {};
        switch (this.state.type) {
            case 1:
                commissionToSave.commission_indep = {...this.props.commissions.commission_indep};
                commissionToSave.commission_agence = {...this.props.commissions.commission_agence};
              break
            case 2:
                commissionToSave.commission_societe = {...this.props.commissions.commission_societe};
                if (commissionType === "2") {
                    commissionToSave.free_duration = {...this.props.commissions.free_duration}
                }
              break
            case 3:
                commissionToSave.commission_agence = {...this.props.commissions.commission_agence};
              break
            case 5:
                commissionToSave.commission_restaurant = {...this.props.commissions.commission_restaurant};
                if (commissionType === "2") {
                    commissionToSave.free_duration = {...this.props.commissions.free_duration}
                }
              break
            default:
        }
        return commissionToSave
    }

    handleSendResponse = () => {
        const data = {
            id: this.props.request.id,
            message: this.state.message,
            response: this.state.response === 1 ? true : false,
            commission: parseInt(this.state.commission),
            commissions: this.getCommissionToSave(this.state.commission),
            sellerName: this.props.request.sellerName,
            type_seller: this.state.type,
        };
        this.props.sendResponse({data});
    };

    render() {
        const request = this.props.request;
        const {t} = this.props;
        return (
            <div className="main-modal scrollbar">
                <div>
                    <h4 className="stitle">{t("Type de prestataire")}</h4>
                    <p>{SELLER_TYPES[request.type].label}</p>
                    <h4 className="stitle">{t("Contact du prestataire")}</h4>
                    <p>{request.sellerName}</p>
                    <h4 className="stitle">{t("Distance")}</h4>
                    <p>{request.distance === "Not specified" ? t("Not specified"):  `${parseFloat(request.distance || 0).toFixed(2)}km`}</p>
                    <h4 className="stitle">{t("Message du prestataire")}</h4>
                    <p>{request.message}</p>
                </div>
                <div>
                    <div className="line-input">
                        <label>Votre réponse</label>
                        <div className="select">
                            <select onChange={this.handleResponseChange}
                                    value={this.state.response}>
                                <option value={0}>{t("Refuser")}</option>
                                <option value={1}>{t("Accepter")}</option>
                            </select>
                        </div>
                    </div>
                    {
                        (this.state.response === 1 || this.state.response === "1") &&
                        <div className="line-input">
                        {
                            this.state.type !== 1 && this.state.type !== 3 &&
                            <React.Fragment>
                                <h4 className="stitle">{t("Commission hôtel")}</h4>
                                <div className="btn-check pt-1">
                                {["1", "2", "3"].map(commissionType =>
                                    <div key={commissionType}>
                                        <label className="check">
                                            <input
                                                type="radio"
                                                name="commission"
                                                checked={this.state.commission === commissionType}
                                                onChange={this.handleCommissionChange}
                                                value={commissionType}
                                            />
                                            <span className="radiobtn"></span> {getCommissionLabel(this.state.type, commissionType, this.props.commissions, t )}
                                        </label>
                                    </div>
                                )}
                                </div>
                            </React.Fragment>
                        }
                        </div>
                    }                    
                    <div  className="line-input">
                        <label>{t("Message pour le prestataire")}</label>
                        <textarea value={this.state.message}
                                    onChange={this.handleMessageChange}></textarea>
                    </div>
                </div>
                <div className="cnt-Btn align-right">
                    <button onClick={hidePopUp} className="btn-cancel">{t("Annuler")}</button>
                    <button onClick={this.handleSendResponse} className="button">{t("Enregistrer")}</button>
                </div>
            </div>
        );
    }
}

export default translate('translation')(PartnershipResponse);

import React from 'react';
import Popup from 'reactjs-popup';
import { HashLink } from "react-router-hash-link";
import './style.css';

export default class PopupHandler {
    static showError = (message, url, onClick) => {
        return (
            <div className="infosError">
                <div className="wrapper clr">
                    <span><span className="ico dashicons dashicons-star-filled"><i className="material-icons arrow-down">error</i></span>{message}</span>
                    { url && url.trim() && url !== 'page' &&
                        <HashLink
                            data-url={url}
                            smooth
                            onClick= {onClick}
                            to={url}
                        >
                            voir
                        </HashLink>
                    }
                    { url && url.trim() && url === 'page' && onClick &&
                        <span
                            className= "clickable"
                            onClick= {onClick}
                        >
                            voir
                        </span>
                    }
                </div>
            </div>
        );
    };

    static showPopupError = (title, message) => {
        return (
            <Popup open={true} closeOnDocumentClick={true} closeOnEscape={true}>
                <React.Fragment>
                    <div className="popup-header">
                        <span>
                            <span className="ico dashicons dashicons-star-filled">
                                <i className="material-icons arrow-down">error</i>
                            </span>
                            <span className="title">{title}</span>
                        </span>
                    </div>
                    <div className="popup-body">
                        {message}
                    </div>
                    <div className="popup-footer"/>
                </React.Fragment>
            </Popup>
        );
    };

    static showSuccess = (message, url) => {
        return (
            <div className="infosSuccess">
                <div className="wrapper clr">
                    <span><span className="ico dashicons dashicons-star-filled"><i className="material-icons">check_circle</i></span>{message}</span>
                    <a href={url} hidden={url === "#" || !url}>Voir</a>
                </div>
            </div>
        );
    };
}

import config from "../../../../src/config";

const baseURL = config.baseURL;

export default {
    URL: `${baseURL}/rest/V1/listOrder/`,
    LOADING: 'orders/LOADING',
    FETCH: 'orders/FETCH',
    DETAILS_ORDER: 'orders/DETAILS',
    //A modifier quand le WS est OK
    DETAILS_ORDER_URL: `${baseURL}/rest/V1/OrderDetails`,
    ORDER_INVOICE: 'orders/ORDER_INVOICE',
    ORDER_INVOICE_URL: `${baseURL}/rest/V1/OrderBill`,
    CANCEL_ORDER: 'orders/CANCEL',
    CANCEL_ORDER_URL: `${baseURL}/rest/V1/hotel/cancelOrder`,
    RELAUNCH_SELLER: 'orders/RELAUNCH-SELLER',
    RELAUNCH_SELLER_URL: `${baseURL}/rest/V1/sendRelanceOrder`,
    EXPORT_URL: `${baseURL}/rest/V1/ExportListOrder/`,
    EXPORT: 'orders/EXPORT',
    GENERATE_INVOICE_URL: `${baseURL}/rest/V1/printInvoicePdf`,
    GENERATE_INVOICE: 'orders/GENERATE_INVOICE',
    REFUND_ORDER_URL: `${baseURL}/rest/V1/refundOrder`,
    REFUND_ORDER: 'orders/REFUND',
    CUSTOMER_REVIEW_URL: `${baseURL}/rest/V1/getAvisOrder`,
    CUSTOMER_REVIEW: 'orders/CUSTOMER-REVIEW',
    ORDERS_QUERY: 'orders/ORDERS_QUERY',
}

import constants from '../constants/hotel';
const fields=[
    "hotelInfo",
    "progression",
    "hotelUser",
    "hotelUpdate",
    "updateCarousel",
    "uploadKbis",
    "uploadIdentityPaper",
    "uploadLogo",
    "stripeStatus",
    "saveHeadOfficeInfos",
    "saveAccountInfos",
    "logoImage",
    "carouselImage",
    "activityZones",
    "getDefaultCommision",
    "getPricePerCuterly"
]
const initState = {}

fields.forEach(field =>{
    initState[field] = {};
})

const hotel = (state = initState, action) => {
    let field = null;
    const newState = {...state};
    const type = action.type !== constants.LOADING ? action.type : action.payload;
    let data = (action.payload && action.payload.data) || null
    switch (type) {
        case constants.LOADING:
            break;
        case constants.FETCH:
            field = fields[0];
            break;
        case constants.GET_PROFIL_PROGRESSION:
            field = fields[1];
            break;
        case constants.GET_HOTEL_USER:
            field = fields[2];
            break;
        case constants.UPDATE:
            field = fields[3];
            break;
        case constants.UPDATE_CAROUSEL:
            if (action.payload && action.payload.data && action.payload.status === 200) {
                const image = action.payload.data.carouselPath;
                if (newState.carouselImage && newState.carouselImage.data && newState.carouselImage.data.data) {
                    newState.carouselImage.data.data[0].image = image
                }
            }
            field = fields[4];
            break;
        case constants.UPLOAD_KBIS_PHOTOCOPY:
            field = fields[5];
            break;
        case constants.UPLOAD_IDENTITY_PAPER:
            field = fields[6];
            break;
        case constants.SAVE_LOGO:
            if (action.payload && action.payload.data && action.payload.status === 200) {
                const image = action.payload.data.image;
                if (newState.logoImage && newState.logoImage.data) {
                    newState.logoImage.data.data = image
                }
            }
            field = fields[7];
            break;
        case constants.GET_STRIPE_STATUS:
            field = fields[8];
            break;
        case constants.SAVE_HEAD_OFFICE_INFOS:
            if(action.type !== constants.LOADING){
                let stripeStatus = "unverified"
                if (newState.stripeStatus && newState.stripeStatus.data &&  newState.stripeStatus.data.status) {
                    if (data) {
                        stripeStatus = data.action_stripe && data.action_stripe === "data_send" ? "verified" : "unverified"
                    }
                    newState.stripeStatus.data.status = stripeStatus
                } 
            }
            field = fields[9];
            break;
        case constants.SAVE_ACCOUNT_INFOS:
            if(action.type !== constants.LOADING){
                let stripeStatus = "unverified"
                if (newState.stripeStatus && newState.stripeStatus.data &&  newState.stripeStatus.data.status) {
                    if (data) {
                        stripeStatus = data.action_stripe && data.action_stripe === "data_send" ? "verified" : "unverified"
                    }
                    newState.stripeStatus.data.status = stripeStatus
                } 
            }
            field = fields[10];
            break;
        case constants.GET_LOGO:
            field = fields[11];
            break;
        case constants.GET_CAROUSEL:
            field = fields[12];
            break;
        case constants.GET_ACTIVITY_ZONE:
            field = fields[13];
            break;
        case constants.GET_DEFAULT_COMMISSION:
            field = fields[14];
            break;
        case constants.GET_PRICE_PER_CUTERLY:
            field = fields[15];
            break;
        default:
            if(action.type !== constants.LOADING){
                return state
            }
    }
    const loading = fields.find(element =>(
        element !== field && newState[element].loading === true
    ));
    if (action.type === constants.LOADING) {
        if (field) {
            newState[field].loading = true;
        }
        newState.loading = true;
        return newState;
    }else if (action.payload && action.payload.status === 200 && action.payload.data) {
        newState[field] = {
            error: false,
            data,
            loading: false
        }
        return { ...newState, loading: !!loading};   
    } else {
        newState[field] = {
            error: true,
            data: (action.payload && action.payload.response && action.payload.response.data) || null,
            loading: false
        }
        return { ...newState, loading: !!loading};
    }
};

export default hotel;
